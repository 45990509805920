import React from "react";

export const AppContext = React.createContext<{
    setRefData: React.Dispatch<React.SetStateAction<DashboardData | null>>;
    showSavedList: boolean;
    setShowSavedList: (newBool: boolean) => void;
    savingAnimation: boolean;
    setSavingAnimation: (newBool: boolean) => void;
    showGeneratingList: boolean;
    setShowGeneratingList: (newBool: boolean) => void;
    drawerOpen: boolean;
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handlePropertySelect: (property: DashboardData) => void;
    selectedProperty: DashboardData | null;
    handleToidSelect: (reference: string) => void;
    image: string;
    setImage: React.Dispatch<React.SetStateAction<string>>;
    orgEnv: number;
    organisations: Organisation[];
    dashboardDatas: DashboardData[];
    mapDatas: MapData[];
    dataLoaded: boolean;
    setDataLoaded: (newBol: boolean) => void;
    setDashboardDatas: React.Dispatch<React.SetStateAction<DashboardData[]>>;
    open: boolean;
    setOpen: (newBool: boolean) => void;
    loadingMap: boolean;
    setLoadingMap: (newBool: boolean) => void;
    getPdf: (property: DashboardData | null) => void;
    loadPdfDownloading: boolean;
    setLoadPdfDownloading: (newBol: boolean) => void;
    targetX: string;
    targetY: string;
    isAnimating: string;
    setTargetX: (newVal: string) => void;
    setTargetY: (newVal: string) => void;
    setIsAnimating: (newVal: string) => void;
    iconRef: any;
    handleDownloadFile: (fileName: string, reference: string) => void;
    refData: DashboardData | null;
    setOpenDialogueHandler: (newVal: string) => void;
}>({
    setRefData: () => {},
    showSavedList: false,
    setShowSavedList: () => {},
    savingAnimation: false,
    setSavingAnimation: () => {},
    showGeneratingList: false,
    setShowGeneratingList: () => {},
    drawerOpen: false,
    setDrawerOpen: () => {},
    handlePropertySelect: () => {},
    selectedProperty: null,
    handleToidSelect: () => {},
    image: '',
    setImage: () => {},
    orgEnv: 0,
    organisations: [],
    dashboardDatas: [],
    mapDatas: [],
    dataLoaded: false,
    setDataLoaded: () => {},
    setDashboardDatas: () => {},
    open: false,
    setOpen: () => {},
    loadingMap: false,
    setLoadingMap: () => {},
    getPdf: () => {},
    loadPdfDownloading: false,
    setLoadPdfDownloading: () => {},
    targetX: '',
    targetY: '',
    isAnimating: '',
    setTargetX: () => {},
    setTargetY: () => {},
    setIsAnimating: () => {},
    iconRef: null,
    handleDownloadFile: () => {},
    refData: null,
    setOpenDialogueHandler: () => '',
  });