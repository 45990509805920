import React, { useState } from "react";

import { Typography, Grid , CardActionArea } from "@mui/material";
import Tech_image from "../images/tech_background.png";

export default function InfoSolarProcess() {
  return (
    <>
      <Typography
        variant="h5"
        style={{ marginTop: 20, borderBottom: "1px grey solid" }}
        gutterBottom
        paragraph
      >
        Common processes involved in a solar PV project
      </Typography>
      <Typography variant="body2" paragraph>
      A solar PV project for rooftop commercial buildings typically involves several key processes. Here's an overview of the typical steps involved:  
      </Typography>
      
      <Typography variant="body2" paragraph>
        <ol>
          <li style={{marginBottom:10}}>
          Initial Assessment: The process begins with an initial assessment of the rooftop and the feasibility of installing a solar PV system. Factors such as roof condition, orientation, shading, and structural suitability are evaluated to determine if the site is suitable for solar panel installation. 
          </li>
          <li style={{marginBottom:10}}>
          Energy Audit, System Design and Financial Analysis: An energy audit is conducted to understand the building's energy consumption patterns. Based on this analysis, a system design is created, taking into account factors such as energy requirements, available roof space, solar panel capacity, and system configuration. A financial analysis is performed to evaluate the cost-effectiveness and potential return on investment of the solar PV project.
          </li>
          <li style={{marginBottom:10}}>
          Approvals: Necessary approvals must be obtained from local authorities and utility companies. Most solar PV projects are considered to be permitted development and no planning applications are required, however, lawful developments can be obtains where its not certain permitted development rights apply. For the majority of installations, the grid must provide consent to connect the solar PV to the national grid. Consent is granted by your local DNO and this typically takes three months to receive their results. 
          </li>
          <li style={{marginBottom:10}}>
          Procurement and Installation: Once approvals are obtained, the solar PV equipment is procured, including solar panels, inverters, mounting structures, and other necessary components. Installation teams then deploy and connect the system, ensuring adherence to safety protocols and industry standards. 
          </li>
          <li style={{marginBottom:10}}>
          Grid Connection and Interconnection: The solar PV system is connected to the building's electrical infrastructure and to the local electrical grid.  
          </li>
          <li style={{marginBottom:10}}>
          System Testing and Commissioning: After installation, the solar PV system undergoes comprehensive testing and commissioning to ensure proper functionality and performance. This includes verifying electrical connections, conducting system performance tests, and addressing any issues or adjustments that may arise. The DNO may wish to witness these procedures to ensure compliance with original designs.  
          </li>
          <li style={{marginBottom:10}}>
          Monitoring and Maintenance: Once operational, the solar PV system is monitored for performance and energy generation. Regular maintenance activities, such as panel cleaning, inspection, and potential repairs, are carried out to ensure optimal system efficiency and longevity. 
          </li>
          <li style={{marginBottom:10}}>
          Performance Analysis and Reporting: Ongoing performance analysis and reporting are conducted to evaluate the system's energy generation, efficiency, and overall performance. This helps identify any underperforming areas or potential improvements to maximize energy production and return on investment. 
          </li>
        </ol>
      </Typography>
      <Typography variant="body2" paragraph>
      Throughout the entire process, effective project management and coordination are crucial, involving collaboration among stakeholders, including building owners, solar installers, electrical contractors, and utility providers, to ensure a successful solar PV project for rooftop commercial buildings.
      </Typography>
    </>
  );
}
