import React, { useState } from "react";

import { Typography } from "@mui/material";

export default function SupportPageTrading() {
  return (
    <>
      <Typography
        variant="h6"
        style={{ marginTop: 30, borderBottom: "1px grey solid" }}
        gutterBottom
        paragraph
      >
        Get paid for your power
      </Typography>
      <Typography variant="body1">
        <b>What is surplus electricity</b>
      </Typography>
      <Typography variant="body2" paragraph>
        Surplus electricity refers to the excess energy generated by solar
        panels that is not consumed by the building or property where the system
        is installed. This can occur when the solar panels produce more energy
        than the building requires, typically during periods of high solar
        irradiation or low energy consumption such as during summer months or
        weekends.
      </Typography>
      <Typography variant="body1">
        <b>Expert, friendly service</b>
      </Typography>
      <Typography variant="body2" paragraph>
      We understand what it takes to sell renewable electricity. From support with new connections to advising you of regulatory changes, we help you make the most of your power.
      </Typography>
      <Typography variant="body1">
        <b>Easy account management</b>
      </Typography>
      <Typography variant="body2" paragraph>
      As well as a dedicated account manager, you’ll have an online account where you can access your Invoices, and update your details.
      </Typography>
    </>
  );
}
