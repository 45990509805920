import React, { useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import {
  Avatar,
  IconButton,
  Grid,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardActionArea,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import HelpIcon from "@mui/icons-material/Help";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

import feature_image from "../images/puzzle.png";
import roadmap_image from "../images/roadmap.png";
import timeline_image from "../images/timeline.png";
import landlord_image from "../images/landlord.png";
import growth_image from "../images/growth.png";
import certificate_image from "../images/certificate.png";
import safety_image from "../images/shield.png";
import monitor_visitor from "../images/monitor_visitor.jpg";
import question_image from "../images/question.png";
import teams_image from "../images/Teams_icon.png";
import InfoLandlordArrangement from "../supportDrawer/infoLandlordArrangement";
import InfoCashflowExample from "../supportDrawer/infoCashflowExample";
import InfoSolarProcess from "../supportDrawer/infoSolarProcess";
import InfoMinimiseDisturb from "../supportDrawer/infoMinimiseDisturb";
import InfoQualification from "../supportDrawer/infoQualification";
import InfoHealthSafety from "../supportDrawer/infoHealthSafety";
import InfoFAQ from "../supportDrawer/infoFAQ";

interface IProps {
  propertyData : DashboardData;
  handleDrawerClose_generalInfo: () => void;
}
export default function GeneralProjectInfo(props: IProps) {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [content, setContent] = useState<React.ReactNode>(
    <div
      style={{
        width: "100%",
        // maxWidth: "1200px",
        // margin: "30px",
        // textAlign: "left",
      }}
    >
      {/* <div className="modal_container_faq"> */}
      {/* <div className="modal_close_button">
          <IconButton size="large">
            <HighlightOffIcon />
          </IconButton>
        </div> */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">More Information</Typography>
        <IconButton onClick={props.handleDrawerClose_generalInfo}>
          <CloseIcon />
        </IconButton>
      </div>
      <Typography
        variant="body1"
        gutterBottom
        style={{
          borderBottom: "1px grey solid",
          fontWeight: "700",
          marginTop: 20,
        }}
      >
        Knowledge Base
      </Typography>
      <Typography variant="body2" gutterBottom style={{ marginBottom: 20 }}>
        The installation of a solar PV system could take around 2 weeks, but its
        preparation is much lengthier and determines the quality of the final
        system. We have gathered some of the most commonly asked questions to
        help you understand a solar project.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3}>
          <div
            style={{
              position: "absolute",
              background: "red",
              padding: "5px 10px",
              borderRadius: 8,
              color: "white",
            }}
          >
            Popular
          </div>
          <CardActionArea
            style={{ height: "100%" }}
            onClick={handleClick_landlordArrangement}
          >
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 10,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={landlord_image}
                  style={{
                    maxWidth: "80%",
                    margin: "auto",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>
                Suitable solar arrangements for landlords / lease properties
              </div>
            </div>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <CardActionArea
            style={{ height: "100%" }}
            onClick={handleClick_cashflowExample}
          >
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 10,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={growth_image}
                  style={{
                    maxWidth: "80%",
                    margin: "auto",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>
                Solar investment: a typical cashflow example
              </div>
            </div>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <div
            style={{
              position: "absolute",
              background: "purple",
              padding: "5px 10px",
              borderRadius: 8,
              color: "white",
              zIndex: 10,
            }}
          >
            Essential
          </div>
          <CardActionArea
            style={{ height: "100%" }}
            onClick={handleClick_solarProcess}
          >
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 10,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={timeline_image}
                  style={{
                    maxWidth: "80%",
                    margin: "auto",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>
                Common processes involved in a solar PV project
              </div>
            </div>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <div
            style={{
              position: "absolute",
              background: "red",
              padding: "5px 10px",
              borderRadius: 8,
              color: "white",
              zIndex: 10,
            }}
          >
            Popular
          </div>
          <CardActionArea
            style={{ height: "100%" }}
            onClick={handleClick_minimiseDisturb}
          >
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 10,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={feature_image}
                  style={{
                    maxWidth: "80%",
                    margin: "auto",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>
                How we minimise the disturbance to your business operation
              </div>
            </div>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <CardActionArea
            style={{ height: "100%" }}
            onClick={handleClick_qualificationNeeded}
          >
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 10,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={certificate_image}
                  style={{
                    maxWidth: "80%",
                    margin: "auto",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>
                What qualifications are needed for solar installation
              </div>
            </div>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <CardActionArea
            style={{ height: "100%" }}
            onClick={handleClick_healthSafety}
          >
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 10,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={safety_image}
                  style={{
                    maxWidth: "80%",
                    margin: "auto",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>
                Health and safety
              </div>
            </div>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <CardActionArea style={{ height: "100%" }} onClick={handleClick_faq}>
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 10,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={question_image}
                  style={{
                    maxWidth: "80%",
                    margin: "auto",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>FAQ</div>
            </div>
          </CardActionArea>
        </Grid>
      </Grid>
      {props.propertyData.cta === 2 ? <>
        <Typography
        variant="body1"
        gutterBottom
        style={{
          marginTop: 30,
          marginBottom: 0,
          borderBottom: "1px grey solid",
          fontWeight: "700",
        }}
      >
        Need more help? 
      </Typography>
      <Typography variant="body2">Should you require more information or assistance, please contact Absolar team.</Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <MailOutlineIcon />
          </ListItemIcon>
          <ListItemText
            primary="survey@absolar.co.uk"
            secondary="Email"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <LocalPhoneIcon />
          </ListItemIcon>
          <ListItemText
            primary="02382 680106"
            secondary="Call us"
          />
        </ListItem>
      </List>
      </> : <>
      <Typography
        variant="body1"
        gutterBottom
        style={{
          marginTop: 30,
          marginBottom: 0,
          borderBottom: "1px grey solid",
          fontWeight: "700",
        }}
      >
        What's the next step
      </Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <SquareFootIcon />
          </ListItemIcon>
          <ListItemText
            primary="Schedule a site survey"
            secondary="A site survey helps firm up the costing of a project and gathers information for logistics planning, thereby ensuring a smooth project with minimum disturbance. We also use the site survey to collect health and safety information, which will be presented to you in advance as a Risk Assessment Method Statement"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <LocalPhoneIcon />
          </ListItemIcon>
          <ListItemText
            primary="Contact us"
            secondary="Should you have any questions regarding the solar PV installation, please feel free to contact us and we aim to bring information and clarity to each and every project."
          />
        </ListItem>
      </List>
      </>}
      {/* <Typography
        variant="body1"
        gutterBottom
        style={{ marginTop: 30, fontWeight: "700" }}
      >
        Contact us
      </Typography>
      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <LocalPhoneIcon color="primary" style={{ marginRight: 20 }} />
        <Typography
          color="primary"
          style={{
            textAlign: "center",
            // marginTop: 10,
            marginBottom: 10,
            // fontSize: "20px",
            // color: "#1565c0",
          }}
        >
          0238 268 0106
        </Typography>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Button
            disabled
            fullWidth
            variant="outlined"
            startIcon={<ChatBubbleOutlineIcon />}
            style={{ paddingTop: 20, paddingBottom: 20, textTransform: "none" }}
          >
            Live chat
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant="outlined"
            startIcon={
              <Avatar
                src={teams_image}
                style={{ height: 20, width: 22, borderRadius: 0 }}
              />
            }
            style={{ paddingTop: 20, paddingBottom: 20, textTransform: "none" }}
          >
            Schedule Teams meeting
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<MailOutlineIcon />}
            style={{ paddingTop: 20, paddingBottom: 20, textTransform: "none" }}
          >
            Email us
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<LocalPhoneIcon />}
            style={{ paddingTop: 20, paddingBottom: 20, textTransform: "none" }}
          >
            Call back
          </Button>
        </Grid>
      </Grid> */}
    </div>
  );

  const [previousContent, setPreviousContent] = useState<string[]>([]);
  const [contentChanged, setContentChanged] = useState(false);

  function handleClick_landlordArrangement() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<InfoLandlordArrangement />);
    setContentChanged(true);
  }

  function handleClick_cashflowExample() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<InfoCashflowExample />);
    setContentChanged(true);
  }
  function handleClick_solarProcess() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<InfoSolarProcess />);
    setContentChanged(true);
  }
  function handleClick_minimiseDisturb() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<InfoMinimiseDisturb />);
    setContentChanged(true);
  }
  function handleClick_qualificationNeeded() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<InfoQualification />);
    setContentChanged(true);
  }
  function handleClick_healthSafety() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<InfoHealthSafety />);
    setContentChanged(true);
  }
  function handleClick_faq() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<InfoFAQ />);
    setContentChanged(true);
  }

  function handleGoBack() {
    const previous = previousContent.pop();
    if (previous) {
      setContent(previous);
      setPreviousContent(previousContent);
      setContentChanged(previousContent.length > 0);
    }
  }

  return (
    <>
      {contentChanged && (
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
          style={{ marginTop: 10 }}
        >
          Go Back
        </Button>
      )}
      <div>{content}</div>
    </>
  );
}
