export const calculateCO2Savings = (calculattionSheet: CalculationSheet) => {
  return parseFloat(
    (
      (calculattionSheet.carbonFactor * calculattionSheet.kWhAtYear0) /
      1000
    ).toLocaleString(undefined, {
      maximumFractionDigits: 1,
    })
  );
};
