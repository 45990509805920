const getFeatureCollectionData = async (bbox: string): Promise<FeatureCustomData[]> => {
    try {
        /**
         * //TODO: Get from the backend
         */
        const headers = new Headers();
        headers.append("key", process.env.REACT_APP_OS_API_KEY ? process.env.REACT_APP_OS_API_KEY : '');

        const response = await fetch(
            `https://api.os.uk/features/ngd/ofa/v1/collections/bld-fts-buildingpart-1/items?bbox=${bbox}`,
            {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                redirect: 'follow',
                headers: headers,
            });

        if (!response.ok) {
            console.error('Network response was not ok.');
        }

        const data: FeatureCollectionApiResponse = await response.json();
        const features = data.features;

        return features.map((feature: Feature) =>
            getFeatureCustomData(feature.properties, feature.geometry));

    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

const convertToLatLng = (coordinates: number[][][]): google.maps.LatLng[] => {
    return coordinates[0].map(([lng, lat]) => {
        return new google.maps.LatLng(lat, lng);
    });
};

const getFeatureCustomData = (property: Property, geometry: FeatureGeometry): FeatureCustomData => {

    return {
        osid: property.osid,
        toid: property.toid,
        absoluteheightmaximum: property.absoluteheightmaximum,
        absoluteheightminimum: property.absoluteheightminimum,
        heightconfidencelevel: property.heightconfidencelevel,
        relativeheightmaximum: property.relativeheightmaximum,
        absoluteheightroofbase: property.absoluteheightroofbase,
        relativeheightroofbase: property.relativeheightroofbase,
        coordinates: convertToLatLng(geometry.coordinates),
        oslandusetiera: property.oslandusetiera,
        geometry_area: property.geometry_area,
        oslandusetierb: property.oslandusetierb
    };
};

export default getFeatureCollectionData;
