import { getData } from "../get-data";

export async function getSharedShortLinkPath(reference: string) {
  try {
    const url =
      process.env.REACT_APP_API_URL +
      `/dashboard/shared-short-link-path/?reference=${reference}`;
    const response = await getData(url, true);

    if (response.ok) {
      const data = await response.json(); // Parse the response as JSON
      return data;
    } else {
      console.log("Error:", response.status);
    }
  } catch (error) {
    console.log("Error:", error);
  }
}
