import React, { useContext } from "react";
import {
  Tooltip,
  Button,
  Modal,
  Menu,
  MenuItem,
  FormControl,
  IconButton,
  Grid,
  Typography,
  LinearProgress,
} from "@mui/material";

import { putData } from "../../get-data";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import SendEmailError from "../../infoPages/PopMessage_SendViaEmail";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import financial_report_image from "../../images/financial_report_2.png";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { UserContext } from "../../contexts/UserContext";
import { createPortal } from "react-dom";
import CloseIcon from "@mui/icons-material/Close";

import "./FoundPropertiesProject.css";
import { AppContext } from "../../contexts/AppContext";

export function PropertyRow(props: {
  dashboardData: DashboardData;
  allDashboardDatas: DashboardData[];
  setAllDashboardData: (newData: DashboardData[]) => void;
  key: string;
  isAdmin: boolean;
  valueTab: string;
  liveData: LiveGeneration | null;
  getProjectPage(data: DashboardData): void;
  setMap: (property: DashboardData) => void;
}): JSX.Element {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);

  const [open_business_case, setOpen_business_case] = React.useState(false);
  const handleOpen_business_case = () => setOpen_business_case(true);
  const handleClose_business_case = () => setOpen_business_case(false);

  const [open_send_via_email_error, setOpen_send_via_email_error] =
    React.useState(false);
  const handleOpen_send_via_email_error = () => {
    setOpen_send_via_email_error(true);
    setAnchorEl(null);
  };
  const handleClose_send_via_email_error = () =>
    setOpen_send_via_email_error(false);

  const [stage, setStage] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setStage(event.target.value);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick_like_button = (reference: string, toUpdateTo: boolean) => {
    //check if we need to update the individual data in teh state too?

    var allDashboards = props.allDashboardDatas.map((dashboardEntry) =>
      dashboardEntry.reference === reference
        ? { ...dashboardEntry, saved: toUpdateTo }
        : dashboardEntry
    );
    props.setAllDashboardData(allDashboards);

    props.dashboardData.saved = toUpdateTo;
    saveRow(reference, toUpdateTo);
  };

  const [starStatus, setStarStatus] = React.useState("");

  const handleClick_savingAnimation = () => {
    appContext.setSavingAnimation(true);
    // Perform some async operation here
    setTimeout(() => {
      appContext.setSavingAnimation(false);
      setStarStatus("");
    }, 3000);
  };

  function saveRow(reference: string, saved: boolean) {
    putData(
      process.env.REACT_APP_API_URL +
        "/Dashboard/saved?reference=" +
        reference +
        "&saved=" +
        saved,
      {},
      true,
      userContext.getCredentials
    )
      .then((res) => res.text())
      .catch((e) => alert("save failed"));
  }

  function setRowStage(reference: string, stage: string) {
    putData(
      process.env.REACT_APP_API_URL +
        "/Dashboard/stage?reference=" +
        reference +
        "&stage=" +
        stage,
      {},
      true,
      userContext.getCredentials
    )
      .then((res) => res.text())
      .catch((e) => alert("stage update failed"));
  }

  const handleArchive = (reference: string, isArchived: boolean) => {
    setSelectionValue(isArchived ? "" : "Archived");

    var allDashboards = props.allDashboardDatas.map((dashboardEntry) =>
      dashboardEntry.reference === reference
        ? { ...dashboardEntry, stage: isArchived ? "" : "Archived" }
        : dashboardEntry
    );
    props.setAllDashboardData(allDashboards);

    // props.dashboardData.stage = isArchived ? "" : "Archived";
    setRowStage(reference, isArchived ? "" : "Archived");
  };
  const handleMoveToStage = (reference: string, stage: string) => {
    var allDashboards = [];

    if (stage == "None") {
      allDashboards = props.allDashboardDatas.map((dashboardEntry) =>
        dashboardEntry.reference === reference
          ? { ...dashboardEntry, stage: "" }
          : dashboardEntry
      );
    } else {
      allDashboards = props.allDashboardDatas.map((dashboardEntry) =>
        dashboardEntry.reference === reference
          ? { ...dashboardEntry, stage: stage }
          : dashboardEntry
      );
    }

    props.setAllDashboardData(allDashboards);
    props.dashboardData.stage = stage;
    setRowStage(reference, stage != "None" ? stage : "");

    setSelectionValue(stage);
  };

  const [selectionValue, setSelectionValue] = React.useState(
    props.dashboardData.stage
  );

  const buttonRef = React.useRef<any>(null);
  const handleButtonClick = () => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const iconRect = appContext.iconRef.current.getBoundingClientRect();

    appContext.setTargetX(`${iconRect.left - buttonRect.left}px`);
    appContext.setTargetY(`${iconRect.top - buttonRect.top}px`);
    appContext.setIsAnimating(props.dashboardData.reference);
  };
  const buttonStyle = {
    animation:
      appContext.isAnimating == props.dashboardData.reference
        ? props.dashboardData.saved
          ? "vacuum-animation 2s forwards"
          : "extraction-animation 2s forwards"
        : "none",
    "--target-x": appContext.targetX,
    "--target-y": appContext.targetY,
  };

  const renderAnimatedElement = () => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const top = buttonRect.top + window.scrollY; // Account for page scrolling
    const left = buttonRect.left + window.scrollX; // Account for page scrolling

    return createPortal(
      <IconButton
        style={{
          ...buttonStyle,
          position: "absolute",
          top: `${top}px`,
          left: `${left}px`,
        }}
        onAnimationEnd={() => appContext.setIsAnimating("")}
        disabled
      >
        {props.dashboardData.saved ? <StarIcon /> : <StarOutlineIcon />}
      </IconButton>,
      document.body
    );
  };

  return (
    <tr className="table_row" key={props.key}>
      <td>
        <Tooltip title="Save Property" placement="top-start">
          <>
            <div style={{ position: "relative" }}>
              {appContext.isAnimating === props.dashboardData.reference &&
                renderAnimatedElement()}

              <IconButton
                id={props.dashboardData.reference}
                ref={buttonRef}
                onClick={() => {
                  // handleButtonClick();
                  console.log(
                    props.dashboardData.reference,
                    !props.dashboardData.saved
                  );
                  handleClick_like_button(
                    props.dashboardData.reference,
                    !props.dashboardData.saved
                  );
                  if (props.dashboardData.saved) {
                    //if we click the savwe button, open the corresponding modal
                    setStarStatus("saved");
                  } else {
                    setStarStatus("unsaved");
                  }
                  handleClick_savingAnimation();
                }}
              >
                {props.dashboardData.saved ? <StarIcon /> : <StarOutlineIcon />}
              </IconButton>
            </div>
            {appContext.savingAnimation && (
              <>
                <Modal
                  open={starStatus == "saved"}
                  style={{
                    opacity: 0.8,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "white",
                      padding: 30,
                      opacity: "1 !important",
                    }}
                  >
                    <Typography variant="body1">
                      This address has been added to your saved properties list.
                    </Typography>
                  </div>
                </Modal>
                <Modal
                  open={starStatus == "unsaved"}
                  style={{
                    opacity: 0.8,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "white",
                      padding: 30,
                      opacity: "1 !important",
                    }}
                  >
                    <Typography variant="body1">
                      This address has been removed from your saved properties
                      list.
                    </Typography>
                  </div>
                </Modal>
              </>
            )}
          </>
        </Tooltip>
      </td>

      <td style={{ textAlign: "left" }}>
        <Button
          style={{
            textAlign: "left",
            maxWidth: "-webkit-fill-available",
            display: "block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            padding: 0,
          }}
          onClick={() => {
            props.getProjectPage(props.dashboardData);
            appContext.setShowGeneratingList(false);
          }}
        >
          <Tooltip
            title={
              <Typography variant="body1">
                {props.dashboardData.address}
              </Typography>
            }
          >
            <span>{props.dashboardData.address}</span>
          </Tooltip>
        </Button>
      </td>

      <td style={{ textAlign: "left" }}>
        <span
          style={{
            maxWidth: "-webkit-fill-available",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {props.dashboardData.reference}
        </span>
      </td>
      {/* <TableCell>{props.dashboardData.postcode}</TableCell> */}
      {props.valueTab === "Generating" ? null : (
        <>
          <td style={{ textAlign: "left" }}>
            <Tooltip
              title={
                <Typography variant="body1">
                  {props.dashboardData.class_sub_desc}
                </Typography>
              }
            >
              <span
                className="classification_text"
                style={{
                  maxWidth: "-webkit-fill-available",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                {props.dashboardData.class_sub_desc}
              </span>
            </Tooltip>
          </td>
          <td style={{ textAlign: "left" }}>
            <span
              style={{
                maxWidth: "-webkit-fill-available",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              {props.dashboardData.class_code}
            </span>
          </td>
          <td style={{ textAlign: "right" }}>
            <span
              style={{
                maxWidth: "-webkit-fill-available",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              {props.dashboardData.install_capacity_kw} kW
            </span>
          </td>
          <td style={{ textAlign: "center" }}>
            <span
              style={{
                maxWidth: "-webkit-fill-available",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              {(props.dashboardData.roi ?? 0 * 100).toFixed(0)} %
            </span>
          </td>
          <td style={{ textAlign: "left" }}>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 120 }}
              style={{ border: "none", margin: 0 }}
            >
              {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
              {props.isAdmin ? (
                <Select
                  disableUnderline
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={props.dashboardData.stage ?? "None"}
                  onChange={handleChange}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {[
                    "None",
                    "Recommended",
                    "Approved",
                    "Installing",
                    "Generating",
                    "Archived",
                  ].map((value, index) => (
                    <MenuItem
                      value={value}
                      onClick={() =>
                        handleMoveToStage(props.dashboardData.reference, value)
                      }
                    >
                      {" "}
                      <Typography variant="body2">
                        <em>{value}</em>
                      </Typography>{" "}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography variant="body2">{selectionValue}</Typography>
              )}
            </FormControl>
          </td>
        </>
      )}
      {props.valueTab === "Generating" ? (
        <>
          <td style={{ textAlign: "center", padding: 0 }}>
            {props.dashboardData.address.includes("KENNETH") && props.liveData
              ? props.liveData.kwKdh.toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                }) + " kW"
              : props.dashboardData.address.includes("PHI") && props.liveData
              ? props.liveData?.kwDekra.toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                }) + " kW"
              : ""}
            {(props.dashboardData.address.includes("KENNETH") &&
              props.liveData &&
              props.liveData.kwKdh > 5) ||
            (props.dashboardData.address.includes("PHI") &&
              props.liveData &&
              props.liveData?.kwDekra > 3) ? (
              <LinearProgress />
            ) : null}
          </td>
          <td style={{ textAlign: "center", padding: 0 }}>
            {props.dashboardData.address.includes("KENNETH") && props.liveData
              ? "£ " +
                (props.liveData.kwKdh * 0.32 * 8).toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                })
              : props.dashboardData.address.includes("PHI") && props.liveData
              ? "£ " +
                (props.liveData?.kwDekra * 0.32 * 8).toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                })
              : ""}
          </td>
        </>
      ) : null}

      <td style={{ textAlign: "center", display: "flex" }}>
        <Tooltip
          title={
            props.dashboardData.install_capacity_kw === 0
              ? "This property is not suitable for solar PV"
              : "View assessment results"
          }
          placement="top-start"
        >
          <span>
            <IconButton
              color="primary"
              disabled={props.dashboardData.install_capacity_kw === 0}
              onClick={() => {
                appContext.handlePropertySelect(props.dashboardData);
              }}
            >
              <ViewInArIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={
            props.dashboardData.install_capacity_kw === 0
              ? "This property is not suitable for solar PV"
              : "Download PDF report"
          }
          placement="top-start"
        >
          <span>
            <IconButton
              color="primary"
              onClick={() => appContext.getPdf(props.dashboardData)}
              disabled={props.dashboardData.install_capacity_kw === 0}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Open map view" placement="top-start">
          <IconButton
            color="primary"
            onClick={() => {
              props.setMap(props.dashboardData);
            }}
          >
            <LocationOnIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            props.dashboardData.install_capacity_kw === 0
              ? "This property is not suitable for solar PV"
              : "Request business case"
          }
          placement="top-start"
        >
          <span>
            <IconButton
              color="primary"
              onClick={handleOpen_business_case}
              disabled={props.dashboardData.install_capacity_kw === 0}
            >
              <AssessmentIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Hide" placement="top-start">
          <>
            <IconButton color="primary" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleOpen_send_via_email_error}>
                Send via email
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleArchive(
                    props.dashboardData.reference,
                    props.dashboardData.stage == "Archived"
                  ); //if it's archived, it will be true'
                  handleClose();
                }}
              >
                {props.dashboardData.stage == "Archived"
                  ? "Unarchive"
                  : "Archive"}
              </MenuItem>
            </Menu>
          </>
        </Tooltip>
      </td>
      <Modal
        open={open_send_via_email_error}
        onClose={handleClose_send_via_email_error}
      >
        <SendEmailError />
      </Modal>
      <Modal open={open_business_case} onClose={handleClose_business_case}>
        <div className="modal_container_business_case">
          <Grid container spacing={3} style={{ height: "100%" }}>
            <div
              className="modal_close_button"
              onClick={handleClose_business_case}
            >
              <CloseIcon className="closeIcon" />
            </div>
            <Grid item xs={6} style={{ width: "100%", height: "100%" }}>
              <iframe
                src="https://forms.office.com/Pages/ResponsePage.aspx?id=0gy-4oN0C0eT04_zvRCUdoBRbbKGL0xFnOkiGjX3aA9UNkpYQUo4SjJNRlAxWUdMQk9ENEIxU0o2Qi4u&embed=true"
                width="100%"
                height="100%"
                style={{ overflow: "hidden", border: "none" }}
              >
                Loading…
              </iframe>
            </Grid>
            <Grid item xs={6} style={{ height: "100%", width: "100%" }}>
              <div style={{ height: "100%", width: "100%" }}>
                <img
                  src={financial_report_image}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </tr>
  );
}
