import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "../App.css";
import FoundPropertiesProjectAdmin from "./FoundPropertiesProject_Admin";
import {
  Grid,
  FormControl,
  MenuItem,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableRow,
  TableHead,
  TableCell,
  Modal,
  OutlinedInput,
  Typography,
  TextareaAutosize,
  Input,
  Checkbox,
  ListItemText,
  Tooltip,
  Select,
  Divider,
  InputLabel,
} from "@mui/material";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";

import { UserContext } from "../contexts/UserContext";

import Image_solar_panel from "../images/solar_panels.jpg";
import Image_panel_mount from "../images/panel_mounting.jpg";
import Image_inverter from "../images/solar_inverter.jpg";


interface Param_panels {
  name: string;
  kw: number;
  cost: number;
  length: number;
  width: number;
  depth: number;
}

function createData_panels(
  name: string,
  kw: number,
  cost: number,
  length: number,
  width: number,
  depth: number
): Param_panels {
  return {
    name,
    kw,
    cost,
    length,
    width,
    depth,
  };
}

const panels = [
  createData_panels("PS410M6-18/VH", 410, 80, 1650, 990, 20),
  createData_panels("JAM66S-30-500", 500, 95, 1880, 1140, 24),
  createData_panels("JAM72S-20-460", 460, 85, 1450, 1290, 22),
  createData_panels("JAM54S-30-415", 415, 75, 1650, 990, 15),
];


interface Param_mounts {
  name: string;
  cost: number;
}

function createData_cost(name: string, cost: number): Param_mounts {
  return {
    name,
    cost,
  };
}

const mounts = [
  createData_cost("Flat (ballast", 67),
  createData_cost("Flat (hook)", 140),
  createData_cost("Metal", 40),
  createData_cost("Seam sheet", 50),
  createData_cost("Slate", 45),
  createData_cost("Tile", 40),
  createData_cost("Trapazoidal", 35),
];

interface Param_inverters {
  name: string;
  cost: number;
}


const inverters = [
  createData_cost("5 kW", 600),
  createData_cost("10 kW", 606),
  createData_cost("15 kW", 697),
  createData_cost("20 kW", 1131),
  createData_cost("25 kW", 1319),
  createData_cost("30 kW", 1383),
  createData_cost("36 kW", 1508),
  createData_cost("40 kW", 1634),
  createData_cost("50 kW", 2001),
  createData_cost("60 kW", 2136),
  createData_cost("80 kW", 2839),
  createData_cost("100 kW", 3192),
  createData_cost("110 kW", 3235),
  createData_cost("255 kW", 6538),
];

export default function ParamUpdate() {
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext.userState.loggedIn) {
      userContext.getNotificationsData();
    }
  }, []);

  return (
    <div
      className="main_container"
      style={{
        padding: 20,
        width: "100%",
        height: "100%",
        overflow: "auto",
        textAlign: "left",
      }}
    >
      <Typography variant="h5">Parameter Update</Typography>
      <div style={{ marginTop: 20 }}>
        <Typography variant="h6" marginTop={2}>
          Solar panels
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <div>
              <img
                src={Image_solar_panel}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
          </Grid>
          <Grid item xs={10}>
            <Table style={{ border: "1px #e8e8e8 solid" }} size="small">
              <TableHead>
                <TableRow style={{ fontWeight: "bold", background: "#e8e8e8" }}>
                  <TableCell>Reference / display name</TableCell>
                  <TableCell>kW</TableCell>
                  <TableCell>Unit cost</TableCell>
                  <TableCell>Length (mm)</TableCell>
                  <TableCell>Width (mm)</TableCell>
                  <TableCell>Depth (mm)</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {panels.map((item) => (
                <TableRow>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <TextField size="small" defaultValue={item.kw} />
                  </TableCell>
                  <TableCell>
                    <TextField size="small" defaultValue={item.cost} />
                  </TableCell>
                  <TableCell>
                    <TextField size="small" defaultValue={item.length} />
                  </TableCell>
                  <TableCell>
                    <TextField size="small" defaultValue={item.width} />
                  </TableCell>
                  <TableCell>
                    <TextField size="small" defaultValue={item.depth} />
                  </TableCell>
                  <TableCell>
                    <IconButton size="small">
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <Button startIcon={<AddIcon />}>Add new</Button>
              </TableRow>
            </Table>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 40 }}>
        <Typography variant="h6" marginTop={2}>
          Mounting kits
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <div>
              <img
                src={Image_panel_mount}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
          </Grid>
          <Grid item xs={10}>
            <Table style={{ border: "1px #e8e8e8 solid" }} size="small">
              <TableHead>
                <TableRow style={{ fontWeight: "bold", background: "#e8e8e8" }}>
                  <TableCell>Type of mounting systems</TableCell>
                  <TableCell>Unit cost</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {mounts.map((item) => (
                <TableRow>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <TextField size="small" defaultValue={item.cost} />
                  </TableCell>
                  <TableCell>
                    <IconButton size="small">
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <Button startIcon={<AddIcon />}>Add new</Button>
              </TableRow>
            </Table>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 40 }}>
        <Typography variant="h6" marginTop={2}>
          Inverters
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <div>
              <img
                src={Image_inverter}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
          </Grid>
          <Grid item xs={10} style={{maxHeight:"400px", overflow:"auto"}}>
            <Table style={{ border: "1px #e8e8e8 solid" }} size="small">
              <TableHead>
                <TableRow style={{ fontWeight: "bold", background: "#e8e8e8" }}>
                  <TableCell>Size of inverter</TableCell>
                  <TableCell>Unit cost</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {inverters.map((item) => (
                <TableRow>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <TextField size="small" defaultValue={item.cost} />
                  </TableCell>
                  <TableCell>
                    <IconButton size="small">
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <Button startIcon={<AddIcon />}>Add new</Button>
              </TableRow>
            </Table>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
