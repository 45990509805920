import {Button, FormControl, MenuItem, SelectChangeEvent, TextField} from '@mui/material';
import React, {useContext, useState, useEffect} from 'react';
import styled from "styled-components";
import Select from "@mui/material/Select";
import {getData} from "../../get-data";
import {UserContext} from "../../contexts/UserContext";

const Container = styled.div `
  margin-bottom: 15px;
`;

const Main = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.h3 `
  margin-bottom: -2px;
`;

const Text = styled.p `
`;

const ReferenceContainer = styled.div `
  display: flex;
  padding: 10px 0;
  align-items: center;
`;

interface IProps {
    reference: string | undefined;
    setReference: (value: string) => void;
    organisationId: number;
    setOrganisationId: (newNumber :  number) => void;
}

const OrganisationReferenceID = (props: IProps) => {

    const userContext = useContext(UserContext);

    const [addingNewOrg, setAddingNewOrg] = useState<boolean>(false);
    const [showSelect, setShowSelect] = React.useState(false);
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [dataCount, setDataCount] = useState<number>(0);

    const getOrganisations = () => {
        getData(
            process.env.REACT_APP_API_URL + "/Organisation/all",
            false,
            userContext.getCredentials
        ).then((organisations: Organisation[]) => {
            setOrganisations(organisations);
        });
    }

    const getReferenceCount = () => {
        getData(
            process.env.REACT_APP_API_URL + "/ProjectDelivery/referenceCount",
            true,
            userContext.getCredentials
        )
            .then((response) => response.text())
            .then((dataCount: string) => {
                setDataCount(parseInt(dataCount));
            });
    }

    useEffect(() => {
        getOrganisations();
        getReferenceCount();
    }, []);

    const handleSelectChange = (event: SelectChangeEvent) => {
        const selectedId = Number(event.target.value);
        props.setOrganisationId(selectedId);
        props.setReference(organisations.find((organisation) =>
                organisation.id === selectedId)?.name + "_" + selectedId + "_" + (dataCount + 1).toString());
    }

    return (
        <Container>
            <Main>
                <Header>Organisation ID</Header>
                <Text>Choose an organisation environment</Text>
                <FormControl fullWidth>
                    <Select
                        size="small"
                        open={showSelect}
                        onOpen={() => setShowSelect(true)}
                        onClose={() => setShowSelect(false)}
                        onChange={handleSelectChange}
                    >
                        { organisations.map((organisation) => {
                            return (
                                <MenuItem key={organisation.id} value={organisation.id}>
                                    {organisation.display_name}
                                </MenuItem>
                            );
                        })}
                        <Button
                            fullWidth
                            onClick={() => {
                                setShowSelect(false);
                                setAddingNewOrg(!addingNewOrg);
                            }}
                            style={{ textTransform: "none" }}
                        >
                            { addingNewOrg ? "Cancel" : "Add a new organisation"}
                        </Button>
                    </Select>
                </FormControl>
                {
                    props.organisationId && props.organisationId > 0 && (
                        <>
                            <ReferenceContainer>
                                <Text>Reference Number (set automatically)* :</Text>
                                <TextField
                                    size="small"
                                    value={props.reference}
                                    onChange={(e) =>
                                        props.setReference(e.target.value)
                                    }
                                    style={{
                                        marginLeft: '10px'
                                    }}
                                    disabled
                                />
                            </ReferenceContainer>
                        </>
                    )
                }
            </Main>
        </Container>
    );
};

export default OrganisationReferenceID;
