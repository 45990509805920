import React, { useEffect, useRef, useState } from "react";
import "./RequestAssessment.css";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Logo_blue from "./images/logo_blue.png";

import {
  Grid,
  FormControl,
  MenuItem,
  Button,
  TextField,
  InputLabel,
  InputAdornment,
  Checkbox,
  Tooltip,
  Stepper,
  StepLabel,
  Step,
  StepContent,
  Typography,
  Paper,
  Box,
  Modal,
  Hidden,
  IconButton,
  Link,
  ToggleButtonGroup,
  StepButton,
  Skeleton,
} from "@mui/material";

import MuiToggleButton from "@mui/material/ToggleButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import InfoIcon from "@mui/icons-material/Info";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";


import { styled } from "@mui/material/styles";

const ToggleButton = styled(MuiToggleButton)({
  color: "black",
  fontWeight: "bold",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#1876d2",
  },
});


interface IProps {
  userName: string;
  userCompany: string;
  userEmail: string;
}

export default function RequestAssessment(props: IProps) {


  const [open_termsOne, setOpen_termsOne] = React.useState(false);
  const handleOpen_termsOne = () => setOpen_termsOne(true);
  const handleClose_termsOne = () => setOpen_termsOne(false);

  const [open_termsTwo, setOpen_termsTwo] = React.useState(false);
  const handleOpen_termsTwo = () => setOpen_termsTwo(true);
  const handleClose_termsTwo = () => setOpen_termsTwo(false);

  const [open_multipleBuildings, setOpen_multipleBuildings] =
    React.useState(false);
  const handleOpen_multipleBuildings = () => setOpen_multipleBuildings(true);
  const handleClose_multipleBuildings = () => setOpen_multipleBuildings(false);

  const [choiceHH, setChoiceHH] = React.useState("");
  const [buildingMaterial, setBuildingMaterial] = React.useState("");
  const [skylights, setSkylights] = React.useState("");

  const [activeStep, setActiveStep] = React.useState(0);

  const [error, setError] = React.useState<string>("");
  const addressRef = React.useRef(document.createElement("input"));
  const [searchOutOfFocus, setSearchOutOfFocus] = React.useState(0);
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] =
    React.useState(false);

  const [answers, setAnswers] = useState<{ [question: string]: string }>({});

  const [addressFieldValue, setAddressFieldValue] = React.useState(
    getAnswer("Address of the building to survey", answers)
  );

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [isSubmissionError, setIsSubmissionError] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState("");
  function formatAnswersEmail() {
    let text = "";
    Object.keys(answers).forEach(
      (x) => (text = text + x + ": " + answers[x] + "%0d%0a ")
    );
    return text;
  }
  function trackClick(buttonClicked: string) {
    // code to track the click event
    gtag("event", buttonClicked, {
      event_category: "Button",
      event_label: buttonClicked,
    });
  }
  const handleNext = (index: number) => {
    var newStep = activeStep + 1;
    console.log(newStep, index);
    if (index === steps.length - 1) {
      setSubmitLoading(true);
      submitData();
    } else {
      setActiveStep(newStep);
    }

    //if a button is clicked, make sure that the address is memorised
    if (index == 0) {
      updateAnswers("Name of person submitting request",props.userName,"");
    updateAnswers("Company name", props.userCompany, "");
    updateAnswers("Email", props.userEmail, "");
      updateAnswers(
        "Address of the building to survey",
        addressFieldValue,
        error
      );
      //setAddressFieldValue(addressRef.current.value);
    }
    checkStep(newStep, answers, error);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    //if a button is clicked, make sure that the address is memorised
    if (activeStep == 0) {
      updateAnswers(
        "Address of the building to survey",
        addressRef.current.value,
        error
      );
      setAddressFieldValue(addressRef.current.value);
    }
    setButtonEnabled(true); //if you were to go back, surely the previous section is completed
  };


  function handleCheckTermsAndConditions(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setTermsAndConditionsChecked(event.target.checked);
    updateAnswers(
      "Read terms and conditions.",
      event.target.checked == true ? "true" : "",
      error
    );

    checkStep(activeStep, answers, error);
  }

  function handleBackToResubmit() {
    setSubmitSuccess(false);
    setAnswers({});
    setBuildingMaterial("");
    setSkylights("");
    setTermsAndConditionsChecked(false);
    setButtonEnabled(false);
    setChoiceHH("");
    setAddressFieldValue("");

    setActiveStep(0);
  }

  function updateAnswers(question: string, answer: string, err: string) {
    const newAnswers = answers;
    newAnswers[question] = answer;
    setAnswers(newAnswers);
    checkStep(activeStep, newAnswers, err);
  }

  function getAnswer(
    question: string,
    customAnswers?: { [question: string]: string }
  ) {
    let usingAnswers = answers;
    if (customAnswers) {
      usingAnswers = customAnswers;
    }
    if (Object.keys(usingAnswers).indexOf(question) != -1) {
      return usingAnswers[question];
    } else {
      return "";
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };
  function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  async function submitData() {
    const url = process.env.REACT_APP_API_URL+"/Form";
    const formData = new FormData();
    // const arrayBuffer = await readFileAsArrayBuffer(file);
    // const octetStream = new Uint8Array(arrayBuffer);

    // formData.append("file", new Blob([octetStream]), file.name);
    if (file) {
      formData.append("file", file, file.name);
    }
    //console.log("SUCCESS 1", answers);
    formData.append("answers", JSON.stringify(answers));
    formData.append("companyName", getAnswer("Company name"));
    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      const result = await response.text();
      if (result == "0") {
        //console.log("SUCCESS 2");
        setSubmitSuccess(true);
      } else {
        alert("Unable to submit form. Please contact Absolar for assistance. Email: survey@absolar.co.uk, or 02382 680106");
      }
    } catch (e: any) {
      //console.log("UNSUCCESS 1", answers);
      const errCode = e.response ? e.response.status : "Unknown error";
      setErrorCode(errCode);
      setIsSubmissionError(true);

      // console.log("ERROR!", e);
      // alert(
      //   "Please check if you have completed all questions in this form and submit again. " + e
      // );
    } finally {
      //console.log("SUCCESS 3");
      setSubmitLoading(false);
    }
  }
  function checkStep(
    index: number,
    answers: { [question: string]: string },
    errorFromParam: string
  ) {
    //OBSERVATION:
    // I added a check if the country is from the united kingdom, and only then to proceed with the query.
    // This is done by checking if there is a string in the address which says "UNITED KINGDOM" (which is produced by royal mail API)
    // This is done when the user automatically retrieves an address.
    // However, when the user decides to write their address by themselves, they could either
    // a) not input the country
    // b) input something different like "UK"
    // And therefore the error would remain.
    // Thus I commented that check and we can discuss further

    if (index == 0) {
      if (
        getAnswer("Address of the building to survey", answers) != "" &&
        // getAnswer("Address of the building to survey", answers).includes("UNITED KINGDOM")
        // &&
        getAnswer(
          "Please select the building roof material (if known)",
          answers
        ) != "" &&
        getAnswer(
          "Please select the building roof material (if known)",
          answers
        ) != "Other (please specify)" &&
        getAnswer(
          "If the building has skylights, do you wish to retain them?",
          answers
        ) != ""
      ) {
        // var errors = error;
        // errors = errors.replace(
        //   "Please provide an address from the United Kingdom.",
        //   ""
        // );
        // setError(errors);
        setButtonEnabled(true);
        // } else if (
        //   getAnswer("Address of the building to survey", answers) != ""
        //   &&
        //   !getAnswer("Address of the building to survey", answers).includes("UNITED KINGDOM")) {
        //     setButtonEnabled(false);
        //     if (!error.includes("Please provide an address from the United Kingdom.")) {
        //        var errors = error;
        //        setError(errors + "Please provide an address from the United Kingdom.");
        //     }
      } else {
        setButtonEnabled(false);
      }
    } else if (index == 1) {
      if (
        errorFromParam == "" &&
        getAnswer(
          "Can you provide us with half-hourly consumption data?",
          answers
        ) != ""
      ) {
        if (
          getAnswer(
            "Can you provide us with half-hourly consumption data?",
            answers
          ) == "No"
        ) {
          setButtonEnabled(true);
        } else {
          if (file) {
            setButtonEnabled(true);
          } else {
            setButtonEnabled(false);
          }
        }
      } else {
        setButtonEnabled(false);
      }
    } else {
      if (getAnswer("Read terms and conditions.", answers) != "") {
        setButtonEnabled(true);
      } else {
        setButtonEnabled(false);
      }
    }
  }

  useEffect(() => {
    // Find the rendered React element
    const reactElement = document.getElementById("addressing");

    if (reactElement) {
      // Create a new script element
      const script = document.createElement("script");

      // Set the script source to the URL of your script
      script.src =
        "https://api.addressnow.co.uk/js/addressnow-2.20.min.js?key=et42-ce83-yj58-de61";

      // Append the script element to the document
      document.head.appendChild(script);
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep == 2 && file) {
      var halfHour = getAnswer(
        "Can you provide us with half-hourly consumption data?",
        answers
      );
      if (halfHour == "Yes") {
        //when file changed, apply checksteps again but also check for errors
        var isFileCorrectType =
          file?.name.includes(".xls") ||
          file?.name.includes(".xlsx") ||
          file?.name.includes(".csv");
        var newError = error;

        if (!isFileCorrectType) {
          newError =
            newError +
            "Please provide a file in CSV/Excel format (i.e. .csv .xls .xlsx). ";
        } else {
          newError = newError.replace(
            "Please provide a file in CSV/Excel format (i.e. .csv .xls .xlsx). ",
            ""
          );
        }
        setError(newError);
        checkStep(activeStep, answers, newError);
      } else {
        //half hour answer is "no"
        var newerror = error;
        newerror = newerror.replace(
          "Please provide a file in CSV/Excel format (i.e. .csv .xls .xlsx). ",
          ""
        );
        setError(newerror);
        checkStep(activeStep, answers, newerror);
      }
    }
  }, [
    file,
    getAnswer("Can you provide us with half-hourly consumption data?", answers),
  ]);
  
  useEffect(() => {
    if (activeStep == 0){
    const interval = setInterval(() => {
      
        const newAddress = addressRef.current?.value.replace(/\n/g, ", ").replace(/\, \,/g, ",");
        if (newAddress !== addressFieldValue) {
          var addressToUpdate = newAddress || '';
          const stringAddress = addressToUpdate.replace(/\n/g, ", ").replace(/\, \,/g, ",");
          setAddressFieldValue(stringAddress);
          updateAnswers("Address of the building to survey", stringAddress, error);
        }
     
    }, 1500);
    return () => clearInterval(interval);
  }
    
  }, [activeStep, addressFieldValue]);

  const steps = [
    {
      label: "About the Building",
      description: (
        <>
        <div className="question_section">
            <div className="question_main_label">Company name</div>
            <TextField
              defaultValue={getAnswer("Company name")}
              size="small"
              className="question_textfield"
              onChange={(e) => {
                updateAnswers("Company name", e.target.value, error);
              }}
            />
          </div>
          <div className="question_section">
            <div className="question_main_label">
              Address of the building to survey
            </div>
            <div id="addressing">
           
              <TextField
                placeholder="Search for address automatically..."
                id="search"
                rows={1}
                size="small"
                className="question_textfield"
                 
               
              />
               <TextField
                inputRef={addressRef}
                placeholder={
                  "Alternatively, please write the full address here..."
                }
                id="address"
                // defaultValue={getAnswer("Address of the building to survey")}
                value={addressFieldValue}
                size="small"
                className="question_textfield"
                // rows={3}
                maxRows={2}
                // minRows={3}
                multiline
                style={{ marginTop: 5 }}
                onChange={(e) => {
                  setAddressFieldValue(e.target.value);
                  updateAnswers(
                    "Address of the building to survey",
                    e.target.value,
                    error
                  );
                }}
              />
              
            </div>
          </div>


          

          <div className="question_section">
            <div className="question_main_label">
              Please select the building roof material (if known)
            </div>
            <FormControl fullWidth>
              <Select
                value={buildingMaterial}
                size="small"
                onChange={(e) => {
                  setBuildingMaterial(e.target.value);

                  updateAnswers(
                    "Please select the building roof material (if known)",
                    e.target.value as string,
                    error
                  );
                }}
              >
                <MenuItem value="Tile">Tile</MenuItem>
                <MenuItem value="Slate">Slate</MenuItem>
                <MenuItem value="Corrugated">Corrugated</MenuItem>
                <MenuItem value="Trapezoidal">Trapezoidal</MenuItem>
                <MenuItem value="Standing seam">Standing seam</MenuItem>
                <MenuItem value="Flat (any material)">
                  Flat (any material)
                </MenuItem>
                <MenuItem value="Sloped (any material)">
                  Sloped (any material)
                </MenuItem>
                <MenuItem value="Other (please specify)">
                  Other (please specify)
                </MenuItem>
                <MenuItem value="I don't know">I don't know</MenuItem>
              </Select>
            </FormControl>
            <TextField
              placeholder={"Please specify here..."}
              style={{
                display:
                  buildingMaterial == "Other (please specify)" ? "" : "none",
              }}
              size="small"
              className="question_textfield"
              onChange={(e) => {
                updateAnswers(
                  "Please select the building roof material (if known)",
                  e.target.value,
                  error
                );
              }}
            />
          </div>
          <div className="question_section">
            <div className="question_main_label">
              If the building has skylights, do you wish to retain them?
              <Tooltip
                title={
                  <Typography style={{ fontSize: "16px" }}>
                    If the building doesn't have skylights or you're not sure,
                    select N/A.
                  </Typography>
                }
              >
                <InfoIcon style={{ fontSize: "20px", color: "#0288d1" }} />
              </Tooltip>
            </div>

            <ToggleButtonGroup
              color="standard"
              sx={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                gridGap: "20px",
                padding: "0px",
              }}
              value={skylights}
              exclusive
              onChange={(e, value) => {
                setSkylights(value);

                updateAnswers(
                  "If the building has skylights, do you wish to retain them?",
                  value as string,
                  error
                );
              }}
              aria-label="Platform"
            >
              <ToggleButton
                sx={{ m: 0, p: 2 }}
                style={{
                  height: "30px",
                  borderRadius: "inherit",
                  borderColor: "lightGray",
                  fontWeight: "inherit",
                }}
                value="Yes"
              >
                Yes
              </ToggleButton>
              <ToggleButton
                sx={{ m: 0, p: 2 }}
                style={{
                  height: "30px",
                  borderRadius: "inherit",
                  borderColor: "lightGray",
                  fontWeight: "inherit",
                }}
                value="No"
              >
                No
              </ToggleButton>
              <ToggleButton
                sx={{ m: 0, p: 2 }}
                style={{
                  height: "30px",
                  borderRadius: "inherit",
                  borderColor: "lightGray",
                  fontWeight: "inherit",
                }}
                value="N/A"
              >
                N/A
              </ToggleButton>
            </ToggleButtonGroup>

          </div>
        </>
      ),
    },
    {
      label: "About your Electricity Usage",
      description: (
        <>
          {/* <p className="form_h3_subtitle">
            Any information you can provide here will make the savings assessment
            more accurate. Please skip if you don't know the answers.
          </p> */}
          <div className="question_section">
            <div className="question_main_label_non_comp">
              What is the current electricity rate at the building?{" "}
              <Tooltip
                title={
                  <Typography style={{ fontSize: "16px" }}>
                    Please provide the day rate if you are on day/night tariffs.
                    If you don't know, we will assume the rate to be 0.32.
                  </Typography>
                }
              >
                <InfoIcon style={{ fontSize: "20px", color: "#0288d1" }} />
              </Tooltip>
            </div>
            <TextField
              defaultValue={getAnswer(
                "What is the current electricity rate at the building?"
              )}
              // placeholder="Not sure? Assume the rate to be 0.32"
              size="small"
              className="question_textfield"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£ </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">/ kWh</InputAdornment>
                ),
              }}
              onChange={(e) => {
                updateAnswers(
                  "What is the current electricity rate at the building?",
                  e.target.value,
                  error
                );
              }}
            />
          </div>
          <div className="question_main_label_non_comp">
            Please provide your typical annual electricity usage in £ or kWh
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <div className="question_section">
                <TextField
                  defaultValue={getAnswer(
                    "Please provide your typical annual electricity usage in £"
                  )}
                  size="small"
                  className="question_textfield"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    var newerror = error;
                    if (isNaN(Number(e.target.value))) {
                      if (
                        !error.includes(
                          "Please provide a valid number for the annual electricity usage in £. "
                        )
                      ) {
                        var errors = error;
                        newerror =
                          errors +
                          "Please provide a valid number for the annual electricity usage in £. ";
                        setError(newerror);
                      }
                    } else {
                      var errors = error;
                      newerror = errors.replace(
                        "Please provide a valid number for the annual electricity usage in £. ",
                        ""
                      );

                      setError(newerror);
                    }

                    updateAnswers(
                      "Please provide your typical annual electricity usage in £",
                      e.target.value,
                      newerror
                    );
                  }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>or</span>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className="question_section">
                <TextField
                  defaultValue={getAnswer(
                    "Please provide your typical annual electricity usage in kWh"
                  )}
                  size="small"
                  className="question_textfield"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kWh</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    var newerror = error;
                    if (isNaN(Number(e.target.value))) {
                      if (
                        !error.includes(
                          "Please provide a valid number for the annual electricity usage in kWh. "
                        )
                      ) {
                        var errors = error;
                        newerror =
                          errors +
                          "Please provide a valid number for the annual electricity usage in kWh. ";
                        setError(newerror);
                      }
                    } else {
                      var errors = error;
                      newerror = errors.replace(
                        "Please provide a valid number for the annual electricity usage in kWh. ",
                        ""
                      );

                      setError(newerror);
                    }

                    updateAnswers(
                      "Please provide your typical annual electricity usage in kWh",
                      e.target.value,
                      newerror
                    );
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <div className="question_section">
            <div className="question_main_label">
              Can you provide us with half-hourly consumption data?{" "}
              <Tooltip
                title={
                  <Typography style={{ fontSize: "16px" }}>
                    This data is normally available to download from your energy
                    supplier if you have a half-hourly meter.
                  </Typography>
                }
              >
                <InfoIcon style={{ fontSize: "20px", color: "#0288d1" }} />
              </Tooltip>
            </div>

            <ToggleButtonGroup
              sx={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                gridGap: "20px",
                padding: "0px",
              }}
              value={choiceHH}
              exclusive
              onChange={(e, value) => {
                setChoiceHH(value);
                updateAnswers(
                  "Can you provide us with half-hourly consumption data?",
                  value,
                  error
                );
              }}
              aria-label="Platform"
            >
              <ToggleButton
                sx={{ m: 0, p: 2 }}
                style={{
                  height: "30px",
                  borderRadius: "inherit",
                  borderColor: "lightGray",
                  fontWeight: "inherit",
                }}
                value="Yes"
              >
                Yes
              </ToggleButton>
              <ToggleButton
                sx={{ m: 0, p: 2 }}
                style={{
                  height: "30px",
                  borderRadius: "inherit",
                  borderColor: "lightGray",
                  fontWeight: "inherit",
                }}
                value="No"
              >
                No
              </ToggleButton>
            </ToggleButtonGroup>

            {/* <FormControl fullWidth>
              <Select size="small" value={choiceHH} onChange={handleChange}>
                <MenuItem value={"yes"}>Yes</MenuItem>
                <MenuItem value={"no"}>No</MenuItem>
              </Select>
            </FormControl> */}
          </div>

          {choiceHH == "Yes" ? (
            <div className="question_section">
              <div className="question_main_label">
                Please upload data for the last 12 months in CSV/Excel format
              </div>
              <Button
                variant="outlined"
                id="upload_file"
                component="label"
                onClick={() => {
                  // trackClick("Uploaded File");
                }}
              >
                {file ? (
                  <TaskAltIcon style={{ fontSize: "50px" }} />
                ) : (
                  <CloudUploadOutlinedIcon style={{ fontSize: "50px" }} />
                )}
                {file ? file.name + " selected" : null}
                <br />
                {file ? (
                  <div style={{ color: "darkgrey" }}>
                    Click again to upload a different file
                  </div>
                ) : (
                  "Upload a file"
                )}
                <input type="file" hidden onChange={handleFileChange} />
              </Button>
            </div>
          ) : choiceHH == "No" ? (
            <>
              <div className="question_section">
                <div className="question_main_label_non_comp">
                  What are the typical days and hours of the buildings main
                  operation?{" "}
                  <Tooltip
                    title={
                      <Typography style={{ fontSize: "16px" }}>
                        This will help us profile your electricity usage.
                      </Typography>
                    }
                  >
                    <InfoIcon style={{ fontSize: "20px", color: "#0288d1" }} />
                  </Tooltip>
                </div>
                <TextField
                  defaultValue={getAnswer(
                    "What are the typical days and hours of the buildings main operation?"
                  )}
                  // size="small"
                  multiline
                  rows={2}
                  className="question_textfield_multiline"
                  onChange={(e) => {
                    updateAnswers(
                      "What are the typical days and hours of the buildings main operation?",
                      e.target.value,
                      error
                    );
                  }}
                />
                <p className="question_help_text"></p>
              </div>
            </>
          ) : null}
        </>
      ),
    },
    {
      label: "Submission",
      description: (
        <>
          <div className="question_section">
            <div className="question_main_label_non_comp">
              Anything else we should know?
            </div>
            <TextField
              defaultValue={getAnswer("Anything else we should know?")}
              // size="small"
              multiline
              rows={3}
              className="question_textfield_multiline"
              onChange={(e) => {
                updateAnswers(
                  "Anything else we should know?",
                  e.target.value,
                  error
                );
              }}
            />
            <p className="question_help_text">
              i.e. how to identify the building, roof condition, any areas of
              the roof to avoid or expected future changes in your electricity
              demand
            </p>
          </div>
          <div className="question_section">
            <div style={{ display: "flex", alignItems:"center" }}>
              <Checkbox
                checked={termsAndConditionsChecked}
                onChange={handleCheckTermsAndConditions}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography className="question_main_label">
                I have read and agree to the{" "}
                <Link
                  onClick={handleOpen_termsOne}
                  style={{ textTransform: "none", padding: 0 }}
                >
                  general terms & conditions
                </Link>{" "}
                and also the{" "}
                <Link
                  onClick={handleOpen_termsTwo}
                  style={{ textTransform: "none", padding: 0 }}
                >
                  services policies
                </Link>
                .
              </Typography>
            </div>
          </div>
        </>
      ),
    },
  ];

  function handleStep(step: number) {
    setActiveStep(step);
  }

  return (
    <div>
      <div
        style={{
          width: "100%",
          // minHeight: "100vh",
          // // top: 0,
          // // position:"absolute",
          // paddingTop: 10,
          // paddingBottom: 100,
          // textAlign: "left",
        
        }}
      >
        <div
          style={{
            // width: "90%",
            maxWidth: "1200px",
            margin: "auto",
            textAlign: "left",
          }}
        >
              {submitLoading ? (
                <div style={{ display: "grid", width: "100%", height: "100%" }}>
                  <Skeleton
                    variant="rectangular"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                      gridColumn: "1",
                      gridRow: "1",
                    }}
                    sx={{ bgcolor: "grey.400" }}
                  ></Skeleton>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      gridColumn: "1",
                      gridRow: "1",
                      textAlign: "center",
                      position: "relative",
                      padding: "35% 0",
                    }}
                  >
                    <img src={Logo_blue} style={{ width: "100px" }} />
                    <p
                      style={{
                        fontStyle: "italic bold",
                        fontWeight: "900",
                        color: "#0d3850",
                      }}
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  <div className="form_container">
                    {submitSuccess ? (
                      <div style={{ padding: 20 }}>
                        <TaskAltIcon style={{ fontSize: 50 }} />
                        <Typography variant="body1" id="submission_message">
                          Thanks. Your request has been successfully submitted.
                          The assessment results will be added to your dashboard within 2 working days.
                        </Typography>

                      
                      </div>
                    ) : (
                      <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                          <Step key={step.label}>
                            <StepButton
                              color="inherit"
                              onClick={() => {
                                handleStep(index);
                                setButtonEnabled(true);
                              }}
                            >
                              <Typography>{step.label}</Typography>
                            </StepButton>

                            <StepContent>
                              <Typography>{step.description}</Typography>
                              <Box sx={{ mb: 2 }}>
                                <div>
                                  <div
                                    className="question_main_label_non_comp"
                                    style={{ color: "red" }}
                                  >
                                    {error}
                                  </div>
                                  <Button
                                    id="continueButton"
                                    variant="contained"
                                    disabled={!buttonEnabled}
                                    onClick={() => {
                                      // index == 3 ?
                                      // trackClickSubmission("Submitted") :
                                      // trackClick(
                                      //   index == 0
                                      //     ? "Continue Section 1"
                                      //     : index == 1
                                      //     ? "Continue Section 2"
                                      //     : index == 2
                                      //     ? "click_submission"
                                      //     : ""
                                      // );

                                      handleNext(index);
                                    }}
                                    sx={{ mt: 1, mr: 1 }}
                                  >
                                    {index === steps.length - 1
                                      ? "Submit"
                                      : "Continue"}
                                  </Button>
                                  <Button
                                    disabled={index === 0}
                                    onClick={() => {
                                      handleBack();
                                      // trackClick(
                                      //   index == 1
                                      //     ? "Back to Section 1"
                                      //     : index == 2
                                      //     ? "Back to Section 2"
                                      //     : ""
                                      // );
                                    }}
                                    sx={{ mt: 1, mr: 1 }}
                                  >
                                    {index === 0 ? null : "Back"}
                                  </Button>
                                </div>
                              </Box>
                            </StepContent>
                          </Step>
                        ))}
                      </Stepper>
                    )}
                  </div>
                </>
              )}
            
        </div>
      </div>
     

      {/* =============== Terms and conditions modal ================= */}
      <Modal open={open_termsOne} onClose={handleClose_termsOne}>
        <div
          className="modal_container_add_new_site"
          style={{ borderStyle: "solid", borderRadius: "5px" }}
        >
          <div className="modal_close_button">
            <IconButton size="large" onClick={handleClose_termsOne}>
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ height: "100%", overflow: "auto" }}>
            <h3>Website Terms and Conditions of use</h3>
            <h4>Introduction</h4>
            <p>
              These terms and conditions apply between you, the User of this
              Website (including any sub-domains, unless expressly excluded by
              their own terms and conditions), and Absolar Solutions Limited,
              the owner and operator of this Website. Please read these terms
              and conditions carefully, as they affect your legal rights. Your
              agreement to comply with and be bound by these terms and
              conditions is deemed to occur upon your first use of the Website.
              If you do not agree to be bound by these terms and conditions, you
              should stop using the Website immediately.
            </p>

            <p>
              In these terms and conditions, User or Users means any third party
              that accesses the Website and is not either (i) employed by
              Absolar Solutions Limited and acting in the course of their
              employment or (ii) engaged as a consultant or otherwise providing
              services to Absolar Solutions Limited and accessing the Website in
              connection with the provision of such services.
            </p>

            <p>
              You must be at least 18 years of age to use this Website. By using
              the Website and agreeing to these terms and conditions, you
              represent and warrant that you are at least 18 years of age.
            </p>
            <h4>Intellectual property and acceptable use</h4>
            <ul className="bulletA">
              <li>
                All Content included on the Website, unless uploaded by Users,
                is the property of Absolar Solutions Limited, our affiliates or
                other relevant third parties. In these terms and conditions,
                Content means any text, graphics, images, audio, video,
                software, data compilations, page layout, underlying code and
                software and any other form of information capable of being
                stored in a computer that appears on or forms part of this
                Website, including any such content uploaded by Users. By
                continuing to use the Website you acknowledge that such Content
                is protected by copyright, trademarks, database rights and other
                intellectual property rights. Nothing on this site shall be
                construed as granting, by implication, estoppel, or otherwise,
                any license or right to use any trademark, logo or service mark
                displayed on the site without the owner's prior written
                permission.
              </li>
              <li>
                You may, for your own personal, non-commercial use only, do the
                following:
              </li>
              <ul className="bulletB">
                <li>Retrieve, display and view the Content on a device;</li>
                <li>Print one copy of the Content;</li>
                <li>
                  You must not otherwise reproduce, modify, copy, distribute or
                  use for commercial purposes any Content without the written
                  permission of Absolar Solutions Limited.{" "}
                </li>
              </ul>
              <li>
                You acknowledge that you are responsible for any Content you may
                submit via the Website, including the legality, reliability,
                appropriateness, originality and copyright of any such Content.
                You may not upload to, distribute or otherwise publish through
                the Website any Content that (i) is confidential, proprietary,
                false, fraudulent, libellous, defamatory, obscene, threatening,
                invasive of privacy or publicity rights, infringing on
                intellectual property rights, abusive, illegal or otherwise
                objectionable; (ii) may constitute or encourage a criminal
                offence, violate the rights of any party or otherwise give rise
                to liability or violate any law; or (iii) may contain software
                viruses, political campaigning, chain letters, mass mailings, or
                any form of "spam." You may not use a false email address or
                other identifying information, impersonate any person or entity
                or otherwise mislead as to the origin of any content. You may
                not upload commercial content onto the Website.
              </li>
              <li>
                You represent and warrant that you own or otherwise control all
                the rights to the Content you post; that the Content is
                accurate; that use of the Content you supply does not violate
                any provision of these terms and conditions and will not cause
                injury to any person; and that you will indemnify Absolar
                Solutions Limited for all claims resulting from Content you
                supply.
              </li>
            </ul>
            <h4>Prohibited use</h4>
            <ul className="bulletA">
              <li>
                You may not use the Website for any of the following purposes:
              </li>
              <ul className="bulletB">
                <li>
                  In any way which causes, or may cause, damage to the Website
                  or interferes with any other person's use or enjoyment of the
                  Website;
                </li>
                <li>
                  In any way which is harmful, unlawful, illegal, abusive,
                  harassing, threatening or otherwise objectionable or in breach
                  of any applicable law, regulation, governmental order;
                </li>
                <li>
                  Making, transmitting or storing electronic copies of Content
                  protected by copyright without the permission of the owner.
                </li>
              </ul>
            </ul>
            <h4>Registration</h4>
            <ul className="bulletA">
              <li>
                You must ensure that the details provided by you on registration
                or at any time are correct and complete.
              </li>
              <li>
                You must inform us immediately of any changes to the information
                that you provide when registering by updating your personal
                details to ensure we can communicate with you effectively.
              </li>
              <li>
                We may suspend or cancel your registration with immediate effect
                for any reasonable purposes or if you breach these terms and
                conditions.
              </li>
              <li>
                You may cancel your registration at any time by informing us in
                writing to the address at the end of these terms and conditions.
                If you do so, you must immediately stop using the Website.
                Cancellation or suspension of your registration does not affect
                any statutory rights.
              </li>
            </ul>
            <h4>Links to other websites</h4>
            <ul className="bulletA">
              <li>
                You may not use the Website for any of the following purposes:
              </li>
              <ul className="bulletB">
                <li>
                  This Website may contain links to other sites. Unless
                  expressly stated, these sites are not under the control of
                  Absolar Solutions Limited or that of our affiliates.
                </li>
                <li>
                  We assume no responsibility for the content of such Websites
                  and disclaim liability for any and all forms of loss or damage
                  arising out of the use of them.
                </li>
                <li>
                  The inclusion of a link to another site on this Website does
                  not imply any endorsement of the sites themselves or of those
                  in control of them.
                </li>
              </ul>
            </ul>
            <h4>Privacy Policy and Cookies Policy</h4>
            <ul className="bulletA">
              <li>
                Use of the Website is also governed by our Privacy Policy and
                Cookies Policy, which are incorporated into these terms and
                conditions by this reference. To view the Privacy Policy and
                Cookies Policy, please click on the following:{" "}
                <a>https://www.absolar.co.uk/policies.</a>
              </li>
            </ul>
            <h4>Availability of the Website and disclaimers</h4>
            <ul className="bulletA">
              <li>
                Any online facilities, tools, services or information that
                Absolar Solutions Limited makes available through the Website
                (the Service) is provided "as is" and on an "as available"
                basis. We give no warranty that the Service will be free of
                defects and/or faults. To the maximum extent permitted by the
                law, we provide no warranties (express or implied) of fitness
                for a particular purpose, accuracy of information, compatibility
                and satisfactory quality. Absolar Solutions Limited is under no
                obligation to update information on the Website.
              </li>

              <li>
                Whilst Absolar Solutions Limited uses reasonable endeavours to
                ensure that the Website is secure and free of errors, viruses
                and other malware, we give no warranty or guaranty in that
                regard and all Users take responsibility for their own security,
                that of their personal details and their computers.
              </li>
              <li>
                Absolar Solutions Limited accepts no liability for any
                disruption or non-availability of the Website.
              </li>
              <li>
                Absolar Solutions Limited reserves the right to alter, suspend
                or discontinue any part (or the whole) of the Website including,
                but not limited to, any products and/or services available.
                These terms and conditions shall continue to apply to any
                modified version of the Website unless it is expressly stated
                otherwise.
              </li>
            </ul>
            <h4>Limitation of liability</h4>
            <ul className="bulletA">
              <li>
                Nothing in these terms and conditions will: (a) limit or exclude
                our or your liability for death or personal injury resulting
                from our or your negligence, as applicable; (b) limit or exclude
                our or your liability for fraud or fraudulent misrepresentation;
                or (c) limit or exclude any of our or your liabilities in any
                way that is not permitted under applicable law.
              </li>

              <li>
                We will not be liable to you in respect of any losses arising
                out of events beyond our reasonable control.
              </li>
              <li>
                To the maximum extent permitted by law, Absolar Solutions
                Limited accepts no liability for any of the following:
              </li>
              <ul className="bulletB">
                <li>
                  Any business losses, such as loss of profits, income, revenue,
                  anticipated savings, business, contracts, goodwill or
                  commercial opportunities;
                </li>
                <li>Loss or corruption of any data, database or software;</li>
                <li>Any special, indirect or consequential loss or damage.</li>
              </ul>
            </ul>
            <h4>General</h4>
            <ul className="bulletA">
              <li>
                You may not transfer any of your rights under these terms and
                conditions to any other person. We may transfer our rights under
                these terms and conditions where we reasonably believe your
                rights will not be affected.
              </li>

              <li>
                These terms and conditions may be varied by us from time to
                time. Such revised terms will apply to the Website from the date
                of publication. Users should check the terms and conditions
                regularly to ensure familiarity with the then current version.
              </li>
              <li>
                These terms and conditions together with the Privacy Policy and
                Cookies Policy contain the whole agreement between the parties
                relating to its subject matter and supersede all prior
                discussions, arrangements or agreements that might have taken
                place in relation to the terms and conditions.
              </li>
              <li>
                The Contracts (Rights of Third Parties) Act 1999 shall not apply
                to these terms and conditions and no third party will have any
                right to enforce or rely on any provision of these terms and
                conditions.
              </li>
              <li>
                If any court or competent authority finds that any provision of
                these terms and conditions (or part of any provision) is
                invalid, illegal or unenforceable, that provision or
                part-provision will, to the extent required, be deemed to be
                deleted, and the validity and enforceability of the other
                provisions of these terms and conditions will not be affected.
              </li>
              <li>
                Unless otherwise agreed, no delay, act or omission by a party in
                exercising any right or remedy will be deemed a waiver of that,
                or any other, right or remedy.
              </li>
              <li>
                This Agreement shall be governed by and interpreted according to
                the law of England and Wales and all disputes arising under the
                Agreement (including non-contractual disputes or claims) shall
                be subject to the exclusive jurisdiction of the English and
                Welsh courts.
              </li>
            </ul>
            <h4>Absolar Solutions Limited details</h4>
            <ul className="bulletA">
              <li>
                Absolar Solutions Limited is a company incorporated in England
                and Wales with registered number 12322471 whose registered
                address is Epsilon House, Enterprise Road, Southampton, SO16 7NS
                and it operates the Website www.absolar.co.uk and
                www.solarwatcher.co.uk. The registered VAT number is 352733306.
                You can contact Absolar Solutions Limited by email on
                info@absolar.co.uk.
              </li>
            </ul>
          </div>
        </div>
      </Modal>

      {/* =============== Remote Solar Survey – Terms and Conditions modal ================= */}
      <Modal open={open_termsTwo} onClose={handleClose_termsTwo}>
        <div
          className="modal_container_add_new_site"
          style={{ borderStyle: "solid", borderRadius: "5px" }}
        >
          <div className="modal_close_button">
            <IconButton size="large" onClick={handleClose_termsTwo}>
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ height: "100%", overflow: "auto" }}>
            <h3>Remote Solar Survey – Terms and Conditions</h3>
            <p>
              <strong>
                Please read these terms and conditions carefully before
                requesting a remote solar survey from us. By requesting a
                survey, you agree to be bound by these terms and conditions.
              </strong>
            </p>
            <h4>Application and entire agreement</h4>
            <p>
              These Terms and Conditions apply to the provision of the Remote
              Solar Survey services (Services) by Absolar Solutions Limited a
              company registered in England and Wales under number 12322471
              whose registered office is at Epsilon House, Enterprise Road,
              Chilworth, Southampton, SO16 7NS (we or us or Service Provider) to
              the person buying the services (you or Customer).
            </p>

            <p>
              You are deemed to have accepted these Terms and Conditions when
              you request a Remote Solar Survey or from the date of any
              performance of the Services (whichever happens earlier) and these
              Terms and Conditions and our quotation for performing the Remote
              Solar Survey (the Contract) are the entire agreement between us.
            </p>

            <p>
              You acknowledge that you have not relied on any statement, promise
              or representation made or given by or on our behalf. These
              Conditions apply to the Contract to the exclusion of any other
              terms that you try to impose or incorporate, or which are implied
              by trade, custom, practice or course of dealing.
            </p>

            <h4>Interpretation</h4>
            <p>
              A "business day" means any day other than a Saturday, Sunday or
              bank holiday in England and Wales.
            </p>
            <p>
              The headings in these Terms and Conditions are for convenience
              only and do not affect their interpretation.
            </p>
            <p>
              Words imparting the singular number shall include the plural and
              vice-versa.
            </p>
            <h4>Services</h4>
            <p>
              We warrant that we will use reasonable care and skill in our
              performance of the Services including any specification in all
              material respects. We can make any changes to the Services which
              are necessary to comply with any applicable law or safety
              requirement, and we will notify you if this is necessary.
            </p>
            <p>
              We warrant that we will use reasonable care and skill in our
              performance of the Services including any specification in all
              material respects. We can make any changes to the Services which
              are necessary to comply with any applicable law or safety
              requirement, and we will notify you if this is necessary.
            </p>

            <p>
              We will use our reasonable endeavours to complete the performance
              of the Services within the time agreed; however, time shall not be
              of the essence in the performance of our obligations.
            </p>

            <p>
              All of these Terms and Conditions apply to the supply of any goods
              as well as Services unless we specify otherwise.
            </p>

            <p>
              You may request Services from us by providing us with the
              information required on our website or through any other means as
              instructed by us.
            </p>

            <p>
              You must provide accurate and complete information to enable us to
              carry out the survey effectively.
            </p>

            <p>
              We reserve the right to refuse any request for a survey without
              providing a reason.
            </p>

            <p>
              Whilst we are able to offer the Remote Solar Survey across
              England, Scotland and Wales, our installer network may be limited
              in your area and therefore site visits and installation options
              are for selected regions only
            </p>

            <p>
              The results of the Service are for information purposes only and
              the Service results are based on the information provided by you
              and the assumptions made by us using industry-standard software
              and methods. The Service results cannot be relied upon without a
              site visit being performed to confirm the assumptions used. The
              Service results may not accurately reflect the actual conditions
              on the site.
            </p>

            <p>
              We do not accept any liability for any losses or damages arising
              from the use of the Services provided to you.
            </p>

            <p>
              A site visit may be required to confirm the assumptions used in
              the Remote Solar Survey. The site visit will be arranged
              separately, and the cost will be agreed upon before the visit
              takes place. The site visit will be conducted by us or our
              authorised representatives. You must provide us with access to the
              property or site for the site visit.
            </p>

            <h4>Your obligations</h4>
            <p>
              You must obtain any permissions, consents, licences or otherwise
              that we need and must give us with access to any and all relevant
              information, materials, properties and any other matters which we
              need to provide the Services.
            </p>

            <p>
              You must provide accurate information about the subject building
              for which the Service relates to.
            </p>

            <p>
              If you do not comply with your obligations, we can terminate the
              Services.
            </p>

            <p>
              We are not liable for any delay or failure to provide the Services
              if this is caused by your failure to comply with the provisions of
              this section (Your obligations).
            </p>

            <h4>Fees</h4>
            <p>
              The fees (Fees) for the Services are set out when you apply for a
              Remote Solar Survey and are on a time and materials basis.
            </p>

            <p>
              The Fees are exclusive of any applicable VAT and other taxes or
              levies which are imposed or charged by any competent authority.
            </p>

            <h4>Cancellation and amendment</h4>
            <p>
              Either we or you can cancel the Service for any reason prior to
              the provision of the Remote Solar Survey results.
            </p>

            <p>
              If you want to amend any details of the Services you must tell us
              in writing as soon as possible. We will use reasonable endeavours
              to make any required changes and additional costs will be included
              in the Fees and invoiced to you.
            </p>

            <p>
              If, due to circumstances beyond our control, including those set
              out below (Circumstances beyond a party's control), we have to
              make any change in the Services or how they are provided, we will
              notify you immediately. We will use reasonable endeavours to keep
              any such changes to a minimum.
            </p>

            <h4>Payment</h4>
            <p>We will invoice you for payment of the Fees either:</p>

            <p>a. in advance of completion of the Services; or</p>

            <p>
              b. on the invoice dates set out in the quotation for Remote Solar
              Survey.
            </p>

            <p>
              You must pay the Fees due within 7 days of the date of our invoice
              or otherwise in accordance with any credit terms agreed between
              us.
            </p>

            <p>Time for payment shall be of the essence of the Contract.</p>

            <p>
              Without limiting any other right or remedy we have for statutory
              interest, if you do not pay within the period set out above, we
              will charge you interest at the rate of 5% per annum above the
              base lending rate of the Bank of England from time to time on the
              amount outstanding until payment is received in full.
            </p>

            <p>
              All payments due under these Terms and Conditions must be made in
              full without any deduction or withholding except as required by
              law and neither of us can assert any credit, set-off or
              counterclaim against the other in order to justify withholding
              payment of any such amount in whole or in part.
            </p>

            <p>
              If you do not pay within the period set out above, we can suspend
              any further provision of the Services and cancel any future
              services which have been ordered by, or otherwise arranged with,
              you.
            </p>

            <p>
              Receipts for payment will be issued by us only at your request.
            </p>

            <p>
              All payments must be made in British Pounds unless otherwise
              agreed in writing between us.
            </p>

            <h4>Sub-Contracting and assignment</h4>
            <p>
              We can at any time assign, transfer, charge, subcontract or deal
              in any other manner with all or any of our rights under these
              Terms and Conditions and can subcontract or delegate in any manner
              any or all of our obligations to any third party.
            </p>

            <p>
              You must not, without our prior written consent, assign, transfer,
              charge, subcontract or deal in any other manner with all or any of
              your rights or obligations under these Terms and Conditions.
            </p>
            <h4>Termination</h4>
            <p>
              We can terminate the provision of the Services immediately if you:
            </p>

            <p>
              a. commit a material breach of your obligations under these Terms
              and Conditions; or
            </p>

            <p>
              b. fail to make pay any amount due under the Contract on the due
              date for payment; or
            </p>

            <p>
              c. are or become or, in our reasonable opinion, are about to
              become, the subject of a bankruptcy order or take advantage of any
              other statutory provision for the relief of insolvent debtor; or
            </p>

            <p>
              d. enter into a voluntary arrangement under Part 1 of the
              Insolvency Act 1986, or any other scheme or arrangement is made
              with its creditors; or
            </p>

            <p>
              e. convene any meeting of your creditors, enter into voluntary or
              compulsory liquidation, have a receiver, manager, administrator or
              administrative receiver appointed in respect of your assets or
              undertakings or any part of them, any documents are filed with the
              court for the appointment of an administrator in respect of you,
              notice of intention to appoint an administrator is given by you or
              any of your directors or by a qualifying floating charge holder
              (as defined in para. 14 of Schedule B1 of the Insolvency Act
              1986), a resolution is passed or petition presented to any court
              for your winding up or for the granting of an administration order
              in respect of you, or any proceedings are commenced relating to
              your insolvency or possible insolvency.
            </p>

            <h4>Intellectual property</h4>
            <p>
              We reserve all copyright and any other intellectual property
              rights which may subsist in any goods supplied in connection with
              the provision of the Services. We reserve the right to take any
              appropriate action to restrain or prevent the infringement of such
              intellectual property rights.
            </p>

            <h4>Liability and indemnity</h4>

            <p>
              Our liability under these Terms and Conditions, and in breach of
              statutory duty, and in tort or misrepresentation or otherwise,
              shall be limited as set out in this section.
            </p>

            <p>
              The total amount of our liability is limited to the total amount
              of Fees payable by you under the Contract.
            </p>

            <p>
              We are not liable (whether caused by our employees, agents or
              otherwise) in connection with our provision of the Services or the
              performance of any of our other obligations under these Terms and
              Conditions for:
            </p>

            <p>
              a. any indirect, special or consequential loss, damage, costs, or
              expenses or;
            </p>

            <p>
              b. any loss of profits; loss of anticipated profits; loss of
              business; loss of data; loss of reputation or goodwill; business
              interruption; or, other third party claims; or
            </p>

            <p>
              c. any failure to perform any of our obligations if such delay or
              failure is due to any cause beyond our reasonable control; or
            </p>

            <p>
              d. any losses caused directly or indirectly by any failure or your
              breach in relation to your obligations; or
            </p>

            <p>
              e. any losses arising directly or indirectly from the choice of
              Services and how they will meet your requirements or your use of
              the Services or any goods supplied in connection with the
              Services.
            </p>

            <p>
              You must indemnify us against all damages, costs, claims and
              expenses suffered by us arising from any loss or damage to any
              equipment (including that belonging to third parties) caused by
              you or your agents or employees.
            </p>

            <p>
              Nothing in these Terms and Conditions shall limit or exclude our
              liability for death or personal injury caused by our negligence,
              or for any fraudulent misrepresentation, or for any other matters
              for which it would be unlawful to exclude or limit liability.
            </p>

            <h4>Data Protection</h4>
            <p>
              When supplying the Services to the Customer, the Service Provider
              may gain access to and/or acquire the ability to transfer, store
              or process personal data of employees of the Customer.
            </p>

            <p>
              The parties agree that where such processing of personal data
              takes place, the Customer shall be the 'data controller' and the
              Service Provider shall be the 'data processor' as defined in the
              General Data Protection Regulation (GDPR) as may be amended,
              extended and/or re-enacted from time to time.
            </p>

            <p>
              For the avoidance of doubt, 'Personal Data', 'Processing', 'Data
              Controller', 'Data Processor' and 'Data Subject' shall have the
              same meaning as in the GDPR.
            </p>

            <p>
              The Service Provider shall only Process Personal Data to the
              extent reasonably required to enable it to supply the Services as
              mentioned in these terms and conditions or as requested by and
              agreed with the Customer, shall not retain any Personal Data
              longer than necessary for the Processing and refrain from
              Processing any Personal Data for its own or for any third party's
              purposes.
            </p>

            <p>
              The Service Provider shall not disclose Personal Data to any third
              parties other than employees, directors, agents, sub-contractors
              or advisors on a strict 'need-to-know' basis and only under the
              same (or more extensive) conditions as set out in these terms and
              conditions or to the extent required by applicable legislation
              and/or regulations.
            </p>

            <p>
              The Service Provider shall implement and maintain technical and
              organisational security measures as are required to protect
              Personal Data Processed by the Service Provider on behalf of the
              Customer.
            </p>

            <p>
              Further information about the Service Provider's approach to data
              protection are specified in its Data Protection Policy, which can
              be found on our website. For any enquiries or complaints regarding
              data privacy, you can email: info@absolar.co.uk.
            </p>

            <h4>Circumstances beyond a party's control</h4>

            <p>
              Neither of us is liable for any failure or delay in performing our
              obligations where such failure or delay results from any cause
              that is beyond the reasonable control of that party. Such causes
              include, but are not limited to: industrial action, civil unrest,
              fire, flood, storms, earthquakes, acts of terrorism, acts of war,
              governmental action or any other event that is beyond the control
              of the party in question. If the delay continues for a period of
              90 days, either of us may terminate or cancel the Services to be
              carried out under these Terms and Conditions.
            </p>

            <h4>Communications</h4>

            <p>
              All notices under these Terms and Conditions must be in writing
              and signed by, or on behalf of, the party giving notice (or a duly
              authorised officer of that party).
            </p>

            <p>Notices shall be deemed to have been duly given:</p>

            <p>
              a. when delivered, if delivered by courier or other messenger
              (including registered mail) during the normal business hours of
              the recipient;
            </p>

            <p>
              b. when sent, if transmitted by fax or email and a successful
              transmission report or return receipt is generated;
            </p>

            <p>
              c. on the fifth business day following mailing, if mailed by
              national ordinary mail; or
            </p>

            <p>
              d. on the tenth business day following mailing, if mailed by
              airmail.
            </p>

            <p>
              All notices under these Terms and Conditions must be addressed to
              the most recent address, email address or fax number notified to
              the other party.
            </p>

            <h4>No waiver</h4>

            <p>
              No delay, act or omission by a party in exercising any right or
              remedy will be deemed a waiver of that, or any other, right or
              remedy nor stop further exercise of any other right, or remedy.
            </p>

            <h4>Severance</h4>

            <p>
              If one or more of these Terms and Conditions is found to be
              unlawful, invalid or otherwise unenforceable, that / those
              provisions will be deemed severed from the remainder of these
              Terms and Conditions (which will remain valid and enforceable).
            </p>

            <h4>Law and jurisdiction</h4>

            <p>
              This Agreement shall be governed by and interpreted according to
              the law of England and Wales and all disputes arising under the
              Agreement (including non-contractual disputes or claims) shall be
              subject to the exclusive jurisdiction of the English and Welsh
              courts.
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        open={isSubmissionError}
        onClose={() => {
          setIsSubmissionError(false);
          setErrorCode("");
        }}
      >
        <div
          className="modal_container_add_new_site"
          style={{ height: "auto" }}
        >
          <div className="modal_close_button">
            <IconButton
              size="large"
              onClick={() => {
                setIsSubmissionError(false);
                setErrorCode("");
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ color: "red", textAlign: "center" }}>
            <WarningAmberIcon></WarningAmberIcon>
            <h3>
              {errorCode == "Unknown error"
                ? "Unknown Error"
                : "Error " + errorCode}{" "}
              in Submitting the Form
            </h3>
          </div>

          <Typography>
            This problem can occur when the submission of this form is blocked
            by your IT system. Please contact your IT administrator to allow
            connection to *.absolar.co.uk, or click here:{" "}
            <a
              href={
                "mailto:info@absolar.co.uk?subject=Solar survey request&body=Dear Absolar Team, %0d%0a Please find below a remote solar survey request, as entered on survey.absolar.co.uk: %0d%0a %0d%0a " +
                formatAnswersEmail()
              }
            >
              Send Email request to Absolar
            </a>{" "}
            to submit the form manually via email. 
          </Typography>
        </div>
      </Modal>

      {/* =============== Request multiple buildings modal ================= */}
      <Modal
        open={open_multipleBuildings}
        onClose={handleClose_multipleBuildings}
      >
        <div
          className="modal_container_add_new_site"
          style={{ borderStyle: "solid", borderRadius: "5px", height: "auto" }}
        >
          <div className="modal_close_button">
            <IconButton size="large" onClick={handleClose_multipleBuildings}>
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ height: "100%", overflow: "auto" }}>
            <h3>Request the assessment of multiple buildings</h3>
            <Typography>
              If you have more than 10 buildings for assessment, you can contact{" "}
              <a href="mailto:info@absolar.co.uk">info@absolar.co.uk</a> and
              request a bulk survey which lets you access our portfolio
              management dashboard.
            </Typography>
          </div>
        </div>
      </Modal>
    </div>
  );
}
