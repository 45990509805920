import React, { useState } from "react";

import { Typography, Grid , CardActionArea } from "@mui/material";
import Tech_image from "../images/tech_background.png";

export default function InfoCashflowExample() {
  return (
    <>
      <Typography
        variant="h5"
        style={{ marginTop: 20, borderBottom: "1px grey solid" }}
        gutterBottom
        paragraph
      >
        Typical cashflow projection for a solar investment
      </Typography>
      <Typography variant="body2" paragraph>
        The cashflow of a solar PV investment typically involves upfront costs upon installation and ongoing cash savings over the system's lifespan. How quickly those cash savings recover the initial investment is what’s known as the ‘payback period’. Here is a simplified description of the cashflow components: 
      </Typography>
      
      {/* [Interactive chart] */}
      <Typography variant="body2" paragraph>
        <ol>
          <li style={{marginBottom:10}}>
          Upfront Costs: The initial investment includes the purchase and installation of the solar PV system. This includes the cost of solar panels, inverters, mounting equipment, and installation labor. Upfront costs can also include engineering studies, approvals, and any necessary electrical upgrades. 
          </li>
          <li style={{marginBottom:10}}>
          Incentives: Various incentives, such as government grants, tax credits, and rebates can offset a portion of the upfront costs. 
          </li>
          <li style={{marginBottom:10}}>
          Electricity Savings: Once the solar PV system is operational, it generates electricity that can be consumed on-site, reducing the need to purchase electricity from the grid. This leads to immediate cost savings on utility bills. The amount of electricity savings depends on factors like the system size, energy consumption, and local electricity rates. 
          </li>
          <li style={{marginBottom:10}}>
          Export Tariffs or Power Purchase Agreements (PPAs): In some regions, organisations can receive additional revenue by selling excess electricity back to the grid through export tariffs or by entering into power purchase agreements with utility companies. These arrangements provide a fixed price per kilowatt-hour for a specific period, ensuring a stable income stream. 
          </li>
          <li style={{marginBottom:10}}>
          System Maintenance and Operating Costs: Solar PV systems generally require minimal maintenance but may incur some ongoing costs for periodic inspections, cleaning, and potential repairs. These costs should be factored into the cashflow analysis. 
          </li>
          <li style={{marginBottom:10}}>
          Return on Investment (ROI) and Payback Period: The cashflow analysis evaluates the total income generated from electricity savings, feed-in tariffs/PPAs, and other revenue streams against the upfront costs and ongoing expenses. The ROI and payback period indicate how long it takes for the investment to generate enough revenue to cover the initial investment and start providing a positive return. 
          </li>
        </ol>
      </Typography>
      <Typography variant="body2" paragraph>
        It's important to note that the cashflow of a solar PV investment can vary based on factors such as system size, location, electricity consumption, financing options, and local regulations. Performing a thorough financial analysis, including revenue projections and cost estimations, is crucial to accurately assess the cashflow and potential return on investment for a specific solar PV project. 
      </Typography>
      <Typography variant="body2" paragraph>
        If you request Absolar to assist in your Solar PV investment, we will provide this analysis to you.  
      </Typography>
    </>
  );
}
