import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import styled from "styled-components";
import { postData } from "../get-data";
import {DataGrid, GridToolbar, GridColDef} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Loading } from "../components/loading/Loading";

interface AnalyticsIProps {
    dashboardDataList: DashboardData[];
    orgEnv: number;
    setRefData: React.Dispatch<React.SetStateAction<DashboardData | null>>;
}

const Container = styled.div `
  padding: 15px;
`;

const AnalyticsPageHeader = styled.h2 `
  margin-bottom: 15px;
  display: flex;
`;

const DataGridContainer = styled.div `
  width: 100%;
  height: 100%;
`;

export default function AnalyticsPage(props: AnalyticsIProps) {

    const userContext = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [analyticsTableData, setAnalyticsTableData] = useState<AnalyticsData[]>([]);
    const navigate = useNavigate();

    const navigateToProjectPage = (address: string) => {
        const matchingDashboardData = props.dashboardDataList.find(data => data.address === address);

        if (matchingDashboardData) {
            props.setRefData(matchingDashboardData);
            navigate("/project");
        }
    }

    useEffect(() => {
        setIsLoading(true);

        let newDashboardData = props.dashboardDataList.filter(
            (data) => data.organisation_id === props.orgEnv
        );
        let references = newDashboardData.map((data) => data.reference);

        const updateAnalyticsTableData = async () => {
            await new Promise<void>((resolve) => {
                setAnalyticsTableData([]);
                setTimeout(() => {
                    resolve();
                }, 100);
            });

            postData(
                process.env.REACT_APP_API_URL + "/Analytics/sharedPagesViewCount",
                references,
                false,
                userContext.getCredentials)
                .then((response: AnalyticsApiData[]) => {
                    setIsLoading(false);

                    const processedData: AnalyticsData[] = response.map((apiData, index) => {

                        const matchingDashboardData = newDashboardData.find(
                            (dashboardData) => dashboardData.reference === apiData.referenceNumber
                        );
                        const analyticsData: AnalyticsData = {
                            id: `row-${index}`,
                            address: matchingDashboardData?.address || "",
                            pageViews: apiData.viewCount,
                            uniqueUsers: apiData.ipAddressesCount,
                            firstViewTime: apiData.createdTime,
                            lastViewTime: apiData.updatedTime,
                            referenceNumber: apiData.referenceNumber
                        };

                        return analyticsData;
                    });
                    setAnalyticsTableData(processedData);
                })
                .catch((e) => {
                    console.log(e.message);
                    alert("Error while getting analytics data");
                    navigate("/");
                });
        };
        updateAnalyticsTableData().then(() => {});
    }, [props.orgEnv]);

    const columns: GridColDef[] = [
        {
            field: "address",
            headerName: "Address",
            width: 750,
            renderCell: (params) => (
                <a
                    href="#"
                    onClick={(event) => {
                        event.preventDefault();
                        navigateToProjectPage(params.value);
                    }}
                >
                    {params.value}
                </a>
            ),
        },
        { field: "referenceNumber", headerName: "Reference Number", width: 150},
        { field: "pageViews", headerName: "Page View", width: 100 },
        { field: "uniqueUsers", headerName: "Unique Users", width: 100 },
        { field: "firstViewTime", headerName: "First Viewed", width: 150 },
        { field: "lastViewTime", headerName: "Last Viewed", width: 150 }
    ];

    return (
        <div className="App">
            {
                isLoading ? (
                    <Loading />
                ) : (
                    <Container>
                        <AnalyticsPageHeader>Analytics</AnalyticsPageHeader>
                        <DataGridContainer>
                            <DataGrid
                                rows={analyticsTableData}
                                columns={columns}
                                pagination
                                pageSizeOptions={[25]}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                            />
                        </DataGridContainer>
                    </Container>
                )
            }
        </div>
    );
}

