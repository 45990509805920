import React, { useState } from "react";
import {
  Grid,
  Button,
  Modal,
  Typography,
  Skeleton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { CardActionArea } from "@mui/material";
import ModalFAQ from "../infoPages/FaqModal";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import feature_image from "../images/puzzle.png";
import speed_image from "../images/speed.png";
import monitor_image from "../images/monitor.png";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PeopleIcon from "@mui/icons-material/People";
import GppGoodIcon from "@mui/icons-material/GppGood";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SupportPageNextStep from "./supportPageNextStep";
import SupportPageFinancial from "./supportPageFinancial";
import SupportPageDataDisplay from "./supportPageDataDisplay";
import SupportPageTrading from "./supportPageTrading";
import SupportPageProjectManagement from "./supportPageProjectManagement";
import SupportPagePreviousProject from "./supportPagePreviousProject";

export default function LandingDrawerPage() {
  const [open_faq, setOpen_faq] = useState(false);
  const handleOpen_faq = () => setOpen_faq(true);
  const handleClose_faq = () => setOpen_faq(false);

  const [content, setContent] = useState<React.ReactNode>(
    <>
      <Typography
        variant="body2"
        style={{
          // borderBottom: "1px lightgrey solid",
          marginTop: 30,
          marginBottom: 5,
        }}
      >
        Download documents
      </Typography>
      <Button
              fullWidth
              startIcon={<PictureAsPdfIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Feasibility report
            </Button>
            <Button
              fullWidth
              startIcon={<AttachFileIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Cash flow projection (.xls)
            </Button>
            <Button
              fullWidth
              disabled
              startIcon={<PictureAsPdfIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Site survey report
            </Button>
            <Button
              fullWidth
              disabled
              startIcon={<PictureAsPdfIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Project proposal
            </Button>
            <Button
              fullWidth
              disabled
              startIcon={<PictureAsPdfIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Contract
            </Button>

      <Typography
        variant="body2"
        style={{
          // borderBottom: "1px lightgrey solid",
          marginTop: 30,
          marginBottom: 5,
        }}
      >
        Explore features and benefits of Absolar
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <CardActionArea style={{ height: "100%" }} onClick={handleClick_financialAssessment}>
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 10,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={feature_image}
                  style={{
                    maxWidth: "80%",
                    margin: "auto",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>
                Comprehensive financial projections
              </div>
            </div>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CardActionArea style={{ height: "100%" }} onClick={handleClick_projectManagement}>
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 10,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={speed_image}
                  style={{
                    maxWidth: "80%",
                    margin: "auto",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>
                Project delivery management
              </div>
            </div>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CardActionArea style={{ height: "100%" }} onClick={handleClick_trading}>
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 10,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={monitor_image}
                  style={{
                    maxWidth: "80%",
                    margin: "auto",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>
                Export energy trading
              </div>
            </div>
          </CardActionArea>
        </Grid>
      </Grid>

      <Typography
        variant="body2"
        style={{
          borderBottom: "1px lightgrey solid",
          marginTop: 30,
        }}
      >
        Supports & services
      </Typography>
      <List dense>
        <ListItem disablePadding >
          <ListItemButton  onClick={handleClick_dataDisplay}>
            <ListItemIcon>
              <LeaderboardIcon />
            </ListItemIcon>
            <ListItemText
              primary="Data monitoring and display"
              secondary="Help you view the live data of solar energy generation, displaying information at your workspace or website."
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText
              primary="Development management"
              secondary="We’ll mitigate risks, maximise returns and ensure the timely delivery of each project."
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <GppGoodIcon />
            </ListItemIcon>
            <ListItemText
              primary="Energy trading & performance support"
              secondary="Negotiate export tariff on your behalf to maximise financial returns and impact."
            />
          </ListItemButton>
        </ListItem>
      </List>

      <Typography
        variant="body2"
        style={{
          borderBottom: "1px lightgrey solid",
          marginTop: 20,
        }}
      >
        Additional information
      </Typography>
      <Button
        fullWidth
        startIcon={<InfoIcon />}
        style={{
          justifyContent: "flex-start",
          textTransform: "none",
        }}
        onClick={handleOpen_faq}
      >
        FAQ
      </Button>
      <Button
        fullWidth
        onClick={handleClick_previousProject}
        startIcon={<PlayCircleFilledWhiteIcon />}
        style={{
          justifyContent: "flex-start",
          textTransform: "none",
        }}
      >
        Interview of previous projects
      </Button>
      
    </>
  );
  const [previousContent, setPreviousContent] = useState<string[]>([]);
  const [contentChanged, setContentChanged] = useState(false);

  function handleClick_nextSteps() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPageNextStep />);
    setContentChanged(true);
  }

  function handleClick_financialAssessment() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPageFinancial />);
    setContentChanged(true);
  }

  function handleClick_dataDisplay() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPageDataDisplay />);
    setContentChanged(true);
  }

  function handleClick_trading() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPageTrading />);
    setContentChanged(true);
  }

  function handleClick_projectManagement() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPageProjectManagement />);
    setContentChanged(true);
  }

  function handleClick_previousProject() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPagePreviousProject />);
    setContentChanged(true);
  }

  

  function handleGoBack() {
    const previous = previousContent.pop();
    if (previous) {
      setContent(previous);
      setPreviousContent(previousContent);
      setContentChanged(previousContent.length > 0);
    }
  }

  return (
    <>
      {contentChanged && <Button startIcon={<ArrowBackIcon />} onClick={handleGoBack} style={{marginTop:10}}>Go Back</Button>}

      <div>{content}</div>
      <Modal open={open_faq} onClose={handleClose_faq}>
        <ModalFAQ />
      </Modal>
    </>
  );
}
