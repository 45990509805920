import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  Grid,
  Accordion,
  AccordionSummary,
  Table,
  TableRow,
  TableCell,
  Tooltip,
  Button,
  IconButton,
  Typography,
  Skeleton,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import ProposalSentIframe from "./ProposalSentIframe";

import Logo_colour from "./images/logo_colour.png";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SRA_image_legend from "./images/legend_1.png";
import rad_image_legend from "./images/legend_2.png";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SavingsIcon from "@mui/icons-material/Savings";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import CloseIcon from "@mui/icons-material/Close";
import { deleteData, getData, postData, putData } from "./get-data";
import CookieConsent from "react-cookie-consent";
import { useLocation, useNavigate } from "react-router-dom";

import { UserContext } from "./contexts/UserContext";

import "./ProjectPage.css";

import { ISideBarProps, SideBar } from "./project_page/side_bar";
import ProjectLandingPage from "./ProjectLandingPage";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { getSharedShortLinkPath } from "./services/getSharedShortLinkPath";

interface ProjectPageProps {
  data: DashboardData;
  setSavingAnimation: (newBool: boolean) => void;
  dashboardDatas: DashboardData[];
  setDashboardDatas: (newdata: DashboardData[]) => void;
  setIsNatwest: (newBol: boolean) => void;
  loadPdfDownloading: boolean;
  setLoadPdfDownloading: (newBol: boolean) => void;
  getPdf: (property: DashboardData | null) => void;
  handleDownloadFile: (fileName: string, reference: string) => void;
}

export default function ProjectPage(props: ProjectPageProps) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ref = searchParams.get("ref");

  const [documentDrawerOpen, setDocumentDrawerOpen] = useState<boolean>(false);
  const handleDocumentDrawerOpen = () => {
    setDocumentDrawerOpen(true);
  };

  const handleDocumentDrawerClose = () => {
    setDocumentDrawerOpen(false);
  };

  const userContext = useContext(UserContext);
  const [newUploadedFiles, setNewUploadedFiles] = React.useState<File[]>([]);

  const [radiation, setRadiation] = useState<string>("");
  const [file_names, setFile_names] = useState<string[]>([]);
  const [file_templates, setFile_templates] = useState<
    {
      template: string;
      is_uploaded: boolean;
    }[]
  >([]);
  const [templatesChecked, setTemplatesChecked] = useState<
    {
      template: string;
      is_uploaded: boolean;
    }[]
  >([]);
  const [finance_option, setFinance] = useState<string | null>("cash_purchase");
  const [shared, setShared] = useState<boolean>(false);
  const [image, setImage] = useState<string>("");
  const [settingShared, setSettingShared] = useState<boolean>(false);
  props.setIsNatwest(
    props.data.organisation_id === 4 ||
      props.data.organisation_id === 20 ||
      props.data.organisation_id === 27 ||
      props.data.organisation_id === 16 ||
      props.data.organisation_id === 21
    // props.data.organisation_id === 26 ||
    // props.data.organisation_id === 27 ||
    // props.data.organisation_id === 18
  );
  const [projectDelivery, setProjectDelivery] =
    useState<ProjectDeliveryDto | null>(null); // Initialize projectDelivery as null

  async function getNewProjectStageForNextSteps() {
    try {
      const url =
        process.env.REACT_APP_API_URL +
        "/ProjectDelivery/" +
        props.data.reference;
      const response = await getData(url, true, userContext.getCredentials);

      if (response.ok) {
        const data: ProjectDeliveryDto = await response.json();
        setProjectDelivery(data);
      } else {
        console.log("Error:", response.status);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }
  useEffect(() => {
    if (userContext.userState.loggedIn) {
      userContext.getNotificationsData();
    }
    getNewProjectStageForNextSteps();
    getFilesForReference(props.data.reference);
    document.title = userContext.userState.loggedIn
      ? "Property View: " + props.data.reference
      : props.data.reference;
  }, []);

  function getFilesForReference(propReference: string) {
    let url =
      process.env.REACT_APP_API_URL +
      "/File/file_names_shared?reference=" +
      propReference;
    if (userContext.userState.loggedIn) {
      url =
        process.env.REACT_APP_API_URL +
        "/File/file_names?reference=" +
        propReference;
    }
    getData(url, false, userContext.getCredentials)
      .then((fileNames: string[]) => {
        setFile_names(fileNames);
        console.log("successful retrieval of files from property");
      })
      .catch((e) => {
        console.log("unable to get files from property");
      });

    //get data for file templates
    var urlForTemplates =
      process.env.REACT_APP_API_URL + "/Template/FileTemplates";

    getData(urlForTemplates, false, userContext.getCredentials)
      .then((data: { orgId: number; templates: string[] }[]) => {
        //for future: if we need to sort these based on organisation id, this next line is the place
        var templatesStrings = data[0].templates;
        //now, retrieving the ones that are already uploaded/marked/
        let urlForMarkedTemplates =
          process.env.REACT_APP_API_URL +
          "/Template/ReferenceTemplates_shared/" +
          propReference;

        if (userContext.userState.loggedIn) {
          urlForMarkedTemplates =
            process.env.REACT_APP_API_URL +
            "/Template/ReferenceTemplates/" +
            propReference;
        }
        getData(urlForMarkedTemplates, false, userContext.getCredentials)
          .then((data: { reference: string; template: string }[]) => {
            //retrieving the "marked"/"done" templates
            let templateArray = data.map((obj) => obj.template);
            //now, making an array for the state variable file_templates that includes which ones are marked and which aren't
            var arrayForFile_templates: {
              template: string;
              is_uploaded: boolean;
            }[] = [];
            templatesStrings.forEach((eachGeneralTemplate) => {
              if (templateArray.includes(eachGeneralTemplate)) {
                arrayForFile_templates.push({
                  template: eachGeneralTemplate,
                  is_uploaded: true,
                });
              } else {
                arrayForFile_templates.push({
                  template: eachGeneralTemplate,
                  is_uploaded: false,
                });
              }
            });
            setFile_templates(arrayForFile_templates);

            const clonedArray = arrayForFile_templates.map((obj) => ({
              ...obj,
            }));

            setTemplatesChecked(clonedArray);
          })
          .catch((e) => {
            console.log("couldn't retrieve file reference templates");
          });
      })
      .catch((e) => {
        console.log("couldn't retrieve file templates");
      });

    //further in frontend: (NOT NEEDED ANYMORE)
    // var objFromBackend = [
    //   {template: "Feasibility Report", is_uploaded: false},
    //   {template: "Cashflow Projections (xlsx)", is_uploaded: false},
    //   {template: "Survey", is_uploaded: false}];
    // setFile_templates(objFromBackend);

    // const clonedArray = objFromBackend.map((obj) => ({ ...obj }));

    // setTemplatesChecked(clonedArray);
  }

  let navigate = useNavigate();

  function trackClick(buttonClicked: string) {
    // code to track the click event
    gtag("event", buttonClicked, {
      event_category: "Button",
      event_label: buttonClicked,
    });
  }

  function getImage(property: DashboardData) {
    if (property) {
      let url =
        process.env.REACT_APP_API_URL + "/Dashboard/satellite_shared?toid=";
      if (userContext.userState.loggedIn) {
        url = process.env.REACT_APP_API_URL + "/Dashboard/satellite?toid=";
      }
      getData(url + property.reference, true, userContext.getCredentials)
        .then((response) => response.blob())
        .then((blob) => {
          setImage(URL.createObjectURL(blob));
        })
        .catch((e) => {
          console.log("couldn't get image");
        });
    }
  }
  useEffect(() => {
    getImage(props.data);
    async function fetchData() {
      if (ref) {
        try {
          const referencePartsArr = ref.split("_");
          const referenceNumber = parseInt(
            referencePartsArr[referencePartsArr.length - 1]
          );
          if (referenceNumber <= 5150) return;
          //redirect the report from share to report if the reference number is between 5000 and 5150
          const sharedData = await getSharedShortLinkPath(ref);
          if (sharedData.shortLinkPath) {
            window.location.href = `https://report.absolar.co.uk/?r=${sharedData.shortLinkPath}`;
          }
        } catch (error) {
          console.error("Failed to fetch shared data", error);
        }
      }
    }

    fetchData();
  }, []);

  function getRadiation(property: DashboardData) {
    if (property) {
      let url =
        process.env.REACT_APP_API_URL + "/Dashboard/radiation_shared?toid=";
      if (userContext.userState.loggedIn) {
        url = process.env.REACT_APP_API_URL + "/Dashboard/radiation?toid=";
      }

      getData(url + property.reference, true, userContext.getCredentials)
        .then((response) => response.blob())
        .then((blob) => {
          setRadiation(URL.createObjectURL(blob));
        })
        .catch((e) => {
          console.log("couldn't get image");
        });
    }
  }
  useEffect(() => {
    getRadiation(props.data);
    if (userContext.userState.loggedIn) {
      setSettingShared(true);
      GetShared()
        .then((sharedBackend: boolean) => {
          setShared(sharedBackend);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setSettingShared(false);
        });
    }
  }, []);

  function updateShared(checked: boolean) {
    setSettingShared(true);
    setShared(checked);
    GetShared()
      .then((sharedBackend: boolean) => {
        if (sharedBackend !== checked) {
          putData(
            process.env.REACT_APP_API_URL +
              "/dashboard/shared?reference=" +
              props.data.reference +
              "&shared=" +
              (checked ? "true" : "false"),
            {},
            true,
            userContext.getCredentials
          )
            .then((res) => {
              console.log("successful sharing set");
            })
            .catch((e) => {
              alert(
                "there is a mismatch between your frontend and the shared value stored in the DB, I am resetting to correct value, please then try again"
              );
              GetShared()
                .then((sharedBackend: boolean) => {
                  setShared(sharedBackend);
                })
                .catch((e) => {
                  alert(
                    "there is a mismatch between your frontend and the shared value stored in the DB, I am resetting to correct value, please then try again"
                  );
                  console.log(e);
                })
                .finally(() => {
                  setSettingShared(false);
                });
            })
            .finally(() => {
              setSettingShared(false);
            });
        } else {
          alert(
            "there is a mismatch between your frontend and the shared value stored in the DB, I am resetting to correct value, please then try again"
          );
          setShared(sharedBackend);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setSettingShared(false));
  }
  const handleNewFileUpload = (file: FileList) => {
    //adding new files through button
    var fileToUse = file.item(0);
    if (fileToUse) {
      const isFileInArray = newUploadedFiles.some(
        (eachFile) => eachFile === fileToUse
      );
      if (!isFileInArray) {
        setNewUploadedFiles([...newUploadedFiles, fileToUse]);
      }
    }
  };
  const handleRemoveNewFile = (file: File) => {
    //removing newly added files
    setNewUploadedFiles(newUploadedFiles.filter((f) => f !== file));
  };

  const handleFinance = (
    event: React.MouseEvent<HTMLElement>,
    newFinance: string | null
  ) => {
    setFinance(newFinance);
  };

  function GetShared() {
    return getData(
      process.env.REACT_APP_API_URL +
        "/dashboard/shared?reference=" +
        props.data.reference
    ).then((data: DashboardData | null) => {
      if (data) {
        return true;
      } else {
        console.log("not shared");
        return false;
      }
    });
  }
  const handleDownloadReport = (property: DashboardData) => {
    props.getPdf(property);
  };

  function updateTemplateFilesStatus() {
    //we have file_templates and templatesChecked which are exactly teh same
    //but file_templates contain teh original values from teh backend
    //and templatesChecked contain the updated values

    //here, we update the changes in the backend:
    // Iterating through checkedCoco and comparing with originalCoco
    templatesChecked.forEach((eachCheck, index) => {
      if (
        file_templates[index] &&
        eachCheck.template === file_templates[index].template &&
        eachCheck.is_uploaded !== file_templates[index].is_uploaded
      ) {
        var endpointForPost =
          process.env.REACT_APP_API_URL +
          "/Template/ReferenceTemplates?reference=" +
          props.data.reference +
          "&template=" +
          eachCheck.template;
        var endpointForDelete =
          process.env.REACT_APP_API_URL +
          "/Template/ReferenceTemplates/" +
          props.data.reference +
          "/" +
          eachCheck.template;
        if (eachCheck.is_uploaded) {
          //it means we are removing it/marking it as done/etc
          postData(endpointForPost, [], true, userContext.getCredentials)
            .then((response) => {
              console.log("response is", response.text());
            })
            .then((data) => {
              console.log("response status is:", data);
            })
            .catch((e) => {
              console.log("unable to post change", e);
            });
        } else {
          //it means we are changing it from "done" to "undone"
          deleteData(endpointForDelete, true, userContext.getCredentials)
            .then((data) => {
              console.log("successful deletion");
            })
            .catch((e) => {
              console.log("failed to delete template", e);
            });
        }
      }
    });

    //here, we update the changes in the frontend:
    setFile_templates((prevTemplates) => {
      const updatedFileTemplates = prevTemplates.map((template, index) => {
        if (template.is_uploaded !== templatesChecked[index].is_uploaded) {
          return {
            ...template,
            is_uploaded: templatesChecked[index].is_uploaded,
          };
        }
        return template;
      });
      return updatedFileTemplates;
    });
  }

  const sideBarProps: ISideBarProps = {
    userContext,
    shared,
    settingShared,
    updateShared,
    projectProps: props,
    file_templates,
    file_names,
    handleNewFileUpload,
    newUploadedFiles,
    handleRemoveNewFile,
    templatesChecked,
    setTemplatesChecked,
    setFile_names,
    setNewUploadedFiles,
    updateTemplateFilesStatus,
    trackClick,
    handleDownloadReport,
    projectDelivery,
    setProjectDelivery,
    getNewProjectStageForNextSteps,
  };

  if (
    projectDelivery?.openSolarLink &&
    projectDelivery.openSolarLink.indexOf("https://") !== -1 &&
    projectDelivery.projectStage === 4
  ) {
    return (
      <div
        style={{ width: "100vw", height: "90vh", padding: "0px", margin: 0 }}
      >
        <ProposalSentIframe
          propertyData={props.data}
          fileNames={file_names}
          projectDelivery={projectDelivery}
          file_templates={file_templates}
          getPdf={props.getPdf}
          handleDownloadFile={props.handleDownloadFile}
          dashboardDatas={props.dashboardDatas}
          setDashboardDatas={props.setDashboardDatas}
          setLoadPdfDownloading={(newBol) =>
            props.setLoadPdfDownloading(newBol)
          }
          sideBarProps={sideBarProps}
        />
      </div>
    );
  }

  if (projectDelivery?.projectStage === 5) {
    return (
      <div style={{ height: "90vh", padding: "0px", margin: 0 }}>
        <ProjectLandingPage
          data={props.data}
          user={userContext.userState}
          sideBarProps={sideBarProps}
        />
      </div>
    );
  }
  if (!projectDelivery) {
    return <LoadingOverlayWrapper />;
  }
  return (
    <div>
      <div
        style={{
          width: "90%",
          maxWidth: "1200px",
          margin: "30px auto",
          textAlign: "left",
        }}
      >
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item md={12} lg={9}>
            <div
              style={{
                fontSize: "22px",
                color: "#1976d2",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              Your solar assessment results
            </div>

            <Grid container spacing={1} justifyContent="space-between">
              <Grid item xs={12} md={6}>
                <p style={{ margin: 0 }}>
                  {props.data.address.split(",").map((x) => {
                    return (
                      <>
                        {" "}
                        {x} <br />{" "}
                      </>
                    );
                  })}
                </p>
              </Grid>
              <Grid item xs={12} md={5}>
                <Table
                  size="small"
                  style={{
                    marginLeft: "20px",
                    // width: "auto",
                    tableLayout: "auto",
                    float: "right",
                  }}
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>Reference No.</TableCell>
                    <TableCell style={{ textTransform: "uppercase" }}>
                      {props.data.reference}
                    </TableCell>
                  </TableRow>
                  {userContext.isNatwest ||
                  props.data.organisation_id === 26 ||
                  props.data.organisation_id === 27 ||
                  props.data.organisation_id === 18 ? null : (
                    <>
                      <TableRow>
                        <TableCell>Property type</TableCell>
                        <TableCell
                          style={{
                            textOverflow: "ellipsis",
                            maxWidth: "150px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {props.data.class_sub_desc}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  <TableRow>
                    <TableCell>Assessment date</TableCell>
                    <TableCell>
                      {props.data.assessment_date.split("T")[0]}
                    </TableCell>
                  </TableRow>
                </Table>
                {/* <Button
                  startIcon={<DownloadIcon />}
                  variant="contained"
                  disabled={props.data.install_capacity_kw === 0}
                  fullWidth
                  style={{ marginTop: 20, textTransform: "none" }}
                  onClick={() => {
                    props.setLoadPdfDownloading(true);
                    handleDownloadReport(props.data);
                    trackClick("Download PDF page top");
                  }}
                >
                  Download PDF report
                </Button> */}
                <Button
                  startIcon={<DownloadIcon />}
                  variant="contained"
                  disabled={props.data.install_capacity_kw === 0}
                  fullWidth
                  style={{ marginTop: 20, textTransform: "none" }}
                  onClick={handleDocumentDrawerOpen}
                >
                  Project Documents
                </Button>
              </Grid>
            </Grid>
            <Drawer
              anchor={"right"}
              open={documentDrawerOpen}
              onClose={handleDocumentDrawerClose}
            >
              <div style={{ width: "100%", maxWidth: "400px", padding: 20 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">Project documents</Typography>
                  <IconButton onClick={handleDocumentDrawerClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <Typography
                  variant="subtitle2"
                  style={{
                    borderLeft: "2px #03a9f4 solid",
                    padding: "5px 0px 5px 20px",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <strong>Tips</strong>: We have stored all documents and data
                  files for you to access at any time.
                </Typography>
                {file_templates.length > 0 ? (
                  <div style={{ marginTop: "5px" }}>
                    <Button
                      startIcon={<AttachFileIcon />}
                      disabled={props.data.install_capacity_kw === 0}
                      onClick={() => props.getPdf(props.data)}
                      size="small"
                      fullWidth
                      style={{
                        textTransform: "none",
                        justifyContent: "flex-start",
                        padding: 0,
                        textAlign: "left",
                        fontSize: "14px",
                        marginBottom: 5,
                      }}
                    >
                      {"Feasibility Report"}
                    </Button>

                    {file_templates.map((eachTemplate, index) =>
                      eachTemplate.is_uploaded ? null : ( // ) //   </Button> //     {eachTemplate.template.replace(/_/g, " ")} //   <Button startIcon={<AttachFileIcon />} onClick={() => eachTemplate.template.toLowerCase().includes("feasibility") ? projectProps.getPdf(projectProps.data) : null} size="small" fullWidth style={{ textTransform: "none", justifyContent: "flex-start", padding: 0 }} key={index}> //   : //   null // (userContext.userState.username.includes("absolar.co.uk") ? //-and if that button is "feasibility", download pdf //-show it as a normal button for the user to be able to interact with //now, a check for the average user: if a template file is marked as done:
                        <Tooltip
                          title={
                            <Typography variant="body2">
                              This document requires the completion of a site
                              survey. Please contact Absolar to arrange a site
                              survey
                            </Typography>
                          }
                        >
                          <span>
                            <Button
                              startIcon={<AttachFileIcon />}
                              disabled
                              size="small"
                              fullWidth
                              style={{
                                textTransform: "none",
                                justifyContent: "flex-start",
                                padding: 0,
                                textAlign: "left",
                                fontSize: "14px",
                                marginBottom: 5,
                              }}
                              key={index}
                            >
                              {eachTemplate.template.replace(/_/g, " ")}
                            </Button>
                          </span>
                        </Tooltip>
                      )
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {file_names.map((eachFile: string, index) => {
                    if (eachFile.includes("images/")) return;
                    if (eachFile.indexOf(props.data.reference + "/") !== -1) {
                      return (
                        <Button
                          startIcon={<AttachFileIcon />}
                          key={index}
                          size="small"
                          onClick={() =>
                            props.handleDownloadFile(
                              eachFile,
                              props.data.reference
                            )
                          }
                          style={{
                            textTransform: "none",
                            justifyContent: "flex-start",
                            padding: 0,
                            textAlign: "left",
                            fontSize: "14px",
                            marginBottom: 5,
                          }}
                        >
                          {eachFile.replace(props.data.reference + "/", "")}
                        </Button>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                <Typography
                  style={{ marginTop: 10, fontSize: "12px", color: "grey" }}
                >
                  <a
                    href={
                      "mailto:survey@absolar.co.uk?subject=Solar Assessment Enquiry - " +
                      props.data.reference.toUpperCase()
                    }
                  >
                    Contact Absolar
                  </a>{" "}
                  to obtain more detailed project documents including cashflow
                  projections and NPV forecast.
                </Typography>
              </div>
            </Drawer>

            <hr style={{ margin: "20px auto" }} />
            {props.data.comments ? (
              <div
                style={{
                  background: "#7b1fa2",
                  color: "white",
                  padding: "20px 30px",
                }}
              >
                <Typography variant="body2">
                  <b>Important information regarding the assessment: </b>
                  {props.data.comments}
                </Typography>
              </div>
            ) : null}

            {userContext.isNatwest ? (
              <>
                <div style={{ margin: "50px auto 20px auto" }}>
                  <ToggleButtonGroup
                    sx={{
                      display: { xs: "grid", sm: "grid", md: "block" },
                      width: { xs: 200, sm: 200, md: 400 },
                    }}
                    color="primary"
                    size="large"
                    value={finance_option}
                    exclusive
                    onChange={handleFinance}
                    style={{ margin: "auto" }}
                  >
                    <ToggleButton
                      value="cash_purchase"
                      style={{ width: "200px" }}
                      // onClick={()=>{trackClick("Cash purchase button")}}
                    >
                      Cash purchase
                    </ToggleButton>
                    <ToggleButton
                      // onClick={()=>{trackClick("Bank loan button")}}
                      value="bank_loan"
                      style={{ width: "200px" }}
                    >
                      Bank loan
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </>
            ) : null}
            <Grid
              container
              style={{ minHeight: "180px" }}
              spacing={2}
              justifyItems="flex-end"
            >
              <Grid item xs={12} sm={6} md={3} className="hover_effect">
                <div
                  style={{
                    background: "#e8e8e8",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div style={{ margin: "10px auto 5px auto" }}>
                    <ViewModuleIcon
                      style={{
                        fontSize: "50px",
                        marginTop: 20,
                        color: "#1976d2",
                      }}
                    />
                  </div>
                  <div style={{ fontSize: "30px" }}>
                    {props.data.install_capacity_kw
                      ? props.data.install_capacity_kw.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 1,
                          }
                        ) + " kW"
                      : "N/A"}
                  </div>
                  <div>System Size</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className="hover_effect">
                <div
                  style={{
                    background: "#e8e8e8",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div style={{ margin: "10px auto 5px auto" }}>
                    <SavingsIcon
                      style={{
                        fontSize: "50px",
                        marginTop: 20,
                        color: "#1976d2",
                      }}
                    />
                  </div>
                  <div style={{ fontSize: "30px" }}>
                    {props.data.potential_saving_year_1
                      ? "£ " +
                        props.data.potential_saving_year_1.toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )
                      : "N/A"}
                  </div>
                  <div>Estimated First Year Savings</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className="hover_effect">
                <div
                  style={{
                    background: "#e8e8e8",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div style={{ margin: "10px auto 5px auto" }}>
                    <EnergySavingsLeafIcon
                      style={{
                        fontSize: "50px",
                        marginTop: 20,
                        color: "#1976d2",
                      }}
                    />
                  </div>
                  <div style={{ fontSize: "30px" }}>
                    {props.data.co2_saving_year_1
                      ? (props.data.co2_saving_year_1 / 1).toLocaleString(
                          undefined,
                          { maximumFractionDigits: 1 }
                        ) + " Tonnes"
                      : "N/A"}{" "}
                  </div>
                  <div>
                    CO<sub>2e</sub> saving in year 1
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className="hover_effect">
                <div
                  style={{
                    background: "#01579b",
                    height: "100%",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <div style={{ margin: "10px auto 5px auto" }}>
                    {finance_option === "cash_purchase" ? (
                      <CurrencyPoundIcon
                        style={{ fontSize: "50px", marginTop: 20 }}
                      />
                    ) : (
                      <AccountBalanceIcon
                        style={{ fontSize: "50px", marginTop: 20 }}
                      />
                    )}
                  </div>
                  <div style={{ fontSize: "30px" }}>
                    {finance_option === "cash_purchase"
                      ? props.data.indicative_cost
                        ? "£ " +
                          props.data.indicative_cost.toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                          })
                        : "N/A"
                      : props.data.monthly_repayment
                      ? "£ " +
                        props.data.monthly_repayment.toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })
                      : "N/A"}
                    {/* {finance_option === "cash_purchase"
                    ? props.data.indicative_cost
                      ? "£ " + props.data.indicative_cost.toLocaleString()
                      : "N/A"
                    : props.data.address ===
                      "EPSILON HOUSE, ENTERPRISE ROAD, SOUTHAMPTON SCIENCE PARK, CHILWORTH, SO16 7NP"
                    ? "£ 1,205"
                    : "N/A"} */}
                  </div>
                  <div>
                    {finance_option === "cash_purchase"
                      ? "Indicative cost (excl VAT)"
                      : "Per month with Bank Loan*"}
                  </div>
                </div>
              </Grid>
            </Grid>
            {finance_option === "cash_purchase" ? (
              <div
                style={{
                  border: "#dddddd 1px solid",
                  // borderRadius:5,
                  // background: "#e8e8e8",
                  // borderLeft:"1px solid lightgrey",
                  // minHeight: "100vh",
                  // height: "calc(100% - 60px)",
                  padding: "20px 15px",
                  // maxHeight:"90vh",
                  // overflow:"auto",
                  marginTop: 50,
                  boxShadow: "2px 2px 8px 1px grey",
                  borderRadius: "6px",
                  // padding: 20,
                }}
              >
                <div style={{ fontSize: "22px", fontWeight: "500" }}>
                  Investment performance - based on cash purchase
                </div>
                <Table>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow className="table_row_result_page">
                    <TableCell>Lifetime net savings (over 20 years)</TableCell>
                    <TableCell style={{ fontSize: "18px", textAlign: "right" }}>
                      {props.data.total_utility_bill_savings_20
                        ? "£" +
                          parseFloat(
                            props.data.total_utility_bill_savings_20.toFixed(0)
                          ).toLocaleString()
                        : "Not Applicable"}
                    </TableCell>
                  </TableRow>
                  <TableRow className="table_row_result_page">
                    <TableCell>Payback</TableCell>
                    <TableCell style={{ fontSize: "18px", textAlign: "right" }}>
                      {props.data.payback}
                    </TableCell>
                  </TableRow>
                  <TableRow className="table_row_result_page">
                    <TableCell>Investment return</TableCell>
                    <TableCell style={{ fontSize: "18px", textAlign: "right" }}>
                      {props.data.roi
                        ? props.data.roi.toLocaleString(undefined, {
                            maximumFractionDigits: 1,
                          }) + "%"
                        : "Not Applicable"}
                    </TableCell>
                  </TableRow>
                </Table>
              </div>
            ) : (
              <div
                style={{
                  marginTop: 50,
                  boxShadow: "2px 2px 8px 1px grey",
                  borderRadius: "6px",
                  padding: 20,
                }}
              >
                <div style={{ fontSize: "22px", fontWeight: "500" }}>
                  Investment performance - based on using bank loans
                </div>
                <Table>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow className="table_row_result_page">
                    <TableCell>Monthly repayment (over 5 years) </TableCell>
                    <TableCell style={{ fontSize: "18px", textAlign: "right" }}>
                      {props.data.monthly_repayment
                        ? "£ " +
                          props.data.monthly_repayment.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 0,
                            }
                          )
                        : "Not Applicable"}
                    </TableCell>
                  </TableRow>
                  <TableRow className="table_row_result_page">
                    <TableCell>Lifetime net savings (over 20 years)</TableCell>
                    <TableCell style={{ fontSize: "18px", textAlign: "right" }}>
                      {props.data.loan_total_utility_bill_savings_20
                        ? "£" +
                          parseFloat(
                            props.data.loan_total_utility_bill_savings_20.toFixed(
                              0
                            )
                          ).toLocaleString()
                        : "Not Applicable"}
                    </TableCell>
                  </TableRow>
                  <TableRow className="table_row_result_page">
                    <TableCell>Payback</TableCell>
                    <TableCell style={{ fontSize: "18px", textAlign: "right" }}>
                      {props.data.loan_payback}
                    </TableCell>
                  </TableRow>
                  <TableRow className="table_row_result_page">
                    <TableCell>Investment return</TableCell>
                    <TableCell style={{ fontSize: "18px", textAlign: "right" }}>
                      {props.data.loan_roi
                        ? props.data.loan_roi.toLocaleString(undefined, {
                            maximumFractionDigits: 1,
                          }) + "%"
                        : "Not Applicable"}
                    </TableCell>
                  </TableRow>
                </Table>
                <div style={{ marginTop: 10, textAlign: "right" }}>
                  * Based on an indicative 60 month loan at 9.0% APR
                </div>
              </div>
            )}

            <Grid
              container
              justifyContent="space-evenly"
              style={{
                // marginTop: 30,
                boxShadow: "2px 2px 8px 1px grey",
                borderRadius: "6px",
                padding: 20,
                width: "100%",
                margin: "30px auto",
              }}
              spacing={2}
              // sx={{ display: { xs: 'none', sm: 'none' , md:'inline-flex' } }}
            >
              <Grid item xs={12} md={6} style={{ padding: 0 }}>
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "500",
                      marginBottom: 10,
                    }}
                  >
                    Suitable Roof Area
                  </div>
                  <div id="radiation_image">
                    {image == "" ? (
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="300px"
                        height="100%"
                        style={{ margin: "auto" }}
                      />
                    ) : (
                      <img
                        alt="image of the building rooftop with an outline for the building and the solar area"
                        src={image}
                        style={{ height: "100%", margin: "auto" }}
                      />
                    )}
                  </div>
                  <img src={SRA_image_legend} style={{ width: "100%" }} />
                </div>
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: 0 }}>
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "500",
                      marginBottom: 10,
                      padding: 0,
                    }}
                  >
                    Solar radiation analysis
                  </div>
                  <div id="radiation_image">
                    {radiation == "" ? (
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="300px"
                        height="100%"
                        style={{ margin: "auto" }}
                      />
                    ) : (
                      <img
                        src={radiation}
                        style={{
                          height: "100%",
                          margin: "auto",
                          maxWidth: "80%",
                        }}
                      />
                    )}
                  </div>
                  <img src={rad_image_legend} style={{ width: "100%" }} />
                </div>
              </Grid>
            </Grid>
            <Accordion
              style={{
                marginTop: 30,
                boxShadow: "2px 2px 8px 1px grey",
                borderRadius: "6px",
                padding: 10,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{ fontSize: "22px", fontWeight: "500" }}
                onClick={() => {
                  trackClick("Assumption accordion");
                }}
              >
                Assumptions
              </AccordionSummary>

              <Table size="small">
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow className="table_row_result_page">
                  <TableCell>Type of installation </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {props.data.type_of_installation}
                  </TableCell>
                </TableRow>
                {props.data.max_building_height === null ? null : (
                  <>
                    <TableRow className="table_row_result_page">
                      <TableCell>Max building height </TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        {" "}
                        {props.data.max_building_height}
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {props.data.min_building_height === null ? null : (
                  <>
                    <TableRow className="table_row_result_page">
                      <TableCell>Roof start height </TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        {props.data.min_building_height}
                      </TableCell>
                    </TableRow>
                  </>
                )}
                <TableRow className="table_row_result_page">
                  <TableCell>Self consumption </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {props.data.self_consumption}%
                  </TableCell>
                </TableRow>
                <TableRow className="table_row_result_page">
                  <TableCell>Reference panel used </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {props.data.reference_panel}
                  </TableCell>
                </TableRow>
                <TableRow className="table_row_result_page">
                  <TableCell>Energy price inflation </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {props.data.energy_price_inflation?.toFixed(0)}%
                  </TableCell>
                </TableRow>
                <TableRow className="table_row_result_page">
                  <TableCell>Assumed carbon equiv. factor </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {props.data.assum_carbon_factor?.toFixed(3)} kg/kWh
                  </TableCell>
                </TableRow>
                <TableRow className="table_row_result_page">
                  <TableCell>Assumed tariffs </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {props.data.assum_tariff}
                  </TableCell>
                </TableRow>
                {userContext.isNatwest ||
                props.data.organisation_id === 25 ||
                props.data.organisation_id === 26 ||
                props.data.organisation_id === 27 ||
                props.data.organisation_id === 18 ? null : (
                  <>
                    <TableRow className="table_row_result_page">
                      <TableCell>Is this a listed building? </TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        {props.data.listed ? "Yes" : "No / Not Assessed"}
                      </TableCell>
                    </TableRow>

                    <TableRow className="table_row_result_page">
                      <TableCell>
                        Is this building in a conservation area?{" "}
                      </TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        {props.data.conservation_area
                          ? "Yes"
                          : "No / Not Assessed"}
                      </TableCell>
                    </TableRow>
                    <TableRow className="table_row_result_page">
                      <TableCell>
                        Is this building in a council property?{" "}
                      </TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        {props.data.council_property
                          ? "Yes"
                          : "No / Not Assessed"}
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </Table>
              <Button
                startIcon={<MailOutlineIcon />}
                href={
                  "mailto:survey@absolar.co.uk?subject=Solar Assessment Enquiry - Change of assumptions - " +
                  props.data.reference.toUpperCase()
                }
                target="_blank"
                style={{ textTransform: "none", marginLeft: 10 }}
              >
                Contact us to request an update should you wish to change any of
                the above assumptions
              </Button>
            </Accordion>

            <div
              style={{
                marginTop: 30,
                marginBottom: 30,
                boxShadow: "2px 2px 8px 1px grey",
                borderRadius: "6px",
                padding: 20,
              }}
            >
              <Typography>
                <p>
                  <strong>Disclaimer</strong> - All information on this report
                  is for informational purposes only and should not be relied
                  upon. It provides an illustration of what could potentially be
                  achieved based on a number of broad assumptions. Should you
                  decide to act upon any information in this report you do so at
                  your own risk.{" "}
                </p>
                <p>
                  While this information on the report has been verified to the
                  best of our abilities we cannot guarantee that there are no
                  mistakes or errors. Absolar Solutions Limited gives no
                  warranty and accepts no responsibility or liability for the
                  accuracy, or the completeness of the information and materials
                  contained in this report. Under no circumstances will Absolar
                  Solutions Limited be held responsible for any claims, damages,
                  losses, expenses, costs or liabilities whatsoever.
                </p>
                <p>
                  You are permitted to use this data solely to enable you to
                  respond to, or interact with, the organisation which has
                  provided you with the data. You are not permitted to copy,
                  sub-license, distribute or sell any of this data to third
                  parties in any form.
                </p>
              </Typography>
            </div>
          </Grid>
          <SideBar {...sideBarProps} />
        </Grid>
        {/* <Typography>&#169; Absolar 2023</Typography> */}
        <div
          style={{
            width: "80%",
            maxWidth: "1200px",
            margin: "40px auto 0px auto",
            paddingBottom: 20,
            textAlign: "center",
          }}
        >
          <div>
            <img
              src={Logo_colour}
              style={{ width: "250px", margin: "0px auto 10px auto" }}
            />
          </div>
          <div>
            <a
              className="footer_policy"
              href="https://www.absolar.co.uk/about"
              target="_blank"
              rel="noreferrer"
              // onClick={() => { trackClick("Footer - About Absolar") }}
            >
              About Absolar
            </a>{" "}
            |{" "}
            <a
              className="footer_policy"
              href="https://www.absolar.co.uk/joinourmission"
              target="_blank"
              rel="noreferrer"
              // onClick={() => { trackClick("Footer - Working with Absolar") }}
            >
              Working with Absolar
            </a>{" "}
            |{" "}
            <a
              className="footer_policy"
              href="https://www.absolar.co.uk/privacy-policy"
              target="_blank"
              rel="noreferrer"
              // onClick={() => { trackClick("Footer - Privacy Policy") }}
            >
              Privacy Policy
            </a>{" "}
            |{" "}
            <a
              className="footer_policy"
              href="https://www.absolar.co.uk/anti-slavery-human-trafficking-policy"
              target="_blank"
              rel="noreferrer"
              // onClick={() => { trackClick("Footer - Anti-Slavery & Human Trafficking Policy") }}
            >
              Anti-Slavery & Human Trafficking Policy
            </a>
          </div>
          <div className="footer_policy">
            Absolar is a trading name of Absolar Solutions Limited Registered in
            England No 12322471. © 2023 by Absolar Solutions Limited
          </div>
        </div>
        {userContext.userState.loggedIn ? null : (
          <CookieConsent
            // debug={true}
            style={{ textAlign: "center", backgroundColor: "#061111" }}
            buttonStyle={{
              backgroundColor: "white",
              borderRadius: "3px",
              fontSize: "15px",
              boxShadow: "3px 3px 6px #b79965",
            }}
          >
            We use cookies to ensure you get the best experience on our website.
            By continuing to browse our site, you consent to our use of cookies.
            For more information, please read our{" "}
            <a
              target="_blank"
              style={{ color: "#b79965" }}
              href="https://www.absolar.co.uk/privacy-policy"
              rel="noreferrer"
            >
              Privacy policy
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              style={{ color: "#b79965" }}
              href="https://www.absolar.co.uk/cookie-policy"
              rel="noreferrer"
            >
              Cookie policy
            </a>{" "}
            for more.
          </CookieConsent>
        )}
      </div>
    </div>
  );
}
