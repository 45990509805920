import React from "react";

export const UserContext = React.createContext<{
  userState: UserState;
  setUserState: (value: React.SetStateAction<UserState>) => void;
  getCredentials: (expiredCredentials?: UserState) => UserState | null;
  isNatwest: boolean;
  userInfo: UserInformation;
  getNotificationsData: () => void;
}>({
  userState: {
    idToken: "",
    refreshToken: "",
    accessToken: "",
    username: "",
    loggedIn: false,
    groups: [],
    expired: false,
  },
  setUserState: (value: React.SetStateAction<UserState>) => {
    console.error("error set user state function not properly set");
  },
  getCredentials: (expiredCredentials?: UserState) => null,
  isNatwest: false,
  userInfo: {
    company: "",
    email: "",
    location: "",
    name: "",
    phone: "",
    title: "",
    user_group: "",
  },
  getNotificationsData: () => {
    console.error("notifications can't be fetched");
  },
});
