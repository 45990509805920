import React, { useState, useEffect, Fragment, useContext } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
  Modal,
  Box,
  Drawer,
  Typography,
  IconButton,
  Card,
  CardContent,
  Fab,
  CardMedia,
  Divider,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Link,
  CardActionArea,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import SupportPage from "./supportDrawer/supportPage";
import LandingDrawerPage from "./supportDrawer/landingDrawer";
import SupportPageContact from "./supportDrawer/supportPageContact";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ModalApprovedInstaller from "./infoPages/ApprovedInstaller";
import ModalFAQ from "./infoPages/FaqModal";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ModalTestimonial from "./infoPages/TestimonialVideo";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import YouTubeIcon from "@mui/icons-material/YouTube";
import data_display_image from "./images/data_display.png";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavigationIcon from "@mui/icons-material/Navigation";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import { getData } from "./get-data";
import { UserContext } from "./contexts/UserContext";
import { ISideBarProps, SideBar } from "./project_page/side_bar";

const usersdummy = [
  {
    name: "Alison Basingstoke",
    organisation: "Basingstoke Council",
    id: 1,
  },
  {
    name: "John Smith",
    organisation: "JS Ltd",
    id: 2,
  },
  {
    name: "Tim Cook",
    organisation: "Apple Inc.",
    id: 3,
  },
];
interface IProps {
  data: DashboardData;
  user: UserState;
  sideBarProps: ISideBarProps;
}

export default function ProjectLandingPage(props: IProps) {
  const [hover, setHover] = useState(false);
  const userContext = useContext(UserContext);

  function getBusinessCase() {
    const name = props.data.reference + "_business_case.xlsx";

    getData(
      process.env.REACT_APP_API_URL +
        "/Dashboard/business_case?reference=" +
        props.data.reference,
      true,
      userContext.getCredentials
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
      .catch((e) => {
        alert(
          "Business case report not available. Please contact survey@absolar.co.uk if the problem persists"
        );
      });
  }
  useEffect(() => {
    userContext.getNotificationsData();
  }, []);
  function getSiteSurveyReport() {
    const name = props.data.reference + "_site_survey.pdf";

    getData(
      process.env.REACT_APP_API_URL +
        "/Dashboard/site_survey?reference=" +
        props.data.reference,
      true,
      userContext.getCredentials
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
      .catch((e) => {
        alert(
          "Site survey report not available. Please contact survey@absolar.co.uk if the problem persists"
        );
      });
  }

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  let navigate = useNavigate();

  const [drawerOpen_old, setDrawerOpen_old] = useState<boolean>(false);
  const handleDrawerOpen_old = () => {
    setDrawerOpen_old(true);
  };

  const handleDrawerClose_old = () => {
    setDrawerOpen_old(false);
  };

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const [open_approved_installer, setOpen_approved_installer] = useState(false);
  const handleOpen_approved_installer = () => setOpen_approved_installer(true);
  const handleClose_approved_installer = () =>
    setOpen_approved_installer(false);

  const [open_testimonial, setOpen_testimonial] = useState(false);
  const handleOpen_testimonial = () => setOpen_testimonial(true);
  const handleClose_testimonial = () => setOpen_testimonial(false);

  const [open_faq, setOpen_faq] = useState(false);
  const handleOpen_faq = () => setOpen_faq(true);
  const handleClose_faq = () => setOpen_faq(false);

  const [open_virtual_meeting, setOpen_virtual_meeting] = useState(false);
  const handleOpen_virtual_meeting = () => setOpen_virtual_meeting(true);
  const handleClose_virtual_meeting = () => setOpen_virtual_meeting(false);

  const [open_site_survey, setOpen_site_survey] = useState(false);
  const handleOpen_site_survey = () => setOpen_site_survey(true);
  const handleClose_site_survey = () => setOpen_site_survey(false);

  const [user, setUser] = React.useState("");

  const handleChangeUser = (event: SelectChangeEvent) => {
    setUser(event.target.value as string);
  };

  const [content_supportBar, setContent_supportBar] = useState<React.ReactNode>(
    <>
      <div
        style={{
          width: "calc(100% - 40px)",
          maxWidth: "500px",
          padding: 20,
          height: "calc(100vh - 120px - 40px - 40px)",
          overflow: "auto",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Support & information</Typography>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <LandingDrawerPage />
      </div>
      <div
        style={{
          height: "120px",
          width: "calc(100% - 40px)",
          bottom: "0px",
          position: "absolute",
          padding: "20px",
          boxShadow: " 0px -1px 5px #cccccc",
          background: "#E4F3FF",
          zIndex: 10,
        }}
      >
        <Button variant="contained" fullWidth onClick={handleClick_contact}>
          Contact us
        </Button>
        <Grid
          container
          justifyContent="space-between"
          style={{
            marginTop: 10,
            // marginBottom: 10,
            overflowWrap: "break-word",
            color: "#1565c0",
            fontWeight: "500",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={2}
            sx={{ textAlign: { md: "center", lg: "left" } }}
          >
            <LocalPhoneIcon style={{ fontSize: "24px" }} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={10}
            sx={{ textAlign: { md: "center", lg: "right" } }}
          >
            <span
              style={{
                fontSize: "16px",
              }}
            >
              02382 680106
            </span>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          style={{
            marginTop: 10,
            overflowWrap: "break-word",
            color: "#1565c0",
            fontWeight: "500",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={2}
            sx={{ textAlign: { md: "center", lg: "left" } }}
          >
            <MailOutlineIcon style={{ fontSize: "24px" }} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={10}
            sx={{ textAlign: { md: "center", lg: "right" } }}
          >
            <span
              style={{
                fontSize: "16px",
                overflowWrap: "break-word",
                maxWidth: "100%",
              }}
            >
              survey@absolar.co.uk
            </span>
          </Grid>
        </Grid>
      </div>
    </>
  );
  const [previousContent_supportBar, setPreviousContent_supportBar] = useState<
    string[]
  >([]);
  const [contentChanged_supportBar, setContentChanged_supportBar] =
    useState(false);

  function handleClick_contact() {
    setPreviousContent_supportBar([
      ...previousContent_supportBar,
      content_supportBar as string,
    ]);
    setContent_supportBar(<SupportPageContact />);
    setContentChanged_supportBar(true);
  }

  function handleGoBack_supportBar() {
    const previous = previousContent_supportBar.pop();
    if (previous) {
      setContent_supportBar(previous);
      setPreviousContent_supportBar(previousContent_supportBar);
      setContentChanged_supportBar(previousContent_supportBar.length > 0);
    }
  }

  return (
    <div
      style={{
        width: "90%",
        maxWidth: "1200px",
        margin: "30px auto",
        textAlign: "left",
      }}
    >
      <Fab
        onClick={handleDrawerOpen_old}
        color="primary"
        variant="extended"
        style={{
          float: "right",
          position: "fixed",
          right: "40px",
          bottom: "40px",
          textTransform:"none", 
          fontSize:"18px"
        }}
      >
        <ArrowLeftIcon sx={{ mr: 1 }} />
        Documents
      </Fab>
      <Typography variant="h5" noWrap>
        {props.data.address}
      </Typography>
      <Typography variant="subtitle2">
        Reference No.: {props.data.reference}
      </Typography>
      <Typography variant="subtitle2">
        Assessment Date: {props.data.assessment_date}
      </Typography>

      {/* <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Button
          onClick={() => {
            navigate("/project");
          }}
          startIcon={<ArrowBackIcon />}
        >
          Go back
        </Button>
      </div> */}

      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Grid container spacing={3}>
          <Grid item xs={2} sm={2} md={1}>
            <div
              style={{
                background: "#1976d2",
                color: "white",
                borderRadius: "100%",
                height: 40,
                width: 40,
                textAlign: "center",
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              1
            </div>
            <div style={{ height: "100%", width: "20px" }}>
              <Divider
                orientation="vertical"
                variant="fullWidth"
                textAlign="center"
                style={{ height: "calc(100% - 20px)", marginTop: 10 }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={11}
            container
            spacing={3}
            style={{ paddingBottom: 20 }}
          >
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <Accordion
                defaultExpanded
                style={{ border: "none", boxShadow: "none" }}
              >
                <AccordionSummary
                  style={{ padding: 0 }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#1976d2",
                        textTransform: "none",
                        alignItems: "center !important",
                      }}
                    >
                      Feasibility assessment
                    </Typography>
                    {/* <IconButton color="info">
                    <InfoOutlinedIcon style={{ fontSize: "30px" }} />
                  </IconButton> */}
                  </div>
                </AccordionSummary>

                <CardActionArea
                  onClick={handleDrawerOpen}
                  style={{
                    marginBottom: 10,
                    padding: "10px 0px",
                    background: "#eeeeee",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      borderLeft: "2px #03a9f4 solid",
                      padding: "5px 0px 5px 20px",
                    }}
                  >
                    <strong>Tips</strong>: We have stored all documents and data
                    files for you to access at any time. Click <Link>here</Link>{" "}
                    to view and download.
                  </Typography>
                </CardActionArea>
                <Typography variant="body2" paragraph>
                  We use AI and remote sensing technologies to survey your
                  building remotely and{" "}
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={handleDrawerOpen}
                  >
                    help you review its potential
                  </Link>{" "}
                  to install solar PV systems . Additionaly, our{" "}
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={handleDrawerOpen}
                  >
                    Financial Calculator
                  </Link>
                  , below, helps you further assess the financial viability of
                  the project.
                </Typography>
                <Typography variant="body2" paragraph>
                  The next step is to conduct a{" "}
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={handleOpen_site_survey}
                  >
                    site survey
                  </Link>
                  , when an Absolar engineer will visit and assess the roof
                  condition and electronic system within the property. This
                  would help the engineer to verify the design of solar PV
                  system, propose the installation configurations, and confirm
                  project costings.
                </Typography>
                <Typography variant="body2" paragraph>
                  After the site survey, a site survey report will be provide to
                  you, together with the project proposal, as well as the{" "}
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={handleDrawerOpen}
                  >
                    contract
                  </Link>{" "}
                  for you to review.
                </Typography>
              </Accordion>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              style={{ textAlign: "center" }}
            >
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <IconButton
                  style={{ float: "right", right: "0px", zIndex: 10 }}
                >
                  <CheckCircleIcon
                    color="primary"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      color="primary"
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        // color: "grey",
                      }}
                    >
                      {/* <CheckCircleOutlineOutlinedIcon style={{ marginRight: 10 }} /> */}
                      Feasibility report
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Roof space assessment</li>
                      <li>Solar radiation simulation</li>
                      <li>CAPEX estimate</li>
                      <li>Payback period</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                      navigate("/project");
                    }}
                  >
                    View details
                  </Button>
                </div>
              </Card>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <IconButton
                  style={{ float: "right", right: "0px", zIndex: 10 }}
                >
                  <CheckCircleIcon
                    color="primary"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      color="primary"
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      Business Case
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Cash flow projection</li>
                      <li>Finance calculator</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      getBusinessCase();
                    }}
                  >
                    View details
                  </Button>
                </div>
              </Card>
            </Grid> */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <IconButton
                  style={{ float: "right", right: "0px", zIndex: 10 }}
                >
                  <CheckCircleIcon

                    color="primary"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      color="primary"
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      Schedule site survey
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>On-site survey</li>
                      <li>Review survey report</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    color= "primary"
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      props.data.site_survey_date === "" ||
                      props.data.site_survey_date === null
                        ? handleOpen_site_survey()
                        : getSiteSurveyReport();
                    }}
                  >
                      Download survey report
                  </Button>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <IconButton
                  style={{ float: "right", right: "0px", zIndex: 10 }}
                >
                  <CheckCircleIcon
                    color="primary"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      color="primary"
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      Proposal & contract
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Review project proposal</li>
                      <li>Contract</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    // disabled={
                    //   props.data.open_solar_link === null ||
                    //   props.data.open_solar_link === ""
                    // }
                    color={
                      props.data.open_solar_link === null ||
                      props.data.open_solar_link === ""
                        ? "primary"
                        : "primary"
                    }
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    href={props.data.open_solar_link}
                    target="_blank"
                  >
                    Download proposal
                  </Button>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          <Grid item xs={2} sm={2} md={1}>
            <div
              style={{
                background: "#1976d2",
                // background: "lightgrey",
                color: "white",
                borderRadius: "100%",
                height: 40,
                width: 40,
                textAlign: "center",
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              2
            </div>
            <div style={{ height: "100%", width: "20px" }}>
              <Divider
                orientation="vertical"
                variant="fullWidth"
                textAlign="center"
                style={{ height: "calc(100% - 20px)", marginTop: 10 }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={11}
            container
            spacing={3}
            style={{ paddingBottom: 20 }}
          >
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: "#1976d2" }}>
                Preparation, applications and installation
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <IconButton
                  style={{ float: "right", right: "0px", zIndex: 10 }}
                >
                  <RadioButtonUncheckedOutlinedIcon
                    color="primary"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      color="primary"
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        // color: "grey",
                      }}
                    >
                      Grid permission
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Submitted: 2 May 2023</li>
                      <li>Processed: 3 May 2023</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    View details
                  </Button>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <IconButton
                  style={{ float: "right", right: "0px", zIndex: 10 }}
                >
                  <RadioButtonUncheckedOutlinedIcon
                    color="disabled"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      color="primary"
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "grey",
                      }}
                    >
                      Project tracking
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Live project progress update</li>
                      <li>Equipment delivery tracking</li>
                      <li>Health and safety check-in</li>
                      <li>Project timetable</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    Open portal
                  </Button>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <IconButton
                  style={{ float: "right", right: "0px", zIndex: 10 }}
                >
                  <RadioButtonUncheckedOutlinedIcon
                    color="disabled"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      color="primary"
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "grey",
                      }}
                    >
                      <AttachFileIcon style={{ marginRight: 10 }} />
                      Handover files
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Certificates</li>
                      <li>User manual</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    Download
                  </Button>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <IconButton
                  style={{ float: "right", right: "0px", zIndex: 10 }}
                >
                  <RadioButtonUncheckedOutlinedIcon
                    color="disabled"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      color="primary"
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "grey",
                      }}
                    >
                      {/* <AttachFileIcon style={{ marginRight: 10 }} /> */}
                      Gallery
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Drone videos</li>
                      <li>Photographic images</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    View Gallery
                  </Button>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          <Grid item xs={2} sm={2} md={1}>
            <div
              style={{
                // background: "#1976d2",
                background: "lightgrey",
                // color: "white",
                borderRadius: "100%",
                height: 40,
                width: 40,
                textAlign: "center",
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              3
            </div>
            {/* <div style={{ height: "100%", width: "20px" }}>
              <Divider
                orientation="vertical"
                variant="fullWidth"
                textAlign="center"
                style={{ height: "calc(100% - 20px)", marginTop: 10 }}
              />
            </div> */}
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={11}
            container
            spacing={3}
            style={{ paddingBottom: 20 }}
          >
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: "#1976d2" }}>
                Maintainance and energy export management
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <IconButton
                  style={{ float: "right", right: "0px", zIndex: 10 }}
                >
                  <RadioButtonUncheckedOutlinedIcon
                    color="disabled"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      color="primary"
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "grey",
                      }}
                    >
                      {/* <ConnectedTvIcon style={{ marginRight: 10 }} /> */}
                      Data Monitoring
                    </Typography>
                  </div>
                  <CardMedia sx={{ height: 120 }} image={data_display_image} />
                  {/* <Typography
                      variant="body2"
                      className="function_card_content"
                    >
                      <ul style={{ paddingLeft: 20 }}>
                      </ul>
                    </Typography> */}
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    View details
                  </Button>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <IconButton
                  style={{ float: "right", right: "0px", zIndex: 10 }}
                >
                  <RadioButtonUncheckedOutlinedIcon
                    color="disabled"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      color="primary"
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "grey",
                      }}
                    >
                      {/* <ScatterPlotIcon style={{ marginRight: 10 }} /> */}
                      Export Tariff
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Activated after installation completion</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    View details
                  </Button>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <IconButton
                  style={{ float: "right", right: "0px", zIndex: 10 }}
                >
                  <RadioButtonUncheckedOutlinedIcon
                    color="disabled"
                    style={{ fontSize: "30px" }}
                  />
                </IconButton>
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      color="primary"
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "grey",
                      }}
                    >
                      {/* <ScatterPlotIcon style={{ marginRight: 10 }} /> */}
                      Support
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Schedule maintenance</li>
                      <li>Contact us</li>
                      <li>FAQ</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    View details
                  </Button>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Drawer anchor={"right"} open={drawerOpen} onClose={handleDrawerClose}>
          <div style={{ padding: "0px 20px" }}>
            {contentChanged_supportBar && (
              <Button
                startIcon={<ArrowBackIcon />}
                onClick={handleGoBack_supportBar}
                style={{ marginTop: 10 }}
              >
                Go Back
              </Button>
            )}
          </div>
          {content_supportBar}
        </Drawer>
        
        <Drawer
          anchor={"right"}
          open={drawerOpen_old}
          onClose={handleDrawerClose_old}
        >
          <div style={{ maxWidth: "500px" , padding: 20 }}>
          <SideBar {...props.sideBarProps} />
          </div>

          { props.sideBarProps.projectDelivery?.projectStage != 5 ?
          <div style={{ width: "300px", padding: 20 }}>
            <Typography
              variant="h6"
              style={{ borderBottom: "1px lightgrey solid" }}
            >
              Documents
            </Typography>
            <Button
              fullWidth
              startIcon={<PictureAsPdfIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Feasibility report
            </Button>
            <Button
              fullWidth
              startIcon={<AttachFileIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Cash flow projection (.xls)
            </Button>
            <Button
              fullWidth
              disabled
              startIcon={<PictureAsPdfIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Site survey report
            </Button>
            <Button
              fullWidth
              disabled
              startIcon={<PictureAsPdfIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Project proposal
            </Button>
            <Button
              fullWidth
              disabled
              startIcon={<PictureAsPdfIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Contract
            </Button>
            <Typography
              variant="h6"
              style={{ borderBottom: "1px lightgrey solid", marginTop: 30 }}
            >
              FAQ
            </Typography>
            <Button
              fullWidth
              startIcon={<AccountTreeIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Procedure
            </Button>
            <Button
              fullWidth
              startIcon={<AccountBalanceIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Finance options
            </Button>
            <Button
              fullWidth
              startIcon={<InfoOutlinedIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Background information
            </Button>
            <Typography
              variant="h6"
              style={{ borderBottom: "1px lightgrey solid", marginTop: 30 }}
            >
              Support
            </Typography>
            <Button
              fullWidth
              startIcon={<PlayCircleFilledWhiteIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Interview of previous projects
            </Button>
            <Button
              fullWidth
              startIcon={<HelpOutlineOutlinedIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Customer support
            </Button>
            <Button
              fullWidth
              startIcon={<CallOutlinedIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Contact us
            </Button>
          </div>
          : <></>
          }
        </Drawer>
      </div>

      <Modal open={open_faq} onClose={handleClose_faq}>
        <ModalFAQ />
      </Modal>

      <Modal open={open_virtual_meeting} onClose={handleClose_virtual_meeting}>
        <div className="modal_container">
          <iframe
            src="https://app.usemotion.com/meet/absolar/solarmeeting"
            title="Virtual meeting"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </Modal>

      <Modal open={open_site_survey} onClose={handleClose_site_survey}>
        <div className="modal_container">
          <iframe
            src="https://calendly.com/absolar/sitesurvey"
            title="Site survey"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </Modal>
    </div>
  );
}
