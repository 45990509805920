import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import DataMonitorPage from "./DataMonitorPage";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import { LogInPage } from "./login/login-page";
import { deleteData, getData } from "./get-data";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import AccountPage from "./account_page";
import UpdatePage from "./UploadPage";
import TablePage from "./components/resultsTable/TablePage";
import TrackingPage from "./TrackingPage";
import ProjectPage from "./ProjectPage";
import { TopBar } from "./TopBar";
import LandingApprovedInstallerPage from "./LandingApprovedInstaller";
import { UserContext } from "./contexts/UserContext";
import { generatePdf } from "./GeneratePdf";
import AnalyticsPage from "./pages/AnalyticsPage";
import AdminLandingPage from "./admin/AdminLanding";
import { AppContext } from "./contexts/AppContext";

const queryParams = new URLSearchParams(window.location.search);
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export function submitData(
  file: File | null,
  fileName: string,
  token: string,
  reference?: string
) {
  let url =
    process.env.REACT_APP_API_URL + "/ingress/file?fileName=" + fileName;
  if (reference) {
    url += "&reference=" + reference;
  }
  const formData = new FormData();

  if (file) {
    formData.append("file", file, file.name);
  }

  return fetch(url, {
    method: "POST",
    body: formData,
    headers: new Headers({
      Authorization: "Bearer " + token,
    }),
  });
}

export function sendFileUpdatesToBackend(
  filesToRemove: { fileName: string; isCrossed: boolean }[],
  filesToUpload: File[],
  reference: string,
  token: string,
  getCredentials?: (expiredCredentials?: UserState) => UserState | null
) {
  //use filesToRemove variable to remove files from that property from the backend
  if (filesToRemove.length > 0) {
    filesToRemove.forEach((file) => {
      deleteData(
        process.env.REACT_APP_API_URL +
          "/File?filePath=" +
          file.fileName +
          "&reference=" +
          reference,
        true,
        getCredentials
      ).catch((e) => {
        alert("failed to delete " + file.fileName);
      });
    });
  }

  const tasks = filesToUpload.map((fileToUpload) => {
    submitData(fileToUpload, fileToUpload.name, token, reference);
  });
  return Promise.all(tasks);
}

export function formatNumber(number: number) {
  if (number > 999) {
    return number.toLocaleString("en-GB", { maximumFractionDigits: 1 }); // toLocaleString adds comma separator and pound sign
  }
  return number.toLocaleString(undefined, {
    maximumFractionDigits: 1,
  }); // returns the original number if it's less than 999
}

export function formatMoney(number: number) {
  if (number > 999) {
    return "£" + number.toLocaleString("en-GB"); // toLocaleString adds comma separator and pound sign
  }
  return "£" + number.toString(); // returns the original number if it's less than 999
}

export function getTodaysDate(): string {
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = currentDate.getFullYear();

  return `${day}/${month}/${year}`;
}

function App() {
  let toid = queryParams.get("toid");

  let reference = queryParams.get("ref");

  const [dashboardDatas, setDashboardDatas] = useState<DashboardData[]>([]);
  const [user, setUser] = useState<UserState>({
    idToken: "",
    refreshToken: "",
    accessToken: "",
    username: "",
    loggedIn: false,
    groups: [],
    expired: false,
  });
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElAddNew, setAnchorElAddNew] =
    React.useState<null | HTMLElement>(null);

  const [accountModalOpen, setAccountModalOpen] = useState<boolean>(false);
  const [anchorOptUser, setAnchorOptUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenOptionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorOptUser(event.currentTarget);
  };
  const handleCloseOptionsMenu = () => {
    setAnchorOptUser(null);
  };
  const handleCloseAddNewMenu = () => {
    setAnchorElAddNew(null);
  };
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleAddNewPopupMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAddNew(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [open, setOpen] = useState<boolean>(false);
  const [loadingMap, setLoadingMap] = useState<boolean>(true);
  const [mapDatas, setMapDatas] = useState<MapData[]>([]);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedProperty, setSelectedProperty] =
    useState<DashboardData | null>(null);
  const [image, setImage] = useState<string>("");
  const [childrenAddresses, setChildrenAddresses] = useState<string[]>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [organisations, setOrganisation] = useState<Organisation[]>([]);
  const [allNotifications, setAllNotifications] = React.useState<
    UserNotification[]
  >([]);
  const [refData, setRefData] = useState<DashboardData | null>(null);
  const [showSavedList, setShowSavedList] = React.useState(false);
  const [savingAnimation, setSavingAnimation] = React.useState(false);
  const [showGeneratingList, setShowGeneratingList] = React.useState(false);

  const [orgEnv, setOrgEnv] = React.useState(-1);
  const [isNatwest, setIsNatwest] = React.useState(false);
  const [userData, setUserData] = React.useState<UserInformation>({
    company: "",
    email: "",
    location: "",
    name: "",
    phone: "",
    title: "",
    user_group: "",
  });

  function getCredentials(expiredCredentials?: UserState) {
    if (expiredCredentials) {
      setUser({ ...user, expired: true });
      return null;
    } else {
      return user;
    }
  }

  function getOrganisationData() {
    getData(
      process.env.REACT_APP_API_URL + "/organisation",
      false,
      getCredentials
    ).then((data: Organisation[]) => {
      setOrganisation(data);
      if (data.length > 0) {
        var defaultOrgID = data[0].id;
        if (user.username.includes("absolar.co.uk")) {
          defaultOrgID = 4;
        }
        setOrgEnv(defaultOrgID);
      }
    });
  }

  function getNotificationsData() {
    console.log(
      process.env.REACT_APP_API_URL + "/notifications?email=" + user.username
    );
    getData(
      process.env.REACT_APP_API_URL + "/notifications?email=" + user.username,
      false,
      getCredentials
    ).then((data: UserNotification[]) => {
      setAllNotifications(data);
    });
  }

  function getUserData() {
    getData(
      process.env.REACT_APP_API_URL + "/users",
      false,
      getCredentials
    ).then((data: UserInformation) => {
      setUserData(data);
    });
  }

  /**
   * Commenting this can uncomment this when added a production GA, Measurement ID in .env file
   */
  // useEffect(() => {
  //     const trackingCode = process.env.REACT_APP_GA_TRACKING_CODE as string;
  //     ReactGA.initialize(trackingCode);
  //     ReactGA.send({ hitType: "Page View", page: window.location.pathname + window.location.search, title: "Dashboard" });
  // },[]);

  useEffect(() => {
    if (user.loggedIn && user.username != "") {
      getMapData();
      getOrganisationData();
      getUserData();
      getNotificationsData();
      getData(
        process.env.REACT_APP_API_URL + "/dashboard",
        false,
        getCredentials
      ).then((data: DashboardData[]) => {
        setDashboardDatas(data);
        //setDataLoaded(true);
      });
    } else {
      if (reference != null && reference != "") {
        getData(
          process.env.REACT_APP_API_URL +
            "/dashboard/shared?reference=" +
            reference
        )
          .then((data: DashboardData) => {
            if (data) {
              setRefData(data);
            } else {
              window.location.replace("https://absolar.co.uk");
            }
          })
          .catch((e) => {
            window.location.replace("https://absolar.co.uk");
          });
      }
    }
  }, [user.loggedIn]);

  //commented for now - solution for environment-based maps
  useEffect(() => {
    if (user.loggedIn && user.username != "") {
      getMapData();
    }
    setIsNatwest(orgEnv === 4 || orgEnv === 20 || orgEnv === 21);
  }, [orgEnv]);

  function checkUprn(root_uprn: string | null, searchTerm: string) {
    if (root_uprn) {
      return root_uprn
        .replace(/ /g, "")
        .replace(/,/g, "")
        .toLowerCase()
        .includes(searchTerm.replace(/ /g, "").replace(/,/g, "").toLowerCase());
    } else {
      return false;
    }
  }

  function getMapData() {
    Promise.all([
      getData(
        process.env.REACT_APP_API_URL +
          "/DashboardMap?polygonType=suitable_roof_area",
        false,
        getCredentials
      ),
      getData(
        process.env.REACT_APP_API_URL +
          "/DashboardMap?polygonType=toid_outline",
        false,
        getCredentials
      ),
    ]).then((responses) => {
      console.log("THe MAP OBJECT", responses);
      const buildingData: MapData = responses[1];
      const solarData: MapData = responses[0];

      var updatedBuildingData = retrieveOnlyEnvironment(buildingData);
      var updatedSolarData = retrieveOnlyEnvironment(solarData);
      setMapDatas([updatedBuildingData, updatedSolarData]);
    });
  }

  function retrieveOnlyEnvironment(objectToChange: MapData) {
    //new version: sort based on current organisation id
    const newMap = JSON.parse(JSON.stringify(objectToChange));

    //if it's natwest, don't sort
    var isNW = orgEnv === 4 || orgEnv === 20;
    if (!isNW) {
      newMap.features = objectToChange.features.filter((feature) => {
        // Extract the reference value from the "properties" object
        const orgId = feature.properties.organisation_id;
        // Check if the reference value is in the set of references to keep
        return orgId == orgEnv;
      });
    }

    return newMap;
  }

  function getChildrenAddresses(rootuprn: string) {
    getData(
      process.env.REACT_APP_API_URL +
        "/Dashboard/addresses?rootuprn=" +
        rootuprn,
      false,
      getCredentials
    ).then((addresses: string[]) => {
      setChildrenAddresses(addresses);
    });
  }

  function getImage(property: DashboardData) {
    if (property) {
      getData(
        process.env.REACT_APP_API_URL +
          "/Dashboard/satellite?toid=" +
          property.reference,
        true,
        getCredentials
      )
        .then((response) => response.blob())
        .then((blob) => {
          setImage(URL.createObjectURL(blob));
        })
        .catch((e) => {
          console.log("couldn't get image");
        });
    }
  }

  const [loadPdfDownloading, setLoadPdfDownloading] = React.useState(false);

  async function getPdf(property: DashboardData | null) {
    if (!property) return;

    setLoadPdfDownloading(true);
    let url =
      process.env.REACT_APP_API_URL + "/Dashboard/radiation_shared?toid=";
    if (user.loggedIn) {
      url = process.env.REACT_APP_API_URL + "/Dashboard/radiation?toid=";
    }

    let radiation = "";
    let imageRoof = "";

    try {
      const radiationResponse = await getData(
        url + property.reference,
        true,
        getCredentials
      );
      const radiationBlob = await radiationResponse.blob();
      radiation = URL.createObjectURL(radiationBlob);
    } catch (e) {
      console.log("couldn't get radiation");
    }

    try {
      let satUrl =
        process.env.REACT_APP_API_URL + "/Dashboard/satellite_shared?toid=";
      if (user.loggedIn) {
        satUrl = process.env.REACT_APP_API_URL + "/Dashboard/satellite?toid=";
      }
      const imageResponse = await getData(
        satUrl + property.reference,
        true,
        getCredentials
      );
      const imageBlob = await imageResponse.blob();
      imageRoof = URL.createObjectURL(imageBlob);
    } catch (e) {
      console.log("couldn't get image");
    }
    if (
      property.reference.includes("admin_12_210") ||
      property.organisation_id === 22 ||
      property.organisation_id === 23 ||
      property.organisation_id === 24 ||
      property.organisation_id === 25 ||
      property.organisation_id === 26 ||
      property.organisation_id === 28 ||
      property.organisation_id === 18 ||
      property.reference.includes("NW") ||
      isNatwest
    ) {
      generatePdf(
        property,
        radiation,
        imageRoof,
        setLoadPdfDownloading,
        isNatwest
      );
    } else {
      //it's not natwest

      const name = property.reference + "_absolar_report.pdf";
      let url = process.env.REACT_APP_API_URL + "/Dashboard/pdf_shared?toid=";
      if (user.loggedIn) {
        url = process.env.REACT_APP_API_URL + "/Dashboard/pdf?toid=";
      }
      getData(url + property.reference, true, getCredentials)
        .then((response) => response.blob())
        .then((blob) => {
          setTimeout(() => {
            console.log("producing");
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = name;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
            setLoadPdfDownloading(false);
          }, 2500);
        })
        .catch((e) => {
          alert(
            "could not download pdf, please contact Absolar if the problem persists"
          );
          setLoadPdfDownloading(false);
        });
    }
  }

  async function handleDownloadFile(fileName: string, propReference: string) {
    let url =
      process.env.REACT_APP_API_URL +
      "/File/file?filepath=/" +
      fileName +
      "&reference=" +
      propReference;
    if (reference != null && reference != "") {
      url =
        process.env.REACT_APP_API_URL +
        "/File/file_shared?filepath=/" +
        fileName +
        "&reference=" +
        propReference;
    }
    const downloadName = fileName.substring(fileName.lastIndexOf("/") + 1);

    getData(url, true, getCredentials)
      .then((response) => response.blob())
      .then((blob) => {
        console.log("downloading..." + downloadName);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = downloadName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
      .catch((e) => {
        alert(
          "could not download file, please contact Absolar if the problem persists"
        );
      });
  }

  const handleToidSelect = (reference: string) => {
    console.log(reference);
    const matchingProperty = dashboardDatas.find(
      (x) => x.reference.toLowerCase() == reference.toLowerCase()
    );
    console.log(matchingProperty?.reference);
    if (matchingProperty) {
      handlePropertySelect(matchingProperty);
    }
  };
  const handlePropertySelect = (property: DashboardData) => {
    setSelectedProperty(property);
    getImage(property);
    if (property.root_uprn) {
      getChildrenAddresses(property.root_uprn);
    }
    setDrawerOpen(true);
  };

  function handleNotificationsUpdate(newNotifications: UserNotification[]) {
    //TODO: complete the backend call to update notifications?
    setAllNotifications(newNotifications);
  }

  //vaccum animation
  const [targetX, setTargetX] = React.useState("0px");
  const [targetY, setTargetY] = React.useState("0px");
  const [isAnimating, setIsAnimating] = React.useState("");
  const iconRef = React.useRef(null);

  const [openDialogue, setOpenDialogue] = React.useState(false);
  const [openDialogueMessage, setOpenDialogueMessage] = React.useState("");

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenDialogue(false);
    setOpenDialogueMessage("");
  };

  const setOpenDialogueHandler = (message: string) => {
    setOpenDialogueMessage(message);
  };

  useEffect(() => {
    if (openDialogueMessage != "") setOpenDialogue(true);
  }, [openDialogueMessage]);

  return (
    <div>
      {loadPdfDownloading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            gridColumn: "1",
            gridRow: "1",
            textAlign: "center",
            position: "fixed",
            top: "0",
            left: "0",
            padding: "0",
            zIndex: "9999",
          }}
        >
          <CircularProgress
            style={{ position: "absolute", marginTop: "45vh" }}
          />
          <h2
            style={{
              position: "absolute",
              marginTop: "52vh",
              left: "0",
              right: "0",
            }}
          >
            Generating PDF Report
          </h2>
        </div>
      ) : (
        <></>
      )}
      <Snackbar
        open={openDialogue}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {openDialogueMessage}
        </Alert>
      </Snackbar>

      <div
        className="App"
        style={{
          filter: loadPdfDownloading ? "blur(10px)" : "",
        }}
      >
        <Modal
          open={user.expired}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{ border: "none" }}>
            <div className="account_details">
              <div>
                <Typography variant="body2">
                  Your login has expired. Please click the button below to login
                  again.
                </Typography>
                <Button
                  variant="contained"
                  style={{ textTransform: "none", marginTop: 20 }}
                  onClick={() => {
                    setUser({
                      idToken: "",
                      refreshToken: "",
                      accessToken: "",
                      username: "",
                      loggedIn: false,
                      groups: [],
                      expired: false,
                    });
                  }}
                >
                  Go to Login
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
        <UserContext.Provider
          value={{
            userState: user,
            setUserState: setUser,
            getCredentials: getCredentials,
            isNatwest: isNatwest,
            userInfo: userData,
            getNotificationsData: getNotificationsData,
          }}
        >
          <Modal
            open={accountModalOpen}
            onClose={() => {
              setAccountModalOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <AccountPage user={user} />
          </Modal>
          <Router>
            <Box sx={{ flexGrow: 1 }}>
              <TopBar
                handleOpenUserMenu={handleOpenUserMenu}
                anchorElUser={anchorElUser}
                handleOpenOptionsMenu={handleOpenOptionsMenu}
                anchorElAddNew={anchorElAddNew}
                handleAddNewPopupMenu={handleAddNewPopupMenu}
                anchorOptUser={anchorOptUser}
                handleCloseUserMenu={handleCloseUserMenu}
                handleCloseOptionsMenu={handleCloseOptionsMenu}
                handleCloseAddNewMenu={handleCloseAddNewMenu}
                setAccountModalOpen={setAccountModalOpen}
                mapDatas={mapDatas}
                selectedProperty={refData}
                setSelectedProperty={setSelectedProperty}
                setRefData={setRefData}
                organisations={organisations}
                handleToidSelect={handleToidSelect}
                setShowSavedList={setShowSavedList}
                showSavedList={showSavedList}
                setSavingAnimation={setSavingAnimation}
                savingAnimation={savingAnimation}
                orgEnv={orgEnv}
                setOrgEnv={setOrgEnv}
                showGeneratingList={showGeneratingList}
                setShowGeneratingList={setShowGeneratingList}
                open={open}
                setOpen={setOpen}
                loadingMap={loadingMap}
                setLoadingMap={setLoadingMap}
                allNotifications={allNotifications}
                setAllNotifications={handleNotificationsUpdate}
                iconRef={iconRef}
              />
            </Box>
            {user.loggedIn ? (
              <AppContext.Provider
                value={{
                  setRefData,
                  showSavedList,
                  setShowSavedList,
                  savingAnimation,
                  setSavingAnimation,
                  showGeneratingList,
                  setShowGeneratingList,
                  drawerOpen,
                  setDrawerOpen,
                  handlePropertySelect,
                  selectedProperty,
                  handleToidSelect,
                  image,
                  setImage,
                  orgEnv,
                  organisations,
                  dashboardDatas,
                  mapDatas,
                  dataLoaded,
                  setDataLoaded,
                  setDashboardDatas,
                  open,
                  setOpen,
                  loadingMap,
                  setLoadingMap,
                  getPdf,
                  loadPdfDownloading,
                  setLoadPdfDownloading,
                  targetX,
                  targetY,
                  isAnimating,
                  setTargetX,
                  setTargetY,
                  setIsAnimating,
                  iconRef,
                  handleDownloadFile,
                  refData,
                  setOpenDialogueHandler,
                }}
              >
                <Routes>
                  <Route
                    path="/analytics"
                    element={
                      <AnalyticsPage
                        dashboardDataList={dashboardDatas}
                        orgEnv={orgEnv}
                        setRefData={setRefData}
                      />
                    }
                  />

                  <Route path="/admin" element={<AdminLandingPage />} />
                  <Route
                    path="/project"
                    element={
                      refData ? (
                        <ProjectPage
                          data={refData}
                          setSavingAnimation={setSavingAnimation}
                          dashboardDatas={dashboardDatas}
                          setDashboardDatas={setDashboardDatas}
                          setIsNatwest={setIsNatwest}
                          loadPdfDownloading={loadPdfDownloading}
                          setLoadPdfDownloading={setLoadPdfDownloading}
                          getPdf={getPdf}
                          handleDownloadFile={handleDownloadFile}
                        />
                      ) : (
                        <Navigate to="/" />
                      )
                    }
                  />
                  <Route
                    path="/"
                    element={
                      reference && reference != "" ? (
                        refData ? (
                          <ProjectPage
                            data={refData}
                            setSavingAnimation={setSavingAnimation}
                            dashboardDatas={dashboardDatas}
                            setDashboardDatas={setDashboardDatas}
                            setIsNatwest={setIsNatwest}
                            loadPdfDownloading={loadPdfDownloading}
                            setLoadPdfDownloading={setLoadPdfDownloading}
                            getPdf={getPdf}
                            handleDownloadFile={handleDownloadFile}
                          />
                        ) : (
                          <>
                            <CircularProgress />
                            <div>Loading</div>
                          </>
                        )
                      ) : dashboardDatas.length != 0 ? (
                        <TablePage />
                      ) : (
                        <></>
                      )
                    }
                  />
                  {user.username.includes("absolar.co.uk") ||
                  user.username.includes("anna_testing") ||
                  user.username.includes("nic_merc") ? (
                    <>
                      <Route
                        path="/landing_approved_installer"
                        element={<LandingApprovedInstallerPage />}
                      />
                    </>
                  ) : null}
                  {/* user.username.includes("absolar.co.uk") */}
                  {user.username.includes("absolar.co.uk") ? (
                    <>
                      <Route path="/tracking" element={<TrackingPage />} />
                      <Route path="/data" element={<DataMonitorPage />} />
                      <Route
                        path="/upload"
                        element={
                          <UpdatePage
                            dashboardDatas={dashboardDatas}
                            setDashboardDatas={setDashboardDatas}
                            handleDownloadFile={handleDownloadFile}
                            setOpenDialogueHandler={setOpenDialogueHandler}
                          />
                        }
                      />
                    </>
                  ) : null}
                </Routes>
              </AppContext.Provider>
            ) : reference && reference != "" ? (
              <Routes>
                <Route
                  path="*"
                  element={
                    refData ? (
                      <ProjectPage
                        data={refData}
                        setSavingAnimation={setSavingAnimation}
                        dashboardDatas={dashboardDatas}
                        setDashboardDatas={setDashboardDatas}
                        setIsNatwest={setIsNatwest}
                        loadPdfDownloading={loadPdfDownloading}
                        setLoadPdfDownloading={setLoadPdfDownloading}
                        getPdf={getPdf}
                        handleDownloadFile={handleDownloadFile}
                      />
                    ) : (
                      <>
                        <CircularProgress />
                        <div>Loading</div>
                      </>
                    )
                  }
                />
              </Routes>
            ) : (
              <Routes>
                <Route
                  path="*"
                  element={
                    <LogInPage redirected={false} setUserState={setUser} />
                  }
                />
              </Routes>
            )}
          </Router>
        </UserContext.Provider>
      </div>
    </div>
  );
}

export default App;
