/**
 * @description This is a tool to get today's time for "assessment_date" in cal sheet in "BusinessCasePage"
 * @returns a formatted date
 * @Writer Unknown but refactored by Roy
 */

export const getTodaysDate = (): string => {
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = currentDate.getFullYear();

  return `${year}-${month}-${day}`;
};
