import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Grid,
  Modal,
  Divider,
} from "@mui/material";
import logo from "./images/basingstoke_logo.png";
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { UserContext } from "./contexts/UserContext";
import Person2Icon from '@mui/icons-material/Person2';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
interface IProps {
  user: UserState;
}
export default function AccountPage(props: IProps) {
  const userContext = useContext(UserContext);

  const [open_userRequest, setOpen_userRequest] = React.useState(false);
  const handleOpen_userRequest = () => setOpen_userRequest(true);
  const handleClose_userRequest = () => setOpen_userRequest(false);


  var fullName = userContext.userInfo.title ? userContext.userInfo.title + " " + userContext.userInfo.name
    : userContext.userInfo.name;

  return (
    <Box sx={style} className="outer_map_container">
      <div className="account_page">
        <div className="account_page_top">
          <div className="account_page_details" style={{paddingTop: "0px"}}>
            {/* <img src={logo} className="profile_image" /> */}
            <Avatar sx={{ bgcolor: stringToColor(props.user.username.split("@")[0]) }} style={{ width: 100, height: 100, margin: "auto", fontSize: "60px", border: "1px solid black" }} >{props.user.username[0][0].toUpperCase()}</Avatar>
            <h1>{props.user.username.split("@")[0].replaceAll(".", " ").replace("_", " ").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</h1>
          </div>
        </div>
        <div className="account_page_bottom">
          <div className="account_page_details">
            <List dense>
            <Grid container spacing={2}>
            <Grid item xs={6}>

              <ListItem>
                <ListItemAvatar>
                  <Avatar><Person2Icon /></Avatar>
                </ListItemAvatar>
                <ListItemText primary={fullName} secondary="Name" />
              </ListItem>
              </Grid>
              <Grid item xs={6}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar><EmailIcon /></Avatar>
                </ListItemAvatar>
                <ListItemText primary={userContext.userInfo.email} secondary="Email" />
              </ListItem>
              </Grid>
             
              {userContext.userInfo.company && (
              <Grid item xs={6}>
             
                <ListItem>
                  <ListItemAvatar>
                    <Avatar><BusinessIcon /></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={userContext.userInfo.company} secondary="Company" />
                </ListItem>
              
              </Grid>
              )}
              {userContext.userInfo.user_group && (
              <Grid item xs={6}>
              
                <ListItem>
                  <ListItemAvatar>
                    <Avatar><GroupIcon /></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={userContext.userInfo.user_group} secondary="User Group" />
                </ListItem>
              
              </Grid>
              )}
              <Grid item xs={12} style={{padding: "0px"}}></Grid>
              {userContext.userInfo.location && (
              <Grid item xs={6}>
              
                <ListItem>
                  <ListItemAvatar>
                    <Avatar><LocationOnIcon /></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={userContext.userInfo.location} secondary="Location" />
                </ListItem>
              
              </Grid>
              )}
                {userContext.userInfo.phone && (
              <Grid item xs={6}>

            
                <ListItem>
                  <ListItemAvatar>
                    <Avatar><PhoneIcon /></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={userContext.userInfo.phone} secondary="Phone Number" />
                </ListItem>
           
              </Grid>
                 )}
                 <Grid item xs={12} style={{padding: "0px"}}></Grid>
              <Grid item xs={12}>
            <ListItem>
                <ListItemAvatar>
                  <Avatar><AssignmentIndIcon /></Avatar>
                </ListItemAvatar>
                <ListItemText primary="View and Download only" secondary="User Type" />
              </ListItem>
                </Grid>
                </Grid>
            </List>
            <hr></hr>
            <Typography variant="body2" style={{ marginTop: 20, marginBottom: 10 }}>Update user profile or add new users</Typography>
            <div style={{display:"flex", justifyContent:"space-evenly"}}>
            <Button variant="outlined" style={{ textTransform: "none" }} href="mailto:info@absolar.co.uk?subject=Solar Dashboard - Update User Profile">Contact support</Button>
            <Button variant="outlined" style={{ textTransform: "none" }} onClick={handleOpen_userRequest}>Request new user</Button>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open_userRequest} onClose={handleClose_userRequest}>
        <div className="modal_container_business_case">
          <Grid container spacing={3} style={{ height: "100%" }}>
            <Grid item xs={12} style={{ width: "100%", height: "100%" }}>
              <iframe
                src="https://forms.office.com/Pages/ResponsePage.aspx?id=0gy-4oN0C0eT04_zvRCUdoBRbbKGL0xFnOkiGjX3aA9UNkpYQUo4SjJNRlAxWUdMQk9ENEIxU0o2Qi4u&embed=true"
                width="100%"
                height="100%"
                style={{ overflow: "hidden", border: "none" }}
              >
                Loading…
              </iframe>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </Box>
  );
}
