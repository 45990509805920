import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const faqs_report = [
  {
    question: "Why is the system size expressed in kW?",
    answer: (
      <>
        <p>
          Solar panels are sized in kilowatts (kW) because it is a standard unit
          of measurement for electrical power. Whilst it may initially be easier
          to understand a system size by the number of solar panels, different
          panels have different kW sizes and physical measurements, meaning it
          is ambiguous. Instead, we assess the solar potential of a roof space
          using an optimum sized panel, giving a more comparable result across
          different roof tops.
        </p>
        <p>
          The kW rating of a solar panel system is a measure of its power,
          rather than its physical size.
        </p>
      </>
    ),
  },
  {
    question: "How are the first-year cost savings calculated?",
    answer: (
      <>
        <p>
          The first-year savings is calculated based upon our modelling of the
          amount of power the solar PV system will generate and then only taking
          the power we expect you to use within the property itself (reducing
          your energy demand from the grid) based upon a number of assumptions.
          We then apply a specific £ per kWh rate to translate this energy
          saving into cost savings. The self-consumption and electricity rates
          used in the calculation are provided in the assumption section of the
          report.
        </p>
      </>
    ),
  },
  {
    question: "How are the CO2 savings calculated?",
    answer: (
      <>
        <p>
          We assess the entire production of the solar PV system and apply a
          grid carbon factor that estimates the carbon mix of the gird
          electricity you would have used had you not have had solar, together
          with the 100% carbon free electricity you send back to the grid (the
          energy you don’t use within the property itself).
        </p>
      </>
    ),
  },
  {
    question: "Why are first-year savings only shown?",
    answer: (
      <>
        <p>
          In this summary report we only show the first year cost and CO2
          savings for simplicity. In future years, inflationary costs and
          changes in the grid carbon mix will alter these numbers. Absolar’s
          consultancy service can help you understand these future impacts and
          apply sensitivity analysis to further investigate the investment
          performance of a solar PV system.
        </p>
      </>
    ),
  },
  {
    question: "How is the indicative cost calculated and what does it include?",
    answer: (
      <>
        <p>
          We use the current cost of materials and labour rates as at the date
          of assessment for the system size and installation type shown in the
          report. Actual costs may vary when considering project specific costs.
        </p>
        <p>
          The indicative cost includes all the materials required for the system
          to operate, labour to install the system together with managing the
          project and access requirements such as scaffolding and safety
          equipment.
        </p>
      </>
    ),
  },
  {
    question: "Why is lifetime net savings over 20 years?",
    answer: (
      <>
        <p>
          Solar panels should be expected to last over 30 years with
          manufacturer warranty’s lasting for 25 years in most cases. However,
          our research suggests that most clients only plan to a maximum of 20
          years hence we cut off analysis at that point to avoid over inflating
          savings figures.
        </p>
      </>
    ),
  },
  {
    question: "What is payback period?",
    answer: (
      <>
        <p>
          This represents the amount of time it should take to recover the
          initial installation cost through energy bill savings or exporting of
          surplus energy.
        </p>
      </>
    ),
  },
  {
    question: "What is the investment return?",
    answer: (
      <>
        <p>
          The figure presented within the report is Investment Rate of Return
          (IRR). The internal rate of return is a measure of the profitability
          of an investment. It is the rate of return that you would earn on your
          investment if you invested all of the money at once and then received
          all of the cash flows at the end of the investment period. IRR is a
          useful tool for comparing different investments. For example, if you
          are considering investing in two different solar PV projects, you can
          use IRR to compare the profitability of the two systems. The system
          with the higher IRR is the more profitable investment.
        </p>
      </>
    ),
  },
  {
    question:
      "How is the radiation analysis conducted and solar panels placement determined?",
    answer: (
      <>
        <p>
          Absolar’s unique technology uses LiDAR (laser based data from
          overflying aircraft) to build a 3D map of a property which is then
          mapped against the suns path at every 30 minute interval throughout
          the year. This allows us to understand which parts of the roof receive
          suitable amounts of solar radiation to warrant the installation of
          solar panels and which areas to avoid due to excessive shading from
          the surrounding environment or on roof obstacles such as chimneys or
          vents. Our machine learning and computer vision technology then
          assesses the satellite imagery to only design solar PV systems on
          areas that are free from obstruction and suitable for installation.
        </p>
      </>
    ),
  },
  {
    question: "What are the key assumptions used in the calculations?    ",
    answer: (
      <>
        <p>
          Apart from an underlying assumption that the electrical condition of
          the building is suitable for solar and that the rooftop is structural
          sound to support installation, our assumptions are shown with the
          ‘Assumptions’ section of the report.
        </p>
      </>
    ),
  },
];

const faqs_solar_pv = [
  {
    question:
      "We rent/lease our building from a Landlord, is solar still an option?",
    answer: (
      <>
        <p>
          Even if you don’t own your roof, solar is still an option by reaching
          an agreement with your Landlord to use the roof in line with existing
          tenancies. Absolar can review the lease and liaise with the Landlord
          on your behalf.
        </p>
      </>
    ),
  },
  {
    question: "What if we need to move property?",
    answer: (
      <>
        <p>
          Solar will mean your property has lower energy costs and carbon
          emissions compared to other properties without solar, resulting in a
          premium property that will likely make it easier to sell or lease.
        </p>
        <p>
          In many circumstances we can decommission the installation and even
          move it to your new premises if desired, but this tends to be rare as
          the new occupants often take over the installation as their own.
        </p>
      </>
    ),
  },
  {
    question: "Can a solar system be upgraded?",
    answer: (
      <>
        <p>
          Yes, if your energy circumstances change you may want more power or
          battery storage. All our installations are able to accommodate
          flexible upgrades. Simply contact us to discuss your changes.
        </p>
      </>
    ),
  },
  {
    question: "How will I monitor performance and bill savings?",
    answer: (
      <>
        <p>
          Absolar can provide you with a full monitoring platform that lets you
          see how much energy you have saved, both in terms of financial savings
          and carbon emissions. This includes an annual report to meet your
          carbon reporting requirements, if required.
        </p>
        <p>
          The monitoring platform also lets us track how well the system is
          performing, making any adjustments needed to ensure you get the best
          out of the solar system.
        </p>
      </>
    ),
  },
  {
    question: "What are the financing options?",
    answer: (
      <>
        <p>
          The three most popular ways to pay for solar on a commercial property:
          cash, loans, or a Power Purchase Agreement (PPA). As part of our
          consultancy service, Absolar helps you assess these options and
          determine the best for your business.
        </p>
        <p>
          We always recommend choosing a cash purchase whenever possible as it
          offers the most favourable returns, simplifies ownership, and
          maximises tax incentives.
        </p>
        <p>
          Low-interest loans offer the next best return on investment and can
          often be matched against your energy bill savings.
        </p>
        <p>
          PPAs are where a third party investor pays for the installation on
          your roof and you agree to pay for any power generated that you use
          from the solar panels at a pre-agreed rate.
        </p>
      </>
    ),
  },
  {
    question: "Can solar panels be temporarily removed for roof maintenance?",
    answer: (
      <>
        <p>
          Yes, modern installation methods mean that solar panels can be removed
          for maintenance if required, although this often comes with a labour
          cost of doing so.
        </p>
      </>
    ),
  },
  {
    question:
      "How long does it take to install solar, and does it cause disruption?",
    answer: (
      <>
        <p>
          It can take around 6 months from making your first enquiry to having
          solar power working on your roof.
        </p>
        <p>
          Most of this is due to obtaining permissions from the Distribution
          Network Operators in your area and any planning permission
          requirements that can take 3 months.
        </p>
        <p>
          Once permission is received, installations can happen quickly and in
          line with your schedule.
        </p>
        <p>
          A solar installation causes very little disruption. Scaffolding will
          be required to access and provide protection on the roof during the
          installation and there may be a day or two where electricians need to
          be inside the building to connect everything up.
        </p>
        <p>
          Often this is carried out in evenings or weekends to minimise
          disruption to your business.
        </p>
      </>
    ),
  },
  {
    question: "What are the biggest risks?",
    answer: (
      <>
        <p>
          As with most infrastructure projects, there are risks that should be
          appropriately managed. These risks can be significantly reduced by
          employing a high-quality installer with experience project management
          to carry out the work, and not going for the cheapest quote available.
        </p>
        <p>
          Once installed, a key risk is failing to monitor and maintain the
          system correctly which means possible faults could go undetected,
          reducing system performance and your savings.
        </p>
        <p>
          Another possible risk is the installation not producing the promised
          savings. This is why it is important to have a clear understanding of
          current and projected future energy demand as part of the project
          appraisal process and assessing these against the solar system’s
          production.
        </p>
        <p>
          Absolar’s consultancy service assesses these risks upfront to
          significantly reduce this risk.
        </p>
        <p>
          Finally, another risk is that your business doesn’t need as much
          energy in the longer term owing to changes in your circumstances.
          These factors can be taken into account in project planning but
          changes in energy demand surrounding the electrification of heat and
          vehicles does mean a reduction in your energy demand is unlikely.
        </p>
        <p>
          The failsafe in this regard is the financial return that the project
          will deliver purely from selling electricity to the grid. If a solar
          project breaks even, based on exporting one hundred percent of the
          electricity it produces to the grid, then there is minimal financial
          risk.
        </p>
      </>
    ),
  },
  {
    question:
      "How is the radiation analysis conducted and solar panels placement determined?",
    answer: (
      <>
        <p>
          Absolar’s unique technology uses LiDAR (laser based data from
          overflying aircraft) to build a 3D map of a property which is then
          mapped against the suns path at every 30 minute interval throughout
          the year. This allows us to understand which parts of the roof receive
          suitable amounts of solar radiation to warrant the installation of
          solar panels and which areas to avoid due to excessive shading from
          the surrounding environment or on roof obstacles such as chimneys or
          vents. Our machine learning and computer vision technology then
          assesses the satellite imagery to only design solar PV systems on
          areas that are free from obstruction and suitable for installation.
        </p>
      </>
    ),
  },
];

export default function ModalFAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div
      style={{
        width: "60%",
        maxWidth: "1200px",
        margin: "30px auto",
        textAlign: "left",
        // background:"#e5e5e5"
      }}
    >
      <div className="modal_container_faq">
        {/* <div className="modal_close_button">
          <IconButton size="large">
            <HighlightOffIcon />
          </IconButton>
        </div> */}
        <Typography variant="h4" gutterBottom style={{ marginBottom: 30 }}>
          Frequently Asked Questions
        </Typography>
        <Typography variant="h5">About the report</Typography>
        {faqs_report.map((faq) => (
          <Accordion
            key={faq.question}
            expanded={expanded === faq.question}
            onChange={handleChange(faq.question)}
            style={{ marginTop: 10 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>{faq.answer}</AccordionDetails>
          </Accordion>
        ))}
        <Typography variant="h5" style={{marginTop:40}}>About Solar PV</Typography>
        {faqs_solar_pv.map((faq) => (
          <Accordion
            key={faq.question}
            expanded={expanded === faq.question}
            onChange={handleChange(faq.question)}
            style={{ marginTop: 10 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>{faq.answer}</AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
