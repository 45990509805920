import React, { useContext, useEffect, useState } from "react";
import {
  useNavigate
} from "react-router-dom";

import "../App.css";
import FoundPropertiesProjectAdmin from "./FoundPropertiesProject_Admin";
import {
  Grid,
  Box,
  Button,
  Tooltip,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Paper,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from "@mui/material";
import { putData } from "../get-data";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import MapComponent from "../Map";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import WbCloudyIcon from "@mui/icons-material/WbCloudy";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import EvStationIcon from "@mui/icons-material/EvStation";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import HelpIcon from "@mui/icons-material/Help";
import { UserContext } from "../contexts/UserContext";
import { AppContext } from "../contexts/AppContext";

const queryParams = new URLSearchParams(window.location.search);
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
interface IProps {

  updatePanelChoice: (newOpt: string) => void;
}
export default function TablePage(props: IProps) {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  let toid = queryParams.get("toid");

  const [loadingMap, setLoadingMap] = useState<boolean>(true);

  const [accountModalOpen, setAccountModalOpen] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const [childrenAddresses, setChildrenAddresses] = useState<string[]>([]);

  useEffect(() => {
    if (userContext.userState.loggedIn) {
      if (toid != null && toid != "") {
        if (appContext.dashboardDatas.length > 0) {
          var matchedData = appContext.dashboardDatas.find((x) => x.toid == toid);
          if (matchedData) {
            setSearch(matchedData.address);
            searchData(matchedData.address);
          }
        }
      }
    }
  }, [appContext.dashboardDatas]);
  function checkReference(root_uprn: string | null, searchTerm: string) {
    if (root_uprn) {
      return root_uprn
        .replace(/ /g, "")
        .replace(/,/g, "")
        .toLowerCase()
        .includes(searchTerm.replace(/ /g, "").replace(/,/g, "").toLowerCase());
    } else {
      return false;
    }
  }
  function searchData(searchOverride: string | null = null) {
    let searchTerm = search;
    if (searchOverride) {
      searchTerm = searchOverride;
    }
    console.log("search term:" + searchTerm);
    if (searchTerm != "") {
      const newData = appContext.dashboardDatas
        .filter((data) => data.organisation_id == appContext.orgEnv)
        .filter(
          (x) =>
            x.address
              .replace(/ /g, "")
              .replace(/,/g, "")
              .toLowerCase()
              .includes(
                searchTerm.replace(/ /g, "").replace(/,/g, "").toLowerCase()
              ) || checkReference(x.reference, searchTerm)
        );
      setTableData(newData);
    } else {
      setTableData(appContext.dashboardDatas);
    }
  }
  function resetSearch() {
    var newData = appContext.dashboardDatas.filter(
      (data) => data.organisation_id === appContext.orgEnv
    );
    if (appContext.showSavedList) {
      newData = newData.filter((data) => data.saved == true);
    }
    setTableData(newData);
    setSearch("");
  }

  const [tableData, setTableData] = React.useState(appContext.dashboardDatas);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    var newData = appContext.dashboardDatas.filter(
      (data) => data.organisation_id === appContext.orgEnv
    );
    if (appContext.showSavedList) {
      newData = newData.filter((data) => data.saved == true);
    }

    const resetTableData = async () => {
      await new Promise<void>((resolve) => {
        setTableData([]);
        setTimeout(() => {
          resolve();
        }, 1000);
      });
      setTableData(newData);
      setIsLoading(false);
      appContext.setDataLoaded(true);
      setSearch("");
    };

    resetTableData();
  }, [appContext.orgEnv, appContext.showSavedList]);

  useEffect(() => {
    if (userContext.userState.loggedIn) {
      userContext.getNotificationsData();
    }
  }, []);

  let navigate = useNavigate();
  const handleClick_like_button = (selectedProperty: DashboardData | null) => {
    //check if we need to update the individual data in teh state too?
    if (selectedProperty) {
      var reference = selectedProperty.reference;
      var toUpdateTo = !selectedProperty.saved;
      var allDashboards = appContext.dashboardDatas.map((dashboardEntry) =>
        dashboardEntry.reference === reference
          ? { ...dashboardEntry, saved: toUpdateTo }
          : dashboardEntry
      );
      appContext.setDashboardDatas(allDashboards);

      selectedProperty.saved = toUpdateTo;
      saveRow(reference, toUpdateTo);
    }
  };

  function saveRow(reference: string, saved: boolean) {
    putData(
      process.env.REACT_APP_API_URL +
        "/Dashboard/saved?reference=" +
        reference +
        "&saved=" +
        saved,
      {},
      true,
      userContext.getCredentials
    )
      .then((res) => res.text())
      .catch((e) => alert("save failed"));
  }

  return (
    <div className="App">
      <>
        <Drawer
          anchor={"right"}
          open={appContext.drawerOpen}
          onClose={() => {
            appContext.setDrawerOpen(false);
            appContext.setImage("");
          }}
          style={{ zIndex: 1 }}
        >
          {appContext.selectedProperty ? (
            <div style={{ padding: 10, maxWidth: 400 }}>
              <div
                style={{
                  padding: "15px 0px 10px 15px",
                  fontSize: "18px",
                  color: "gray",
                  fontWeight: "bold",
                }}
              >
                {appContext.selectedProperty?.address}
              </div>
              <div
                style={{ height: "300px", textAlign: "center", padding: 10 }}
              >
                {appContext.image == "" ? (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <img
                    alt="image of the building rooftop with an outline for the building and the solar area"
                    src={appContext.image}
                    style={{ height: "100%", margin: "auto" }}
                  />
                )}
              </div>

              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  appContext.setRefData(
                    appContext.selectedProperty ? appContext.selectedProperty : null
                  );
                  navigate("/project");
                  appContext.setShowGeneratingList(false);
                  appContext.setDrawerOpen(false);
                  appContext.setOpen(false);
                  appContext.setLoadingMap(false);
                }}
                style={{ marginBottom: "10px", textTransform: "none" }}
              >
                View Details
              </Button>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Tooltip
                    title={
                      appContext.selectedProperty.saved
                        ? "Unsave Property"
                        : "Save Property"
                    }
                    placement="top-start"
                    style={{ zIndex: 2000 }}
                  >
                    <span>
                      <Button
                        fullWidth
                        variant="contained"
                        style={{ textTransform: "none" }}
                        onClick={() => {
                          handleClick_like_button(appContext.selectedProperty);
                          appContext.setSavingAnimation(true);
                          // Perform some async operation here
                          setTimeout(() => {
                            appContext.setSavingAnimation(false);
                          }, 2000);
                        }}
                        disabled={
                          appContext.selectedProperty.install_capacity_kw === 0
                        }
                      >
                        {/* {appContext.selectedProperty.saved ? (<StarIcon /> : <StarOutlineIcon />} */}
                        {/* <p style={{marginLeft: "5px", padding: "0px", marginTop: "0px", marginBottom: "0px"}}></p> */}
                        {appContext.selectedProperty.saved ? "Unsave" : "Save"}
                      </Button>
                    </span>

                    {/* <IconButton
                    aria-label="Customer support"
                    color="primary"
                    size="large"
                    style={{marginTop: "-3px", marginLeft: "2px"}}
                    onClick={() => {
                      handleClick_like_button(
                        appContext.selectedProperty
                      );
                      appContext.setSavingAnimation(true);
                      // Perform some async operation here
                      setTimeout(() => {
                        appContext.setSavingAnimation(false);
                      }, 2000);
                    }}
                  >
                    {appContext.selectedProperty.saved ? <StarIcon /> : <StarOutlineIcon />}
                  </IconButton> */}
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip
                    title={
                      appContext.selectedProperty.install_capacity_kw === 0
                        ? "This property is not suitable for solar PV"
                        : null
                    }
                    placement="top-start"
                  >
                    <span>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => appContext.getPdf(appContext.selectedProperty)}
                        disabled={
                          appContext.selectedProperty.install_capacity_kw === 0
                        }
                        style={{ textTransform: "none" }}
                      >
                        Download Report
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>

              <List dense>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FactCheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={appContext.selectedProperty.reference}
                    secondary="Reference number"
                  />
                </ListItem>
                <Divider />
                {appContext.selectedProperty.class_desc === null ? null : (
                  <>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <LocationCityIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={appContext.selectedProperty.class_desc}
                        secondary="Building class"
                      />
                    </ListItem>
                    <Divider />
                  </>
                )}
                {appContext.selectedProperty.class_sub_desc === null ? null : (
                  <>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <CorporateFareIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={appContext.selectedProperty.class_sub_desc}
                        secondary="Sub classification"
                      />
                    </ListItem>
                    <Divider />
                  </>
                )}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <LocationOnIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <a
                        href={
                          "https://www.google.co.uk/maps/@" +
                          appContext.selectedProperty?.latitude +
                          "," +
                          appContext.selectedProperty?.longitude +
                          ",19z"
                        }
                        target="_blank"
                      >
                        {appContext.selectedProperty.latitude},{" "}
                        {appContext.selectedProperty.longitude}
                      </a>
                    }
                    secondary="View on Google map"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ViewModuleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <span>
                        {appContext.selectedProperty.install_capacity_kw?.toLocaleString()}{" "}
                        kW
                      </span>
                    }
                    secondary="Estimated solar system capacity"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <EvStationIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <span>
                        {appContext.selectedProperty.expected_power_generation_kwh?.toLocaleString()}{" "}
                        kWh
                      </span>
                    }
                    secondary="Estimated annual electricity generation"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <EnergySavingsLeafIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <span>
                        £{" "}
                        {appContext.selectedProperty.potential_saving_year_1?.toLocaleString()}
                      </span>
                    }
                    secondary="Energy saving in year 1"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <WbCloudyIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <span>
                        {appContext.selectedProperty.co2_saving_year_1?.toLocaleString()}{" "}
                        Tonnes
                      </span>
                    }
                    secondary="CO2 saving in year 1"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <EmojiObjectsIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <span>
                        £{" "}
                        {(
                          appContext.selectedProperty.indicative_cost ?? 0
                        ).toLocaleString()}
                      </span>
                    }
                    secondary="Indicative cost"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ElectricMeterIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <span>
                        £{" "}
                        {appContext.selectedProperty.total_utility_bill_savings_20?.toLocaleString()}
                      </span>
                    }
                    secondary="Total cost saving over 20 years"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <EqualizerIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      (appContext.selectedProperty.roi ?? 0 * 100).toFixed(0) + " %"
                    }
                    secondary="Return on Investment"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <EqualizerIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={appContext.selectedProperty.payback}
                    secondary="Estimated payback period"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <HelpIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      appContext.selectedProperty.council_property ? "Yes" : "No"
                    }
                    secondary="Is this a council property?"
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <HelpIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      appContext.selectedProperty.conservation_area ? "Yes" : "No"
                    }
                    secondary="Is it in a conservation area? "
                  />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <HelpIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={appContext.selectedProperty.listed ? "Yes" : "No"}
                    secondary="Is this a listed building? "
                  />
                </ListItem>

                {childrenAddresses.length > 0 ? (
                  <>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        primary="Other addresses:"
                        secondary={childrenAddresses.map((address: string) => {
                          return (
                            <>
                              <br />
                              <br />
                              {address}
                            </>
                          );
                        })}
                      />
                    </ListItem>
                  </>
                ) : null}
                <Divider />
              </List>
              {/* <Button fullWidth variant="contained" onClick={getPdf}>
                Download report
              </Button> */}
            </div>
          ) : (
            "Loading..."
          )}
        </Drawer>

        {/* <Paper elevation={3} /> */}
        {/* <LoadingOverlay active={isLoading} spinner text="Loading table..." className="loading_table"> */}
        <Card>
          <CardContent style={{ paddingTop: "2px" }}>
            {!isLoading && appContext.dataLoaded ? (
              <FoundPropertiesProjectAdmin
                setRefData={appContext.setRefData}
                dashboardDatas={tableData}
                setSearchDatas={setTableData}
                defaultRows={20}
                isAdmin={
                  userContext.userState.username.includes("absolar.co.uk")
                }
                search={search}
                setSearch={setSearch}
                resetSearch={resetSearch}
                searchData={searchData}
                updatePanelChoice={props.updatePanelChoice}
              />
            ) : (
              <>
                <CircularProgress
                  style={{ position: "absolute", marginTop: "45vh" }}
                />
                <h2
                  style={{
                    position: "absolute",
                    marginTop: "52vh",
                    left: "0",
                    right: "0",
                  }}
                >
                  Loading data...
                </h2>
                <Stack>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="40%"
                    height="40px"
                    style={{ marginTop: "20px" }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="48px"
                    style={{ marginTop: "20px" }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="calc(100vh - 64px - 100px - 60px - 20px)"
                    style={{ marginTop: "5px" }}
                  />
                </Stack>
                {/* <div >Loading1</div> */}
              </>
            )}
            {/* <EditPage/> */}
          </CardContent>
        </Card>
        {/* </LoadingOverlay> */}

        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
            setLoadingMap(true);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="outer_map_container">
            {loadingMap ? (
              <div className="map_loading">
                <div className="loading_overlay">
                  <CircularProgress /> <div>Loading</div>
                </div>
              </div>
            ) : null}
            <div className="map_container">
              {appContext.mapDatas.length > 0 ? (
                <MapComponent
                  mapData={appContext.mapDatas}
                  lat={51.27367722667764}
                  lng={-1.1061605536998302}
                  zoom={16}
                  handleToidSelect={appContext.handleToidSelect}
                />
              ) : null}
            </div>
          </Box>
        </Modal>
      </>
      {/* <Modal
        open={accountModalOpen}
        onClose={() => {
          setAccountModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AccountPage  />
      </Modal> */}
    </div>
  );
}
