import jsPDF from "jspdf";
import natwest_script from "./pdf_generator/natwest_script.txt";
import uos_script from "./pdf_generator/nonnatwest_script.txt";
import natwest_background_base64 from "./pdf_generator/natwest_background_base64.txt";
import standard_background_base64 from "./pdf_generator/standard_background_base64.txt";
import logo_absolar_base64 from "./pdf_generator/logo_absolar_base64.txt";

import solar_icon_base64 from "./pdf_generator/solar_icon_base64.txt";
import piggie_icon_base64 from "./pdf_generator/piggie_icon_base64.txt";
import plug_icon_base64 from "./pdf_generator/plug_icon_base64.txt";
import co2_icon_base64 from "./pdf_generator/co2_icon_base64.txt";
import tool_icon_base64 from "./pdf_generator/tool_icon_base64.txt";
import { formatMoney, formatNumber, getTodaysDate } from "./App";

export async function generatePdf(
  property: DashboardData,
  radiation: string,
  image: string,
  setLoadPdfDownloading: React.Dispatch<React.SetStateAction<boolean>>,
  isNatwest: boolean
) {
  //processing of images
  const logo_absolar_Retrieved = await fetch(logo_absolar_base64);
  const logo_absolar_text64 = await logo_absolar_Retrieved.text();

  const solar_icon_Retrieved = await fetch(solar_icon_base64);
  const solar_icon_text64 = await solar_icon_Retrieved.text();

  const piggie_icon_Retrieved = await fetch(piggie_icon_base64);
  const piggie_icon_text64 = await piggie_icon_Retrieved.text();

  const plug_icon_Retrieved = await fetch(plug_icon_base64);
  const plug_icon_text64 = await plug_icon_Retrieved.text();

  const co2_icon_Retrieved = await fetch(co2_icon_base64);
  const co2_icon_text64 = await co2_icon_Retrieved.text();

  const tool_icon_Retrieved = await fetch(tool_icon_base64);
  const tool_icon_text64 = await tool_icon_Retrieved.text();

  //processing of natwest base64images
  const natwest_background_Retrieved = await fetch(natwest_background_base64);
  const natwest_background_text64 = await natwest_background_Retrieved.text();

  //processing of standard base64images
  const standard_background_Retrieved = await fetch(standard_background_base64);
  const standard_background_text64 = await standard_background_Retrieved.text();

  if (
    property.reference.includes("admin_12_210") ||
    property.organisation_id === 22 ||
    property.organisation_id === 25 ||
    property.organisation_id === 18 ||
    property.organisation_id === 16 ||
    property.organisation_id === 28 ||
    property.organisation_id === 27 ||
    property.organisation_id === 18 
  ) {
    //organisation ID should have the company name - eitehr natwest or not
    fetch(uos_script)
      .then((r) => r.text())
      .then((text) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "text/html");

        const addressE = doc.getElementById("address");

        if (addressE !== null) {
          addressE.textContent = property.address;
        }
        const referenceE = doc.getElementById("reference");
        if (referenceE !== null) {
          referenceE.textContent = property.reference.toUpperCase();
        }
        // Update the date variable - it's different because we have MULTIPLE fields with that class
        const dateElements = doc.getElementsByClassName("pdf_date_editable");
        if (dateElements.length > 0) {
          //and we iterate through all that have that class
          for (let i = 0; i < dateElements.length; i++) {
            const dateElement = dateElements[i];
            dateElement.textContent = getTodaysDate();
          }
        }

        // Update the installCap variable
        const installCapElement = doc.getElementById("install-cap");
        if (installCapElement !== null) {
          installCapElement.textContent =
            property.install_capacity_kw != null
              ? property.install_capacity_kw.toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                })
              : "N/A";
        }

        // Update the potentialSav variable
        const potentialSavElement = doc.getElementById("potential-sav");
        if (potentialSavElement !== null) {
          potentialSavElement.textContent =
            property.potential_saving_year_1 != null
              ? formatNumber(property.potential_saving_year_1)
              : "N/A";
        }

        // Update the Co2Sav variable
        const Co2SavElement = doc.getElementById("co2-sav");
        if (Co2SavElement !== null) {
          Co2SavElement.textContent =
            property.co2_saving_year_1 != null
              ? formatNumber(property.co2_saving_year_1).toString()
              : "N/A";
        }

        // Update the electricity generation variable
        const electricityGenerationElement =
          doc.getElementById("annual-generation");
        if (electricityGenerationElement !== null) {
          electricityGenerationElement.textContent =
            property.expected_power_generation_kwh != null
              ? formatNumber(property.expected_power_generation_kwh).toString()
              : "N/A";
        }

        // Update the indicCost variable
        const indicCostElement = doc.getElementById("indic-cost");
        if (indicCostElement !== null) {
          indicCostElement.textContent =
            property.indicative_cost != null
              ? formatNumber(parseInt(property.indicative_cost.toFixed(0), 10)).toString()
              : "N/A";
        }

        // Update the indicCost variable
        const indicCostElement2 = doc.getElementById("indic-cost-2");
        if (indicCostElement2 !== null) {
          indicCostElement2.textContent =
            property.indicative_cost != null
              ? formatNumber(property.indicative_cost).toString()
              : "N/A";
        }

        // Update the perMonth variable
        const perMonthElement = doc.getElementById("per-month");
        if (perMonthElement !== null) {
          perMonthElement.textContent =
            property.monthly_repayment != null
              ? property.monthly_repayment.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })
              : "N/A";
        }

        // Update the payback period variable
        const payBackPeriodElement = doc.getElementById("payback-period");
        if (payBackPeriodElement !== null) {
          payBackPeriodElement.textContent =
            property.payback != null ? property.payback : "N/A";
        }

        // Update the ROI variable
        const roiElement = doc.getElementById("investment-return");
        if (roiElement !== null) {
          roiElement.textContent = property.roi
            ? property.roi.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              }) + "%"
            : "Not Applicable";
        }

        // Update the cashLifetimeSav variable
        const totalLifetimeSavElement = doc.getElementById(
          "total-lifetime-saving"
        );
        if (totalLifetimeSavElement !== null) {
          totalLifetimeSavElement.textContent =
            property.total_utility_bill_savings_20 != null
              ? formatMoney(parseInt(property.total_utility_bill_savings_20.toFixed(0), 10))
              : "N/A";
        }

        // Update the net saving variable
        const netLifetimeSavElement = doc.getElementById("net-lifetime-sav");
        if (netLifetimeSavElement !== null) {
          netLifetimeSavElement.textContent =
            property.total_utility_bill_savings_20 != null &&
            property.indicative_cost != null
              ? formatMoney(
                  property.total_utility_bill_savings_20 -
                    property.indicative_cost
                )
              : "N/A";
        }

        // Update the cashPayback variable
        const cashPaybackElement = doc.getElementById("cash-payback");
        if (cashPaybackElement !== null) {
          cashPaybackElement.textContent =
            property.payback != null ? property.payback.toString() : "N/A";
        }

        // Update the cashInvestment variable
        const cashInvestmentElement = doc.getElementById("cash-investment");
        if (cashInvestmentElement !== null) {
          cashInvestmentElement.textContent =
            property.roi != null
              ? property.roi.toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                }) + "%"
              : "";
        }
        // Update the financeMonthly variable
        const financeMonthlyElement = doc.getElementById("finance-monthly");
        if (financeMonthlyElement !== null) {
          financeMonthlyElement.textContent =
            property.monthly_repayment != null
              ? "£" +
                property.monthly_repayment.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })
              : "";
        }

        // Update the financeLifetimeSav variable
        const financeLifetimeSavElement = doc.getElementById(
          "finance-lifetime-sav"
        );
        if (financeLifetimeSavElement !== null) {
          financeLifetimeSavElement.textContent =
            property.loan_total_utility_bill_savings_20 != null
              ? "£" +
                property.loan_total_utility_bill_savings_20.toLocaleString(
                  undefined,
                  { maximumFractionDigits: 0 }
                )
              : "";
        }

        // Update the financePayback variable
        const financePaybackElement = doc.getElementById("finance-payback");
        if (financePaybackElement !== null) {
          financePaybackElement.textContent =
            property.loan_payback != null
              ? property.loan_payback.toString()
              : "";
        }
        // Update the financeInvestment variable
        const financeInvestmentElement =
          doc.getElementById("finance-investment");
        if (financeInvestmentElement !== null) {
          financeInvestmentElement.textContent =
            property.loan_roi != null
              ? property.loan_roi.toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                }) + "%"
              : "";
        }
        // Update the typeInstall variable
        const typeInstallElement = doc.getElementById("type-install");
        if (typeInstallElement !== null) {
          typeInstallElement.textContent =
            property.type_of_installation != null
              ? property.type_of_installation.toString()
              : "";
        }

        // Update the buildingHeight variable
        const buildingHeightElement = doc.getElementById("building-height");
        if (buildingHeightElement !== null) {
          buildingHeightElement.textContent =
            property.max_building_height != null
              ? property.max_building_height.toString()
              : "";
        }
        // Update the roofHeight variable
        const roofHeightElement = doc.getElementById("roof-height");
        if (roofHeightElement !== null) {
          roofHeightElement.textContent =
            property.min_building_height != null
              ? property.min_building_height.toString()
              : "";
        }

        // Update the selfConsumption variable
        const selfConsumptionElement = doc.getElementById("self-consumption");
        if (selfConsumptionElement !== null) {
          selfConsumptionElement.textContent =
            property.self_consumption != null
              ? property.self_consumption.toString() + "%"
              : "";
        }
        // Update the referencePanel variable
        const referencePanelElement = doc.getElementById("reference-panel");
        if (referencePanelElement !== null) {
          referencePanelElement.textContent =
            property.reference_panel != null
              ? property.reference_panel.toString()
              : "";
        }

        // Update the energyPriceInfl variable
        const energyPriceInflElement = doc.getElementById("energy-price-infl");
        if (energyPriceInflElement !== null) {
          energyPriceInflElement.textContent =
            property.energy_price_inflation != null
              ? property.energy_price_inflation.toString()
              : "";
        }
        // Update the assumedCarbon variable
        const assumedCarbonElement = doc.getElementById("assumed-carbon");
        if (assumedCarbonElement !== null) {
          assumedCarbonElement.textContent =
            property.assum_carbon_factor != null
              ? property.assum_carbon_factor.toString()
              : "";
        }

        // Update the assumedTariff variable
        const assumedTariffElement = doc.getElementById("assumed-tariff");
        if (assumedTariffElement !== null) {
          assumedTariffElement.textContent =
            property.assum_tariff != null
              ? property.assum_tariff.toString()
              : "";
        }

        if (image != "") {
          var img = document.createElement("img");
          img.src = image;

          // set the position of the image
          img.style.position = "absolute";
          img.style.top = "334px";
          img.style.left = "498px";

          // set the size of the image
          img.style.width = "44%";
          img.style.height = "23%";

          // get the body element of the HTML document
          var body = doc.getElementsByTagName("body")[0];

          // append the image to the body
          body.appendChild(img);
        } else {
          //if there is no image

          var grayRect = document.createElement("div");

          // set the background color of the div to gray
          grayRect.style.backgroundColor = "#ddd";

          // set the size and position of the div
          grayRect.style.width = "200px";
          grayRect.style.height = "100px";
          // set the position of the image
          grayRect.style.position = "absolute";
          grayRect.style.top = "324px";
          grayRect.style.left = "498px";

          // set the size of the image
          grayRect.style.width = "44%";
          grayRect.style.height = "23%";

          // get the body element of the HTML document
          var body = doc.getElementsByTagName("body")[0];

          // append the image to the body
          body.appendChild(grayRect);
        }

        if (radiation != "") {
          var imgRadiation = document.createElement("img");
          imgRadiation.src = radiation;

          // set the position of the image
          imgRadiation.style.position = "absolute";
          imgRadiation.style.top = "710px";
          imgRadiation.style.left = "497px";

          // set the size of the image
          imgRadiation.style.width = "44%";
          imgRadiation.style.height = "23%";
          // get the body element of the HTML document
          var body = doc.getElementsByTagName("body")[0];

          // append the image to the body
          body.appendChild(imgRadiation);
        } else {
          var grayRect = document.createElement("div");

          // set the background color of the div to gray
          grayRect.style.backgroundColor = "#ddd";

          // set the size and position of the div
          grayRect.style.width = "200px";
          grayRect.style.height = "100px";
          // set the position of the image
          grayRect.style.position = "absolute";
          grayRect.style.top = "668px";
          grayRect.style.left = "497px";
          // set the size of the image
          grayRect.style.width = "44%";
          grayRect.style.height = "23%";

          // get the body element of the HTML document
          var body = doc.getElementsByTagName("body")[0];

          // append the image to the body
          body.appendChild(grayRect);
        }

        //update background image
        const imgelement = doc.getElementById("backgr") as HTMLImageElement;
        if (imgelement !== null) {
          var stringBase64 = "";
          imgelement.src = `data:image/png;base64,${standard_background_text64}`;
        }

        //update logo image
        const imglogoelement = doc.getElementById(
          "logo_absolar"
        ) as HTMLImageElement;
        if (imglogoelement !== null) {
          const image = new Image();
          image.onload = function () {
            imglogoelement.src = image.src;
            imglogoelement.style.height = "30px";
            imglogoelement.style.marginLeft = "10px";
            imglogoelement.style.marginTop = "5px";
          };
          image.src = `data:image/png;base64,${logo_absolar_text64}`;
        }

        //update solar icon image
        const imgSolarelement = doc.getElementById(
          "solar_icon"
        ) as HTMLImageElement;
        if (imgSolarelement !== null) {
          const image = new Image();
          image.onload = function () {
            imgSolarelement.src = image.src;
            imgSolarelement.style.width = "65px";
            imgSolarelement.style.marginTop = "10px";
          };
          image.src = `data:image/png;base64,${solar_icon_text64}`;
        }

        //update plug icon image
        const imgPluglement = doc.getElementById(
          "plug_icon"
        ) as HTMLImageElement;
        if (imgPluglement !== null) {
          const image = new Image();
          image.onload = function () {
            imgPluglement.src = image.src;
            imgPluglement.style.width = "65px";
            imgPluglement.style.marginTop = "10px";
          };
          image.src = `data:image/png;base64,${plug_icon_text64}`;
        }

        //update piggie icon image
        const imgPiggielement = doc.getElementById(
          "piggie_icon"
        ) as HTMLImageElement;
        if (imgPiggielement !== null) {
          const image = new Image();
          image.onload = function () {
            imgPiggielement.src = image.src;
            imgSolarelement.style.width = "65px";
            imgPiggielement.style.marginTop = "10px";
          };
          image.src = `data:image/png;base64,${piggie_icon_text64}`;
        }

        //update co2 icon image
        const imgco2lement = doc.getElementById("co2_icon") as HTMLImageElement;
        if (imgco2lement !== null) {
          const image = new Image();
          image.onload = function () {
            imgco2lement.src = image.src;
            imgco2lement.style.width = "65px";
            imgco2lement.style.marginTop = "10px";
          };
          image.src = `data:image/png;base64,${co2_icon_text64}`;
        }
        setTimeout(() => {
          //this timeout is needed to make sure that the base64 images have time to load

          console.log("producing");

          console.log("text decoded:", doc);
          //pdf generator
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: "a4",
            hotfixes: ["px_scaling"],
          });

          pdf.html(doc.documentElement, {
            callback: function (pdf) {
              // Save the PDF
              // Get the number of pages in the PDF
              const pageCount = pdf.internal.pages.length;

              // If there are more than one page, delete the second page
              if (pageCount > 1) {
                pdf.deletePage(2);
              }

              pdf.save(property.reference + ".pdf");
              setLoadPdfDownloading(false);
            },
            x: 0,
            y: -5.5,
            width: 170, //target width in the PDF document
            windowWidth: 650, //window width in CSS pixels
          });
        }, 2000);
      });
  } else if (property.reference.includes("NW") || isNatwest) {
    //organisation ID should have the company name - eitehr natwest or not
    fetch(natwest_script)
      .then((r) => r.text())
      .then((text) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "text/html");

        const addressE = doc.getElementById("address");

        if (addressE !== null) {
          addressE.textContent = property.address;
        }
        const referenceE = doc.getElementById("reference");
        if (referenceE !== null) {
          referenceE.textContent = property.reference;
        }
        // Update the date variable - it's different because we have MULTIPLE fields with that class
        const dateElements = doc.getElementsByClassName("pdf_date_editable");
        if (dateElements.length > 0) {
          //and we iterate through all that have that class
          for (let i = 0; i < dateElements.length; i++) {
            const dateElement = dateElements[i];
            dateElement.textContent = getTodaysDate();
          }
        }

        // Update the installCap variable
        const installCapElement = doc.getElementById("install-cap");
        if (installCapElement !== null) {
          installCapElement.textContent =
            property.install_capacity_kw != null
              ? property.install_capacity_kw.toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                })
              : "N/A";
        }

        // Update the potentialSav variable
        const potentialSavElement = doc.getElementById("potential-sav");
        if (potentialSavElement !== null) {
          potentialSavElement.textContent =
            property.potential_saving_year_1 != null
              ? formatNumber(property.potential_saving_year_1)
              : "N/A";
        }

        // Update the Co2Sav variable
        const Co2SavElement = doc.getElementById("co2-sav");
        if (Co2SavElement !== null) {
          Co2SavElement.textContent =
            property.co2_saving_year_1 != null
              ? formatNumber(property.co2_saving_year_1).toString()
              : "N/A";
        }

        // Update the indicCost variable
        const indicCostElement = doc.getElementById("indic-cost");
        if (indicCostElement !== null) {
          indicCostElement.textContent =
            property.indicative_cost != null
              ? formatNumber(parseInt(property.indicative_cost.toFixed(0), 10)).toString()
              : "N/A";
        }

        // Update the perMonth variable
        const perMonthElement = doc.getElementById("per-month");
        if (perMonthElement !== null) {
          perMonthElement.textContent =
            property.monthly_repayment != null
              ? property.monthly_repayment.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })
              : "N/A";
        }

        // Update the cashLifetimeSav variable
        const cashLifetimeSavElement = doc.getElementById("cash-lifetime-sav");
        if (cashLifetimeSavElement !== null) {
          cashLifetimeSavElement.textContent =
            property.total_utility_bill_savings_20 != null
              ? formatMoney(
                  parseInt(
                    property.total_utility_bill_savings_20.toFixed(0),
                    10
                  )
                )
              : "N/A";
        }

        // Update the cashPayback variable
        const cashPaybackElement = doc.getElementById("cash-payback");
        if (cashPaybackElement !== null) {
          cashPaybackElement.textContent =
            property.payback != null ? property.payback.toString() : "N/A";
        }

        // Update the cashInvestment variable
        const cashInvestmentElement = doc.getElementById("cash-investment");
        if (cashInvestmentElement !== null) {
          cashInvestmentElement.textContent =
            property.roi != null
              ? property.roi.toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                }) + "%"
              : "";
        }
        // Update the financeMonthly variable
        const financeMonthlyElement = doc.getElementById("finance-monthly");
        if (financeMonthlyElement !== null) {
          financeMonthlyElement.textContent =
            property.monthly_repayment != null
              ? "£" +
                property.monthly_repayment.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })
              : "";
        }

        // Update the financeLifetimeSav variable
        const financeLifetimeSavElement = doc.getElementById(
          "finance-lifetime-sav"
        );
        if (financeLifetimeSavElement !== null) {
          financeLifetimeSavElement.textContent =
            property.loan_total_utility_bill_savings_20 != null
              ? "£" +
                property.loan_total_utility_bill_savings_20.toLocaleString(
                  undefined,
                  { maximumFractionDigits: 0 }
                )
              : "";
        }

        // Update the financePayback variable
        const financePaybackElement = doc.getElementById("finance-payback");
        if (financePaybackElement !== null) {
          financePaybackElement.textContent =
            property.loan_payback != null
              ? property.loan_payback.toString()
              : "";
        }
        // Update the financeInvestment variable
        const financeInvestmentElement =
          doc.getElementById("finance-investment");
        if (financeInvestmentElement !== null) {
          financeInvestmentElement.textContent =
            property.loan_roi != null
              ? property.loan_roi.toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                }) + "%"
              : "";
        }
        // Update the typeInstall variable
        const typeInstallElement = doc.getElementById("type-install");
        if (typeInstallElement !== null) {
          typeInstallElement.textContent =
            property.type_of_installation != null
              ? property.type_of_installation.toString()
              : "";
        }

        // Update the buildingHeight variable
        const buildingHeightElement = doc.getElementById("building-height");
        if (buildingHeightElement !== null) {
          buildingHeightElement.textContent =
            property.max_building_height != null
              ? property.max_building_height.toString()
              : "";
        }
        // Update the roofHeight variable
        const roofHeightElement = doc.getElementById("roof-height");
        if (roofHeightElement !== null) {
          roofHeightElement.textContent =
            property.min_building_height != null
              ? property.min_building_height.toString()
              : "";
        }

        // Update the selfConsumption variable
        const selfConsumptionElement = doc.getElementById("self-consumption");
        if (selfConsumptionElement !== null) {
          selfConsumptionElement.textContent =
            property.self_consumption != null
              ? property.self_consumption.toString()
              : "";
        }
        // Update the referencePanel variable
        const referencePanelElement = doc.getElementById("reference-panel");
        if (referencePanelElement !== null) {
          referencePanelElement.textContent =
            property.reference_panel != null
              ? property.reference_panel.toString()
              : "";
        }

        // Update the energyPriceInfl variable
        const energyPriceInflElement = doc.getElementById("energy-price-infl");
        if (energyPriceInflElement !== null) {
          energyPriceInflElement.textContent =
            property.energy_price_inflation != null
              ? property.energy_price_inflation.toString()
              : "";
        }
        // Update the assumedCarbon variable
        const assumedCarbonElement = doc.getElementById("assumed-carbon");
        if (assumedCarbonElement !== null) {
          assumedCarbonElement.textContent =
            property.assum_carbon_factor != null
              ? property.assum_carbon_factor.toString()
              : "";
        }

        // Update the assumedTariff variable
        const assumedTariffElement = doc.getElementById("assumed-tariff");
        if (assumedTariffElement !== null) {
          assumedTariffElement.textContent =
            property.assum_tariff != null
              ? property.assum_tariff.toString()
              : "";
        }

        if (image != "") {
          var img = document.createElement("img");
          img.src = image;

          // set the position of the image
          img.style.position = "absolute";
          img.style.top = "324px";
          img.style.left = "498px";

          // set the size of the image
          img.style.width = "44%";
          img.style.height = "23%";

          // get the body element of the HTML document
          var body = doc.getElementsByTagName("body")[0];

          // append the image to the body
          body.appendChild(img);
        } else {
          //if there is no image

          var grayRect = document.createElement("div");

          // set the background color of the div to gray
          grayRect.style.backgroundColor = "#ddd";

          // set the size and position of the div
          grayRect.style.width = "200px";
          grayRect.style.height = "100px";
          // set the position of the image
          grayRect.style.position = "absolute";
          grayRect.style.top = "324px";
          grayRect.style.left = "498px";

          // set the size of the image
          grayRect.style.width = "44%";
          grayRect.style.height = "23%";

          // get the body element of the HTML document
          var body = doc.getElementsByTagName("body")[0];

          // append the image to the body
          body.appendChild(grayRect);
        }

        if (radiation != "") {
          var imgRadiation = document.createElement("img");
          imgRadiation.src = radiation;

          // set the position of the image
          imgRadiation.style.position = "absolute";
          imgRadiation.style.top = "668px";
          imgRadiation.style.left = "497px";

          // set the size of the image
          imgRadiation.style.width = "44%";
          imgRadiation.style.height = "23%";
          // get the body element of the HTML document
          var body = doc.getElementsByTagName("body")[0];

          // append the image to the body
          body.appendChild(imgRadiation);
        } else {
          var grayRect = document.createElement("div");

          // set the background color of the div to gray
          grayRect.style.backgroundColor = "#ddd";

          // set the size and position of the div
          grayRect.style.width = "200px";
          grayRect.style.height = "100px";
          // set the position of the image
          grayRect.style.position = "absolute";
          grayRect.style.top = "668px";
          grayRect.style.left = "497px";
          // set the size of the image
          grayRect.style.width = "44%";
          grayRect.style.height = "23%";

          // get the body element of the HTML document
          var body = doc.getElementsByTagName("body")[0];

          // append the image to the body
          body.appendChild(grayRect);
        }

        //update background image
        const imgelement = doc.getElementById("backgr") as HTMLImageElement;
        if (imgelement !== null) {
          imgelement.src = `data:image/png;base64,${natwest_background_text64}`;
        }

        //update logo image
        const imglogoelement = doc.getElementById(
          "logo_absolar"
        ) as HTMLImageElement;
        if (imglogoelement !== null) {
          const image = new Image();
          image.onload = function () {
            imglogoelement.src = image.src;
            imglogoelement.style.width = "220px";
            imglogoelement.style.marginTop = "8px";
          };
          image.src = `data:image/png;base64,${logo_absolar_text64}`;
        }

        //update solar icon image
        const imgSolarelement = doc.getElementById(
          "solar_icon"
        ) as HTMLImageElement;
        if (imgSolarelement !== null) {
          const image = new Image();
          image.onload = function () {
            imgSolarelement.src = image.src;
            imgSolarelement.style.width = "65px";
            imgSolarelement.style.marginTop = "10px";
          };
          image.src = `data:image/png;base64,${solar_icon_text64}`;
        }

        //update piggie icon image
        const imgPiggielement = doc.getElementById(
          "piggie_icon"
        ) as HTMLImageElement;
        if (imgPiggielement !== null) {
          const image = new Image();
          image.onload = function () {
            imgPiggielement.src = image.src;
            imgPiggielement.style.width = "65px";
            imgPiggielement.style.marginTop = "10px";
          };
          image.src = `data:image/png;base64,${piggie_icon_text64}`;
        }

        //update co2 icon image
        const imgco2lement = doc.getElementById("co2_icon") as HTMLImageElement;
        if (imgco2lement !== null) {
          const image = new Image();
          image.onload = function () {
            imgco2lement.src = image.src;
            imgco2lement.style.width = "65px";
            imgco2lement.style.marginTop = "10px";
          };
          image.src = `data:image/png;base64,${co2_icon_text64}`;
        }

        //update tool icon image
        const imgtoollement = doc.getElementById(
          "tool_icon"
        ) as HTMLImageElement;
        if (imgtoollement !== null) {
          const image = new Image();
          image.onload = function () {
            imgtoollement.src = image.src;
            imgtoollement.style.width = "65px";
            imgtoollement.style.marginTop = "10px";
          };
          image.src = `data:image/png;base64,${tool_icon_text64}`;
        }
        setTimeout(() => {
          //this timeout is needed to make sure that the base64 images have time to load

          console.log("producing");

          console.log("text decoded:", doc);
          //pdf generator
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: "a4",
            hotfixes: ["px_scaling"],
            compress: true,
          });

          pdf.html(doc.documentElement, {
            callback: function (pdf) {
              // Save the PDF
              // Get the number of pages in the PDF
              const pageCount = pdf.internal.pages.length;

              // If there are more than one page, delete the second page
              if (pageCount > 1) {
                pdf.deletePage(2);
              }

              pdf.save(property.reference + ".pdf");
              setLoadPdfDownloading(false);
            },
            x: 0,
            y: -5.5,
            width: 170, //target width in the PDF document
            windowWidth: 650, //window width in CSS pixels
          });
        }, 2000);
      });
  }
}
