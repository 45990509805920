import React, { useState, useEffect, useContext } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Button,
  TextField,
  Tooltip,
  IconButton,
  InputAdornment,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Eclipse_logo from "./../images/eclipse.png";
import Ecogen_logo from "./../images/ecogen.png";
import Infinity_logo from "./../images/infinity.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { getData } from "../get-data";
import { UserContext } from "../contexts/UserContext";
import MCS_cert from "../images/mcs_cert.png";

interface IProps {
  user: UserState;
  reference: string;
}
export default function ModalApprovedInstaller(props: IProps) {
  // function trackClick(buttonClicked: string) {
  //   // code to track the click event
  //   gtag("event", buttonClicked, {
  //     event_category: "Button",
  //     event_label: buttonClicked,
  //   });
  // }
  const userContext = useContext(UserContext);

  const [installers, setInstallers] = useState<Installer[]>([]);
  useEffect(() => {
    getData(
      process.env.REACT_APP_API_URL+"/Installer/building_data_installer?reference=" +
        props.reference,
      false,
      userContext.getCredentials
    )
      .then((installers: Installer[]) => {
        setInstallers(installers);
      })
      .catch((e) => {
        console.log("unable to get installers");
      });
  }, []);
  return (
    <div
      style={{
        width: "80%",
        maxWidth: "1200px",
        margin: "30px auto",
        textAlign: "left",
        // background:"#e5e5e5"
      }}
    >
      <div className="modal_container_full">
        {/* <div className="modal_close_button">
          <IconButton size="large">
            <HighlightOffIcon />
          </IconButton>
        </div> */}
        <Typography variant="h4">Approved installers</Typography>

        {installers.length === 0 ? (
          <div style={{ marginTop: 30 }}>
      <Typography>
        We are unable to identify any suitable installers based on your property
        location and characteristics and may need more information to enable us
        to do so. Please contact  <a href="mailto:survey@absolar.co.uk">survey@absolar.co.uk</a> for further support. ​
      </Typography>
      <div
        style={{
          borderRadius: "10px",
          border: "1.5px solid black",
          marginTop: "30px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img
          src={MCS_cert}
          alt="MCS logo"
          style={{ margin: "10px", width: "90px" }}
        />
        <Typography>
          The Microgeneration Certification Scheme (MCS) online map search allows
          you to identify suitable installers that have passed the rigorous MCS
          certification scheme and may be able to help you further. Simply visit
          the below site and select ‘Solar PV’ in the What would you like
          installed section and your postcode in the search by location section.
          To find out more about MCS you can visit:{" "}
          <a target="_blank" href="https://mcscertified.com/about-us">https://mcscertified.com/about-us</a>{" "}
        </Typography>
        <Button variant="contained" style={{ margin: "10px", textAlign: "center" }} target="_blank" 
        href="https://mcscertified.com/find-an-installer/"
        >Find a MCS certified contractor <br/>(external link)</Button>
      </div>
          </div>
        ) : (
          <>
            <p>
              The following installers have been selected based on your location
              and the property characteristics:
            </p>
            <Grid container spacing={3} style={{ marginTop: 50 }}>
              {installers.map((installer) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={installer.name}>
                  <Card
                    style={{
                      textAlign: "left",
                      boxShadow: "3px 3px 6px grey",
                      height: "100%",
                    }}
                  >
                    <CardMedia
                      sx={{
                        height: 140,
                        backgroundColor: "#e5e5e5",
                        backgroundSize: "contain",
                      }}
                      image={installer.logo}
                      title="Infinity_logo"
                    />
                    <CardContent
                      style={{
                        paddingBottom: 0,
                        height: "calc(100% - 140px - 65px)",
                      }}
                    >
                      <Typography>
                        {installer.certificates?.map((certificateName) => {
                          return (
                            <a
                              style={{
                                background: "#ffb74d",
                                fontWeight: "500",
                                fontSize: "10px",
                                padding: "3px 12px",
                                borderRadius: 12,
                                marginRight: 5,
                              }}
                            >
                              {certificateName}
                            </a>
                          );
                        })}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        style={{ marginTop: 10 }}
                      >
                        {installer.name}
                      </Typography>
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            {installer.description}
                          </Typography>
                        }
                      >
                        <div className="approved_installer_desc">
                          <Typography variant="body2" color="text.secondary">
                            {installer.description}
                          </Typography>
                        </div>
                      </Tooltip>
                      <List dense>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <LocationOnIcon />
                          </ListItemIcon>
                          <ListItemText primary={installer.location} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <LocalPhoneIcon />
                          </ListItemIcon>
                          <ListItemText primary={installer.phone} />
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            <EmailIcon />
                          </ListItemIcon>
                          <ListItemText primary={installer.email} />
                        </ListItem>
                      </List>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        href={installer.website}
                        target="_blank"
                        size="small"
                        style={{textTransform:"none"}}
                      >
                        Visit Website
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {/* <div style={{ marginTop: 30 , height: "calc(100% - 330px - 32px - 21px - 32px - 25px - 20px)"}}>
          <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=1T3n8cc0Yax2mR7IvXoLCmc_c4AF-D6I&ehbc=2E312F"
            width="100%"
            height="100% !important"
          ></iframe>
        </div> */}
      </div>
    </div>
  );
}
