import React, { useContext, useEffect, useState } from "react";
import { Loading } from "../components";
import { Typography } from "@mui/material";
import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TablePage from "./TablePage_Admin";
import EditPage from "./EditPage";
import UserManagement from "./UserManagement_Admin";
import ParamUpdate from "./ParamUpdate_Admin";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./AdminLanding.css";
import ToidSearch from "./ToidSearch";
import { AppContext } from "../contexts/AppContext";

export default function AdminLandingPage() {
  const props = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [inputData, setCalculationSheet] = useState<CalculationSheet | null>(
    null
  );

  const menuItems = [
    {
      label: "Dashboard",
      subOptions: [{ label: "Property Edit", subOptions: [] }],
    },
    { label: "User Management", subOptions: [] },
    { label: "Upload Results", subOptions: [] },
    { label: "Toid Search", subOptions: [] },
    { label: "Parameter update", subOptions: [] },
  ];

  const [selectedMenuOption, setSelectedMenuOption] = useState<string>("");
  const [expandedElements, setExpandedElements] = useState<string[]>([]);

  //function to cover logic when a new page is selected on menu
  const handleOptionClick = (event: React.ChangeEvent<{}>, nodeIds: string) => {
    const index = expandedElements.indexOf(nodeIds);
    const copyExpanded = Array.from(expandedElements);
    if (index === -1) {
      copyExpanded.push(nodeIds);
    } else {
      copyExpanded.splice(index, 1);
    }
    //this next line added on in case
    //we want to remove the "Property Edit" panel whenever the user moves from the page
    setshowSubtree("");
    setExpandedElements(copyExpanded);
    setSelectedMenuOption(nodeIds);

    /** CAUTION: This is a temporary solution to stop the "Review" page crashing
     * The issue was: When clicking the "Toid Search" on side menu during the process of creating a new toid,
     * the page always goes blank at the stage of "Review", this is .
     * By Roy
     */
    if (!inputData) setCalculationSheet(null);
    //setCalculationSheet(null);
  };

  //variable handling whether the menu to show subtree for "Dashboard":
  //the logic is that the editing of a property should only occur once a property is selected
  //therefore this menu item - "Property Edit" should only show up upon clicking on a property
  const [showSubtree, setshowSubtree] = useState("");

  //this function is called upon clicking on a property in teh dashboard
  //this is also passed down to the otehr Table classes
  const updatePanelChoice = (nodeId: string) => {
    setshowSubtree(nodeId);
    setSelectedMenuOption(nodeId);
  };

  //due to asynchornous issues with the variables
  //i.e. updating the selected choice for the menu item BEFORE the "Dashboard" is expanded
  //we have a useEffect function
  //to trigger when the "Dashboard" is officially expanded, and only then to select "Property Edit"
  useEffect(() => {
    console.log("started");
    if (showSubtree !== "") {
      console.log("started2");
      setSelectedMenuOption(showSubtree);
      const exp = expandedElements;
      exp.push("Dashboard");
      setExpandedElements(exp);
    }
  }, [expandedElements, showSubtree]);

  //function rendering the subtree of each menu item - can be recursive if needed later on
  const renderSubtree = (nodes: any) => {
    return (
      <>
        {nodes.subOptions.length !== 0
          ? nodes.subOptions.map((node: any) => (
              <TreeItem
                key={node.label}
                nodeId={node.label}
                label={node.label}
                onClick={(e) => handleOptionClick(e, node.label)}
              ></TreeItem>
            ))
          : null}
      </>
    );
  };

  const [drawerClosed, setDrawerClosed] = useState(false);
  return (
    <div className="App">
      {isLoading ? (
        <Loading />
      ) : (
        <div
          className="main_container"
          style={{
            background: "#e8e8e8",
            zIndex: 0,
            height: "calc(100vh - 64px)",
            padding: "10px 0px",
          }}
        >
          <div
            className="main_container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              className="main_container"
              style={{
                background: "white",
                width: drawerClosed ? "30px" : "300px",
              }}
            >
              {drawerClosed ? (
                <button
                  className="fold_button"
                  onClick={() => {
                    setDrawerClosed(false);
                  }}
                >
                  <ArrowForwardIosIcon
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      color: "darkgrey",
                    }}
                  />
                </button>
              ) : (
                <button
                  className="fold_button"
                  onClick={() => {
                    setDrawerClosed(true);
                  }}
                >
                  <ArrowBackIosIcon
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      color: "darkgrey",
                    }}
                  />
                </button>
              )}
              <div
                style={{
                  display: drawerClosed ? "none" : undefined,
                  padding: "30px 16px 0px 16px",
                }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  FUNCTION PANEL
                </Typography>
                <hr></hr>
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  multiSelect={false}
                  style={{ textAlign: "start" }}
                  expanded={expandedElements}
                  selected={selectedMenuOption}
                  onNodeSelect={handleOptionClick}
                >
                  {menuItems.map((option: any) => (
                    <TreeItem
                      key={option.label}
                      nodeId={option.label}
                      label={option.label}
                      onClick={(e) => handleOptionClick(e, option.label)}
                    >
                      {option.subOptions.length > 0
                        ? showSubtree !== "" && option.label === "Dashboard"
                          ? renderSubtree(option)
                          : option.label !== "Dashboard"
                          ? renderSubtree(option)
                          : null
                        : null}
                    </TreeItem>
                  ))}
                </TreeView>
              </div>
            </div>
            <div
              style={{
                background: "white",
                width: drawerClosed
                  ? "calc(100vw - 30px - 10px)"
                  : "calc(100vw - 300px - 10px)",
                height: "calc( 100vh - 64px - 20px )",
              }}
            >
              <div style={{ height: "100%", overflow: "auto" }}>
                {selectedMenuOption === "Dashboard" && (
                  <TablePage updatePanelChoice={updatePanelChoice} />
                )}
                {selectedMenuOption === "Property Edit" && (
                  <EditPage
                    dashboardDatas={props.dashboardDatas}
                    setDashboardDatas={props.setDashboardDatas}
                    handleDownloadFile={props.handleDownloadFile}
                    upRef={props.refData ? props.refData.reference : null}
                    setOpenDialogueHandler={props.setOpenDialogueHandler}
                  />
                )}

                {selectedMenuOption === "User Management" && (
                  <UserManagement
                    upRef={props.refData ? props.refData.reference : null}
                  />
                )}
                {selectedMenuOption === "Parameter update" && <ParamUpdate />}

                {selectedMenuOption === "Upload Results" && (
                  <EditPage
                    dashboardDatas={props.dashboardDatas}
                    setDashboardDatas={props.setDashboardDatas}
                    handleDownloadFile={props.handleDownloadFile}
                    upRef={null}
                    setOpenDialogueHandler={props.setOpenDialogueHandler}
                  />
                )}

                {selectedMenuOption === "Toid Search" && (
                  <ToidSearch
                    inputData={inputData}
                    setCalculationSheet={setCalculationSheet}
                    backButtonInnacesible={false}
                    setDashboardDatas={props.setDashboardDatas}
                    address=""
                    setOpenDialogueHandler={props.setOpenDialogueHandler}
                  />
                )}
                {selectedMenuOption === "" && (
                  <div>
                    Select an option from the left-side navigation panel
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
