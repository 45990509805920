import React, { useState } from "react";

import {
  Typography,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { CardActionArea } from "@mui/material";

import HelpIcon from "@mui/icons-material/Help";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const faqs_report = [
  {
    question: "Why is the system size expressed in kW?",
    answer: (
      <>
        <p>
          Solar panels are sized in kilowatts (kW) because it is a standard unit
          of measurement for electrical power. Whilst it may initially be easier
          to understand a system size by the number of solar panels, different
          panels have different kW sizes and physical measurements, meaning it
          is ambiguous. Instead, we assess the solar potential of a roof space
          using an optimum sized panel, giving a more comparable result across
          different roof tops.
        </p>
        <p>
          The kW rating of a solar panel system is a measure of its power,
          rather than its physical size.
        </p>
      </>
    ),
  },
  {
    question: "How are the first-year cost savings calculated?",
    answer: (
      <>
        <p>
          The first-year savings is calculated based upon our modelling of the
          amount of power the solar PV system will generate and then only taking
          the power we expect you to use within the property itself (reducing
          your energy demand from the grid) based upon a number of assumptions.
          We then apply a specific £ per kWh rate to translate this energy
          saving into cost savings. The self-consumption and electricity rates
          used in the calculation are provided in the assumption section of the
          report.
        </p>
      </>
    ),
  },
  {
    question: "How are the CO2 savings calculated?",
    answer: (
      <>
        <p>
          We assess the entire production of the solar PV system and apply a
          grid carbon factor that estimates the carbon mix of the gird
          electricity you would have used had you not have had solar, together
          with the 100% carbon free electricity you send back to the grid (the
          energy you don’t use within the property itself).
        </p>
      </>
    ),
  },
  {
    question: "Why are first-year savings only shown?",
    answer: (
      <>
        <p>
          In this summary report we only show the first year cost and CO2
          savings for simplicity. In future years, inflationary costs and
          changes in the grid carbon mix will alter these numbers. Absolar’s
          consultancy service can help you understand these future impacts and
          apply sensitivity analysis to further investigate the investment
          performance of a solar PV system.
        </p>
      </>
    ),
  },
  {
    question: "How is the indicative cost calculated and what does it include?",
    answer: (
      <>
        <p>
          We use the current cost of materials and labour rates as at the date
          of assessment for the system size and installation type shown in the
          report. Actual costs may vary when considering project specific costs.
        </p>
        <p>
          The indicative cost includes all the materials required for the system
          to operate, labour to install the system together with managing the
          project and access requirements such as scaffolding and safety
          equipment.
        </p>
      </>
    ),
  },
  {
    question: "Why is lifetime net savings over 20 years?",
    answer: (
      <>
        <p>
          Solar panels should be expected to last over 30 years with
          manufacturer warranty’s lasting for 25 years in most cases. However,
          our research suggests that most clients only plan to a maximum of 20
          years hence we cut off analysis at that point to avoid over inflating
          savings figures.
        </p>
      </>
    ),
  },
  {
    question: "What is payback period?",
    answer: (
      <>
        <p>
          This represents the amount of time it should take to recover the
          initial installation cost through energy bill savings or exporting of
          surplus energy.
        </p>
      </>
    ),
  },
  {
    question: "What is the investment return?",
    answer: (
      <>
        <p>
          The figure presented within the report is Investment Rate of Return
          (IRR). The internal rate of return is a measure of the profitability
          of an investment. It is the rate of return that you would earn on your
          investment if you invested all of the money at once and then received
          all of the cash flows at the end of the investment period. IRR is a
          useful tool for comparing different investments. For example, if you
          are considering investing in two different solar PV projects, you can
          use IRR to compare the profitability of the two systems. The system
          with the higher IRR is the more profitable investment.
        </p>
      </>
    ),
  },
  {
    question:
      "How is the radiation analysis conducted and solar panels placement determined?",
    answer: (
      <>
        <p>
          Absolar’s unique technology uses LiDAR (laser based data from
          overflying aircraft) to build a 3D map of a property which is then
          mapped against the suns path at every 30 minute interval throughout
          the year. This allows us to understand which parts of the roof receive
          suitable amounts of solar radiation to warrant the installation of
          solar panels and which areas to avoid due to excessive shading from
          the surrounding environment or on roof obstacles such as chimneys or
          vents. Our machine learning and computer vision technology then
          assesses the satellite imagery to only design solar PV systems on
          areas that are free from obstruction and suitable for installation.
        </p>
      </>
    ),
  },
  {
    question: "What are the key assumptions used in the calculations?    ",
    answer: (
      <>
        <p>
          Apart from an underlying assumption that the electrical condition of
          the building is suitable for solar and that the rooftop is structural
          sound to support installation, our assumptions are shown with the
          ‘Assumptions’ section of the report.
        </p>
      </>
    ),
  },
];

export default function SupportPageContact() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div
      style={{
        width: "calc(100% - 40px)",
        maxWidth: "500px",
        padding: 20,
        height: "calc(100vh - 120px - 40px - 40px)",
        overflow: "auto",
      }}
    >
      <Typography
        variant="h6"
        style={{ marginTop: 10 }}
        gutterBottom
        paragraph
      >
        How can we help you?
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button disabled fullWidth variant="outlined" startIcon={<ChatBubbleOutlineIcon />} style={{paddingTop:20, paddingBottom:20, textTransform:"none"}}>Live chat</Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth variant="outlined" startIcon={<MailOutlineIcon />} style={{paddingTop:20, paddingBottom:20, textTransform:"none"}} href={"mailto:survey@absolar.co.uk?subject=Solar Assessment Enquiry "}>Email us</Button>
        </Grid>
        {/* <Grid item xs={4}>
          <Button disabled fullWidth variant="outlined" startIcon={<LocalPhoneIcon />} style={{paddingTop:20, paddingBottom:20, textTransform:"none"}}>Call back</Button>
        </Grid> */}
      </Grid>

      <Typography
        variant="body1"
        style={{
          // borderBottom: "1px lightgrey solid",
          marginTop: 30,
          marginBottom: 5,
        }}
      >
        Top questions
      </Typography>
      {faqs_report.map((faq) => (
        <Accordion
          key={faq.question}
          expanded={expanded === faq.question}
          onChange={handleChange(faq.question)}
          // style={{ marginTop: 10 }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails><Typography variant="body2">{faq.answer}</Typography></AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
