import React, { createRef } from "react";
import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import {
  CircleMarker,
  FeatureGroup,
  GeoJSON,
  LayersControl,
  Marker,
  Polygon,
  Popup,
} from "react-leaflet";
import { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Slider,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import hash from "object-hash";
import LayersIcon from "@mui/icons-material/Layers";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PaletteIcon from "@mui/icons-material/Palette";
import TerrainOutlinedIcon from "@mui/icons-material/TerrainOutlined";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import L, { latLng, LatLng } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { ElectricalServicesTwoTone } from "@mui/icons-material";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapComponent = (props) => {
  

 
  // All map files used
  const [maps, setMaps] = useState([]);
  const [allMaps, setAllMaps] = useState([]);
  const [allMapsState, setAllMapsState] = useState([]);
  // const maps = Object.values(
  //     importAll(require.context("../../public/maps", false, /.json/))
  // );
  const [mapConstants, setMapConstants] = useState({
    mapCenter: { lat: props.lat, lng: props.lng },
    mapZoom: props.zoom,
  });
  useEffect(() => {
    setMaps(props.mapData);
    setAllMaps(props.mapData);
    // //console.log(allMaps);
  }, [props.mapData]);
 
  // Excluded filters
  const toExcludeFilters = ["fid", "OBJECTID", "id"];

  //** */

  const colors = ["#fd3183", "#0080ff", "#e90000", "#17c200"];
  const outlines = ["#370016", "#004283", "#6d6141", "#0a5400"];
  const outlinesWidth = [2, 2, 2, 2];

  const geoJsonLayer1 = useRef(null);
  const geoJsonLayer2 = useRef(null);

  const [newLayerColours, setNewLayerColours] = useState([]);

  const [layerColours, setLayerColours] = useState([]);
  const [filterChips, setFilterChips] = useState([]);



  const [myRefs, setMyRefs] = useState([geoJsonLayer1, geoJsonLayer2]);
  const mapRef = React.createRef();

  const [selectedLayers, setSelectedLayers] = useState(
    allMaps.map((x) => x.name)
  );
  function componentToHex(c) {
    if (c) {
      ////console.log("RGB-1", c);

      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }
  }

  function rgbToHex(rgb) {
    if (rgb) {
      ////console.log("RGB", rgb);

      var r = rgb.r ? rgb.r : rgb.minusR ? rgb.minusR : rgb.plusR;
      var g = rgb.g ? rgb.g : rgb.minusG ? rgb.minusG : rgb.plusG;
      var b = rgb.b ? rgb.b : rgb.minusB ? rgb.minusB : rgb.plusB;

      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }
  }

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  function checkRBG(c) {
    let value = c;
    if (value > 255) {
      value = value - (value - 255);
    }
    return value;
  }
  function generate(rgb, variations) {
    // var r = document.querySelector("#R").value % 256;
    // var g = document.querySelector("#G").value % 256;
    // var b = document.querySelector("#B").value % 256;
    var r = rgb.r;
    var g = rgb.g;
    var b = rgb.b;

    var minusR = rgb.r;
    var minusG = rgb.g;
    var minusB = rgb.b;

    var plusR = rgb.r;
    var plusG = rgb.g;
    var plusB = rgb.b;

    var middle = parseInt(variations / 2);
    ////console.log(middle);

    ////console.log("HEX", rgbToHex(rgb));
    ////console.log("R", r, "G", g, "B", b);
    var str = [];
    str.push({ r, b, g });
    for (var i = 0; i < variations - 1; i++) {
      if (i > middle - 1) {
        if (minusR - 50 > 0) {
          minusR -= 50;
        }
        if (minusG - 50 > 0) {
          minusG -= 50;
        }
        if (minusB - 50 > 0) {
          minusB -= 50;
        }
        str.push({ minusR, minusG, minusB });
      } else if (i == middle - 1) {
        str.push({ r, g, b });
      } else if (i < middle - 1) {
        if (plusR + 50 < 255) {
          plusR += 50;
        }
        if (plusG + 50 < 255) {
          plusG += 50;
        }
        if (plusB + 50 < 255) {
          plusB += 50;
        }
        str.push({ plusR, plusG, plusB });
      }

      // ////console.log("HEX", rgbToHex(r, b, g));
    }
    return str;
  }

  const prettyPrint = (name) => {
    let newName = name;
    newName = newName.replaceAll("_", " ");

    let splitStr = newName.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };
  const [popupValues, setPopupValues] = useState();

  const [filterValue, setFilterValue] = useState("");

  const [paletteLayer, setPaletteLayer] = useState("");

  const [filterField, setFilterField] = useState("");

  const [maxFilterValue, setMaxFilterValue] = useState("");
  const [minFilterValue, setMinFilterValue] = useState("");

  const [filterChecked, setFilterChecked] = useState(true);

  const [coloringSwitch, setColoringSwitch] = useState(true);

  const [panelState, setPanelState] = React.useState("");
  const [expandedPanel, setExpandedPanel] = useState(false);

  const [clustering, setClustering] = useState(true);

  const colorChange = (e, layer, type) => {
    ////console.log("colors", e.target.value, layer, type);

    let colors = layerColours.filter((x) => x.layer !== layer);
    let color = layerColours.filter((x) => x.layer === layer)[0];

    if (type === "fill") {
      color.color = e.target.value;
      colors.push(color);
    } else if (type === "outline") {
      color.outline = e.target.value;
      colors.push(color);
    } else if (type === "outlineWidth") {
      color.outlineWidth = parseInt(e.target.value);
      colors.push(color);
    } else if (type === "fillOpacity") {
      color.fillOpacity = parseInt(e.target.value) / 10;
      colors.push(color);
    } else if (type === "outlineOpacity") {
      color.outlineOpacity = parseInt(e.target.value) / 10;
      colors.push(color);
    } else if (type === "pointSize") {
      color.pointSize = parseInt(e.target.value);
      colors.push(color);
    } else {
      return;
    }

    setLayerColours(colors);
  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
    setFilterValue("");
    setMaxFilterValue("");
    setMinFilterValue("");
  };

  const handleChangePanel = (newPanelState) => {
    if (newPanelState === panelState) {
      setPanelState("");
    } else {
      setPanelState(newPanelState);
      setFilterValue("");
      setMaxFilterValue("");
      setMinFilterValue("");
      setPaletteLayer("");
      setFilterField("");
    }
  };

  function uniqueArray1(ar) {
    var j = {};

    ar.forEach(function (v) {
      j[v + "::" + typeof v] = v;
    });

    return Object.keys(j).map(function (v) {
      return j[v];
    });
  }
  const handleFiltering = (layer, field, value, type, min, max) => {
    let newMap = allMapsState;
    let chipText = "";
    // //console.log(
    //     "filtering",
    //     layer,
    //     field,
    //     value,
    //     type,
    //     min !== "" ? min : "none",
    //     max !== "" ? max : "none"
    // );

    if (min !== "" && max !== "" && type !== "string") {
      ////console.log("max min");
      newMap = allMapsState.map((x) => {
        return {
          ...x,
          features:
            x.name === layer
              ? x.features.filter(
                  (x) =>
                    parseInt(x.properties[field]) > parseInt(min) &&
                    parseInt(x.properties[field]) < parseInt(max)
                )
              : x.features,
        };
      });
      ////console.log(newMap);

      chipText = min.toString() + " < " + field + " < " + max.toString();
    } else if (min !== "" && max === "" && type !== "string") {
      ////console.log(" min");

      newMap = allMapsState.map((x) => {
        return {
          ...x,
          features:
            x.name === layer
              ? x.features.filter(
                  (x) => parseInt(x.properties[field]) > parseInt(min)
                )
              : x.features,
        };
      });

      chipText = field + " > " + min.toString();
    } else if (min === "" && max !== "" && type !== "string") {
      ////console.log("max ");

      newMap = allMapsState.map((x) => {
        return {
          ...x,
          features:
            x.name === layer
              ? x.features.filter(
                  (x) => parseInt(x.properties[field]) < parseInt(max)
                )
              : x.features,
        };
      });
      ////console.log(newMap);

      chipText = field + " < " + max.toString();
    } else {
      let searches = allMapsState.flatMap((x) =>
        x.features.map((x) => x.properties[field])
      );
      searches.push(value.toString());

      newMap =
        type === "string"
          ? allMapsState.flatMap((x) => x.features).length ==
            allMaps.flatMap((x) => x.features).length
            ? allMapsState.map((x) => {
                return {
                  ...x,
                  features:
                    x.name === layer
                      ? x.features.filter(
                          (x) => x.properties[field] === value.toString()
                        )
                      : x.features,
                };
              })
            : allMaps.map((x) => {
                return {
                  ...x,
                  features:
                    x.name === layer
                      ? x.features.filter((x) =>
                          searches.includes(x.properties[field].toString())
                        )
                      : x.features,
                };
              })
          : allMapsState.map((x) => {
              return {
                ...x,
                features:
                  x.name === layer
                    ? x.features.filter(
                        (x) => x.properties[field] === parseInt(value)
                      )
                    : x.features,
              };
            });

      chipText = field + " = " + value.toString();
    }
    ////console.log(newMap);

    let removedIds = allMapsState
      .flatMap((x) => x.features)
      .map((x) => x.properties[field])
      .filter(
        (x) =>
          !newMap
            .flatMap((x) => x.features)
            .map((x) => x.properties[field])
            .includes(x)
      );

    let newFilterChips = filterChips;
    newFilterChips.push({
      layer,
      removedIds,
      chipText,
      field,
      value,
      type,
      min,
      max,
    });

    setFilterChips(newFilterChips);

    ////console.log("removed", removedIds);

    setAllMapsState(newMap);
    setFilterValue("");
    setMaxFilterValue("");
    setMinFilterValue("");
    setPaletteLayer("");
    setFilterField("");

    setPanelState("");
  };
  const getSearchValues = (field, layer) => {
    var values = [];
    values = uniqueArray1(
      allMaps
        .filter((x) => x.name === layer)
        .flatMap((x) => x.features)
        .map((x) => x.properties[field].toString())
    );
    return values;
  };

  const mapStyle = {
    height: "100%",
  };
  const handleReset = () => {
    setFilterChips([]);
    setAllMapsState(allMaps);
  };

  const handleDelete = (
    layer,
    removedIds,
    chipText,
    field,
    value,
    type,
    min,
    max
  ) => {
    // //console.log(
    //     "You clicked the delete icon.",
    //     layer,
    //     removedIds,
    //     chipText,
    //     field,
    //     value,
    //     type,
    //     min,
    //     max
    // );
    if (filterChips.length < 2) {
      setFilterChips([]);
      setAllMapsState(allMaps);
    } else {
      let newFilterChips = filterChips;
      newFilterChips = newFilterChips.filter(
        (x) =>
          x.layer !== layer ||
          x.field !== field ||
          x.value !== value ||
          x.type !== type ||
          x.min !== min ||
          x.max !== max
      );
      setFilterChips(newFilterChips);

      // let getBack = allMaps.map((x) => {
      //     return {
      //         ...x,
      //         features: x.features.filter((x) =>
      //             removedIds.includes(x.properties.id)
      //         ),
      //     };
      // });

      // ////console.log(getBack);

      let currentMap = allMapsState;

      if (type === "string") {
        //current state without the affected layer
        let stateWithoutLayer = allMapsState.filter((x) => x.name !== layer);

        //values of the affected layer state
        let currentStateValues = allMapsState
          .filter((x) => x.name === layer)
          .flatMap((x) => x.features.map((x) => x.properties[field]));

        //remove the ones that should go with the filter deletion
        currentStateValues = currentStateValues.filter(
          (x) => x !== value.toString()
        );

        ////console.log("deleting string", currentStateValues);

        //get the original layer that corresponds to the affected one and take the needed values
        let originalLayer = allMaps
          .filter((x) => x.name === layer)
          .map((x) => {
            return {
              ...x,
              features: x.features.filter((x) =>
                currentStateValues.includes(x.properties[field].toString())
              ),
            };
          });

        currentMap = stateWithoutLayer.concat(originalLayer);
      } else if (type === "number") {
        ////console.log("deleting number");

        let searches = allMapsState
          .filter((x) => x.name == layer)
          .flatMap((x) =>
            x.features.map((x) => x.properties[field].toString())
          );

        searches = searches.concat(removedIds.map((x) => x.toString()));

        ////console.log("deleting number", searches);

        currentMap = allMaps.map((x) => {
          return {
            ...x,
            features:
              x.name === layer
                ? x.features.filter((x) =>
                    searches.includes(x.properties[field].toString())
                  )
                : x.features,
          };
        });
      }
      setAllMapsState(currentMap);
    }
  };
  const PopperMy = function (props) {
    return (
      <Popper
        {...props}
        style={{
          maxWidth: "300px",
        }}
        placement="bottom-start"
      />
    );
  };
  function getPopupData(e, geo) {
    ////console.log(e);

    // const properties = e.target.feature.properties;
    const properties = e;

    ////console.log(properties);

    if (properties) {
      setPopupValues(properties);
      setPanelState("popup");
    } else {
      setPopupValues();
      handleChangePanel("");
    }
  }

  const onEachPolygon = (geo, layer) => {
    layer.on("click", function (e) {
      getPopupData(e);
    });
  };

  const handleLayers = (event, name) => {
    var checked = event.target.checked;

    ////console.log(checked);

    let layers = [...selectedLayers];
    let newMaps = [...allMapsState];

    if (checked.toString() === "false") {
      ////console.log("filter", name);

      layers = layers.filter((x) => x !== name);
      newMaps = newMaps.filter((x) => x.name !== name);
    } else if (checked.toString() === "true") {
      ////console.log("push", name);

      layers.push(name);
      newMaps.push(allMaps.filter((x) => x.name === name).map((x) => x)[0]);
    }

    setSelectedLayers(layers);
    setAllMapsState(newMaps);

    ////console.log(newMaps);
    ////console.log(layers);
  };

  const handleSavePalette = () => {
    setPanelState("");
    setPaletteLayer("");
  };
  const getCheckBoxState = (name) => {
    var result = selectedLayers.some(function (item, index, arr) {
      return item === name;
    });

    return result;
  };
  useEffect(() => {
    if (maps.length > 0) {
      let layerColors = [];

      allMaps.map((x, index) =>
        layerColors.push({
          layer: x.name,
          color: x.name == "suitable_roof_area" ? "#FFFF00" : "#808080",
          outline: outlines[index - 4 * Math.trunc(index / 4)],
          outlineWidth: outlinesWidth[index - 4 * Math.trunc(index / 4)],
          fillOpacity: 0.7,
          outlineOpacity: 0.8,
          pointSize: 5,
        })
      );

      setLayerColours(layerColors);
    }
  }, [maps]);

  useEffect(() => {
    if (layerColours.length > 0) {
      localStorage.setItem("layerColours", JSON.stringify(layerColours));
    }
  }, [layerColours]);

  useEffect(() => {
    // if (geoJsonLayer.current) {
    //     geoJsonLayer.current.clearLayers().addData(allMapsState);
    // }

    myRefs.forEach((x, i) => {
      if (x.current) {
        x.current.clearLayers().addData(allMapsState[i]);
      }
    });
    setExpandedPanel(true);
    setExpandedPanel(false);
    if (
      filterChips.length < 1 &&
      allMapsState.flatMap((x) => x.features).length < 1
    ) {
      setAllMapsState(allMaps);
    }
  }, [allMapsState || filterChips.length]);

  const { BaseLayer, Overlay } = LayersControl;

  function setNewStyles(field, layer) {
    let fieldValue = field;

    let newColors = newLayerColours;

    if (!field && !layer && newColors.length < 1) {
      maps.map((x, index) => {
        newColors.push({
          layer: x.name,
          field: undefined,
          categories: [],
        });
      });
    } else {
      // if (!fieldValue) {
      //     fieldValue = "Shape_Area";
      // }
      if (layerColours) {
        maps.map((x, index) => {
          let features = x.features;
          ////console.log("features", features);
          let colorsArray = [];
          let rgbArray = generate(
            hexToRgb(
              layerColours
                .filter((y) => y.layer === x.name)
                .map((x) => x.color)[0]
            ),
            6
          );

          let max = Math.max(
            ...features
              // .map((x) => x.features)
              .flatMap((x) => parseFloat(x.properties[fieldValue]))
          );
          let min = Math.min(
            ...features
              // .flatMap((x) => x.features)
              .flatMap((x) => parseFloat(x.properties[fieldValue]))
          );

          [...Array(6)].map((y, index) => {
            if (index != 0) {
              let from = parseFloat(min + (index - 1) * ((max - min) / 5));
              let to = min + index * ((max - min) / 5);
              let color = rgbToHex(rgbArray[index]);

              ////console.log("from", from);

              colorsArray.push({ from, to, color });
            }
          });
          if (x.name === layer) {
            newColors = newColors.filter(function (item) {
              return item.layer !== x.name;
            });

            newColors.push({
              layer: x.name,
              field: fieldValue,
              categories: colorsArray,
            });
          }
        });
      }
      ////console.log("NewStyling", newColors);

      setNewLayerColours(newColors);
    }
  }
  useEffect(() => {
    if (maps.length > 0) {
      setNewStyles();
      ////console.log("testColor", newLayerColours);
    }
  }, [maps]);

  const [map, setMap] = useState(null);

  return (
    <div
      className="container"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {mapConstants && maps.length > 0 && (
        <MapContainer
          // whenCreated={setMap}
          // onMoveEnd={//console.log(map)}
          ref={setMap}
          style={mapStyle}
          center={mapConstants.mapCenter}
          zoom={mapConstants.mapZoom}
          scrollWheelZoom={true}
          preferCanvas={true}
          renderer={L.canvas()}
        >
          {layerColours.length < 1 &&
          newLayerColours.length < 1 &&
          paletteLayer == "" ? (
            <div style={{ margin: "0 auto" }}>Loading...</div>
          ) : (
            <LayersControl style={{ zIndex: "3" }} position="bottomright">
              <BaseLayer checked name="OpenStreetMap">
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                 <Marker position={[props.lat, props.lng]} />
              </BaseLayer>
              <BaseLayer name="Satelite">
                <TileLayer
                  attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />
                 <Marker position={[props.lat, props.lng]} />
              </BaseLayer>

              {allMapsState.map((x, index) => {
                if (x.features.length > 0) {
                  return (
                    <LayersControl.Overlay key={index} checked name={x.name}>
                      <FeatureGroup>
                        {clustering ? (
                          <MarkerClusterGroup chunkedLoading>
                            {x.features.map((feature, index2) => {
                              if (feature.geometry.type === "Point") {
                                return (
                                  <CircleMarker
                                    key={index2}
                                    center={
                                      new latLng(
                                        feature.geometry.coordinates[1],
                                        feature.geometry.coordinates[0]
                                      )
                                    }
                                    pathOptions={{
                                      weight: 0.5,
                                      fillColor: coloringSwitch
                                        ? layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.color)[0]
                                        : paletteLayer === x.name
                                        ? newLayerColours
                                            .filter((y) => y.layer === x.name)
                                            .map(
                                              (y) =>
                                                y.categories
                                                  .filter(
                                                    (x) =>
                                                      x.from <
                                                        feature.properties[
                                                          y.field
                                                        ] &&
                                                      x.to >
                                                        feature.properties[
                                                          y.field
                                                        ]
                                                  )
                                                  .map((y) => y.color)[0]
                                            )
                                        : layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.color)[0],
                                      color: "black",
                                      fillOpacity: 1,
                                    }}
                                    radius={
                                      layerColours
                                        .filter((y) => y.layer === x.name)
                                        .map((x) => x.pointSize)[0]
                                    }
                                    eventHandlers={{
                                      click: (e) => {
                                        props.handleToidSelect(feature.properties["reference"])
                                      },
                                    }}
                                  />
                                );
                              } else if (
                                feature.geometry.type === "MultiPolygon"
                              ) {
                                return feature.geometry.coordinates.flatMap(
                                  (polygon, indexPolygon) => {
                                    const coordinates = polygon[0].map(
                                      (item) => [item[1], item[0]]
                                    );
                                    //console.log("bob " + coordinates);

                                    return (
                                      <Polygon
                                        key={index2 + indexPolygon}
                                        pathOptions={{
                                          fillColor: coloringSwitch
                                            ? layerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map((x) => x.color)[0]
                                            : paletteLayer === x.name
                                            ? newLayerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map(
                                                  (y) =>
                                                    y.categories
                                                      .filter(
                                                        (x) =>
                                                          x.from <
                                                            feature.properties[
                                                              y.field
                                                            ] &&
                                                          x.to >
                                                            feature.properties[
                                                              y.field
                                                            ]
                                                      )
                                                      .map((y) => y.color)[0]
                                                )
                                            : layerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map((x) => x.color)[0],
                                          fillOpacity: layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.fillOpacity)[0],
                                          weight: layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.outlineWidth)[0],

                                          opacity: layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.outlineOpacity)[0],
                                          dashArray: 0,
                                          color: layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.outline)[0],
                                        }}
                                        positions={coordinates}
                                        eventHandlers={{
                                          mouseover: (e) => {
                                            const layer = e.target;
                                            layer.setStyle({
                                              dashArray: "",
                                              fillColor: "#00bd1c",
                                              fillOpacity: 0.7,
                                              weight: 2,
                                              opacity: 1,
                                              color: "#028015",
                                            });
                                          },
                                          mouseout: (e) => {
                                            const layer = e.target;
                                            layer.setStyle({
                                              fillOpacity: layerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map((x) => x.fillOpacity)[0],
                                              weight: layerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map((x) => x.outlineWidth)[0], // dashArray: "3",
                                              color: layerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map((x) => x.outline)[0],
                                              fillColor: coloringSwitch
                                                ? layerColours
                                                    .filter(
                                                      (y) => y.layer === x.name
                                                    )
                                                    .map((x) => x.color)[0]
                                                : paletteLayer === x.name
                                                ? newLayerColours
                                                    .filter(
                                                      (y) => y.layer === x.name
                                                    )
                                                    .map(
                                                      (y) =>
                                                        y.categories
                                                          .filter(
                                                            (x) =>
                                                              x.from <
                                                                feature
                                                                  .properties[
                                                                  y.field
                                                                ] &&
                                                              x.to >
                                                                feature
                                                                  .properties[
                                                                  y.field
                                                                ]
                                                          )
                                                          .map(
                                                            (y) => y.color
                                                          )[0]
                                                    )
                                                : layerColours
                                                    .filter(
                                                      (y) => y.layer === x.name
                                                    )
                                                    .map((x) => x.color)[0],
                                              opacity: layerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map(
                                                  (x) => x.outlineOpacity
                                                )[0],
                                            });
                                          },
                                          click: (e) => {
                                            props.handleToidSelect(feature.properties["reference"])
                                          },
                                        }}
                                      />
                                    );
                                  }
                                );
                              } else {
                                const coordinates =
                                  feature.geometry.coordinates[0].map(
                                    (item) => [item[1], item[0]]
                                  );
                                //console.log("polygon " + coordinates);

                                return (
                                  <Polygon
                                    key={index2}
                                    pathOptions={{
                                      fillColor: coloringSwitch
                                        ? layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.color)[0]
                                        : paletteLayer === x.name
                                        ? newLayerColours
                                            .filter((y) => y.layer === x.name)
                                            .map(
                                              (y) =>
                                                y.categories
                                                  .filter(
                                                    (x) =>
                                                      x.from <
                                                        feature.properties[
                                                          y.field
                                                        ] &&
                                                      x.to >
                                                        feature.properties[
                                                          y.field
                                                        ]
                                                  )
                                                  .map((y) => y.color)[0]
                                            )
                                        : layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.color)[0],
                                      fillOpacity: layerColours
                                        .filter((y) => y.layer === x.name)
                                        .map((x) => x.fillOpacity)[0],
                                      weight: layerColours
                                        .filter((y) => y.layer === x.name)
                                        .map((x) => x.outlineWidth)[0],

                                      opacity: layerColours
                                        .filter((y) => y.layer === x.name)
                                        .map((x) => x.outlineOpacity)[0],
                                      dashArray: 0,
                                      color: layerColours
                                        .filter((y) => y.layer === x.name)
                                        .map((x) => x.outline)[0],
                                    }}
                                    positions={coordinates}
                                    eventHandlers={{
                                      mouseover: (e) => {
                                        const layer = e.target;
                                        layer.setStyle({
                                          dashArray: "",
                                          fillColor: "#00bd1c",
                                          fillOpacity: 0.7,
                                          weight: 2,
                                          opacity: 1,
                                          color: "#028015",
                                        });
                                      },
                                      mouseout: (e) => {
                                        const layer = e.target;
                                        layer.setStyle({
                                          fillOpacity: layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.fillOpacity)[0],
                                          weight: layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.outlineWidth)[0], // dashArray: "3",
                                          color: layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.outline)[0],
                                          fillColor: coloringSwitch
                                            ? layerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map((x) => x.color)[0]
                                            : paletteLayer === x.name
                                            ? newLayerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map(
                                                  (y) =>
                                                    y.categories
                                                      .filter(
                                                        (x) =>
                                                          x.from <
                                                            feature.properties[
                                                              y.field
                                                            ] &&
                                                          x.to >
                                                            feature.properties[
                                                              y.field
                                                            ]
                                                      )
                                                      .map((y) => y.color)[0]
                                                )
                                            : layerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map((x) => x.color)[0],
                                          opacity: layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.outlineOpacity)[0],
                                        });
                                      },
                                      click: (e) => {
                                        props.handleToidSelect(feature.properties["reference"])
                                      },
                                    }}
                                  />
                                );
                              }
                            })}
                          </MarkerClusterGroup>
                        ) : (
                          <>
                            {x.features.map((feature, index2) => {
                              if (feature.geometry.type === "Point") {
                                return (
                                  <CircleMarker
                                    key={index2}
                                    center={
                                      new latLng(
                                        feature.geometry.coordinates[1],
                                        feature.geometry.coordinates[0]
                                      )
                                    }
                                    pathOptions={{
                                      weight: 0.5,
                                      fillColor: coloringSwitch
                                        ? layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.color)[0]
                                        : paletteLayer === x.name
                                        ? newLayerColours
                                            .filter((y) => y.layer === x.name)
                                            .map(
                                              (y) =>
                                                y.categories
                                                  .filter(
                                                    (x) =>
                                                      x.from <
                                                        feature.properties[
                                                          y.field
                                                        ] &&
                                                      x.to >
                                                        feature.properties[
                                                          y.field
                                                        ]
                                                  )
                                                  .map((y) => y.color)[0]
                                            )
                                        : layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.color)[0],
                                      color: "black",
                                      fillOpacity: 1,
                                    }}
                                    radius={
                                      layerColours
                                        .filter((y) => y.layer === x.name)
                                        .map((x) => x.pointSize)[0]
                                    }
                                    eventHandlers={{
                                      click: (e) => {
                                        props.handleToidSelect(feature.properties["reference"])
                                      },
                                    }}
                                  />
                                );
                              } else if (
                                feature.geometry.type === "MultiPolygon"
                              ) {
                                ////console.log("Multi ^#")
                                feature.geometry.coordinates.map((polygon) => {
                                  const coordinates = polygon[0].map((item) => [
                                    item[1],
                                    item[0],
                                  ]);
                                  ////console.log("bob " + polygon)

                                  return (
                                    <Polygon
                                      key={index2}
                                      pathOptions={{
                                        fillColor: coloringSwitch
                                          ? layerColours
                                              .filter((y) => y.layer === x.name)
                                              .map((x) => x.color)[0]
                                          : paletteLayer === x.name
                                          ? newLayerColours
                                              .filter((y) => y.layer === x.name)
                                              .map(
                                                (y) =>
                                                  y.categories
                                                    .filter(
                                                      (x) =>
                                                        x.from <
                                                          feature.properties[
                                                            y.field
                                                          ] &&
                                                        x.to >
                                                          feature.properties[
                                                            y.field
                                                          ]
                                                    )
                                                    .map((y) => y.color)[0]
                                              )
                                          : layerColours
                                              .filter((y) => y.layer === x.name)
                                              .map((x) => x.color)[0],
                                        fillOpacity: layerColours
                                          .filter((y) => y.layer === x.name)
                                          .map((x) => x.fillOpacity)[0],
                                        weight: layerColours
                                          .filter((y) => y.layer === x.name)
                                          .map((x) => x.outlineWidth)[0],

                                        opacity: layerColours
                                          .filter((y) => y.layer === x.name)
                                          .map((x) => x.outlineOpacity)[0],
                                        dashArray: 0,
                                        color: layerColours
                                          .filter((y) => y.layer === x.name)
                                          .map((x) => x.outline)[0],
                                      }}
                                      positions={coordinates}
                                      eventHandlers={{
                                        mouseover: (e) => {
                                          const layer = e.target;
                                          layer.setStyle({
                                            dashArray: "",
                                            fillColor: "#00bd1c",
                                            fillOpacity: 0.7,
                                            weight: 2,
                                            opacity: 1,
                                            color: "#028015",
                                          });
                                        },
                                        mouseout: (e) => {
                                          const layer = e.target;
                                          layer.setStyle({
                                            fillOpacity: layerColours
                                              .filter((y) => y.layer === x.name)
                                              .map((x) => x.fillOpacity)[0],
                                            weight: layerColours
                                              .filter((y) => y.layer === x.name)
                                              .map((x) => x.outlineWidth)[0], // dashArray: "3",
                                            color: layerColours
                                              .filter((y) => y.layer === x.name)
                                              .map((x) => x.outline)[0],
                                            fillColor: coloringSwitch
                                              ? layerColours
                                                  .filter(
                                                    (y) => y.layer === x.name
                                                  )
                                                  .map((x) => x.color)[0]
                                              : paletteLayer === x.name
                                              ? newLayerColours
                                                  .filter(
                                                    (y) => y.layer === x.name
                                                  )
                                                  .map(
                                                    (y) =>
                                                      y.categories
                                                        .filter(
                                                          (x) =>
                                                            x.from <
                                                              feature
                                                                .properties[
                                                                y.field
                                                              ] &&
                                                            x.to >
                                                              feature
                                                                .properties[
                                                                y.field
                                                              ]
                                                        )
                                                        .map((y) => y.color)[0]
                                                  )
                                              : layerColours
                                                  .filter(
                                                    (y) => y.layer === x.name
                                                  )
                                                  .map((x) => x.color)[0],
                                            opacity: layerColours
                                              .filter((y) => y.layer === x.name)
                                              .map((x) => x.outlineOpacity)[0],
                                          });
                                        },
                                        click: (e) => {
                                            props.handleToidSelect(feature.properties["reference"])
                                        },
                                      }}
                                    />
                                  );
                                });
                              } else {
                                const coordinates =
                                  feature.geometry.coordinates[0].map(
                                    (item) => [item[1], item[0]]
                                  );
                                return (
                                  <Polygon
                                    key={index2}
                                    pathOptions={{
                                      fillColor: coloringSwitch
                                        ? layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.color)[0]
                                        : paletteLayer === x.name
                                        ? newLayerColours
                                            .filter((y) => y.layer === x.name)
                                            .map(
                                              (y) =>
                                                y.categories
                                                  .filter(
                                                    (x) =>
                                                      x.from <
                                                        feature.properties[
                                                          y.field
                                                        ] &&
                                                      x.to >
                                                        feature.properties[
                                                          y.field
                                                        ]
                                                  )
                                                  .map((y) => y.color)[0]
                                            )
                                        : layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.color)[0],
                                      fillOpacity: layerColours
                                        .filter((y) => y.layer === x.name)
                                        .map((x) => x.fillOpacity)[0],
                                      weight: layerColours
                                        .filter((y) => y.layer === x.name)
                                        .map((x) => x.outlineWidth)[0],

                                      opacity: layerColours
                                        .filter((y) => y.layer === x.name)
                                        .map((x) => x.outlineOpacity)[0],
                                      dashArray: 0,
                                      color: layerColours
                                        .filter((y) => y.layer === x.name)
                                        .map((x) => x.outline)[0],
                                    }}
                                    positions={coordinates}
                                    eventHandlers={{
                                      mouseover: (e) => {
                                        const layer = e.target;
                                        layer.setStyle({
                                          dashArray: "",
                                          fillColor: "#00bd1c",
                                          fillOpacity: 0.7,
                                          weight: 2,
                                          opacity: 1,
                                          color: "#028015",
                                        });
                                      },
                                      mouseout: (e) => {
                                        const layer = e.target;
                                        layer.setStyle({
                                          fillOpacity: layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.fillOpacity)[0],
                                          weight: layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.outlineWidth)[0], // dashArray: "3",
                                          color: layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.outline)[0],
                                          fillColor: coloringSwitch
                                            ? layerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map((x) => x.color)[0]
                                            : paletteLayer === x.name
                                            ? newLayerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map(
                                                  (y) =>
                                                    y.categories
                                                      .filter(
                                                        (x) =>
                                                          x.from <
                                                            feature.properties[
                                                              y.field
                                                            ] &&
                                                          x.to >
                                                            feature.properties[
                                                              y.field
                                                            ]
                                                      )
                                                      .map((y) => y.color)[0]
                                                )
                                            : layerColours
                                                .filter(
                                                  (y) => y.layer === x.name
                                                )
                                                .map((x) => x.color)[0],
                                          opacity: layerColours
                                            .filter((y) => y.layer === x.name)
                                            .map((x) => x.outlineOpacity)[0],
                                        });
                                      },
                                      click: (e) => {
                                        props.handleToidSelect(feature.properties["reference"])
                                      },
                                    }}
                                  />
                                );
                              }
                            })}
                          </>
                        )}
                      </FeatureGroup>
                    </LayersControl.Overlay>
                  );
                } else return null;
              })}
            </LayersControl>
          )}
        </MapContainer>
      )}
      {allMapsState.flatMap((x) => x.features).length < 1 && (
        <Paper
          elevation={3}
          style={{
            margin: "5px",
            position: "absolute",
            // width: "70%",
            height: 15,
            bottom: 0,
            left: "40%",
            // zIndex: 10000,
            padding: "10px",
            opacity: "0.95",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <strong>Map loading</strong>
        </Paper>
      )}

      <div
        style={{
          // margin: "5px",
          marginLeft: "50px",
          position: "absolute",
          maxWidth: "70%",
          maxHeight: 200,
          top: 0,
          left: 0,
          // zIndex: 10000,
          padding: "15px",
        }}
      >
        <div
          style={{
            // maxWidth: "70%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {filterChips.length > 0 && (
            <Button
              style={{
                marginRight: "10px",
              }}
              size="small"
              variant="contained"
              onClick={() => handleReset()}
            >
              Reset
            </Button>
          )}
          {filterChips.map((x, index) => {
            return (
              <Chip
                key={index}
                size="small"
                label={prettyPrint(x.chipText)}
                // color="success"
                style={{
                  background: layerColours
                    .filter((y) => y.layer === x.layer)
                    .map((x) => x.color)[0],
                  color: "white",
                }}
                onDelete={() =>
                  handleDelete(
                    x.layer,
                    x.removedIds,
                    x.chipText,
                    x.field,
                    x.value,
                    x.type,
                    x.min,
                    x.max
                  )
                }
              />
            );
          })}
        </div>
      </div>
      <div
        style={{
          marginTop: "7px",
          marginRight: "225px",
          position: "absolute",
          top: 0,
          right: 0,
          // zIndex: 10000,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              onChange={(e) => setClustering(e.target.checked)}
            />
          }
          label={
            <strong
              style={{
                color: "white",
                textShadow: " 2px 2px 2px #000000",
              }}
            >
              Cluster Points
            </strong>
          }
        />
      </div>
      <div
        style={{
          margin: "7px",
          position: "absolute",
          width: 225,
          maxHeight: 500,
          top: 0,
          right: 0,
          // zIndex: 10000,
          overflowY: "auto",
          overflowX: "hidden",
          opacity: "0.95",
        }}
      >
        <Button
          size="small"
          style={{
            margin: "5px",
          }}
          variant="contained"
          onClick={() => handleChangePanel("palette")}
        >
          <PaletteIcon />
        </Button>
        <Button
          size="small"
          style={{
            margin: "5px",
          }}
          variant="contained"
          onClick={() => handleChangePanel("filter")}
        >
          <FilterAltIcon />
        </Button>
        <Button
          style={{
            margin: "5px",
          }}
          size="small"
          variant="contained"
          onClick={() => handleChangePanel("search")}
        >
          <SearchOutlinedIcon />
        </Button>
        {/* <Button
                    size="small"
                    style={{
                        margin: "5px",
                    }}
                    variant="contained"
                    onClick={() => handleChangePanel("layers")}
                >
                    <LayersIcon />
                </Button> */}
      </div>

      {panelState === "filter" && (
        <ResizableBox
          width={250}
          height={300}
          minConstraints={[100, 150]}
          maxConstraints={[500, 600]}
          resizeHandles={["sw"]}
          style={{
            margin: "60px 15px 15px 15px",
            position: "absolute",
            top: 0,
            right: 0,
            // zIndex: 10000,
            overflowY: "auto",
            overflowX: "hidden",
            padding: "15px",
            background: "white",
            borderRadius: "5px",
            boxShadow: "0px 5px 7px 0px rgba(0, 0, 0, 0.5)",
          }}
        >
          <ButtonGroup fullWidth aria-label="split button">
            <Autocomplete
              fullWidth
              PopperComponent={PopperMy}
              disablePortal
              id="combo-box-demo"
              // value={filterValue === "" && ""}
              onChange={(event, values) => {
                setPaletteLayer(event.target.innerText);
              }}
              options={allMaps.map((x) => x.name)}
              renderInput={(params) => (
                <TextField
                  type="text"
                  {...params}
                  label={"Select layer to filter"}
                />
              )}
            />
            <div style={{ marginLeft: "7px", marginTop: "7px" }}>
              {paletteLayer !== "" && paletteLayer && (
                <TerrainOutlinedIcon
                  fontSize="large"
                  style={{
                    color: layerColours.filter(
                      (x) => x.layer === paletteLayer
                    )[0].color,
                  }}
                />
              )}
            </div>
          </ButtonGroup>
          {allMaps.map((x) => x.name).includes(paletteLayer) && (
            <>
              <Autocomplete
                fullWidth
                style={{ marginTop: "15px" }}
                PopperComponent={PopperMy}
                disablePortal
                id="combo-box-demo"
                // value={filterValue === "" && ""}
                onChange={(event, values) => {
                  setFilterField(event.target.innerText);
                }}
                options={Object.entries(
                  allMaps
                    .filter((x) => x.name === paletteLayer)
                    .map((x) => x.features[0].properties)[0]
                )
                  .filter((x) => typeof x[1] === "number")
                  .filter((x) => !toExcludeFilters.includes(x[0]))
                  .map((x) => x[0])}
                renderInput={(params) => (
                  <TextField
                    type="text"
                    {...params}
                    label={"Select filter field"}
                  />
                )}
              />
            </>
          )}
          {filterField !== "" &&
            filterField &&
            allMaps.map((x) => x.name).includes(paletteLayer) && (
              <div>
                <div
                  style={{
                    margin: "15px auto ",
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  {" "}
                  <strong>{prettyPrint(filterField)}</strong>
                </div>
                <div>
                  <div
                    style={{
                      margin: "15px auto",
                      width: "60%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <div>
                      <strong>{"><"}</strong>
                      <Switch
                        // {...label}
                        checked={filterChecked}
                        onChange={() => {
                          setFilterChecked(!filterChecked);
                        }}
                      />
                      <strong>{"="}</strong>
                    </div>
                  </div>
                  {filterChecked ? (
                    <ButtonGroup
                      fullWidth
                      variant="outlined"
                      aria-label="split button"
                    >
                      <TextField
                        fullWidth
                        value={filterValue}
                        type="number"
                        onChange={(e) => {
                          setFilterValue(e.target.value);
                        }}
                        label={prettyPrint(filterField)}
                      />
                      <Button
                        style={{
                          marginLeft: "5px",
                        }}
                        disabled={filterValue === ""}
                        onClick={() =>
                          handleFiltering(
                            paletteLayer,
                            filterField,
                            filterValue,
                            "number",
                            "",
                            ""
                          )
                        }
                        sx={{
                          width: 60,
                        }}
                      >
                        <SearchOutlinedIcon />
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <ButtonGroup
                      fullWidth
                      variant="outlined"
                      aria-label="split button"
                    >
                      <TextField
                        fullWidth
                        value={minFilterValue}
                        type="number"
                        onChange={(e) => {
                          setMinFilterValue(e.target.value);
                        }}
                        label="Min"
                      />
                      <TextField
                        fullWidth
                        value={maxFilterValue}
                        type="number"
                        onChange={(e) => {
                          setMaxFilterValue(e.target.value);
                        }}
                        label="Max"
                      />

                      <Button
                        style={{
                          marginLeft: "5px",
                        }}
                        disabled={
                          minFilterValue === "" && maxFilterValue === ""
                        }
                        onClick={() =>
                          handleFiltering(
                            paletteLayer,
                            filterField,
                            "",
                            "number",
                            minFilterValue !== "" ? minFilterValue : "",
                            maxFilterValue !== "" ? maxFilterValue : ""
                          )
                        }
                        sx={{
                          width: 90,
                        }}
                      >
                        <SearchOutlinedIcon />
                      </Button>
                    </ButtonGroup>
                  )}
                </div>
              </div>
            )}
        </ResizableBox>
      )}
      {panelState === "search" && (
        <ResizableBox
          width={250}
          height={300}
          minConstraints={[100, 150]}
          maxConstraints={[500, 600]}
          resizeHandles={["sw"]}
          style={{
            margin: "60px 15px 15px 15px",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 10000,
            overflowY: "auto",
            overflowX: "hidden",
            padding: "15px",
            background: "white",
            borderRadius: "5px",
            boxShadow: "0px 5px 7px 0px rgba(0, 0, 0, 0.5)",
          }}
        >
          <ButtonGroup fullWidth aria-label="split button">
            <Autocomplete
              fullWidth
              PopperComponent={PopperMy}
              disablePortal
              id="combo-box-demo"
              // value={filterValue === "" && ""}
              onChange={(event, values) => {
                setPaletteLayer(event.target.innerText);
              }}
              options={allMaps.map((x) => x.name)}
              renderInput={(params) => (
                <TextField
                  type="text"
                  {...params}
                  label={"Select layer to search"}
                />
              )}
            />
            <div style={{ marginLeft: "7px", marginTop: "7px" }}>
              {paletteLayer !== "" && paletteLayer && (
                <TerrainOutlinedIcon
                  fontSize="large"
                  style={{
                    color: layerColours.filter(
                      (x) => x.layer === paletteLayer
                    )[0].color,
                  }}
                />
              )}
            </div>
          </ButtonGroup>
          {allMaps.map((x) => x.name).includes(paletteLayer) && (
            <Autocomplete
              fullWidth
              style={{ marginTop: "15px" }}
              PopperComponent={PopperMy}
              disablePortal
              id="combo-box-demo"
              // value={filterValue === "" && ""}
              onChange={(event, values) => {
                setFilterField(event.target.innerText);
              }}
              options={Object.entries(
                allMaps
                  .filter((x) => x.name === paletteLayer)
                  .map((x) => x.features[0].properties)[0]
              )
                .filter((x) => typeof x[1] === "string")
                .filter((x) => !toExcludeFilters.includes(x[0]))
                .map((x) => x[0])}
              renderInput={(params) => (
                <TextField
                  type="text"
                  {...params}
                  label={"Select search field"}
                />
              )}
            />
          )}
          {filterField !== "" &&
            filterField &&
            allMaps.map((x) => x.name).includes(paletteLayer) && (
              <div>
                <div
                  style={{
                    margin: "15px auto ",
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  {" "}
                  <strong>{prettyPrint(filterField)}</strong>
                </div>
                <div>
                  <ButtonGroup
                    fullWidth
                    variant="outlined"
                    aria-label="split button"
                  >
                    <Autocomplete
                      fullWidth
                      PopperComponent={PopperMy}
                      disablePortal
                      id="combo-box-demo"
                      // value={filterValue === "" && ""}
                      onChange={(event, values) => {
                        setFilterValue(event.target.innerText);
                      }}
                      options={getSearchValues(filterField, paletteLayer)}
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          {...params}
                          label={prettyPrint(filterField)}
                        />
                      )}
                    />
                    <Button
                      style={{
                        marginLeft: "5px",
                      }}
                      variant="contained"
                      disabled={filterValue === ""}
                      onClick={() =>
                        handleFiltering(
                          paletteLayer,
                          filterField,
                          filterValue,
                          "string",
                          "",
                          ""
                        )
                      }
                      sx={{ width: 60 }}
                    >
                      <SearchOutlinedIcon />
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            )}
        </ResizableBox>
      )}
      {panelState === "popup" && popupValues && (
        <ResizableBox
          width={300}
          minConstraints={[100, 150]}
          maxConstraints={[500, 600]}
          resizeHandles={["e"]}
          style={{
            maxHeight: "80%",
            margin: "60px 15px 15px 15px",
            position: "absolute",
            bottom: 0,
            left: 0,
            zIndex: 10000,
            overflowY: "auto",
            overflowX: "hidden",
            padding: "15px",
            background: "white",
            borderRadius: "5px",
            boxShadow: "0px 5px 7px 0px rgba(0, 0, 0, 0.5)",
          }}
        >
          <IconButton
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: "3",
            }}
            onClick={() => setPanelState("")}
          >
            <CloseIcon />
          </IconButton>{" "}
          {/* <List
                        sx={{
                            width: "100%",
                            bgcolor: "background.paper",
                        }}
                        style={{
                            marginTop: "13px",
                        }}
                    > */}{" "}
          <div
            style={{
              marginTop: "20px",
            }}
          >
            {Object.entries(popupValues).map((x) => {
              return (
                <>
                  <Divider />
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        marginTop: "15px",
                        marginBottom: "15px",
                        minWidth: "150px",
                      }}
                    >
                      <strong
                        style={{
                          // maxWidth: "50%",
                          wordBreak: "break-word",
                        }}
                      >
                        {" "}
                        {prettyPrint(x[0])}
                      </strong>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "5px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <p
                        style={{
                          // maxWidth: "50%",
                          wordBreak: "break-word",
                        }}
                      >
                        {" "}
                        {x[1]}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          {/* </List> */}
        </ResizableBox>
      )}
      {panelState === "layers" && (
        <Paper
          elevation={3}
          style={{
            margin: "60px 15px 15px 15px",
            position: "absolute",
            width: 250,
            maxHeight: 500,
            top: 0,
            right: 0,
            zIndex: 10000,
            overflowY: "auto",
            overflowX: "hidden",
            padding: "15px",
            opacity: "0.95",
          }}
        >
          <strong>Layers</strong> - in development
          <FormGroup>
            {allMapsState.map((x) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={() => selectedLayers.includes(x.name)}
                      onChange={(e) => handleLayers(e, x.name)}
                      color="success"
                    />
                  }
                  label={x.name}
                />
              );
            })}{" "}
          </FormGroup>
        </Paper>
      )}
      {panelState === "palette" && (
        <ResizableBox
          width={300}
          height={450}
          minConstraints={[100, 250]}
          maxConstraints={[500, 600]}
          resizeHandles={["sw"]}
          style={{
            margin: "60px 15px 15px 15px",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 10000,
            overflowY: "auto",
            overflowX: "hidden",
            padding: "15px",
            background: "white",
            borderRadius: "5px",
            boxShadow: "0px 5px 7px 0px rgba(0, 0, 0, 0.5)",
          }}
        >
          <Autocomplete
            fullWidth
            PopperComponent={PopperMy}
            disablePortal
            id="combo-box-demo"
            // value={filterValue === "" && ""}
            onChange={(event, values) => {
              setPaletteLayer(event.target.innerText);
            }}
            options={layerColours.map((x) => x.layer)}
            renderInput={(params) => (
              <TextField
                type="text"
                {...params}
                label={"Select layer to style"}
              />
            )}
          />{" "}
          {layerColours
            .filter((x) => x.layer === paletteLayer)
            .map((x) => {
              let havePoint = false;
              if (
                maps
                  .filter((y) => y.name === paletteLayer)
                  .flatMap((x) => x.features.map((x) => x.geometry.type))
                  .includes("Point")
              ) {
                havePoint = true;
              }
              let rgbArray = generate(hexToRgb(x.color), 6);
              // let fieldValue = newLayerColours.filter(
              //     (x) => x.layer === paletteLayer
              // )[0].field;
              // ////console.log("filterValue", fieldValue);
              let bla = newLayerColours.filter((x) => x.layer === paletteLayer);
              ////console.log("filterValue", bla);

              return (
                <>
                  <div
                    style={{
                      margin: "15px auto",
                      width: "60%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <div>
                      <strong>{"Layer"}</strong>
                      <Switch
                        // {...label}
                        checked={!coloringSwitch}
                        onChange={() => {
                          setColoringSwitch(!coloringSwitch);
                        }}
                      />
                      <strong>{"Field"}</strong>
                    </div>
                  </div>
                  {!coloringSwitch ? (
                    <div>
                      <div>
                        <Autocomplete
                          value={
                            newLayerColours.filter(
                              (x) => x.layer === paletteLayer
                            )[0].field
                          }
                          fullWidth
                          style={{
                            marginTop: "15px",
                          }}
                          PopperComponent={PopperMy}
                          disablePortal
                          id="combo-box-demo"
                          // value={filterValue === "" && ""}
                          onChange={(event, values) => {
                            setNewStyles(event.target.innerText, paletteLayer);
                          }}
                          options={Object.entries(
                            allMaps
                              .filter((x) => x.name === paletteLayer)
                              .map((x) => x.features[0].properties)[0]
                          )
                            .filter((x) => typeof x[1] === "number")
                            .filter((x) => !toExcludeFilters.includes(x[0]))
                            .map((x) => x[0])}
                          renderInput={(params) => (
                            <TextField
                              type="text"
                              {...params}
                              label={"Select filter field"}
                            />
                          )}
                        />
                      </div>
                      {newLayerColours.filter(
                        (x) => x.layer === paletteLayer
                      )[0].field &&
                        newLayerColours
                          .filter((x) => x.layer === paletteLayer)[0]
                          .categories.map((y, index) => {
                            return (
                              <div
                                style={{
                                  marginTop: "5px",
                                }}
                              >
                                <span>
                                  <input
                                    style={{
                                      marginLeft: "5px",
                                      maxWidth: "30px",
                                      borderColor: "transparent",
                                      border: "none",
                                    }}
                                    type="color"
                                    value={y.color}
                                  />{" "}
                                  {y.from < 2
                                    ? y.from.toFixed(2)
                                    : parseInt(y.from)}
                                  -{y.to < 2 ? y.to.toFixed(2) : parseInt(y.to)}
                                </span>
                              </div>
                            );
                          })}

                      {/* <div>
                                                    {rgbArray.map((z) => (
                                                        <input
                                                            type="color"
                                                            value={rgbToHex(z)}
                                                        />
                                                    ))}
                                                </div> */}
                    </div>
                  ) : (
                    <List
                      sx={{
                        width: "100%",
                        // maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                      style={{
                        marginTop: "7px",
                      }}
                    >
                      <ListItem
                        style={{
                          marginTop: "7px",
                        }}
                        disablePadding
                      >
                        <ListItemText>
                          <Typography variant="body1">
                            {havePoint ? "Color" : "Fill"}
                          </Typography>
                        </ListItemText>
                        {/* <SketchPicker /> */}
                        <input
                          type="color"
                          value={x.color}
                          onChange={(e) => {
                            colorChange(e, x.layer, "fill");
                          }}
                        />
                      </ListItem>
                      {!havePoint ? (
                        <>
                          <ListItem
                            style={{
                              marginTop: "7px",
                            }}
                            disablePadding
                          >
                            <ListItemText>
                              <Typography variant="body1">Outline</Typography>
                            </ListItemText>
                            {/* <SketchPicker /> */}
                            <input
                              type="color"
                              value={x.outline}
                              onChange={(e) => {
                                colorChange(e, x.layer, "outline");
                              }}
                            />
                          </ListItem>
                          <ListItem
                            style={{
                              marginTop: "7px",
                            }}
                            disablePadding
                          >
                            <ListItemText>
                              <Typography variant="body1">
                                Transparency
                              </Typography>
                            </ListItemText>
                            {/* <SketchPicker /> */}
                            <Slider
                              style={{
                                marginRight: "10px",
                              }}
                              aria-label="Transparency"
                              value={x.fillOpacity * 10}
                              onChange={(e) => {
                                colorChange(e, x.layer, "fillOpacity");
                              }}
                              // getAriaValueText={valuetext}
                              valueLabelDisplay="auto"
                              step={1}
                              marks
                              min={1}
                              max={10}
                              sx={{
                                width: 125,
                              }}
                            />
                          </ListItem>
                          <ListItem
                            style={{
                              marginTop: "7px",
                            }}
                            disablePadding
                          >
                            <ListItemText>
                              <Typography variant="body1">
                                Outline Transparency
                              </Typography>
                            </ListItemText>
                            {/* <SketchPicker /> */}
                            <Slider
                              style={{
                                marginRight: "10px",
                              }}
                              aria-label="Transparency"
                              value={x.outlineOpacity * 10}
                              onChange={(e) => {
                                colorChange(e, x.layer, "outlineOpacity");
                              }}
                              // getAriaValueText={valuetext}
                              valueLabelDisplay="auto"
                              step={1}
                              marks
                              min={1}
                              max={10}
                              sx={{
                                width: 125,
                              }}
                            />
                          </ListItem>
                          <ListItem
                            style={{
                              marginTop: "7px",
                            }}
                            disablePadding
                          >
                            <ListItemText>
                              <Typography variant="body1">
                                Outline width
                              </Typography>
                            </ListItemText>
                            {/* <SketchPicker /> */}
                            <TextField
                              value={x.outlineWidth}
                              type="number"
                              onChange={(e) => {
                                colorChange(e, x.layer, "outlineWidth");
                              }}
                              sx={{
                                width: 75,
                              }}
                            />
                          </ListItem>
                        </>
                      ) : (
                        <>
                          <ListItem
                            style={{
                              marginTop: "7px",
                            }}
                            disablePadding
                          >
                            <ListItemText>
                              <Typography variant="body1">Size</Typography>
                            </ListItemText>
                            <Slider
                              style={{
                                marginRight: "10px",
                              }}
                              aria-label="Transparency"
                              value={x.pointSize}
                              onChange={(e) => {
                                colorChange(e, x.layer, "pointSize");
                              }}
                              // getAriaValueText={valuetext}
                              valueLabelDisplay="auto"
                              step={1}
                              marks
                              min={1}
                              max={10}
                              sx={{
                                width: 125,
                              }}
                            />
                          </ListItem>
                        </>
                      )}
                    </List>
                  )}
                  <div
                    style={{
                      margin: "15px auto ",
                      width: "50%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={
                        layerColours.filter((x) => x.layer === paletteLayer)
                          .length < 1
                      }
                      onClick={() => handleSavePalette()}
                    >
                      Save
                    </Button>
                  </div>
                </>
              );
              // } else {
              //     return (
              //         <>
              //             <List
              //                 sx={{
              //                     width: "100%",
              //                     // maxWidth: 360,
              //                     bgcolor: "background.paper",
              //                 }}
              //                 style={{
              //                     marginTop: "7px",
              //                 }}
              //             >
              //                 <ListItem
              //                     style={{
              //                         marginTop: "7px",
              //                     }}
              //                     disablePadding
              //                 >
              //                     <ListItemText>
              //                         <Typography variant="body1">
              //                             Color
              //                         </Typography>
              //                     </ListItemText>
              //                     {/* <SketchPicker /> */}
              //                     <input
              //                         type="color"
              //                         value={x.color}
              //                         onChange={(e) => {
              //                             colorChange(
              //                                 e,
              //                                 x.layer,
              //                                 "fill"
              //                             );
              //                         }}
              //                     />
              //                 </ListItem>
              //             </List>
              //             <div
              //                 style={{
              //                     margin: "15px auto ",
              //                     width: "50%",
              //                     display: "grid",
              //                     placeItems: "center",
              //                 }}
              //             >
              //                 <Button
              //                     variant="contained"
              //                     disabled={
              //                         layerColours.filter(
              //                             (x) =>
              //                                 x.layer ===
              //                                 paletteLayer
              //                         ).length < 1
              //                     }
              //                     onClick={() =>
              //                         handleSavePalette()
              //                     }
              //                 >
              //                     Save
              //                 </Button>
              //             </div>
              //         </>
              //     );
              // }
            })}
        </ResizableBox>
      )}
    </div>
  );
};

export default MapComponent;
