/**
 * @description totalUtilityBillSavingConverter - well well well, "total_utility_bill_savings_20" in calculationsheet is f**king string
 * but in dashboardData is number, hence we need this util to convert , for example "£842,600" into a number to be saved in Table "building data"
 *
 * @returns a pure number
 * @Writer Roy Luo
 */
export const totalUtilityBillSavingConverter = (moneyString: string) => {
  // Remove the pound sign and comma from the money string
  const numericString = moneyString.replace("£", "").replace(",", "");

  // Parse the numeric string to a number
  const numericValue = parseFloat(numericString);

  return numericValue;
};
