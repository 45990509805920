import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import "./App.css";
import "./TopBar.css";
import { styled, alpha } from "@mui/material/styles";
import AbsolarLogo from "./images/absolar_logo.png";
import { useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import feature from "./images/feature.png";
import { getData, postData, putData } from "./get-data";

import {
  InputBase,
  Grid,
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  FormControl,
  TextField,
  Tooltip,
  MenuItem,
  Menu,
  Avatar,
  Box,
  CircularProgress,
  keyframes,
  Badge,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Modal from "@mui/material/Modal";

import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LogoutIcon from "@mui/icons-material/Logout";
import MapIcon from "@mui/icons-material/Map";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import FolderSpecialOutlinedIcon from "@mui/icons-material/FolderSpecialOutlined";
import HelpIcon from "@mui/icons-material/Help";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import PlaceIcon from "@mui/icons-material/Place";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LaunchIcon from "@mui/icons-material/Launch";
import ModalFAQ from "./infoPages/FaqModal";
import CloseIcon from '@mui/icons-material/Close';

import ModalAboutPage from "./infoPages/AboutPage";
import MapComponent from "./Map";
import Akash_image from "./images/akash.png";
import { UserContext } from "./contexts/UserContext";
import RequestAssessment from "./RequestAssessment";
import { Segment } from "semantic-ui-react";

interface ITopBarProps {
  handleOpenUserMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleOpenOptionsMenu: (event: React.MouseEvent<HTMLElement>) => void;
  anchorElUser: HTMLElement | null;
  handleAddNewPopupMenu: (event: React.MouseEvent<HTMLElement>) => void;
  anchorElAddNew: HTMLElement | null;
  anchorOptUser: HTMLElement | null;
  handleCloseUserMenu: () => void;
  handleCloseOptionsMenu: () => void;
  handleCloseAddNewMenu: () => void;
  setAccountModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mapDatas: MapData[];
  organisations: Organisation[];
  handleToidSelect: (toid: string) => void;
  setShowSavedList: (newBool: boolean) => void;
  showSavedList: boolean;
  setSavingAnimation: (newBool: boolean) => void;
  savingAnimation: boolean;
  setShowGeneratingList: (newBool: boolean) => void;
  showGeneratingList: boolean;
  orgEnv: number;
  setOrgEnv: (newOrg: number) => void;
  selectedProperty: DashboardData | null;
  setSelectedProperty: (newProp: DashboardData | null) => void;
  setRefData: React.Dispatch<React.SetStateAction<DashboardData | null>>;
  open: boolean;
  setOpen: (newBool: boolean) => void;
  loadingMap: boolean;
  setLoadingMap: (newBool: boolean) => void;
  allNotifications: UserNotification[];
  setAllNotifications: (newNotif: UserNotification[]) => void;
  iconRef: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export function TopBar(props: ITopBarProps) {
  const userContext = useContext(UserContext);

  let navigate = useNavigate();

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "0px",
        "&:focus": {
          width: "300px",
        },
      },
    },
  }));

  const handleChange = (event: SelectChangeEvent) => {
    props.setOrgEnv(parseInt(event.target.value));
    props.setShowSavedList(false);
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  const [open_add_new_env, setOpen_add_new_env] = React.useState(false);
  const handleOpen_add_new_env = () => setOpen_add_new_env(true);
  const handleClose_add_new_env = () => setOpen_add_new_env(false);

  const [open_bug_report, setOpen_bug_report] = React.useState(false);
  const handleOpen_bug_report = () => setOpen_bug_report(true);
  const handleClose_bug_report = () => setOpen_bug_report(false);

  const [open_faq, setOpen_faq] = useState(false);
  const handleOpen_faq = () => setOpen_faq(true);
  const handleClose_faq = () => setOpen_faq(false);

  const [anchorEl_notification, setAnchorEl_notification] =
    React.useState<null | HTMLElement>(null);
  const open_notification = Boolean(anchorEl_notification);
  const handleClick_notification = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl_notification(event.currentTarget);
    if (userContext.userState.loggedIn) {
      userContext.getNotificationsData();
    }
  };
  const handleClose_notification = () => {
    setAnchorEl_notification(null);
  };

  const [anchorEl_support, setAnchorEl_support] =
    React.useState<null | HTMLElement>(null);
  const open_support = Boolean(anchorEl_support);
  const handleClick_support = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl_support(event.currentTarget);
  };
  const handleClose_support = () => {
    setAnchorEl_support(null);
  };

  const [open_aboutpage, setOpen_aboutpage] = React.useState(false);
  const handleOpen_aboutpage = () => setOpen_aboutpage(true);
  const handleClose_aboutpage = () => setOpen_aboutpage(false);

  // const refreshPage = () => window.location.reload(true)

  const [coordinates, setCoordinates] = useState<{ lat: number; lon: number }>({
    lat: 0,
    lon: 0,
  });

  function setMap(property: DashboardData) {
    setCoordinates({
      lat: property.latitude ? property.latitude : 50,
      lon: property.longitude ? property.longitude : -1,
    });
  }

  useEffect(() => {
    if (props.selectedProperty != null) {
      setMap(props.selectedProperty);
    }
  }, [props.selectedProperty]);

  const [isRequestAssessmentModalOpen, setIsRequestAssessmentModalOpen] =
    React.useState(false);

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function isMobile() {
    return width <= 768;
  }

  const [selectedNotification, setSelectedNotification] =
    React.useState<UserNotification | null>(null);
  const [open_notif_modal, set_open_notif_modal] = React.useState(false);

  async function submitUpdatedNotifications(
    eachNotification: UserNotification,
    index: number
  ) {
    console.log(eachNotification);
    putData(
      process.env.REACT_APP_API_URL +
      "/notifications?notificationId=" +
      eachNotification.id +
      "&email=" +
      userContext.userInfo.email +
      "&seen=" +
      !eachNotification.seen,
      {},
      true,
      userContext.getCredentials
    )
      .then((res) => {
        console.log("DID IT?");
        res.text();
        // Update the property of eachNotification
        const updatedNotification = { ...eachNotification, seen: true };
        // Update the props.allNotifications array
        const updatedNotifications = [...props.allNotifications];
        updatedNotifications[index] = updatedNotification;
        props.setAllNotifications(updatedNotifications);
      })
      .catch((e) => alert("save failed"));
  }

  useEffect(() => {
    // const iconRect = props.iconRef.current.getBoundingClientRect();
    // Use the icon's position as needed
  }, []);


  const [dashboardButton, setDashboardButton] = useState("Back to Dashboard");

  useEffect(() => {
    if (props.showSavedList || props.selectedProperty) {
      setDashboardButton("Back to Dashboard");
    } else {
      setDashboardButton("Dashboard");
    }
  }, [props.showSavedList, props.selectedProperty]);


  useEffect(() => {
    const handlePopstate = () => {
      const newPath = window.location.pathname;
      if (newPath == "/") {
        props.setShowSavedList(false);
        props.setSelectedProperty(null);
        setDashboardButton("Dashboard")
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);


  const [topBarSelected, setTopBarSelected] = React.useState("Dashboard");

  return (
    <AppBar position="static">
      {userContext.userState.loggedIn ? (
        <Toolbar>
          <FormControl
            style={{ textAlign: "left", width: isMobile() ? "40px" : "" }}
            size="small"
          >
            <Select
              autoWidth
              value={isMobile() ? "" : props.orgEnv.toString()}
              onChange={handleChange}
              style={{
                background: "lightblue",
                paddingRight: "20px",
                borderRadius: 5,
              }}
            >
              <div style={{ marginLeft: 15, marginBottom: 10, color: "grey" }}>
                {isMobile() ? (
                  <>
                    Selected Site:{" "}
                    {props.organisations.length != 0 && props.orgEnv != -1
                      ? props.organisations.filter(
                        (each) => each.id == props.orgEnv
                      )[0].display_name
                      : ""}
                  </>
                ) : (
                  <>Select site: </>
                )}
              </div>
              <hr
                style={{
                  width: "calc(100% - 30px)",
                  border: "1px solid lightgrey",
                }}
              />
              {props.organisations.map((organisation) => {
                return (
                  <MenuItem
                    value={organisation.id}
                    onClick={() => {
                      props.setRefData(null);
                    }}
                  >
                    {organisation.display_name}
                  </MenuItem>
                );
              })}
              <Button
                fullWidth
                style={{
                  textTransform: "none",
                  justifyContent: "flex-start",
                  paddingLeft: 15,
                }}
                startIcon={<AddIcon />}
                // onClick={handleOpen_add_new_env}
                onClick={() => {
                  handleOpen_add_new_env();
                }}
              >
                Add a new site
              </Button>
              <hr
                style={{
                  width: "calc(100% - 30px)",
                  border: "1px solid lightgrey",
                }}
              />
              <Button
                fullWidth
                style={{
                  textTransform: "none",
                  justifyContent: "flex-start",
                  paddingLeft: 15,
                }}
                onClick={handleOpen_aboutpage}
              >
                About
              </Button>
              <Tooltip
                title="Only admin has access to settings"
                placement="top-start"
                style={{ fontSize: "14px" }}
              >
                <span>
                  <Button
                    disabled
                    fullWidth
                    style={{
                      textTransform: "none",
                      justifyContent: "flex-start",
                      paddingLeft: 15,
                    }}
                  >
                    Settings
                  </Button>
                </span>
              </Tooltip>
              <Button
                href="https://www.absolar.co.uk/dashboardtermsofuse"
                target="_blank"
                endIcon={<LaunchIcon />}
                fullWidth
                style={{
                  textTransform: "none",
                  justifyContent: "space-between",
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                Terms and conditions
              </Button>
              <hr
                style={{
                  width: "calc(100% - 30px)",
                  border: "1px solid lightgrey",
                }}
              />
              <Button
                endIcon={<LaunchIcon />}
                fullWidth
                style={{
                  textTransform: "none",
                  justifyContent: "space-between",
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
                href="https://absolar.co.uk"
                target="_blank"
              >
                Visit Absolar website
              </Button>
            </Select>
          </FormControl>
          <Modal open={open_aboutpage} onClose={handleClose_aboutpage}>
            <ModalAboutPage />
          </Modal>
          <Modal open={open_add_new_env} onClose={handleClose_add_new_env}>
            <div className="modal_container_add_new_site_iframe">
              <iframe
                src="https://forms.office.com/Pages/ResponsePage.aspx?id=0gy-4oN0C0eT04_zvRCUdoBRbbKGL0xFnOkiGjX3aA9UNlpXOEFOUFZMMlBGQTNVS05BWDM0TkQ3OS4u&embed=true"
                width="100%"
                height="100%"
                style={{ overflow: "hidden" }}
              >
                Loading…
              </iframe>
            </div>
          </Modal>
          {props.showGeneratingList ? null : (
            <Button
              color="inherit"
              style={topBarSelected == "Dashboard" ? {
                textTransform: "capitalize",
                marginLeft: 30,
                fontWeight: "bold",
                backgroundColor: "#3887dc"
              } :
              {
                textTransform: "capitalize",
                marginLeft: 30,
                fontWeight: "bold",
              }}
              onClick={() => {
                setTopBarSelected("Dashboard");
                props.setRefData(null);
                if (props.showSavedList) {
                  props.setShowSavedList(false);
                } else {
                  navigate("/");
                }
              }}
            >

              {dashboardButton == "Back to Dashboard" ?
                <>
                  {isMobile() ? (
                    <ArrowBackIcon style={{ marginRight: 10 }} />
                  ) : (
                    <>
                      <ArrowBackIcon style={{ marginRight: 10 }} />
                      {dashboardButton}
                    </>
                  )}
                </>
                :
                <>
                  {isMobile() ? <span>{dashboardButton}</span> : <span>{dashboardButton}</span>}
                </>

              }

            </Button>
          )}
          {props.orgEnv === 2 ? (
            <Button
              color="inherit"
              style={topBarSelected == "Live" ? {
                textTransform: "capitalize",
                marginLeft: 30,
                fontWeight: "bold",
                backgroundColor: "#3887dc"
              } :
              {
                textTransform: "capitalize",
                marginLeft: 30,
                fontWeight: "bold",
              }}
              onClick={() => {
                setTopBarSelected("Live");
                if (props.showGeneratingList) {
                  props.setShowGeneratingList(false);
                } else {
                  props.setShowGeneratingList(true);
                }
              }}
            >
              {props.showGeneratingList ? (
                <>
                  <KeyboardBackspaceIcon />
                  Show all properties
                </>
              ) : (
                "Live data"
              )}
            </Button>
          ) : null}

          {userContext.userState.username.includes("phil@absolar.co.uk") ? (
            <>
              <Button
                color="inherit"
                style={topBarSelected == "Business Case" ? {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                  backgroundColor: "#3887dc"
                } :
                {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setTopBarSelected("Business Case");
                  navigate("/business_case");
                }}
              >
                Business case
              </Button>
              {/* <Button
                color="inherit"
                style={topBarSelected == "Landing" ? {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                  backgroundColor: "#3887dc"
                } :
                {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setTopBarSelected("Landing");
                  navigate("/landing");
                }}
              >
                Landing
              </Button>
              <Button
                color="inherit"
                style={topBarSelected == "Landing2" ? {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                  backgroundColor: "#3887dc"
                } :
                {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setTopBarSelected("Landing2");
                  navigate("/landing_approved_installer");
                }}
              >
                Landing_2
              </Button> */}
              {/* <Button
                color="inherit"
                style={topBarSelected == "Installation" ? {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                  backgroundColor: "#3887dc"
                } :
                {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setTopBarSelected("Installation");
                  navigate("/tracking");
                }}
              >
                Installation tracking
              </Button> */}
              <Button
                color="inherit"
                style={topBarSelected == "Data M" ? {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                  backgroundColor: "#3887dc"
                } :
                {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setTopBarSelected("Data M");
                  navigate("/data");
                }}
              >
                Data Monitor
              </Button>
            </>
          ) : null}
          {userContext.userState.username.includes("absolar.co.uk") ? (
            <>
              <Button
                color="inherit"
                style={ topBarSelected == "Control Panel" ? {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                  backgroundColor: "#3887dc"
                } :
                {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                }
              }
                onClick={() => {
                  setTopBarSelected("Control Panel");
                  navigate("/upload");
                }}
              >
                Control panel
              </Button>
            </>
          ) : null}
          {userContext.userState.username.includes("absolar.co.uk") ? (
            <>
              <Button
                color="inherit"
                style={topBarSelected == "Analytics" ? {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                  backgroundColor: "#3887dc"
                } :
                {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setTopBarSelected("Analytics");
                  navigate("/analytics");
                }}
              >
                Analytics
              </Button>
              <Button
                color="inherit"
                style={topBarSelected == "Admin" ? {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                  backgroundColor: "#3887dc"
                } :
                {
                  textTransform: "capitalize",
                  marginLeft: 30,
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setTopBarSelected("Admin");
                  navigate("/admin");
                }}
              >
                Admin
              </Button>
            </>
          ) : null}
          <div
            style={{
              right: 30,
              position: "absolute",
              display: "flex",
              alignItems: "center",
            }}
          >
            <>
              {isMobile() ? (
                <>
                  <IconButton
                    onClick={props.handleOpenOptionsMenu}
                    sx={{ p: 0 }}
                  >
                    <MenuIcon style={{ color: "white", fontSize: "35px" }} />
                  </IconButton>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={props.anchorOptUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(props.anchorOptUser)}
                    onClose={props.handleCloseOptionsMenu}
                  >
                    <MenuItem
                      onClick={() => {
                        setIsRequestAssessmentModalOpen(true);
                        props.handleCloseOptionsMenu();
                      }}
                    >
                      <Typography textAlign="center">Add new</Typography>
                    </MenuItem>
                    {props.organisations.length != 0 &&
                      props.selectedProperty == null ? (
                      <MenuItem
                        onClick={() => {
                          props.setOpen(true);
                          props.setLoadingMap(true);
                          props.setShowSavedList(false);
                          props.handleCloseOptionsMenu();
                        }}
                      >
                        <Typography>Map View</Typography>
                      </MenuItem>
                    ) : null}

                    <MenuItem
                      onClick={
                        props.showSavedList
                          ? () => {
                            navigate("/");
                            props.setShowSavedList(false);
                            props.handleCloseUserMenu();
                            props.handleCloseOptionsMenu();
                          }
                          : () => {
                            navigate("/");
                            props.setRefData(null);
                            props.setShowSavedList(true);
                            props.handleCloseUserMenu();
                            props.handleCloseOptionsMenu();
                          }
                      }
                    >
                      <Typography textAlign="center">
                        {props.showSavedList
                          ? "View All Properties"
                          : "Saved Properties"}
                      </Typography>
                    </MenuItem>

                    <MenuItem onClick={props.handleCloseOptionsMenu}>
                      <Typography onClick={handleClick_support}>
                        Help & Support
                      </Typography>
                    </MenuItem>
                    <Menu
                      anchorEl={anchorEl_support}
                      open={open_support}
                      onClose={handleClose_support}
                    >
                      <div
                        style={{
                          minHeight: "200px",
                          width: "90%",
                          maxWidth: "400px",
                          padding: 20,
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" gutterBottom align="left">
                          Support
                        </Typography>
                        <hr></hr>
                        <Typography gutterBottom variant="body2" align="left">
                          For individual assitance, please contact your Project
                          Manager using the details below:
                        </Typography>
                        <Grid
                          container
                          spacing={3}
                          style={{
                            marginTop: 10,
                            marginBottom: 20,
                            textAlign: "left",
                          }}
                        >
                          <Grid item xs={4}>
                            <img src={Akash_image} style={{ width: "100%" }} />
                          </Grid>
                          <Grid item xs={8}>
                            <p>
                              <a>akash@absolar.co.uk</a>
                            </p>
                            <p>
                              <a>info@absolar.co.uk</a>
                            </p>
                            <p>
                              <a>+44 2382 680106</a>
                            </p>
                          </Grid>
                        </Grid>
                        <Typography gutterBottom variant="body2" align="left">
                          You may find answers to questions about our Remote
                          Solar Survey findings or Solar PV in general in our
                          Frequently Asked Questions.{" "}
                        </Typography>
                        <Button
                          onClick={() => {
                            handleOpen_faq();
                            handleClose_support();
                          }}
                          variant="outlined"
                          style={{ marginTop: 10 }}
                        >
                          Frequently Asked Questions
                        </Button>
                      </div>
                    </Menu>
                  </Menu>

                  <Tooltip
                    title={
                      <Typography variant="body2">Notifications</Typography>
                    }
                  >
                    <IconButton
                      size="large"
                      aria-label="new notifications"
                      color="inherit"
                      onClick={handleClick_notification}
                    >
                      <Badge
                        color="secondary"
                        badgeContent={
                          props.allNotifications.filter(
                            (eachNotification) => eachNotification.seen == false
                          ).length
                        }
                      >
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl_notification}
                    open={open_notification}
                    onClose={handleClose_notification}
                    style={{ marginLeft: `calc(45% - ${width / 2}px)` }}
                  >
                    {props.allNotifications.length > 0 ? (
                      props.allNotifications.map((eachNotification, index) => (
                        <MenuItem
                          style={{
                            fontWeight: !eachNotification.seen
                              ? "bold"
                              : "normal",
                            backgroundColor: !eachNotification.seen
                              ? eachNotification.variant === "urgent"
                                ? "#ffd8d8"
                                : "lightblue"
                              : "white",
                          }}
                          onClick={() => {
                            handleClose_notification();
                            set_open_notif_modal(true);
                            setSelectedNotification(eachNotification);

                            if (!eachNotification.seen) {
                              //update if it's not seen yet

                              submitUpdatedNotifications(
                                eachNotification,
                                index
                              );
                            }
                          }}
                        >
                          {eachNotification.image === "" ? (
                            eachNotification.variant === "urgent" ? (
                              <NotificationsActiveIcon
                                style={{
                                  color: "darkred",
                                  border: "1px solid black",
                                  borderRadius: "30%",
                                  padding: "4px",
                                  fontSize: "30px",
                                }}
                              />
                            ) : (
                              <NotificationsIcon
                                style={{
                                  border: "1px solid black",
                                  borderRadius: "30%",
                                  padding: "4px",
                                  fontSize: "30px",
                                }}
                              />
                            )
                          ) : (
                            <img
                              style={{
                                width: "30px",
                                border: "1px solid black",
                                borderRadius: "30%",
                                padding: "4px",
                              }}
                              src={eachNotification.image}
                              alt=""
                            />
                          )}
                          <span
                            style={{ whiteSpace: "pre-line", lineHeight: "1" }}
                          >
                            <span
                              style={{
                                marginLeft: "10px",
                                fontWeight: !eachNotification.seen
                                  ? "bold"
                                  : "normal",
                                color: !eachNotification.seen
                                  ? eachNotification.variant === "urgent"
                                    ? "darkred"
                                    : "black"
                                  : "black",
                              }}
                            >
                              {eachNotification.title}
                            </span>
                            {"\n"}
                            <span
                              style={{
                                marginLeft: "10px",
                                color: "gray",
                                fontSize: "12px",
                              }}
                            >
                              {eachNotification.timestamp}
                            </span>
                          </span>
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem onClick={handleClose_notification}>
                        You don't have new notifications
                      </MenuItem>
                    )}
                    <div style={{ minHeight: "200px", padding: 10 }}></div>
                    {/* <MenuItem>My account</MenuItem> */}
                  </Menu>
                </>
              ) : (
                <>
                  <Tooltip
                    title={
                      <Typography variant="body2">
                        Request new assessment of a property
                      </Typography>
                    }
                  >
                    <Button
                      color="inherit"
                      size="small"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      style={{ marginRight: 20, textTransform: "none" }}
                      // onClick={props.handleAddNewPopupMenu}
                      onClick={()=>setIsRequestAssessmentModalOpen(true)}
                    
                    >
                      Request new assessment
                    </Button>
                  
                  </Tooltip>
                  {/* <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={props.anchorElAddNew}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(props.anchorElAddNew)}
                    onClose={props.handleCloseAddNewMenu}
                  >
        
          
                    <MenuItem>
                      <Button
                        onClick={() => {
                          setIsRequestAssessmentModalOpen(true);
                          props.handleCloseAddNewMenu();
                        }}
                        style={{
                          textAlign: "left",
                          padding: 0,
                          textTransform: "none",
                          color: "inherit",
                          fontSize: "inherit",
                          display: "flex",
                        }}
                      >
                        Request new assessment
                      </Button>
                    </MenuItem>
                    {userContext.userInfo.email.includes("absolar.co.uk") ?
                    <MenuItem>
                    <Button
                      onClick={() => {
                        setTopBarSelected("Control Panel");
                        navigate("/upload");
                        props.handleCloseAddNewMenu()
                      }}
                      style={{
                        textAlign: "left",
                        padding: 0,
                        textTransform: "none",
                        color: "inherit",
                        fontSize: "inherit",
                        display: "flex",
                      }}
                    >
                      Upload results
                    </Button>
                  </MenuItem> 
                    :
                    <></>}
                  </Menu> */}
                  {props.orgEnv === 4 || props.orgEnv === 21 ? (
                    <>
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            Please upgrade to Pro account to access map function
                          </Typography>
                        }
                      >
                        <span>
                          <Button
                            disabled
                            color="inherit"
                            startIcon={<PlaceIcon />}
                          >
                            Map View
                          </Button>
                        </span>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      {props.organisations ? (
                        <Tooltip
                          title={
                            props.selectedProperty !== null ? (
                              <Typography variant="body2">
                                Please return to dashboard first
                              </Typography>
                            ) : null
                          }
                        >
                          <span
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                          >
                            <Segment
                              onClick={() => {
                                props.setOpen(true);
                                props.setLoadingMap(true);
                                props.setShowSavedList(false);
                              }}
                              style={
                                props.organisations.length == 0 ||
                                  dashboardButton == "Back to Dashboard"
                                  ? { pointerEvents: "none" }
                                  : { cursor: "pointer" }
                              }
                              className="button"
                            >
                              <div
                                className="icon"
                                style={
                                  props.organisations.length == 0 ||
                                    dashboardButton == "Back to Dashboard"
                                    ? { color: "grey" }
                                    : {}
                                }
                              >
                                <PlaceIcon />
                              </div>
                              <span className="text">Map View</span>
                            </Segment>
                          </span>
                        </Tooltip>
                      ) : null}
                    </>
                  )}

                  <div
                    style={{
                      display: "inline-flex",
                      position: "relative",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip
                      title={
                        props.showSavedList ? (
                          <Typography variant="body2">
                            View All Properties
                          </Typography>
                        ) : (
                          <Typography variant="body2">
                            Saved Properties
                          </Typography>
                        )
                      }
                    >
                      <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color={props.showSavedList ? "warning" : "inherit"}
                        onClick={
                          
                          props.showSavedList
                            ? () => {
                              setTopBarSelected("Dashboard");
                              navigate("/");
                              props.setShowSavedList(false);
                              props.handleCloseUserMenu();
                            }
                            : () => {
                              setTopBarSelected("Dashboard");
                              navigate("/");
                              props.setRefData(null);
                              props.setShowSavedList(true);
                              props.handleCloseUserMenu();
                            }
                        }
                      >
                        <FolderSpecialIcon ref={props.iconRef} />
                      </IconButton>
                    </Tooltip>
                    {props.savingAnimation && (
                      <CircularProgress
                        color="inherit"
                        style={{ position: "absolute" }}
                      />
                    )}
                  </div>
                  <Tooltip
                    title={
                      <Typography variant="body2">Notifications</Typography>
                    }
                  >
                    <IconButton
                      size="large"
                      aria-label="show 17 new notifications"
                      color="inherit"
                      onClick={handleClick_notification}
                    >
                      <Badge
                        color="secondary"
                        badgeContent={
                          props.allNotifications.filter(
                            (eachNotification) => eachNotification.seen == false
                          ).length
                        }
                      >
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl_notification}
                    open={open_notification}
                    onClose={handleClose_notification}
                    style={{ marginLeft: `calc(45% - ${width / 2}px)` }}
                  >
                    {props.allNotifications.length > 0 ? (
                      props.allNotifications.map((eachNotification, index) => (
                        <MenuItem
                          style={{
                            fontWeight: !eachNotification.seen
                              ? "bold"
                              : "normal",
                            backgroundColor: !eachNotification.seen
                              ? eachNotification.variant === "urgent"
                                ? "#ffd8d8"
                                : "lightblue"
                              : "white",
                          }}
                          onClick={() => {
                            handleClose_notification();
                            set_open_notif_modal(true);
                            setSelectedNotification(eachNotification);

                            if (!eachNotification.seen) {
                              //update if it's not seen yet

                              submitUpdatedNotifications(
                                eachNotification,
                                index
                              );
                            }
                          }}
                        >
                          {eachNotification.image === "" ? (
                            eachNotification.variant === "urgent" ? (
                              <NotificationsActiveIcon
                                style={{
                                  color: "darkred",
                                  border: "1px solid black",
                                  borderRadius: "30%",
                                  padding: "4px",
                                  fontSize: "30px",
                                }}
                              />
                            ) : (
                              <NotificationsIcon
                                style={{
                                  border: "1px solid black",
                                  borderRadius: "30%",
                                  padding: "4px",
                                  fontSize: "30px",
                                }}
                              />
                            )
                          ) : (
                            <img
                              style={{
                                width: "30px",
                                border: "1px solid black",
                                borderRadius: "30%",
                                padding: "4px",
                              }}
                              src={eachNotification.image}
                              alt=""
                            />
                          )}
                          <span
                            style={{ whiteSpace: "pre-line", lineHeight: "1" }}
                          >
                            <span
                              style={{
                                marginLeft: "10px",
                                fontWeight: !eachNotification.seen
                                  ? "bold"
                                  : "normal",
                                color: !eachNotification.seen
                                  ? eachNotification.variant === "urgent"
                                    ? "darkred"
                                    : "black"
                                  : "black",
                              }}
                            >
                              {eachNotification.title}
                            </span>
                            {"\n"}
                            <span
                              style={{
                                marginLeft: "10px",
                                color: "gray",
                                fontSize: "12px",
                              }}
                            >
                              {eachNotification.timestamp}
                            </span>
                          </span>
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem onClick={handleClose_notification}>
                        You don't have new notifications
                      </MenuItem>
                    )}
                    <div style={{ minHeight: "200px", padding: 10 }}></div>
                    {/* <MenuItem>My account</MenuItem> */}
                  </Menu>
                  <Tooltip
                    title={
                      <Typography variant="body2">Help & support</Typography>
                    }
                  >
                    <IconButton
                      size="large"
                      color="inherit"
                      onClick={handleClick_support}
                      style={{ marginRight: 20 }}
                    >
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl_support}
                    open={open_support}
                    onClose={handleClose_support}
                  >
                    {/* <MenuItem onClick={handleClose_support}>
                  You don't have new notifications
                </MenuItem> */}
                    <div
                      style={{
                        minHeight: "200px",
                        width: "90%",
                        maxWidth: "400px",
                        padding: 20,
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h6" gutterBottom align="left">
                        Support
                      </Typography>
                      <hr></hr>
                      <Typography gutterBottom variant="body2" align="left">
                        For individual assitance, please contact your Project
                        Manager using the details below:
                      </Typography>
                      <Grid
                        container
                        spacing={3}
                        style={{
                          marginTop: 10,
                          marginBottom: 20,
                          textAlign: "left",
                        }}
                      >
                        <Grid item xs={4}>
                          <img src={Akash_image} style={{ width: "100%" }} />
                        </Grid>
                        <Grid item xs={8}>
                          <p>
                            <a>akash@absolar.co.uk</a>
                          </p>
                          <p>
                            <a>info@absolar.co.uk</a>
                          </p>
                          <p>
                            <a>+44 2382 680106</a>
                          </p>
                        </Grid>
                      </Grid>
                      <Typography gutterBottom variant="body2" align="left">
                        You may find answers to questions about our Remote Solar
                        Survey findings or Solar PV in general in our Frequently
                        Asked Questions.{" "}
                      </Typography>
                      <Button
                        onClick={() => {
                          handleOpen_faq();
                          handleClose_support();
                        }}
                        variant="outlined"
                        style={{ marginTop: 10 }}
                      >
                        Frequently Asked Questions
                      </Button>
                    </div>
                  </Menu>
                </>
              )}
            </>
            <Modal open={open_faq} onClose={handleClose_faq}>
              <ModalFAQ />
            </Modal>
            <Modal
              open={props.open}
              onClose={() => {
                props.setOpen(false);
                props.setLoadingMap(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ zIndex: 1 }}
            >
              
              <Box sx={style} className="outer_map_container">
              <div className="modal_close_button" onClick={() => props.setOpen(false)}>
      <CloseIcon className="closeIcon"/>
    </div>
                {props.loadingMap ? (
                  <div className="map_loading">
                    <div className="loading_overlay">
                      <CircularProgress /> <div>Loading</div>
                    </div>
                  </div>
                ) : null}
                <div className="map_container">
                  {props.mapDatas.length > 0 ? (
                    <MapComponent
                      mapData={props.mapDatas}
                      lat={
                        props.selectedProperty == null
                          ? props.organisations.filter(
                            (eachOrg) => eachOrg.id === props.orgEnv
                          )[0]?.lat
                          : coordinates.lat
                      }
                      lng={
                        props.selectedProperty == null
                          ? props.organisations.filter(
                            (eachOrg) => eachOrg.id === props.orgEnv
                          )[0]?.lon
                          : coordinates.lon
                      }
                      zoom={16}
                      handleToidSelect={props.handleToidSelect}
                    />
                  ) : null}
                </div>
              </Box>
            </Modal>
            <Modal
              open={isRequestAssessmentModalOpen}
              onClose={() => {
                setIsRequestAssessmentModalOpen(false);
              }}
              // disableEnforceFocus
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="requestNewModal">
                <Typography variant="h6">Request a new assessment</Typography>
                <Divider
                  style={{
                    backgroundColor: "#888888",
                    height: "1px",
                    marginBottom: 20,
                  }}
                ></Divider>
                <RequestAssessment
                  userCompany={userContext.userInfo.company ? userContext.userInfo.company : userContext.userState.username}
                  userEmail={userContext.userState.username}
                  userName={userContext.userInfo.name ? userContext.userInfo.name  : "SolarWatcher User"}
                ></RequestAssessment>
              </div>
            </Modal>

            <Modal open={open_bug_report} onClose={handleClose_bug_report}>
              <div className="modal_container_add_new_site_iframe">
                <iframe
                  src="https://forms.office.com/Pages/ResponsePage.aspx?id=0gy-4oN0C0eT04_zvRCUdoBRbbKGL0xFnOkiGjX3aA9UQzFQMVZTWVJKNTNJVkJXME5WUU1KRDlGNC4u&embed=true"
                  width="100%"
                  height="100%"
                  style={{ overflow: "hidden" }}
                >
                  Loading…
                </iframe>
              </div>
            </Modal>
            <Modal
              open={open_notif_modal}
              onClose={() => {
                setSelectedNotification(null);
                set_open_notif_modal(false);
              }}
            >
              <div
                className="modal_container_add_new_site_iframe"
                style={{ height: "fit-content", padding: "15px", overflow: "auto" }}
              >
                <div>
                  <h2 style={{ margin: "0px" }}>
                    <span style={{ whiteSpace: "pre-line", lineHeight: "1" }}>
                      <span
                        style={{
                          color: !selectedNotification?.seen
                            ? selectedNotification?.variant === "urgent"
                              ? "darkred"
                              : "black"
                            : "black",
                        }}
                      >
                        {selectedNotification?.title}
                      </span>
                      {"\n"}
                      <span style={{ color: "gray", fontSize: "15px" }}>
                        Date: {selectedNotification?.timestamp.split("T")[0]}
                      </span>
                    </span>
                  </h2>
                </div>

                <hr></hr>
                <div>
                  {selectedNotification?.image === "" ? (
                    selectedNotification?.variant === "urgent" ? (
                      <NotificationsActiveIcon
                        style={{
                          color: "darkred",
                          border: "1px solid black",
                          borderRadius: "30%",
                          padding: "4px",
                          fontSize: "50px",
                        }}
                      />
                    ) : (
                      <NotificationsIcon
                        style={{
                          border: "1px solid black",
                          borderRadius: "30%",
                          padding: "4px",
                          fontSize: "50px",
                        }}
                      />
                    )
                  ) : (
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "50vh",
                        // border: "1px solid black",
                        // borderRadius: "30%",
                        // padding: "4px",
                      }}
                      src={selectedNotification?.image}
                      alt=""
                    />
                  )}
                </div>
                <p>{selectedNotification?.description}</p>
              </div>
            </Modal>
            <>
              <Tooltip
                title={<Typography variant="body2">My account</Typography>}
              >
                <IconButton onClick={props.handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    sx={{
                      bgcolor: stringToColor(
                        userContext.userState.username.split("@")[0]
                      ),
                    }}
                  >
                    {userContext.userState.username[0][0].toUpperCase()}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={props.anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(props.anchorElUser)}
                onClose={props.handleCloseUserMenu}
              >
                <MenuItem
                  onClick={() => {
                    props.setAccountModalOpen(true);
                  }}
                >
                  <Typography textAlign="center">Account</Typography>
                </MenuItem>
                {/* <MenuItem
                  onClick={
                    props.showSavedList
                      ? () => {
                        props.setShowSavedList(false);
                        props.handleCloseUserMenu();
                      }
                      : () => {
                        props.setShowSavedList(true);
                        props.handleCloseUserMenu();
                      }
                  }
                >
                  <Typography textAlign="center">
                    {props.showSavedList
                      ? "View All Properties"
                      : "Saved Properties"}
                  </Typography>
                </MenuItem> */}
                <MenuItem>
                  <Button
                    href="https://survey.absolar.co.uk"
                    target="_blank"
                    style={{
                      textAlign: "left",
                      padding: 0,
                      textTransform: "none",
                      color: "inherit",
                      fontSize: "inherit",
                      display: "block",
                    }}
                  >
                    Request new assessment
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    onClick={handleOpen_bug_report}
                    style={{
                      textAlign: "left",
                      padding: 0,
                      textTransform: "none",
                      color: "inherit",
                      fontSize: "inherit",
                      display: "block",
                    }}
                  >
                    Bug report
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    endIcon={<LogoutIcon />}
                    onClick={() => {
                      window.location.reload();
                    }}
                    style={{
                      textAlign: "left",
                      padding: 0,
                      textTransform: "none",
                      color: "inherit",
                      fontSize: "inherit",
                      display: "flex",
                    }}
                  >
                    Log out
                  </Button>
                </MenuItem>
              </Menu>
            </>
          </div>
        </Toolbar>
      ) : (
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            disabled
          >
            <img
              alt="absolar solutions ltd logo"
              src={AbsolarLogo}
              style={{ width: 120, filter: "brightness(0) invert(1)" }}
            />
          </IconButton>
        </Toolbar>
      )}
    </AppBar>
  );
}
