import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import image_public_page from "../images/make_page_public.png";

import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function SendEmailError() {
  return (
    <div
      style={{
        width: "80%",
        maxWidth: "1200px",
        margin: "30px auto",
        textAlign: "left",
        // background:"#e5e5e5"
      }}
    >
      <div className="modal_container_add_new_site">
        {/* <div className="modal_close_button">
          <IconButton size="large">
            <HighlightOffIcon />
          </IconButton>
        </div> */}
        <h3>Attention</h3>
        <p>
          Please go to the project result page and make sure that the
          "Visibility" setting has been turned to "public", as shown in image below:
        </p>
        <img src={image_public_page} style={{border:"2px dashed red"}} />
      </div>
    </div>
  );
}
