import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Autocomplete, GoogleMap } from "@react-google-maps/api";
import { TextField, Typography } from "@mui/material";
import getFeatureCollectionData from "../../services/getFeatureCollectionData";
import { PropaneSharp } from "@mui/icons-material";
import OrganisationReferenceID from "../toidSearchHelpers/OrganisationReferenceID";

const Container = styled.div``;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.h2``;

const SelectAddressContainer = styled.div`
  display: flex;
`;

const Button = styled.button`
  color: white;
  background-color: #3b82f6ff;
  padding: 10px 35px;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const Hr = styled.hr`
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 20px 0;
`;

const FetchButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const FetchButton = styled.button`
  color: white;
  background-color: #3b82f6ff;
  padding: 10px 35px;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.01);
  }
  &:disabled {
    background-color: lightgray; /* Change the style for disabled state if needed */
    cursor: not-allowed;
  }
`;

const containerStyle = {
  width: "100%",
  height: "80vh",
};

interface FetchOSDataProps {
  onNext: (
    bounds: google.maps.LatLngBounds,
    featureCustomDataArray: FeatureCustomData[]
  ) => void;
  setAddress: (newAddress: string) => void;
  setCalculationSheet: (newData: CalculationSheet | null) => void;
  setPostcode: (newPostcode: string) => void;
  reference: string | undefined;
  setReference: (value: string) => void;
  organisationId: number;
  setOrganisationId: (newNumber: number) => void;
}

const FetchOSData: React.FC<FetchOSDataProps> = (props) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const [center, setCenter] = useState({ lat: latitude, lng: longitude });

  const [isOrgSet, setisOrgSet] = useState(false);

  const mapRef = useRef<google.maps.Map | null>(null);

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };


  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      props.setAddress(place.formatted_address ? place.formatted_address : "");
      var inputString = place.adr_address ? place.adr_address : "";

      // Define a regular expression to match the postal code
      var regex = /<span class="postal-code">(.*?)<\/span>/;

      // Use the regular expression to extract the postal code
      var match = inputString.match(regex);

      // Check if a match was found
      if (match && match.length > 1) {
        var postcode = match[1];
        console.log("Extracted postcode:", postcode);
        props.setPostcode(postcode);
      } else {
        console.log("Postal code not found in the input string.");
      }

      if (place.geometry && place.geometry.location) {
        setLatitude(place.geometry.location.lat());
        setLongitude(place.geometry.location.lng());
      } else {
        console.log("Place data is incomplete. Unable to get position.");
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  useEffect(() => {
    setCenter({ lat: latitude, lng: longitude });
    props.setCalculationSheet(null);
  }, [latitude, longitude]);

  useEffect(() => {
    console.log("ENTER");
    if (props.organisationId != -1){
      setisOrgSet(true);
    } else {
      setisOrgSet(false);
    }
  }, [props.organisationId]);

  const onLoadMap = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  const handleButtonClick = async () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      if (bounds) {
        const bbox = `${bounds.getSouthWest().lng()},${bounds
          .getSouthWest()
          .lat()},${bounds.getNorthEast().lng()},${bounds
          .getNorthEast()
          .lat()}`;
        const result = await getFeatureCollectionData(bbox);
        props.onNext(bounds, result);
      }
    }
  };

  return (
    <Container>
      <SearchContainer>
        {/* <Typography variant='h5'>Search the location</Typography> */}
        <SelectAddressContainer>
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            options={{ componentRestrictions: { country: "uk" } }}
          >
            <TextField
              type="text"
              size="small"
              placeholder="Address"
              style={{ width: "500px" }}
            />
          </Autocomplete>
          {/* <Button>Submit</Button> */}
        </SelectAddressContainer>
      </SearchContainer>
      <Hr />
      {latitude !== 0 && longitude !== 0 && (
        <>
          <OrganisationReferenceID
            reference={props.reference}
            setReference={props.setReference}
            organisationId={props.organisationId}
            setOrganisationId={props.setOrganisationId}
          />
          <FetchButtonContainer>
            <FetchButton disabled={!isOrgSet}
            onClick={handleButtonClick}>
              Identify Buildings
            </FetchButton>
          </FetchButtonContainer>
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={19}
            center={center}
            mapTypeId="satellite"
            options={{
              tilt: 0,
              mapTypeId: "satellite",
            }}
            onLoad={onLoadMap}
          />
        </>
      )}
    </Container>
  );
};

export default FetchOSData;
