import React, { useState } from "react";

import { Typography, Grid , CardActionArea } from "@mui/material";
import ccs_image from "../images/ccs.jpg";
import chas_image from "../images/chas.png";
import mcs_image from "../images/mcs_2.png";
import hub_image from "../images/renewable_hub.png";
import solar_energy_uk_image from "../images/solar_energy_uk.png";
import rics_image from "../images/rics_partner.jpg";
import os_image from "../images/os_partner.png";
import cyber_image from "../images/cyber_image.png";


export default function InfoQualification() {
  return (
    <>
      <Typography
        variant="h5"
        style={{ marginTop: 20, borderBottom: "1px grey solid" }}
        gutterBottom
        paragraph
      >
        What qualifications are needed for solar installers
      </Typography>
      <Grid container spacing={2} style={{alignItems:"center"}}>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={mcs_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={ccs_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={chas_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={hub_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={solar_energy_uk_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={rics_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={os_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={cyber_image} style={{width:"100%"}} />
          </div>
        </Grid>
      </Grid>

    </>
  );
}
