import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";

import {
  Grid,
  Button,
  TextField,
  Divider,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stepper,
  Step,
  Typography,
  StepLabel,
  ListSubheader,
  List,
  ListItem,
  ListItemButton,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Collapse,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ConstructionIcon from "@mui/icons-material/Construction";
import BuildIcon from "@mui/icons-material/Build";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import EditIcon from "@mui/icons-material/Edit";
import ArticleIcon from "@mui/icons-material/Article";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LockClockIcon from '@mui/icons-material/LockClock';
import akash_image from "./images/akash.png";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import MailOutlineIcon from "@mui/icons-material/MailOutline";




const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#42a5f5 0%,#1976d2 50%,#1565c0 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#42a5f5 0%,#1976d2 50%,#1565c0 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 95deg,#42a5f5 0%,#1976d2 50%,#1565c0 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 95deg,#42a5f5 0%,#1976d2 50%,#1565c0 100%)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <PostAddIcon />,
    2: <LocalShippingIcon />,
    3: <BuildIcon />,
    4: <SettingsInputComponentIcon />,
    5: <CheckCircleIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  "Planning & grid permission",
  "Equipment delivery",
  "Installing",
  "System connection",
  "Completed",
];

export default function TrackingPage() {
  return (
    <div
      style={{
        width: "80%",
        maxWidth: "1200px",
        margin: "30px auto",
        textAlign: "left",
        // background:"#e5e5e5"
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <Stepper
          alternativeLabel
          activeStep={1}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={9}>
          <div
            style={{
              fontSize: "22px",
              // color: "#1976d2",
              marginTop: 50,
              fontWeight: "600",
              textTransform: "capitalize",
            }}
          >
            Project management portal
          </div>
          <div style={{marginTop:5}}>
          AVENUE NURSERIES GARDEN CENTRE LTD, AVENUE NURSERIES GARDEN CENTRE, THE AVENUE, LASHAM, GU34 5SU
          </div>

          {/* ### letter of authority */}
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={1} style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <CheckBoxIcon style={{ fill: "#2e7d32" }} />
              </div>
            </Grid>
            <Grid item xs={11}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{fontWeight:"600"}}>Letter of Authority</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <IconButton>
                          <ArticleIcon />
                        </IconButton>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Letter of authority (LOA) submission"
                        secondary={"Received on 01 March 2023"}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* ### grid permission */}
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={1} style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <LockClockIcon style={{ fill: "#ff9800" }} />
              </div>
            </Grid>
            <Grid item xs={11}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{fontWeight:"600"}}>Grid permission application, G99</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="String diagram"
                        secondary={"Completed on 01 March 2023"}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Power Generating Facility (generator) general data"
                        secondary={"Completed on 01 March 2023"}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Equipment choice & details"
                        secondary={"Completed on 01 March 2023"}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Online application form"
                        secondary={"Completed on 01 March 2023"}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                          <IconButton>
                            <LockClockIcon />
                          </IconButton>
                      </ListItemAvatar>
                      <ListItemText
                        primary="G99 approval"
                        secondary={"Received on 12 March 2023"}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* ### structural survey */}
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={1} style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <ReportProblemIcon style={{ fill: "#d32f2f" }} />
              </div>
            </Grid>
            <Grid item xs={11}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{fontWeight:"600"}}>Structural survey</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                          <IconButton>
                            <ReportProblemIcon />
                          </IconButton>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Structural survey"
                        secondary={"Completed on 01 March 2023"}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* ### Equipment delivery */}
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={1} style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <CheckBoxIcon style={{ fill: "#2e7d32" }} />
              </div>
            </Grid>
            <Grid item xs={11}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{fontWeight:"600"}}>Equipment delivery</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Solar panels"
                        secondary={"Delivered on 01 March 2023"}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Mounting kit"
                        secondary={"Completed on 01 March 2023"}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Wiring and connectors"
                        secondary={"Completed on 01 March 2023"}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Inverter"
                        secondary={"Completed on 01 March 2023"}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Accessories"
                        secondary={"Received on 12 March 2023"}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          
          {/* ### Risk Assessment Method Statement  */}
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={1} style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <CheckBoxIcon style={{ fill: "#2e7d32" }} />
              </div>
            </Grid>
            <Grid item xs={11}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{fontWeight:"600"}}>Risk Assessment Method Statement (RAMS)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <IconButton>
                          <ArticleIcon />
                        </IconButton>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Main RAMS"
                        secondary={"Delivered on 01 March 2023"}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          
          {/* ### Pre-installation planning */}
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={1} style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                {/* <TaskAltIcon fontSize="large" style={{ fill: "#1565c0" }} /> */}
              </div>
            </Grid>
            <Grid item xs={11}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{fontWeight:"600"}}>Pre-installation planning </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Scaffolding"
                        secondary={"To be delivered on 01 April 2023"}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="On-site storage"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Project managor & point of contact"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Skip"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Parking spaces"
                        secondary={""}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          
          {/* ### DC work */}
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={1} style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                {/* <TaskAltIcon fontSize="large" style={{ fill: "#1565c0" }} /> */}
              </div>
            </Grid>
            <Grid item xs={11}>
              <Accordion disabled >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{fontWeight:"600"}}>DC works</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="String diagram"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Mounting system installation"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Panel installation"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary=""
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary=""
                        secondary={""}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* ### AC work */}
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={1} style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                {/* <TaskAltIcon fontSize="large" style={{ fill: "#1565c0" }} /> */}
              </div>
            </Grid>
            <Grid item xs={11}>
              <Accordion disabled>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{fontWeight:"600"}}>AC and system connection</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Panel connection inspection"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Inverter monting"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Inverter connection"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Shutdown"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Commissioning"
                        secondary={""}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* ### Commissioning and Data */}
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={1} style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                {/* <TaskAltIcon fontSize="large" style={{ fill: "#1565c0" }} /> */}
              </div>
            </Grid>
            <Grid item xs={11}>
              <Accordion disabled>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{fontWeight:"600"}}>Commissioning and data connection</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="System inspection"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Inverter data connection"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Dashboard setup"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Witness test"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary=""
                        secondary={""}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* ### Handover */}
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={1} style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                {/* <TaskAltIcon fontSize="large" style={{ fill: "#1565c0" }} /> */}
              </div>
            </Grid>
            <Grid item xs={11}>
              <Accordion disabled>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{fontWeight:"600"}}>Handover procedure</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="Handover document"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="EIC certificate"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary="MCS certification"
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary=""
                        secondary={""}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end">
                          <EditIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary=""
                        secondary={""}
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>



        </Grid>
        <Grid item xs={3}>
          <div
            style={{
              background: "#e8e8e8",
              // borderLeft:"1px solid lightgrey",
              minHeight: "100vh",
              height: "calc(100% - 60px)",
              padding: "20px 30px",
            }}
          >
            <div style={{ textAlign: "left", lineHeight: "1.5",  fontSize:"16px", fontFamily:"sans-serif" }}>
              Contact project manager
            </div>
            <Grid
              container
              spacing={1}
              style={{
                margin: "5px auto 30px auto",
                width:"100%", 
                overflowWrap:"break-word",
                color: "#1565c0",
                fontWeight: "500",
              }}
            >
              <Grid item xs={2} style={{ marginTop: 20, paddingLeft:0 }}>
                <LocalPhoneIcon style={{ fontSize: "28px" }} />
              </Grid>
              <Grid
                item
                xs={10}
                style={{ fontSize: "16px", marginTop: 22, textAlign: "left" , width:"100%"}}
              >
                02382 680106
              </Grid>
              <Grid item xs={2} style={{ marginTop: 10, paddingLeft:0 }}>
                <MailOutlineIcon style={{ fontSize: "28px" }} />
              </Grid>
              <Grid
                item
                xs={10}
                style={{ fontSize: "16px", marginTop: 12, textAlign: "left" , width:"100%", overflowWrap:"break-word"}}
              >
                survey@absolar.co.uk
              </Grid>
            </Grid>
            
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <img src={akash_image} style={{ width: "100px" }} />
            </div>

            <Button
              startIcon={<LocalPhoneIcon />}
              size="large"
              fullWidth
              variant="contained"
              style={{ marginTop: "30px" , justifyContent:"space-between" , textTransform:"none" }}
              // onClick={handleOpen_virtual_meeting}
            >
              Schedule call back
            </Button>
            <Button
              startIcon={<ContentPasteIcon />}
              size="large"
              fullWidth
              variant="contained"
              style={{ marginTop: "20px" , justifyContent:"space-between" , textTransform:"none" }}
              // onClick={handleOpen_site_survey}
            >
              Book a meeting
            </Button>
            
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
