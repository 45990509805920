import React, { useState, useEffect } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Button,
  TextField,
  Tooltip,
  IconButton,
  InputAdornment,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Eclipse_logo from "./../images/eclipse.png";
import Ecogen_logo from "./../images/ecogen.png";
import Infinity_logo from "./../images/infinity.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

const installers = [
    {
      name: "Eclipse Electrical Services",
      description:
        "Based in Alresford, Hampshire, Eclipse serve a large range of customers in Industrial, Commercial and Domestic premises across the UK.",
      location: "Alresford",
      phone: "01962 732221",
      email: "matt@et-systems.co.uk",
      website: "http://www.eclipse-electrical-services.co.uk/",
      logo: require("./../images/eclipse.png"),
      cert1: "MCS",
      cert2: "NICEIC",
      cert3: "SSIP",
      cert4: null,
    },
    {
      name: "Ecogen Energy",
      description:
        "Ecogen Energy specialises in solar PV, battery storage, EV charging and solar carports for commercial, residential, and community projects.",
      location: "Southampton",
      phone: "0333 772 11 22",
      email: "info@ecogen.energy",
      website: "https://ecogen.energy/",
      logo: require("./../images/ecogen.png"),
      cert1: "MCS",
      cert2: "SSIP",
      cert3: "RECC",
      cert4: "NAPIT",
    },
    {
      name: "Infinity Energy",
      description:
        "Infinity is a unique and intelligent approach from industry experts. Their qualified team of designers, engineers and installers have unrivalled knowledge of the products and systems we specify.",
      location: "Swanwick",
      phone: "01489 533500",
      email: "info@infinity-energy.co.uk",
      website: "https://www.infinity-energy.co.uk/",
      logo: require("./../images/infinity.png"),
      cert1: "MCS",
      cert2: "NICEIC",
      cert3: "RECC",
      cert4: "NAPIT",
    },
  ];
  

export default function ModalApprovedInstallerDummy() {
  return (
    <div
      style={{
        width: "80%",
        maxWidth: "1200px",
        margin: "30px auto",
        textAlign: "left",
        // background:"#e5e5e5"
      }}
    >
      <div className="modal_container_full">
        {/* <div className="modal_close_button">
          <IconButton size="large">
            <HighlightOffIcon />
          </IconButton>
        </div> */}
        <Typography variant="h4">Approved installers</Typography>
        <p>
          The following installers have been selected based on your location and
          the property characteristics:
        </p>
        <Grid container spacing={3} style={{ marginTop: 50 }}>
          {installers.map((installer) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={installer.name}>
              <Card
                style={{
                  textAlign: "left",
                  boxShadow: "3px 3px 6px grey",
                  height: "100%",
                }}
              >
                <CardMedia
                  sx={{
                    height: 140,
                    backgroundColor: "#e5e5e5",
                    backgroundSize: "contain",
                  }}
                  image={installer.logo}
                  title="Infinity_logo"
                />
                <CardContent
                  style={{
                    paddingBottom: 0,
                    height: "calc(100% - 140px - 65px)",
                  }}
                >
                  {/* <Typography>
                    {installer.certificates?.map((certificateName) => {
                      return (
                        <a
                          style={{
                            background: "#ffb74d",
                            fontWeight: "500",
                            fontSize: "10px",
                            padding: "3px 12px",
                            borderRadius: 12,
                            marginRight: 5,
                          }}
                        >
                          {certificateName}
                        </a>
                      );
                    })}
                  </Typography> */}
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    style={{ marginTop: 10 }}
                  >
                    {installer.name}
                  </Typography>
                  <Tooltip title={<Typography variant="body2">{installer.description}</Typography>}>
                  <div className="approved_installer_desc">
                    <Typography variant="body2" color="text.secondary">
                      {installer.description}
                    </Typography>
                  </div>
                  </Tooltip>
                  <List dense>
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <LocationOnIcon />
                      </ListItemIcon>
                      <ListItemText primary={installer.location} />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <LocalPhoneIcon />
                      </ListItemIcon>
                      <ListItemText primary={installer.phone} />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <EmailIcon />
                      </ListItemIcon>
                      <ListItemText primary={installer.email} />
                    </ListItem>
                  </List>
                </CardContent>
                <CardActions>
                  <Button href={installer.website} target="_blank" size="small" >
                    website
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
