import React, { useState } from "react";

import { Typography } from "@mui/material";

export default function SupportPagePreviousProject() {
  return (
    <>
      <Typography
        variant="h6"
        style={{ marginTop: 30, borderBottom: "1px grey solid" }}
        gutterBottom
        paragraph
      >
        Professional project management
      </Typography>
      <Typography variant="body2" paragraph>
        Project development management plays a crucial role in ensuring the
        success of a solar installation project. By effectively managing the
        planning, organization, and execution of the project, it helps to ensure
        that the solar panels are installed efficiently and effectively. Absolar
        provides fully managed services for orgnaisations to receive efficient
        system installation services, mitigating risks, and maximising returns.
      </Typography>
      <Typography>
        <b>Key benefits</b>
      </Typography>
      <Typography variant="body2" paragraph>
        One of the main benefits of Absolar's project management services is
        that it helps to ensure that the installation is completed on time and
        within budget. This is particularly important given the rapidly growing
        demand for solar energy and the need to meet renewable energy targets.
        Project management can help to streamline workflows, allocate resources
        efficiently, and reduce waste, all of which can help to ensure that the
        installation is completed in a timely and cost-effective manner.
      </Typography>
      <Typography variant="body2" paragraph>
        Another benefit of Absolar's managed services is improved quality
        control. Solar panels must be installed to a high standard to ensure
        that they are safe, reliable, and efficient. Effective project
        management can help to ensure that quality standards are met throughout
        the installation process, from selecting the right materials and
        equipment to ensuring that the installation is carried out by qualified
        and experienced professionals.
      </Typography>
      <Typography variant="body2" paragraph>
        Absolar's project management can also help to identify potential risks
        and develop strategies to mitigate them. For example, it can help to
        identify potential issues with the site, such as shading or structural
        problems, and develop a plan to address them. This can help to reduce
        the likelihood of delays or complications during the installation
        process.
      </Typography>
      <Typography variant="body2" paragraph>
        Finally, Absolar helps foster clear communication and collaboration
        between stakeholders, including the solar installation team, the client,
        and any relevant regulatory bodies. This can help to ensure that
        everyone is on the same page, expectations are managed effectively, and
        any issues or concerns are addressed promptly and efficiently.
      </Typography>
      <Typography variant="body2" paragraph>
        Overall, effective project development management is essential for
        achieving successful and high-quality solar installation projects that
        deliver reliable and sustainable renewable energy solutions.
      </Typography>
    </>
  );
}
