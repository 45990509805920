import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import {
  Button,
  TextField,
  Typography,
  FormControl,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import { getData, postData, putData } from "../../get-data";
import { UserContext } from "../../contexts/UserContext";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { Theme, useTheme } from "@mui/material/styles";
import { getTodaysDate } from "../../App";
import { useNavigate } from "react-router-dom";

const Container = styled.div``;

const InfoWindowContainer = styled.div``;

const InfoWindowEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftText = styled.div`
  font-weight: bold;
`;

const RightText = styled.div``;

type CustomError = {
  message: string;
  // Add other error properties if needed
};

interface ReviewPageProps {
  reference: string | undefined;
  onNext: (data: String) => void;
  setDashboardDatas: (newdata: DashboardData[]) => void;
  dashboardDatas: DashboardData[];
  setOpenDialogueHandler: (newVal: string) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      overflowX: "scroll" as const,
    },
  },
};

function getStyles(id: number, installerName: number[], theme: Theme) {
  return {
    fontWeight:
      installerName.indexOf(id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ReviewPage: React.FC<ReviewPageProps> = ({
  reference,
  onNext,
  setDashboardDatas,
  dashboardDatas,
  setOpenDialogueHandler,
}) => {
  const userContext = useContext(UserContext);

  const theme = useTheme();
  let navigate = useNavigate();

  const [projectReference, setProjectReference] = useState<string>(
    reference ?? ""
  );
  const [projectData, setProjectData] = useState<any>({});
  const [installerName, setInstallerName] = React.useState<number[]>([]);
  const [installers, setInstallers] = useState<Installer[]>([]);
  const [dataCount, setDataCount] = useState<number>(0);

  const [uploadData, setUploadData] = useState<IngressDto>({
    reference: projectReference,
    assessment_date: getTodaysDate(),
    root_uprn: "",
    uprn_type: "",
    toid: "",
    class_code: "",
    class_desc: "",
    class_sub_desc: "",
    latitude: null,
    longitude: null,
    address: "",
    postcode: "",
    max_building_height: null,
    min_building_height: null,
    install_capacity_kw: null,
    expected_power_generation_kwh: null,
    potential_saving_year_1: 0,
    co2_saving_year_1: null,
    indicative_cost: null,
    total_utility_bill_savings_20: 0,
    roi: null,
    payback: "",
    monthly_repayment: 0,
    loan_total_utility_bill_savings_20: 0,
    loan_payback: "",
    loan_roi: 0,

    council_property: null,
    conservation_area: null,
    listed: null,
    organisation_id: 0,
    building_geom: "",
    suitable_roof_geoms: [],
    type_of_installation: "",
    self_consumption: 0,
    reference_panel: "PS410M6-18/VH",
    energy_price_inflation: 7,
    assum_carbon_factor: 0.253,
    assum_tariff: "32p import / 8p export",
    existing_solar: false,
    open_solar_link: "",

    comments: "",
    cta: 1,
  });

  // {onNext.toString}

  useEffect(() => {
    if (reference !== undefined) {
      setProjectReference(reference);
    }

    //fetchProjectsData();
    fetchInstallerData();
    fetchBuildingDataObject();
  }, []);

  async function fetchProjectsData() {
    try {
      console.log(
        "PRINT",
        "/CostingFormulae/getSpecificCalculationSheet?projectReference=" +
          projectReference
      );
      const url =
        process.env.REACT_APP_API_URL +
        "/CostingFormulae/getSpecificCalculationSheet?projectReference=" +
        projectReference;
      const response = await getData(url, true, userContext.getCredentials);

      if (response.ok) {
        const data = await response.json();
        console.log("Data:", data);

        setProjectData(data[0]);

        // Update the uploadData object with properties from projectData
        const updatedUploadData = {
          ...uploadData,
          cta: data[0].cta, // Update the cta property
          comments: data[0].comments, // Update the comments property
          panelReference: data[0].panelReference,
        };

        setUploadData(updatedUploadData); // Set the updated uploadData
      } else {
        console.log("Error:", response.status);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const handleApproveClick = () => {
    putData(
      process.env.REACT_APP_API_URL +
        "/ProjectDelivery/setProjectToRemoteSurvey?projectReference=" +
        reference,
      {},
      true,
      userContext.getCredentials
    )
      .then(() => {
        onNext("Approve Page");
      })
      .catch((e) => {
        console.error("Update failed:", e);
        alert("Update failed");
      });
  };
  async function fetchInstallerData() {
    getData(
      process.env.REACT_APP_API_URL + "/Installer",
      false,
      userContext.getCredentials
    ).then((installers: Installer[]) => {
      setInstallers(installers);
    });
  }

  async function fetchBuildingDataObject() {
    getData(
      process.env.REACT_APP_API_URL + "/DashboardData/" + reference,
      false,
      userContext.getCredentials
    ).then((data: IngressDto) => {
      setUploadData({
        ...data,
        assessment_date: data.assessment_date.split("/").reverse().join("-"),
      });
    });
  }

  const handleChange = (event: SelectChangeEvent<number[] | string>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === "string") {
      const ids = value.split(",").map((x) => parseInt(x));
      setInstallerName(ids);
    } else {
      setInstallerName(value);
    }
  };

  function setUploadDataProperty<K extends keyof IngressDto>(
    key: K,
    value: IngressDto[K]
  ): void {
    setUploadData((prevState) => ({ ...prevState, [key]: value }));
  }

  function sendDataToTable() {
    var allDashboardDatas = dashboardDatas;
    var objectToAdd: DashboardData = {
      reference: projectReference,
      assessment_date: uploadData.assessment_date,
      root_uprn: uploadData.root_uprn,
      uprn_type: uploadData.uprn_type,
      toid: uploadData.toid,
      class_code: uploadData.class_code,
      class_desc: uploadData.class_desc,
      class_sub_desc: uploadData.class_sub_desc,
      latitude: uploadData.latitude,
      longitude: uploadData.longitude,
      address: uploadData.address,
      postcode: uploadData.postcode,
      max_building_height: uploadData.max_building_height,
      min_building_height: uploadData.min_building_height,
      install_capacity_kw: uploadData.install_capacity_kw,
      expected_power_generation_kwh: uploadData.expected_power_generation_kwh,
      potential_saving_year_1: uploadData.potential_saving_year_1,
      co2_saving_year_1: uploadData.co2_saving_year_1,
      indicative_cost: uploadData.indicative_cost,
      total_utility_bill_savings_20: uploadData.total_utility_bill_savings_20,
      roi: uploadData.roi,
      payback: uploadData.payback,
      monthly_repayment: uploadData.monthly_repayment,
      loan_total_utility_bill_savings_20:
        uploadData.loan_total_utility_bill_savings_20,
      loan_payback: uploadData.loan_payback,
      loan_roi: uploadData.loan_roi,
      council_property: uploadData.council_property,
      conservation_area: uploadData.conservation_area,
      listed: uploadData.listed,
      organisation_id: uploadData.organisation_id,
      type_of_installation: uploadData.type_of_installation,
      self_consumption: uploadData.self_consumption,
      reference_panel: uploadData.reference_panel,
      energy_price_inflation: uploadData.energy_price_inflation,
      assum_carbon_factor: uploadData.assum_carbon_factor,
      assum_tariff: uploadData.assum_tariff,
      stage: null,
      saved: false,
      existing_solar: uploadData.existing_solar,
      open_solar_link: uploadData.open_solar_link,
      site_survey_date: "",
      project_stage: null,
      comments: uploadData.comments,
      cta: uploadData.cta,
    };

    var indexOfEditedProperty = -1;
    indexOfEditedProperty = allDashboardDatas.findIndex(
      (eachObj) => eachObj.reference === objectToAdd.reference
    );
    if (indexOfEditedProperty != -1) {
      // If the index is found, it means we are editing and not uploading a new one
      allDashboardDatas = allDashboardDatas.map((obj, i) => {
        if (i === indexOfEditedProperty) {
          return objectToAdd;
        } else {
          return obj;
        }
      });
    } else {
      allDashboardDatas.push(objectToAdd);
    }

    setDashboardDatas(allDashboardDatas);

    console.log("DashboardDatas:", dashboardDatas);
  }

  function getReferenceCount() {
    getData(
      process.env.REACT_APP_API_URL + "/Ingress/referenceCount",
      true,
      userContext.getCredentials
    )
      .then((response) => response.text())
      .then((dataCount: string) => {
        setDataCount(parseInt(dataCount));
      });
  }

  async function submitUploadData() {
    try {
      const response = await postData(
        process.env.REACT_APP_API_URL + "/ingress",
        uploadData,
        true,
        userContext.getCredentials
      );
      const result = await response.text();

      if (result == 0) {
        //alert("successfully uploaded");
        setOpenDialogueHandler("Successfully Uploaded.");
        await putData(
          process.env.REACT_APP_API_URL +
            "/Installer/building_data_installer?reference=" +
            reference,
          installerName,
          false,
          userContext.getCredentials
        );
        sendDataToTable();
        getReferenceCount();
        // navigate("/");
        handleApproveClick();
      }
    } catch (error: any) {
      alert(
        error.message +
          "we haven't loaded the reference properly and therefore can't submit, check the reference let chris know if this continues."
      );
    }
  }

  return (
    <div>
      <div>
        <Container>
          <Typography variant="h5">Review Page</Typography>

          <TextField
            disabled
            required
            fullWidth
            label="Project Reference"
            defaultValue={projectReference}
            style={{ marginTop: 20 }}
            // onChange={(event) =>
            //   setProjectReference(parseInt(event.target.value))
            // }
          />

          {Object.keys(projectData).map((key, index) => (
            <div key={index}>
              <strong>{key}:</strong> {projectData[key]}
            </div>
          ))}
        </Container>
      </div>
      <div
        style={{
          marginTop: 30,
          marginBottom: 30,
          boxShadow: "2px 2px 8px 1px grey",
          borderRadius: "6px",
          padding: 20,
        }}
      >
        <div
          style={{
            fontSize: "22px",
            fontWeight: "500",
            marginBottom: 20,
          }}
        >
          Selection of installers (optional)
        </div>
        <FormControl fullWidth sx={{ m: 1 }}>
          {/* <InputLabel id="demo-multiple-name-label">Name</InputLabel> */}
          <Select
            multiple
            value={installerName}
            onChange={handleChange}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
          >
            {installers.map((installer) => (
              <MenuItem
                key={installer.name}
                value={installer.id}
                style={getStyles(installer.id, installerName, theme)}
              >
                {installer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {/* <div
        style={{
          marginTop: 30,
          marginBottom: 30,
          boxShadow: "2px 2px 8px 1px grey",
          borderRadius: "6px",
          padding: 20,
        }}
      >
        <div
          style={{
            fontSize: "22px",
            fontWeight: "500",
            marginBottom: 20,
          }}
        >
          CTA category
        </div>
        <FormControl fullWidth sx={{ m: 1 }}>
          <Select
            value={uploadData.cta}
            type="number"
            onChange={(e) => {
              if (e.target.value) {
                setUploadDataProperty(
                  "cta",
                  parseInt(e.target.value.toString())
                );
              }
            }}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
          >
            <MenuItem value={1}>
              Suitable for Absolar turnkey installation
            </MenuItem>
            <MenuItem value={2}>Not suitable. Third-party installers</MenuItem>
          </Select>
        </FormControl>
      </div>

      <h3 style={{ marginTop: "20px" }}>Additional comments</h3>
      <TextField
        multiline
        rows={6}
        style={{ marginBottom: "10px", width: "100%" }}
        value={uploadData.comments}
        onChange={(e) => setUploadDataProperty("comments", e.target.value)}
      /> */}

      <Button
        variant="contained"
        fullWidth
        onClick={() => {
          handleApproveClick();
        }}
        style={{ marginTop: 10 }}
      >
        Approve
      </Button>

      <Button
        fullWidth
        variant="contained"
        style={{ textTransform: "none" }}
        // onClick={submitUploadData}
        onClick={() => {
          //add it to the table

          submitUploadData();
        }}
        //   disabled={
        //     uploadData.reference != "" &&
        //     uploadData.assessment_date != ""
        //     // &&
        //     // uploadData.address != "" &&
        //     // uploadData.postcode != "" &&
        //     // uploadData.payback != "" &&
        //     // !Number.isNaN(uploadData.monthly_repayment) &&
        //     // !Number.isNaN(
        //     //   uploadData.loan_total_utility_bill_savings_20
        //     // ) &&
        //     // uploadData.loan_payback != "" &&
        //     // !Number.isNaN(uploadData.loan_roi) &&
        //     // !Number.isNaN(uploadData.organisation_id) &&
        //     // uploadData.type_of_installation != "" &&
        //     // !Number.isNaN(uploadData.self_consumption) &&
        //     // uploadData.reference_panel != "" &&
        //     // !Number.isNaN(uploadData.energy_price_inflation) &&
        //     // !Number.isNaN(uploadData.assum_carbon_factor) &&
        //     // uploadData.assum_tariff != ""
        //       ? false
        //       : true
        //   }
      >
        Submit
      </Button>
    </div>
  );
};

export default ReviewPage;
