import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import {
  Button,
  Checkbox,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DrawIcon from "@mui/icons-material/Draw";
import { saveAs } from "file-saver";
import CachedIcon from "@mui/icons-material/Cached";
import { Slider } from "@mui/material";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import "./CanvasWithEditor.css";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import LabelIcon from "@mui/icons-material/Label";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import CropIcon from "@mui/icons-material/Crop";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";

interface IProps {
  image: string;
  polygonData: Shape[];
  setPolygonData: (newVal: Shape[]) => void;
  tileData: Shape[];
  setTileData: (newVal: Shape[]) => void;
  zoomLevel: number;
  canvasImageURL: string;
  setCanvasImageURL: (newVal: string) => void;
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
  convertedImage: boolean;
  setConvertedImage: (newVal: boolean) => void;
  setpreparedToProceed: (newVal: boolean) => void;
  preparedToProceed: boolean;
  createNewProject: (newVal: string) => void;
  onNext: (newVal: string) => void;
  isLargeSatelliteImage: boolean;
  zoomStatelliteImage: () => void;
  jumpCalculations: () => void;
}

const CanvasWithEditor = (props: IProps) => {
  const [loadedImage, setLoadedImage] = useState<HTMLImageElement | null>(null);
  const [canvasOption, setCanvasOption] = useState("");
  const [selectedPolygonIndex, setSelectedPolygonIndex] = useState<
    number | null
  >(null);
  const [hoveredPolygonIndex, setHoveredPolygonIndex] = useState<number | null>(
    null
  );
  const [selectedZoomOptions, setSelectedZoomOptions] =
    useState<boolean>(false);

  const [showDots, setShowDots] = useState<boolean>(true);

  //loading the static image
  useEffect(() => {
    console.log("1");
    if (!props.canvasRef.current) return;

    const canvas = props.canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      console.error("2D context not supported.");
      return;
    }

    // Load the image
    const image = new Image();
    image.src = loadedImage ? loadedImage.src : props.image; // Use a test image URL
    image.onload = () => {
      // Set the canvas dimensions to match the image
      canvas.width = image.width;
      canvas.height = image.height;

      // Store the loaded image in state
      if (!loadedImage) setLoadedImage(image);

      // Draw the polygon on top of the image
      drawPolygon(ctx, props.polygonData);
    };
  }, [loadedImage]);

  // Helper function to draw the polygon
  const drawPolygon = (
    ctx: CanvasRenderingContext2D,
    polygons: Shape[],
    image?: string
  ) => {
    if (!loadedImage) return;

    ctx.clearRect(
      0,
      0,
      props.canvasRef.current!.width,
      props.canvasRef.current!.height
    );
    ctx.save();

    // Apply transformations for the image only
    ctx.translate(translate.x, translate.y);
    ctx.scale(scale, scale);

    // Draw the image
    ctx.drawImage(loadedImage, 0, 0, loadedImage.width, loadedImage.height);

    // Restore the context state to remove transformations
    ctx.restore();

    for (let i = 0; i < polygons.length; i++) {
      const vertices = polygons[i].points;
      if (vertices.length < 2) continue; // Do not draw the polygon if it has less than 2 points

      // Draw the points as small dots
      ctx.fillStyle = showDots ? "blue" : "rgba(0,0,0,0)"; // Set the dot color to blue
      for (let j = 0; j < vertices.length; j++) {
        const point = vertices[j];
        if (point.x !== undefined && point.y !== undefined) {
          ctx.beginPath();
          ctx.arc(point.x, point.y, 5, 0, 2 * Math.PI);
          ctx.fill();
        }
      }

      ctx.beginPath();

      // Set the fill color based on the label

      ctx.moveTo(
        vertices[vertices.length - 1].x,
        vertices[vertices.length - 1].y
      );

      for (let j = 0; j < vertices.length; j++) {
        const currentVertex = vertices[j];
        const nextVertex = vertices[(j + 1) % vertices.length];

        ctx.lineTo(currentVertex.x, currentVertex.y);
        ctx.lineTo(nextVertex.x, nextVertex.y);

        // ctx.closePath();
        // ctx.fill(); // Fill the entire polygon

        // Highlight the hovered side in yellow
        if (
          hoveredSide &&
          hoveredSide.polygonIndex === i &&
          hoveredSide.vertexIndex === j
        ) {
          ctx.strokeStyle = "green";
        } else {
          ctx.strokeStyle = polygons[i].label == "sra" ? "yellow" : "red";
        }

        ctx.lineWidth = 2;
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(nextVertex.x, nextVertex.y);
      }
      ctx.closePath();
      //ctx.strokeStyle = 'red'; // Set the stroke color to red
      //ctx.lineWidth = 2; // Set the line width
      ctx.stroke();
    }

    // Highlight the hovered polygon in red

    if (hoveredPolygonIndex !== null) {
      const hoveredVertices = polygons[hoveredPolygonIndex];
      try {
        if (hoveredVertices.points.length >= 2) {
          ctx.beginPath();
          ctx.moveTo(hoveredVertices.points[0].x, hoveredVertices.points[0].y);
          for (let i = 1; i < hoveredVertices.points.length; i++) {
            ctx.lineTo(
              hoveredVertices.points[i].x,
              hoveredVertices.points[i].y
            );
          }
          ctx.closePath();
          ctx.fillStyle =
            hoveredVertices.label == "sra"
              ? "rgba(255, 255, 0, 0.3)"
              : "rgba(255, 0, 0, 0.3)"; // Set the fill color to red or yellow depending on SRA
          ctx.fill(); // Fill the polygon with red color
          ctx.strokeStyle = hoveredVertices.label == "sra" ? "yellow" : "red"; // Set the stroke color to red
          ctx.lineWidth = 2; // Set the line width
          ctx.stroke();
        }
      } catch (e) { }
    }

    if (selectedLabelingPolygons.length != 0) {
      for (let i = 0; i < polygons.length; i++) {
        // Highlight the selected polygons in green
        if (selectedLabelingPolygons.includes(i)) {
          const verticesSelected = polygons[i].points;
          try {
            if (verticesSelected.length >= 2) {
              ctx.beginPath();
              ctx.moveTo(verticesSelected[0].x, verticesSelected[0].y);
              for (let i = 1; i < verticesSelected.length; i++) {
                ctx.lineTo(verticesSelected[i].x, verticesSelected[i].y);
              }
              ctx.closePath();
              ctx.fillStyle =
                polygons[i].label == "sra"
                  ? "rgba(255, 255, 0, 0.3)"
                  : "rgba(255, 0, 0, 0.3)"; // Set the fill color to red or yellow depending on SRA
              ctx.fill(); // Fill the polygon with red color
              ctx.strokeStyle = polygons[i].label == "sra" ? "yellow" : "red"; // Set the stroke color to red
              ctx.lineWidth = 2; // Set the line width
              ctx.stroke();
            }
          } catch (e) { }
        }
      }
    }

    for (let i = 0; i < polygons.length; i++) {
      const verticesSelected = polygons[i].points;
      try {
        if (verticesSelected.length >= 2) {
          ctx.beginPath();
          ctx.moveTo(verticesSelected[0].x, verticesSelected[0].y);
          for (let i = 1; i < verticesSelected.length; i++) {
            ctx.lineTo(verticesSelected[i].x, verticesSelected[i].y);
          }
          ctx.closePath();
          ctx.fillStyle =
            polygons[i].label == "sra"
              ? "rgba(255, 255, 0, 0.3)"
              : "rgba(0,0,0,0)"; // Set the fill color to yellow depending if SRA
          ctx.fill(); // Fill the polygon with red color
          ctx.strokeStyle = polygons[i].label == "sra" ? "yellow" : "red"; // Set the stroke color to red
          ctx.lineWidth = 2; // Set the line width
          ctx.stroke();
        }
      } catch (e) { }
    }

    //Add tiles
    for (const vertices of props.tileData) {
      if (vertices.points.length !== 4) continue; // Skip if not a valid rectangle with 4 points

      // Draw the rectangle
      ctx.beginPath();
      ctx.moveTo(vertices.points[0].x, vertices.points[0].y);
      for (let i = 1; i < vertices.points.length; i++) {
        ctx.lineTo(vertices.points[i].x, vertices.points[i].y);
      }
      ctx.closePath();
      ctx.fillStyle = "rgba(0, 0, 255, 0.3)"; // Set the fill color to blue with transparency
      ctx.fill(); // Fill the rectangle with blue color
    }

    //if it's been converted to an image, this part of the code gets triggered:
    if (props.convertedImage) {
      //just save the canvas as normal
      if (!props.canvasRef.current) return;
      const canvas = props.canvasRef.current;
      //reset canvas option
      setCanvasOption("");
      // Get the canvas data as a data URL (PNG format)
      const canvasDataUrl = canvas.toDataURL("image/png");
      console.log(canvasDataUrl);
      props.setCanvasImageURL(canvasDataUrl);
      if (props.preparedToProceed) {
        //if the user proceeds without converting, then we move to the next step
        props.createNewProject(canvasDataUrl);
       
        props.jumpCalculations();
      }
    }
  };

  // Event handlers
  const [draggingState, setDraggingState] = useState<{
    polygonIndex: number | null;
    vertexIndex: number | null;
  }>({ polygonIndex: null, vertexIndex: null });

  /*
   *
   *EDIT HANDLERS
   * */
  const [rightClickMenuVisible, setRightClickMenuVisible] = useState(false);
  const [rightClickMenuPosition, setRightClickMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [rightClickVertexIndex, setRightClickVertexIndex] = useState<
    number | null
  >(null);
  const [rightClickPolygonIndex, setRightClickPolygonIndex] = useState<
    number | null
  >(null);
  const [hoveredSide, setHoveredSide] = useState<any>(null);

  const handleMouseDownEdit = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = props.canvasRef.current;
    if (!canvas) return;

    const mouseX = event.nativeEvent.offsetX;
    const mouseY = event.nativeEvent.offsetY;
    // Check if any vertex is clicked in any polygon
    for (let i = 0; i < props.polygonData.length; i++) {
      const vertices = props.polygonData[i];
      for (let j = 0; j < vertices.points.length; j++) {
        const vertex = vertices.points[j];
        const distance = Math.sqrt(
          (vertex.x - mouseX) ** 2 + (vertex.y - mouseY) ** 2
        );
        if (distance < 5) {
          setDraggingState({ polygonIndex: i, vertexIndex: j });
          break;
        }
      }
    }
  };
  function isPointOnLine(x: number, y: number, start: Point, end: Point) {
    // Apply the reverse transformation to the point (x, y)
    const transformedX = (x - translate.x) / zoomFactor;
    const transformedY = (y - translate.y) / zoomFactor;

    const lineLength = Math.sqrt(
      (start.x - end.x) ** 2 + (start.y - end.y) ** 2
    );

    // Calculate the distance from the point to both line endpoints
    const distanceToStart = Math.sqrt(
      (transformedX - start.x) ** 2 + (transformedY - start.y) ** 2
    );
    const distanceToEnd = Math.sqrt(
      (transformedX - end.x) ** 2 + (transformedY - end.y) ** 2
    );

    // Calculate the distance along the line from start to the projected point
    const projectionDistance =
      ((transformedX - start.x) * (end.x - start.x) +
        (transformedY - start.y) * (end.y - start.y)) /
      lineLength;

    // Check if the projected point is within the line segment and close enough to the actual point
    return (
      projectionDistance >= 0 &&
      projectionDistance <= lineLength &&
      Math.abs(distanceToStart + distanceToEnd - lineLength) < 0.1
    ); // Adjust the threshold as needed
  }

  function distance(x1: number, y1: number, x2: number, y2: number) {
    return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
  }

  const handleMouseMoveEdit = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (
      draggingState.polygonIndex !== null &&
      draggingState.vertexIndex !== null
    ) {
      const newPolygonData = [...props.polygonData];
      newPolygonData[draggingState.polygonIndex].points[
        draggingState.vertexIndex
      ] = {
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY,
      };
      props.setPolygonData(newPolygonData);
      drawPolygon(props.canvasRef.current!.getContext("2d")!, newPolygonData);
    } else {
      //hovering over polygon side

      const canvas = props.canvasRef.current;
      if (!canvas) return;
      const mouseX = event.nativeEvent.offsetX;
      const mouseY = event.nativeEvent.offsetY;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      let hoveredSideInfo = null;

      for (let i = 0; i < props.polygonData.length; i++) {
        const vertices = props.polygonData[i];
        if (vertices.points.length < 2) continue;

        ctx.beginPath();
        ctx.moveTo(
          vertices.points[vertices.points.length - 1].x,
          vertices.points[vertices.points.length - 1].y
        );

        for (let j = 0; j < vertices.points.length; j++) {
          const currentVertex = vertices.points[j];
          const nextVertex = vertices.points[(j + 1) % vertices.points.length];
          if (isPointOnLine(mouseX, mouseY, currentVertex, nextVertex)) {
            hoveredSideInfo = { polygonIndex: i, vertexIndex: j };
            break;
          }
        }

        setHoveredSide(hoveredSideInfo);
      }
    }
  };
  const handleMouseUpEdit = () => {
    setDraggingState({ polygonIndex: null, vertexIndex: null });
  };
  const handleRightClickEdit = (event: React.MouseEvent<HTMLCanvasElement>) => {
    event.preventDefault();

    const mouseX = event.nativeEvent.offsetX;
    const mouseY = event.nativeEvent.offsetY;

    // Check if any vertex is right-clicked
    for (let i = 0; i < props.polygonData.length; i++) {
      const vertices = props.polygonData[i];
      for (let j = 0; j < vertices.points.length; j++) {
        const vertex = vertices.points[j];
        const distance = Math.sqrt(
          (vertex.x - mouseX) ** 2 + (vertex.y - mouseY) ** 2
        );
        if (distance < 5) {
          setRightClickPolygonIndex(i);
          setRightClickVertexIndex(j);
          setRightClickMenuPosition({ x: mouseX, y: mouseY });
          setRightClickMenuVisible(true);
          return;
        }
      }
    }
  };
  const handleLeftClickEdit = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (rightClickMenuVisible) {
      setRightClickMenuVisible(false);
    } else if (hoveredSide) {
      const newPoint = {
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY,
      };
      const newPolygonData = [...props.polygonData];
      newPolygonData[hoveredSide.polygonIndex].points.splice(
        hoveredSide.vertexIndex + 1,
        0,
        newPoint
      );
      props.setPolygonData(newPolygonData);
      drawPolygon(props.canvasRef.current!.getContext("2d")!, newPolygonData);
    }
  };

  const handleRemovePoint = () => {
    if (rightClickPolygonIndex !== null && rightClickVertexIndex !== null) {
      const newPolygonData = [...props.polygonData];
      newPolygonData[rightClickPolygonIndex].points.splice(
        rightClickVertexIndex,
        1
      );
      props.setPolygonData(newPolygonData);
      setRightClickMenuVisible(false);
      drawPolygon(props.canvasRef.current!.getContext("2d")!, newPolygonData);
    }
  };

  /*
   *
   *DRAWING HANDLERS
   * */
  const [isDrawingPolygon, setIsDrawingPolygon] = useState(false);

  const handleCanvasClickDraw = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ) => {
    if (!loadedImage) return;
    const canvas = props.canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const clickX = (event.clientX - rect.left) * scaleX;
    const clickY = (event.clientY - rect.top) * scaleY;
    if (isDrawingPolygon) {
      // If you are drawing a polygon, add a new point to the current polygon being drawn
      var updatedPolygons = [...props.polygonData];
      updatedPolygons[updatedPolygons.length - 1].points = [
        ...updatedPolygons[updatedPolygons.length - 1].points,
        { x: clickX, y: clickY },
      ];

      props.setPolygonData(updatedPolygons);

      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.drawImage(loadedImage, 0, 0, canvas.width, canvas.height);

        drawPolygon(ctx, updatedPolygons);

        ctx.fillStyle = showDots ? "blue" : "rgba(0,0,0,0)"; // Set the dot color to blue
        ctx.beginPath();
        ctx.arc(clickX, clickY, 5, 0, 2 * Math.PI);
        ctx.fill();
      }
    } else {
      // If you are not drawing a polygon, start a new polygon

      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.drawImage(loadedImage, 0, 0, canvas.width, canvas.height);

        drawPolygon(ctx, [
          ...props.polygonData,
          {
            label: drawSRA ? "sra" : "",
            points: [{ x: clickX, y: clickY }],
            shape_type: "polygon",
          },
        ]);

        ctx.fillStyle = showDots ? "blue" : "rgba(0,0,0,0)"; // Set the dot color to blue
        ctx.beginPath();
        ctx.arc(clickX, clickY, 5, 0, 2 * Math.PI);
        ctx.fill();
      }
      props.setPolygonData([
        ...props.polygonData,
        {
          label: drawSRA ? "sra" : "",
          points: [{ x: clickX, y: clickY }],
          shape_type: "polygon",
        },
      ]);
      setIsDrawingPolygon(true);
    }
  };
  const handleRightClickDraw = (event: React.MouseEvent<HTMLCanvasElement>) => {
    event.preventDefault();

    setIsDrawingPolygon(false);
  };

  const handleDrawing = () => {
    if (canvasOption == "draw") {
      setIsDrawingPolygon(false);
      setCanvasOption("");
    } else {
      setCanvasOption("draw");
    }
  };

  const [drawSRA, setDrawSRA] = useState(true);

  /*
   *
   *MOVE HANDLERS
   * */

  const [dragging, setDragging] = useState<boolean>(false);
  const [draggingOffset, setDraggingOffset] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [draggingPolygonIndex, setDraggingPolygonIndex] = useState<
    number | null
  >(null);

  const pointInPolygon = (point: Point, polygon: Point[]) => {
    let isInside = false;
    const x = point.x;
    const y = point.y;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i].x;
      const yi = polygon[i].y;
      const xj = polygon[j].x;
      const yj = polygon[j].y;

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) {
        isInside = !isInside;
      }
    }

    return isInside;
  };

  const handleMouseDownMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!dragging) setDragging(true);

    const mouseX = event.nativeEvent.offsetX;
    const mouseY = event.nativeEvent.offsetY;

    // Check if any polygon is clicked
    for (let i = 0; i < props.polygonData.length; i++) {
      const vertices = props.polygonData[i];
      if (pointInPolygon({ x: mouseX, y: mouseY }, vertices.points)) {
        setDragging(true);
        setDraggingPolygonIndex(i);
        setDraggingOffset({
          x: mouseX - vertices.points[0].x,
          y: mouseY - vertices.points[0].y,
        });
        break;
      }
    }
  };
  const handleMouseMoveMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!loadedImage) return;

    if (dragging && draggingPolygonIndex !== null) {
      const mouseX = event.nativeEvent.offsetX;
      const mouseY = event.nativeEvent.offsetY;
      const newOffset = {
        x: mouseX - props.polygonData[draggingPolygonIndex].points[0].x,
        y: mouseY - props.polygonData[draggingPolygonIndex].points[0].y,
      };

      // Update the position of the vertices for the specific polygon based on the new offset
      const newPolygonData = props.polygonData.map((vertices, index) => {
        if (index === draggingPolygonIndex) {
          return {
            label: vertices.label,
            points: vertices.points.map((vertex) => ({
              x: vertex.x + newOffset.x - draggingOffset.x,
              y: vertex.y + newOffset.y - draggingOffset.y,
            })),
            shape_type: "polygon",
          };
        } else {
          return vertices;
        }
      });

      // Redraw the canvas with the updated polygons
      const ctx = props.canvasRef.current!.getContext("2d");
      if (ctx) {
        ctx.clearRect(
          0,
          0,
          props.canvasRef.current!.width,
          props.canvasRef.current!.height
        );
        ctx.drawImage(
          loadedImage,
          0,
          0,
          props.canvasRef.current!.width,
          props.canvasRef.current!.height
        );

        drawPolygon(ctx, newPolygonData);
      }

      // Update the polygon data and dragging offset
      props.setPolygonData(newPolygonData);
    }

    handleMouseHoverMove(event);
  };

  const handleMouseUpMove = () => {
    if (dragging) {
      setDragging(false);
      setDraggingPolygonIndex(null);
    }
  };

  //move hovering
  const handleMouseHoverMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = props.canvasRef.current;
    if (!canvas) return;
    const mouseX = event.nativeEvent.offsetX;
    const mouseY = event.nativeEvent.offsetY;

    // Check if the mouse is over any polygon
    for (let i = 0; i < props.polygonData.length; i++) {
      const vertices = props.polygonData[i];
      if (vertices.points.length < 2) continue;

      // Create a Path2D object to represent the polygon path
      const path = new Path2D();
      path.moveTo(vertices.points[0].x, vertices.points[0].y);
      for (let j = 1; j < vertices.points.length; j++) {
        path.lineTo(vertices.points[j].x, vertices.points[j].y);
      }
      path.closePath();

      const ctx = canvas.getContext("2d");
      // Check if the mouse is inside the polygon
      if (ctx && ctx.isPointInPath(path, mouseX, mouseY)) {
        setHoveredPolygonIndex(i);
        return;
      }
    }

    // If the mouse is not over any polygon, reset the hovered polygon index
    setHoveredPolygonIndex(null);
  };

  /*
   *
   *ROTATION HANDLERS
   * */
  const [rotationAngle, setRotationAngle] = useState<number>(0);
  const [isRotating, setIsRotating] = useState(false);
  // Helper function to check if a point is inside a polygon
  const isPointInPolygon = (point: Point, vertices: Point[]): boolean => {
    let inside = false;
    const x = point.x;
    const y = point.y;

    for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
      const xi = vertices[i].x;
      const yi = vertices[i].y;
      const xj = vertices[j].x;
      const yj = vertices[j].y;

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

      if (intersect) inside = !inside;
    }

    return inside;
  };
  const handleCanvasClickRotate = (
    event: React.MouseEvent<HTMLCanvasElement>
  ) => {
    const mouseX = event.nativeEvent.offsetX;
    const mouseY = event.nativeEvent.offsetY;
    // Check if any polygon is clicked
    for (let i = 0; i < props.polygonData.length; i++) {
      const vertices = props.polygonData[i];
      // Check if the click is inside the polygon using the point-in-polygon algorithm
      if (isPointInPolygon({ x: mouseX, y: mouseY }, vertices.points)) {
        setSelectedPolygonIndex(i);

        // Also hightlight the polygon
        const ctx = props.canvasRef.current?.getContext("2d");
        if (!ctx || !loadedImage) return;
        drawPolygonRotation(ctx, props.polygonData, rotationAngle);
        break;
      }
    }
  };
  const handleRotation = (event: Event, newValue: number | number[]) => {
    setIsRotating(true);
    setRotationAngle(newValue as number);
  };
  const drawPolygonRotation = (
    ctx: CanvasRenderingContext2D,
    polygons: Shape[],
    rotationAngle: number
  ) => {
    if (!loadedImage) return;
    ctx.clearRect(
      0,
      0,
      props.canvasRef.current!.width,
      props.canvasRef.current!.height
    );
    ctx.save();

    // Apply transformations for the image only
    ctx.translate(translate.x, translate.y);
    ctx.scale(scale, scale);

    // Draw the image
    ctx.drawImage(loadedImage, 0, 0, loadedImage.width, loadedImage.height);

    // Restore the context state to remove transformations
    ctx.restore();

    for (let i = 0; i < polygons.length; i++) {
      const vertices = polygons[i];
      if (vertices.points.length < 2) continue; // Skip empty or single-point polygons

      // Calculate the center of the polygon
      let centerX = 0;
      let centerY = 0;
      for (let j = 0; j < vertices.points.length; j++) {
        centerX += vertices.points[j].x;
        centerY += vertices.points[j].y;
      }
      centerX /= vertices.points.length;
      centerY /= vertices.points.length;

      var rotatedVertices;
      if (isRotating) {
        // Apply rotation transformation to the polygon vertices only for the selected polygon
        rotatedVertices =
          i === selectedPolygonIndex
            ? {
              label: vertices.label,
              points: vertices.points.map((vertex) => {
                const angleRadians = (rotationAngle * Math.PI) / 180;
                const cosTheta = Math.cos(angleRadians);
                const sinTheta = Math.sin(angleRadians);
                const x =
                  cosTheta * (vertex.x - centerX) -
                  sinTheta * (vertex.y - centerY) +
                  centerX;
                const y =
                  sinTheta * (vertex.x - centerX) +
                  cosTheta * (vertex.y - centerY) +
                  centerY;
                return { x, y };
              }),
              shape_type: "polygon",
            }
            : vertices;
      } else {
        rotatedVertices = vertices;
      }

      // Draw the points as small dots
      ctx.fillStyle = showDots ? "blue" : "rgba(0,0,0,0)"; // Set the dot color to blue
      for (let j = 0; j < rotatedVertices.points.length; j++) {
        const point = rotatedVertices.points[j];
        if (point.x !== undefined && point.y !== undefined) {
          ctx.beginPath();
          ctx.arc(point.x, point.y, 5, 0, 2 * Math.PI);
          ctx.fill();
        }
      }

      ctx.beginPath();
      ctx.moveTo(rotatedVertices.points[0].x, rotatedVertices.points[0].y);
      for (let j = 1; j < rotatedVertices.points.length; j++) {
        if (
          rotatedVertices.points[j].x !== undefined &&
          rotatedVertices.points[j].y !== undefined
        ) {
          ctx.lineTo(rotatedVertices.points[j].x, rotatedVertices.points[j].y);
        }
      }
      ctx.closePath();

      ctx.strokeStyle = rotatedVertices.label == "sra" ? "yellow" : "red"; // Set the stroke color to yellow for the selected polygon, red for others
      ctx.lineWidth = 2; // Set the line width
      ctx.stroke();

      if (i === selectedPolygonIndex) {
        try {
          if (rotatedVertices.points.length >= 2) {
            ctx.beginPath();
            ctx.moveTo(
              rotatedVertices.points[0].x,
              rotatedVertices.points[0].y
            );
            for (let j = 1; j < rotatedVertices.points.length; j++) {
              ctx.lineTo(
                rotatedVertices.points[j].x,
                rotatedVertices.points[j].y
              );
            }
            ctx.closePath();
            ctx.fillStyle =
              rotatedVertices.label == "sra"
                ? "rgba(255, 255, 0, 0.3)"
                : "rgba(255, 0, 0, 0.3)"; // Set the fill color to red or yellow depending on SRA
            ctx.fill(); // Fill the polygon with red color
            ctx.strokeStyle = rotatedVertices.label == "sra" ? "yellow" : "red"; // Set the stroke color to red
            ctx.lineWidth = 2; // Set the line width
            ctx.stroke();
          }
        } catch (e) { }
      }
    }
    // Highlight the hovered polygon in red for the ones that havent been selected
    if (
      hoveredPolygonIndex !== null &&
      hoveredPolygonIndex !== selectedPolygonIndex
    ) {
      const hoveredVertices = polygons[hoveredPolygonIndex];
      try {
        if (hoveredVertices.points.length >= 2) {
          ctx.beginPath();
          ctx.moveTo(hoveredVertices.points[0].x, hoveredVertices.points[0].y);
          for (let i = 1; i < hoveredVertices.points.length; i++) {
            ctx.lineTo(
              hoveredVertices.points[i].x,
              hoveredVertices.points[i].y
            );
          }
          ctx.closePath();
          ctx.fillStyle =
            hoveredVertices.label == "sra"
              ? "rgba(255, 255, 0, 0.3)"
              : "rgba(255, 0, 0, 0.3)"; // Set the fill color to red or yellow depending on SRA
          ctx.fill(); // Fill the polygon with red color
          ctx.strokeStyle = hoveredVertices.label == "sra" ? "yellow" : "red"; // Set the stroke color to red
          ctx.lineWidth = 2; // Set the line width
          ctx.stroke();
        }
      } catch (e) { }
    }

    for (let i = 0; i < polygons.length; i++) {
      if (i != selectedPolygonIndex) {
        const verticesSelected = polygons[i].points;
        try {
          if (verticesSelected.length >= 2) {
            ctx.beginPath();
            ctx.moveTo(verticesSelected[0].x, verticesSelected[0].y);
            for (let i = 1; i < verticesSelected.length; i++) {
              ctx.lineTo(verticesSelected[i].x, verticesSelected[i].y);
            }
            ctx.closePath();
            ctx.fillStyle =
              polygons[i].label == "sra"
                ? "rgba(255, 255, 0, 0.3)"
                : "rgba(0,0,0,0)"; // Set the fill color to yellow depending if SRA
            ctx.fill(); // Fill the polygon with red color
            ctx.strokeStyle = polygons[i].label == "sra" ? "yellow" : "red"; // Set the stroke color to red
            ctx.lineWidth = 2; // Set the line width
            ctx.stroke();
          }
        } catch (e) { }
      }
    }

    for (const vertices of props.tileData) {
      if (vertices.points.length !== 4) continue; // Skip if not a valid rectangle with 4 points

      // Draw the rectangle
      ctx.beginPath();
      ctx.moveTo(vertices.points[0].x, vertices.points[0].y);
      for (let i = 1; i < vertices.points.length; i++) {
        ctx.lineTo(vertices.points[i].x, vertices.points[i].y);
      }
      ctx.closePath();
      ctx.fillStyle = "rgba(0, 0, 255, 0.3)"; // Set the fill color to blue with transparency
      ctx.fill(); // Fill the rectangle with blue color
    }
  };

  useEffect(() => {
    //triggered to update the coordinates of teh polygon state, only after it finished dragging the slider
    console.log("2");
    //regardless of which functionality we select, we reset the selected polygon to null
    setSelectedPolygonIndex(null);
    if (
      canvasOption == "zoomin" ||
      canvasOption == "zoomout" ||
      canvasOption == "zoommove"
    )
      setSelectedZoomOptions(true);
    //update teh polygon data if the zooming has been updated and we switch to a new option
    if (
      selectedZoomOptions &&
      temporaryPolygonData.length > 0 &&
      canvasOption != "zoomin" &&
      canvasOption != "zoomout" &&
      canvasOption != "zoommove"
    ) {
      handleImageTransformations();
      const tempPol = [...temporaryPolygonData];
      props.setPolygonData(tempPol);
      setTemporaryPolygonData([]);

      const tempTile = [...temporaryTileData];
      props.setTileData(tempTile);
      setTemporaryTileData([]);
      setScale(1);
      setTranslate({ x: 0, y: 0 });
      setZoomFactor(1);
      setSelectedZoomOptions(false);
    }
    if (!isRotating && canvasOption === "rotate") {
      if (selectedPolygonIndex !== null) {
        const rotatedVertices = props.polygonData.map((vertices, index) => {
          if (index === selectedPolygonIndex) {
            // Calculate the center of the polygon
            let centerX = 0;
            let centerY = 0;
            for (let j = 0; j < vertices.points.length; j++) {
              centerX += vertices.points[j].x;
              centerY += vertices.points[j].y;
            }
            centerX /= vertices.points.length;
            centerY /= vertices.points.length;

            const angleRadians = (rotationAngle * Math.PI) / 180;
            const cosTheta = Math.cos(angleRadians);
            const sinTheta = Math.sin(angleRadians);

            return {
              label: vertices.label,
              points: vertices.points.map((vertex) => ({
                x:
                  cosTheta * (vertex.x - centerX) -
                  sinTheta * (vertex.y - centerY) +
                  centerX,
                y:
                  sinTheta * (vertex.x - centerX) +
                  cosTheta * (vertex.y - centerY) +
                  centerY,
              })),
              shape_type: "polygon",
            };
          } else {
            return vertices;
          }
        });

        props.setPolygonData(rotatedVertices);
      }
    }
    if (canvasOption != "draw") {
      //if we switch from the drawing option to anything, we STOP the drawing functionality (complete reset)
      setIsDrawingPolygon(false);
    }
  }, [rotationAngle, canvasOption, isRotating]);

  /*
   *
   *LABEL HANDLERS
   * */
  const [selectedLabelingPolygons, setSelectedLabelingPolygons] = useState<
    number[]
  >([]);
  const [labelName, setLabelName] = useState("");

  const handleClickLabel = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = props.canvasRef.current;
    if (!canvas) return;

    const mouseX = event.nativeEvent.offsetX;
    const mouseY = event.nativeEvent.offsetY;

    // Check if the click is over any polygon
    for (let i = 0; i < props.polygonData.length; i++) {
      const vertices = props.polygonData[i];
      if (vertices.points.length < 2) continue;

      // Create a Path2D object to represent the polygon path
      const path = new Path2D();
      path.moveTo(vertices.points[0].x, vertices.points[0].y);
      for (let j = 1; j < vertices.points.length; j++) {
        path.lineTo(vertices.points[j].x, vertices.points[j].y);
      }
      path.closePath();

      const ctx = canvas.getContext("2d");
      // Check if the click is inside the polygon
      if (ctx && ctx.isPointInPath(path, mouseX, mouseY)) {
        if (selectedLabelingPolygons.includes(i)) {
          // Polygon is already selected, deselect it
          setLabelName("");
          setSelectedLabelingPolygons(
            selectedLabelingPolygons.filter((index) => index !== i)
          );
        } else {
          // Polygon is not selected, select it
          setSelectedLabelingPolygons([...selectedLabelingPolygons, i]);
          if (labelName == "" && vertices.label != "") {
            setLabelName(vertices.label);
          }
          if (labelName != "" && vertices.label != labelName) {
            setLabelName("");
          }
        }

        return;
      }
    }
  };

  const handleMouseHoverLabelMove = (
    event: React.MouseEvent<HTMLCanvasElement>
  ) => {
    const canvas = props.canvasRef.current;
    if (!canvas) return;

    const mouseX = event.nativeEvent.offsetX;
    const mouseY = event.nativeEvent.offsetY;
    let isHovering = false;

    // Check if the mouse is over any polygon
    for (let i = 0; i < props.polygonData.length; i++) {
      const vertices = props.polygonData[i];
      if (vertices.points.length < 2) continue;

      // Create a Path2D object to represent the polygon path
      const path = new Path2D();
      path.moveTo(vertices.points[0].x, vertices.points[0].y);
      for (let j = 1; j < vertices.points.length; j++) {
        path.lineTo(vertices.points[j].x, vertices.points[j].y);
      }
      path.closePath();

      const ctx = canvas.getContext("2d");
      // Check if the mouse is inside the polygon
      if (ctx && ctx.isPointInPath(path, mouseX, mouseY)) {
        setHoveredPolygonIndex(i);
        isHovering = true;
        break; // Exit the loop if a polygon is hovered
      }
    }

    // If the mouse is not over any polygon, reset the hovered polygon index
    if (!isHovering) {
      setHoveredPolygonIndex(null);
    }
  };

  const setLabelForPolygons = (newLabel?: string) => {
    const newPolygonData = [...props.polygonData];

    // use: selectedLabelingPolygons
    for (let i = 0; i < newPolygonData.length; i++) {
      // if the polygon is highlighted
      if (selectedLabelingPolygons.includes(i)) {
        // Set the label for the selected polygon
        newPolygonData[i].label = newLabel ? "sra" : labelName;
      }
    }
    // Update the state or variable that holds the polygonData
    props.setPolygonData(newPolygonData);
    setSelectedLabelingPolygons([]);
  };

  /*
   *
   *DELETING HANDLERS
   * */
  const handleDeletePolygon = () => {
    if (!loadedImage) return;
    // Check if a polygon is currently selected for deletion
    if (selectedPolygonIndex !== null) {
      // Remove the selected polygon from the polygonData state
      const newPolygonData = [...props.polygonData];
      newPolygonData.splice(selectedPolygonIndex, 1);
      props.setPolygonData(newPolygonData);

      // Redraw the canvas with the updated polygons
      const ctx = props.canvasRef.current?.getContext("2d");
      if (ctx) {
        ctx.clearRect(
          0,
          0,
          props.canvasRef.current!.width,
          props.canvasRef.current!.height
        );
        ctx.drawImage(
          loadedImage,
          0,
          0,
          props.canvasRef.current!.width,
          props.canvasRef.current!.height
        );

        drawPolygon(ctx, newPolygonData);
      }
    }

    // Reset the selectedPolygonIndex
    setSelectedPolygonIndex(null);
  };
  const handleCanvasClickDelete = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ) => {
    const canvas = props.canvasRef.current;
    if (!canvas) return;
    if (!loadedImage) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const clickX = (event.clientX - rect.left) * scaleX;
    const clickY = (event.clientY - rect.top) * scaleY;

    // Check if any polygon is clicked
    for (let i = 0; i < props.polygonData.length; i++) {
      const vertices = props.polygonData[i];
      const ctx = canvas.getContext("2d");
      try {
        if (ctx) {
          ctx.beginPath();
          ctx.moveTo(vertices.points[0].x, vertices.points[0].y);
          for (let j = 1; j < vertices.points.length; j++) {
            ctx.lineTo(vertices.points[j].x, vertices.points[j].y);
          }
          ctx.closePath();
          if (ctx.isPointInPath(clickX, clickY)) {
            // Polygon is clicked, delete it from polygonData state
            const newPolygonData = [...props.polygonData];
            if (newPolygonData.length > 1) {
              newPolygonData.splice(i, 1);
              props.setPolygonData(newPolygonData);
            } else {
              props.setPolygonData([]);
            }

            // Redraw the canvas with the updated polygons
            ctx.clearRect(
              0,
              0,
              props.canvasRef.current!.width,
              props.canvasRef.current!.height
            );
            ctx.drawImage(
              loadedImage,
              0,
              0,
              props.canvasRef.current!.width,
              props.canvasRef.current!.height
            );

            drawPolygon(ctx, newPolygonData);

            break;
          }
        }
      } catch (e) { }
    }
  };

  /*
   *
   *SAVING HANDLERS
   * */
  const saveFilePolygon = () => {
    // Step 1: Convert the polygonData array into the desired JSON format
    const mergedShapes = props.polygonData.concat(props.tileData);

    const jsonShapeData = {
      shapes: mergedShapes,
    };

    // Step 2: Create a new file with the JSON content
    const jsonString = JSON.stringify(jsonShapeData);
    const blob = new Blob([jsonString], { type: "application/json" });

    // Step 3: Trigger a file download for the user
    saveAs(blob, "polygon_data.json");
  };

  useEffect(() => {
    console.log("3");
    const ctx = props.canvasRef.current?.getContext("2d");
    if (!ctx || !loadedImage) return;

    if (canvasOption !== "rotate") {
      drawPolygon(ctx, props.polygonData);
    } else {
      drawPolygonRotation(ctx, props.polygonData, rotationAngle);
    }
  }, [
    rotationAngle,
    selectedPolygonIndex,
    hoveredPolygonIndex,
    hoveredSide,
    selectedLabelingPolygons,
    showDots,
  ]);

  /*
   *
   *ZOOM IN HANDLING
   * */

  const [scale, setScale] = useState(1);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [zoomFactor, setZoomFactor] = useState(1);
  const [temporaryPolygonData, setTemporaryPolygonData] = useState<Shape[]>([]);
  const [temporaryTileData, setTemporaryTileData] = useState<Shape[]>([]);

  const handleCanvasZoomClick = (
    event: React.MouseEvent<HTMLCanvasElement> | null,
    inOut: string
  ) => {
    const canvas = props.canvasRef.current;
    if (!canvas) return;
    const mouseX = event ? event.nativeEvent.offsetX : 150;
    const mouseY = event ? event.nativeEvent.offsetY : 150;

    // Calculate the new scale based on the inOut parameter
    const newZoomFactor = inOut === "in" ? 1.1 : 1 / 1.1;

    // Calculate the change in scale
    const scaleChange = newZoomFactor - 1;

    // Calculate the new translation based on the mouse position
    const offsetX = (canvas.width / 2 - mouseX) * scaleChange;
    const offsetY = (canvas.height / 2 - mouseY) * scaleChange;

    // Update scale and translation
    setScale(scale * newZoomFactor);
    setTranslate({ x: translate.x + offsetX, y: translate.y + offsetY });

    // Update zoom factor
    setZoomFactor(newZoomFactor);
  };

  // Use useEffect to redraw the canvas when the translate state changes
  useEffect(() => {
    console.log("5");
    if (props.canvasRef.current) {
      drawCanvas();
    }
  }, [scale, translate]);

  const drawCanvas = () => {
    const canvas = props.canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw background image with separate transformations
    if (loadedImage) {
      // Save the current context state
      ctx.save();

      // Apply transformations for the image only
      ctx.translate(translate.x, translate.y);
      ctx.scale(scale, scale);

      // Draw the image
      ctx.drawImage(loadedImage, 0, 0, loadedImage.width, loadedImage.height);

      // Restore the context state to remove transformations
      ctx.restore();
    }

    const zoomedPolygonData = props.polygonData.map((vertices) => ({
      ...vertices,
      points: vertices.points.map((point) => ({
        x: translate.x + point.x * scale,
        y: translate.y + point.y * scale,
      })),
    }));
    const zoomedTileData = props.tileData.map((vertices) => ({
      ...vertices,
      points: vertices.points.map((point) => ({
        x: translate.x + point.x * scale,
        y: translate.y + point.y * scale,
      })),
    }));

    setTemporaryTileData(zoomedTileData);

    setTemporaryPolygonData(zoomedPolygonData);
    drawPolygon(ctx, zoomedPolygonData);
  };

  // Function to apply transformations to the loaded image and update the state
  const applyImageTransformations = (
    image: HTMLImageElement,
    transformations: { translate: { x: number; y: number }; scale: number }
  ): HTMLImageElement => {
    const { translate, scale } = transformations;

    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;

    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("Canvas context is null");

    ctx.translate(translate.x, translate.y);
    ctx.scale(scale, scale);

    ctx.drawImage(image, 0, 0, image.width, image.height);

    const transformedImage = new Image();
    transformedImage.src = canvas.toDataURL();

    return transformedImage;
  };

  const handleImageTransformations = () => {
    if (loadedImage) {
      const transformedImage = applyImageTransformations(loadedImage, {
        translate: translate, // Your translation values here
        scale: scale, // Your scale value here
      });

      setLoadedImage(transformedImage);
    }
  };

  /*
   *
   *ZOOM MOVEMENT HANDLING
   * */

  const handleMouseZoomDownMove = (
    event: React.MouseEvent<HTMLCanvasElement>
  ) => {
    if (!dragging) {
      setDragging(true);

      const mouseX = event.nativeEvent.offsetX;
      const mouseY = event.nativeEvent.offsetY;

      setDraggingOffset({ x: mouseX, y: mouseY });
    }
  };

  const handleMouseZoomMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (dragging) {
      const mouseX = event.nativeEvent.offsetX;
      const mouseY = event.nativeEvent.offsetY;

      // Calculate the new translation based on the dragging offset and mouse position
      const offsetX = (mouseX - draggingOffset.x) / scale;
      const offsetY = (mouseY - draggingOffset.y) / scale;

      setTranslate({ x: translate.x + offsetX, y: translate.y + offsetY });

      // Update the dragging offset for the next movement
      setDraggingOffset({ x: mouseX, y: mouseY });
    }
  };
  const handleMouseZoomMoveUp = () => {
    if (dragging) {
      setDragging(false);
    }
  };

  //styles
  const [hovered, setHovered] = useState(false);

  /*
   *
   *SOLAR PANELS HANDLING
   * */

  const [tileOption, setTileOption] = useState("");
  const [solarNumber, setSolarNumber] = useState(0);
  const [selectedTileIndex, setSelectedTileIndex] = useState<number | null>(
    null
  );
  const [hoveredTileIndex, setHoveredTileIndex] = useState<number | null>(null);

  useEffect(() => {
    console.log("6");
    const canvas = props.canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Call your drawTile function here to draw the tiles
    drawTile(ctx, props.tileData, props.polygonData);
  }, [props.tileData]);

  const computeDefaultTiles = (canvasWidth: number, canvasHeight: number) => {
    const tileWidth = 10;
    const tileHeight = 20;
    const padding = 5;

    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;

    const tiles: Shape[] = [...props.tileData];

    // Generate three rectangular tiles next to each other
    for (let i = 0; i < solarNumber; i++) {
      const startX =
        centerX - (1.5 * tileWidth + padding) + i * (tileWidth + padding);
      const startY = centerY - tileHeight / 2;

      const tile: Shape = {
        label: "",
        points: [
          { x: startX, y: startY },
          { x: startX + tileWidth, y: startY },
          { x: startX + tileWidth, y: startY + tileHeight },
          { x: startX, y: startY + tileHeight },
        ],
        shape_type: "tile",
      };

      tiles.push(tile);
    }

    props.setTileData(tiles);
  };

  const drawTile = (
    ctx: CanvasRenderingContext2D,
    tilesData: Shape[],
    polygonsData: Shape[]
  ) => {
    if (!loadedImage) return;

    ctx.clearRect(
      0,
      0,
      props.canvasRef.current!.width,
      props.canvasRef.current!.height
    );
    ctx.drawImage(
      loadedImage,
      0,
      0,
      props.canvasRef.current!.width,
      props.canvasRef.current!.height
    );

    for (const vertices of tilesData) {
      if (vertices.points.length !== 4) continue; // Skip if not a valid rectangle with 4 points

      // Draw the rectangle
      ctx.beginPath();
      ctx.moveTo(vertices.points[0].x, vertices.points[0].y);
      for (let i = 1; i < vertices.points.length; i++) {
        ctx.lineTo(vertices.points[i].x, vertices.points[i].y);
      }
      ctx.closePath();
      ctx.fillStyle = "rgba(0, 0, 255, 0.3)"; // Set the fill color to blue with transparency
      ctx.fill(); // Fill the rectangle with blue color
    }

    if (hoveredTileIndex !== null) {
      const hoveredVertices = props.tileData[hoveredTileIndex];
      try {
        if (hoveredVertices.points.length >= 2) {
          ctx.beginPath();
          ctx.moveTo(hoveredVertices.points[0].x, hoveredVertices.points[0].y);
          for (let i = 1; i < hoveredVertices.points.length; i++) {
            ctx.lineTo(
              hoveredVertices.points[i].x,
              hoveredVertices.points[i].y
            );
          }
          ctx.closePath();
          ctx.fillStyle =
            hoveredVertices.label == "sra"
              ? "rgba(255, 255, 0, 0.3)"
              : "rgba(255, 0, 0, 0.3)"; // Set the fill color to red or yellow depending on SRA
          ctx.fill(); // Fill the polygon with red color
          ctx.strokeStyle = hoveredVertices.label == "sra" ? "yellow" : "red"; // Set the stroke color to red
          ctx.lineWidth = 2; // Set the line width
          ctx.stroke();
        }
      } catch (e) { }
    }

    // Draw the rest of the polygons
    for (const vertices of polygonsData) {
      if (vertices.points.length < 2) continue; // Skip empty or single-point polygons

      // Draw the points as small dots
      ctx.fillStyle = showDots ? "blue" : "rgba(0,0,0,0)"; // Set the dot color to blue
      for (let i = 0; i < vertices.points.length; i++) {
        const point = vertices.points[i];
        if (point.x !== undefined && point.y !== undefined) {
          ctx.beginPath();
          ctx.arc(point.x, point.y, 5, 0, 2 * Math.PI);
          ctx.fill();
        }
      }

      ctx.beginPath();
      ctx.moveTo(vertices.points[0].x, vertices.points[0].y);
      for (let i = 1; i < vertices.points.length; i++) {
        if (
          vertices.points[i].x !== undefined &&
          vertices.points[i].y !== undefined
        ) {
          ctx.lineTo(vertices.points[i].x, vertices.points[i].y);
        }
      }
      ctx.closePath();
      ctx.closePath();
      ctx.fillStyle =
        vertices.label == "sra" ? "rgba(255, 255, 0, 0.3)" : "rgba(0,0,0,0)"; // Set the fill color to yellow depending if SRA
      ctx.fill(); // Fill the polygon with color
      ctx.strokeStyle = vertices.label == "sra" ? "yellow" : "red"; // Set the stroke color to red
      ctx.lineWidth = 2; // Set the line width
      ctx.stroke();
    }
  };

  // Event handlers

  /*
   *
   *SIZE EDIT HANDLERS
   * */

  const [draggingStateTile, setDraggingStateTile] = useState<{
    tileIndex: number | null;
    vertexIndex: number | null;
  }>({ tileIndex: null, vertexIndex: null });

  const handleMouseDownTileEdit = (
    event: React.MouseEvent<HTMLCanvasElement>
  ) => {
    const canvas = props.canvasRef.current;
    if (!canvas) return;

    const mouseX = event.nativeEvent.offsetX;
    const mouseY = event.nativeEvent.offsetY;

    // Check if the mouse is near any corner of the tiles
    for (let i = 0; i < props.tileData.length; i++) {
      const vertices = props.tileData[i].points;
      for (let j = 0; j < vertices.length; j++) {
        const vertex = vertices[j];
        const distance = Math.sqrt(
          (vertex.x - mouseX) ** 2 + (vertex.y - mouseY) ** 2
        );
        if (distance < 5) {
          setDraggingStateTile({ tileIndex: i, vertexIndex: j });
          break;
        }
      }
    }
  };

  // const [dodo, setDODO] = useState({x: 0, y: 0});
  // const [lolo, setLolo] = useState({x: 0, y: 0});

  const handleMouseMoveTileEdit = (
    event: React.MouseEvent<HTMLCanvasElement>
  ) => {
    if (
      draggingStateTile.tileIndex !== null &&
      draggingStateTile.vertexIndex !== null
    ) {
      const newTilesData = [...props.tileData];
      const { tileIndex, vertexIndex } = draggingStateTile;
      const vertex = newTilesData[tileIndex].points[vertexIndex];

      const mouseX = event.nativeEvent.offsetX;
      const mouseY = event.nativeEvent.offsetY;

      const adjacent1Index = (vertexIndex + 1) % 4;
      const adjacent2Index = (vertexIndex + 3) % 4;

      // Calculate the displacement of the dragged point
      const deltaX = mouseX - vertex.x;
      const deltaY = mouseY - vertex.y;

      // Update the dragged point
      vertex.x = mouseX;
      vertex.y = mouseY;

      //calciulate d2
      //center of the polygon/tile
      // var centerOfTile = dodo;
      // if (centerOfTile.x == 0) {
      //   centerOfTile = calculateTileCenter(props.tileData[0].points);
      //   setDODO(centerOfTile);
      // }
      // var lololo = lolo;
      // if (lololo.x == 0) {
      //   lololo = newTilesData[tileIndex].points[adjacent2Index];
      //   setLolo(lololo);
      // }

      //Function that calculates the distance between original adjacent x and Cx above
      //   lololo = newTilesData[tileIndex].points[adjacent2Index];
      // const d2 = calculateXDistance( lololo.x, centerOfTile.x);

      //calculate d1
      // const pointPerpendicular = calculatePerpendicularIntersection(mouseX, mouseY, lololo.x, lololo.y, newTilesData[tileIndex].points[(vertexIndex + 2) % 4].x, newTilesData[tileIndex].points[(vertexIndex + 2) % 4].y);
      // const d1 = calculateXDistance(pointPerpendicular.x, centerOfTile.x);
      // console.log("DEBUGGING");
      // console.log("centre", centerOfTile);
      // console.log("d2", d2);
      // console.log("pointPerpendicular", pointPerpendicular);
      // console.log("d1", d1);

      // const difference = Math.abs(d2-d1);

      // Update the adjacent corners based on the dragged vertex
      if (vertexIndex === 0) {
        // Top left corner
        newTilesData[tileIndex].points[adjacent1Index].y += deltaY;
        newTilesData[tileIndex].points[adjacent2Index].x += deltaX;
      } else if (vertexIndex === 1) {
        // Top right corner
        // console.log("DIFF", d2, d1);
        // newTilesData[tileIndex].points[adjacent2Index].x -= difference;
        //newTilesData[tileIndex].points[adjacent2Index].y += deltaY;
        newTilesData[tileIndex].points[adjacent1Index].x += deltaX;
        newTilesData[tileIndex].points[adjacent2Index].y += deltaY;
      } else if (vertexIndex === 2) {
        // Bottom right corner
        newTilesData[tileIndex].points[adjacent1Index].y += deltaY;
        newTilesData[tileIndex].points[adjacent2Index].x += deltaX;
      } else if (vertexIndex === 3) {
        // Bottom left corner
        newTilesData[tileIndex].points[adjacent1Index].x += deltaX;
        newTilesData[tileIndex].points[adjacent2Index].y += deltaY;
      }

      props.setTileData(newTilesData);
      drawTile(
        props.canvasRef.current!.getContext("2d")!,
        newTilesData,
        props.polygonData
      );
    }
  };

  function calculateTileCenter(tileVertices: Point[]) {
    const centerX =
      (tileVertices[0].x +
        tileVertices[1].x +
        tileVertices[2].x +
        tileVertices[3].x) /
      4;
    const centerY =
      (tileVertices[0].y +
        tileVertices[1].y +
        tileVertices[2].y +
        tileVertices[3].y) /
      4;

    return { x: centerX, y: centerY };
  }

  function calculateXDistance(point1X: number, point2X: number) {
    return Math.abs(point2X - point1X);
  }

  // function calculatePerpendicularIntersection(mouseX: number, mouseY: number, tileVertexX1: number, tileVertexY1: number, tileVertexX2: number, tileVertexY2: number) {
  //   const dx = tileVertexX2 - tileVertexX1;
  //   const dy = tileVertexY2 - tileVertexY1;

  //   const length = Math.sqrt(dx * dx + dy * dy);

  //   const unitDX = dx / length;
  //   const unitDY = dy / length;

  //   const dotProduct = (mouseX - tileVertexX1) * unitDX + (mouseY - tileVertexY1) * unitDY;

  //   const intersectionX = tileVertexX1 + dotProduct * unitDX;
  //   const intersectionY = tileVertexY1 + dotProduct * unitDY;

  //   return { x: intersectionX, y: intersectionY };
  // }

  // const handleMouseMoveTileEdit = (event: React.MouseEvent<HTMLCanvasElement>) => {
  //   if (draggingStateTile.tileIndex !== null && draggingStateTile.vertexIndex !== null) {
  //     const newTilesData = [...props.tileData];
  //     const { tileIndex, vertexIndex } = draggingStateTile;
  //     const vertex = newTilesData[tileIndex].points[vertexIndex];

  //     const mouseX = event.nativeEvent.offsetX;
  //     const mouseY = event.nativeEvent.offsetY;

  //     const adjacent1Index = (vertexIndex + 1) % 4;
  //     const adjacent2Index = (vertexIndex + 3) % 4;

  //     // Calculate the displacement of the dragged point
  //     const deltaX = mouseX - vertex.x;
  //     const deltaY = mouseY - vertex.y;

  //     // Update the dragged point
  //     vertex.x = mouseX;
  //     vertex.y = mouseY;

  //     // Get the rotation angle in radians
  //     const rotationAngleRadians = calculateRotationAngle(props.tileData[tileIndex].points) /180;
  //     console.log("DODO", calculateRotationAngle(props.tileData[0].points));

  //     // Calculate adjusted deltaX and deltaY based on rotation
  //     const adjustedDeltaX = deltaX * Math.cos(rotationAngleRadians) + deltaY * Math.sin(rotationAngleRadians);
  //     const adjustedDeltaY = -deltaX * Math.sin(rotationAngleRadians) + deltaY * Math.cos(rotationAngleRadians);

  //     // Update the adjacent corners based on the dragged vertex
  //     if (vertexIndex === 0) {
  //       // Top left corner
  //       newTilesData[tileIndex].points[adjacent1Index].y += adjustedDeltaY;
  //       newTilesData[tileIndex].points[adjacent2Index].x += adjustedDeltaX;
  //     } else if (vertexIndex === 1) {
  //       // Top right corner
  //       newTilesData[tileIndex].points[adjacent1Index].x += adjustedDeltaX;
  //       newTilesData[tileIndex].points[adjacent2Index].y += adjustedDeltaY;
  //     } else if (vertexIndex === 2) {
  //       // Bottom right corner
  //       newTilesData[tileIndex].points[adjacent1Index].y += adjustedDeltaY;
  //       newTilesData[tileIndex].points[adjacent2Index].x += adjustedDeltaX;
  //     } else if (vertexIndex === 3) {
  //       // Bottom left corner
  //       newTilesData[tileIndex].points[adjacent1Index].x += adjustedDeltaX;
  //       newTilesData[tileIndex].points[adjacent2Index].y += adjustedDeltaY;
  //     }

  //     props.setTileData(newTilesData);
  //     drawTile(canvasRef.current!.getContext('2d')!, newTilesData, props.polygonData);
  //   }
  // };

  function calculateRotationAngle(rectangleVertices: Point[]) {
    if (rectangleVertices.length < 2) {
      return 0; // Not enough vertices to calculate rotation angle
    }

    const vertex1 = rectangleVertices[0];
    const vertex2 = rectangleVertices[1];

    // Calculate the angle in radians between vertex1 and vertex2
    const deltaX = vertex2.x - vertex1.x;
    const deltaY = vertex2.y - vertex1.y;
    const angleRadians = Math.atan2(deltaY, deltaX);

    // Convert the angle to degrees
    const angleDegrees = (angleRadians * 180) / Math.PI;

    return angleDegrees;
  }

  const handleMouseUpTileEdit = () => {
    setDraggingStateTile({ tileIndex: null, vertexIndex: null });
  };

  /*
   *
   *MOVE HANDLERS
   * */

  const [draggingTile, setDraggingTile] = useState<boolean>(false);
  const [draggingTileOffset, setDraggingTileOffset] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [draggingTileIndex, setDraggingTileIndex] = useState<number | null>(
    null
  );

  const pointInTile = (point: Point, tile: Point[]) => {
    let isInside = false;
    const x = point.x;
    const y = point.y;

    for (let i = 0, j = tile.length - 1; i < tile.length; j = i++) {
      const xi = tile[i].x;
      const yi = tile[i].y;
      const xj = tile[j].x;
      const yj = tile[j].y;

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) {
        isInside = !isInside;
      }
    }

    return isInside;
  };

  const handleMouseDownTileMove = (
    event: React.MouseEvent<HTMLCanvasElement>
  ) => {
    if (!draggingTile) setDraggingTile(true);

    const mouseX = event.nativeEvent.offsetX;
    const mouseY = event.nativeEvent.offsetY;

    // Check if any polygon is clicked
    for (let i = 0; i < props.tileData.length; i++) {
      const vertices = props.tileData[i];
      if (pointInTile({ x: mouseX, y: mouseY }, vertices.points)) {
        setDraggingTile(true);
        setDraggingTileIndex(i);
        setDraggingTileOffset({
          x: mouseX - vertices.points[0].x,
          y: mouseY - vertices.points[0].y,
        });
        break;
      }
    }
  };

  const handleMouseMoveTileMove = (
    event: React.MouseEvent<HTMLCanvasElement>
  ) => {
    if (!loadedImage) return;

    if (draggingTile && draggingTileIndex !== null) {
      const mouseX = event.nativeEvent.offsetX;
      const mouseY = event.nativeEvent.offsetY;
      const newOffset = {
        x: mouseX - props.tileData[draggingTileIndex].points[0].x,
        y: mouseY - props.tileData[draggingTileIndex].points[0].y,
      };

      // Update the position of the vertices for the specific polygon based on the new offset
      const newTileData = props.tileData.map((vertices, index) => {
        if (index === draggingTileIndex) {
          return {
            label: vertices.label,
            points: vertices.points.map((vertex) => ({
              x: vertex.x + newOffset.x - draggingTileOffset.x,
              y: vertex.y + newOffset.y - draggingTileOffset.y,
            })),
            shape_type: "tile",
          };
        } else {
          return vertices;
        }
      });

      // Redraw the canvas with the updated polygons
      const ctx = props.canvasRef.current!.getContext("2d");
      if (ctx) {
        ctx.clearRect(
          0,
          0,
          props.canvasRef.current!.width,
          props.canvasRef.current!.height
        );
        ctx.drawImage(
          loadedImage,
          0,
          0,
          props.canvasRef.current!.width,
          props.canvasRef.current!.height
        );

        drawTile(ctx, newTileData, props.polygonData);
      }

      // Update the polygon data and dragging offset
      props.setTileData(newTileData);
    }

    handleMouseHoverTileMove(event);
  };

  const handleMouseUpTileMove = () => {
    if (draggingTile) {
      setDraggingTile(false);
      setDraggingTileIndex(null);
    }
  };

  //move hovering
  const handleMouseHoverTileMove = (
    event: React.MouseEvent<HTMLCanvasElement>
  ) => {
    const canvas = props.canvasRef.current;
    if (!canvas) return;
    const mouseX = event.nativeEvent.offsetX;
    const mouseY = event.nativeEvent.offsetY;

    // Check if the mouse is over any tile
    for (let i = 0; i < props.tileData.length; i++) {
      const vertices = props.tileData[i];
      if (vertices.points.length < 2) continue;

      // Create a Path2D object to represent the tile path
      const path = new Path2D();
      path.moveTo(vertices.points[0].x, vertices.points[0].y);
      for (let j = 1; j < vertices.points.length; j++) {
        path.lineTo(vertices.points[j].x, vertices.points[j].y);
      }
      path.closePath();

      const ctx = canvas.getContext("2d");
      // Check if the mouse is inside the polygon
      if (ctx && ctx.isPointInPath(path, mouseX, mouseY)) {
        setHoveredTileIndex(i);
        return;
      }
    }

    // If the mouse is not over any polygon, reset the hovered polygon index
    setHoveredTileIndex(null);
  };

  /*
   *
   *ROTATION HANDLERS
   * */
  const [rotationAngleTile, setRotationAngleTile] = useState<number>(0);
  const [isRotatingTile, setIsRotatingTile] = useState(false);

  const handleCanvasClickTileRotate = (
    event: React.MouseEvent<HTMLCanvasElement>
  ) => {
    const mouseX = event.nativeEvent.offsetX;
    const mouseY = event.nativeEvent.offsetY;
    // Check if any tile is clicked
    for (let i = 0; i < props.tileData.length; i++) {
      const vertices = props.tileData[i];
      // Check if the click is inside the tile using the point-in-polygon algorithm
      if (isPointInPolygon({ x: mouseX, y: mouseY }, vertices.points)) {
        setSelectedTileIndex(i);

        // Also hightlight the polygon
        const ctx = props.canvasRef.current?.getContext("2d");
        if (!ctx || !loadedImage) return;
        drawTileRotation(ctx, props.tileData, rotationAngleTile);
        break;
      }
    }
  };
  const handleTileRotation = (event: Event, newValue: number | number[]) => {
    setIsRotatingTile(true);
    setRotationAngleTile(newValue as number);
  };
  const drawTileRotation = (
    ctx: CanvasRenderingContext2D,
    tiles: Shape[],
    rotationAngleTile: number
  ) => {
    if (!loadedImage) return;
    ctx.clearRect(
      0,
      0,
      props.canvasRef.current!.width,
      props.canvasRef.current!.height
    );
    ctx.drawImage(
      loadedImage,
      0,
      0,
      props.canvasRef.current!.width,
      props.canvasRef.current!.height
    );

    for (let i = 0; i < tiles.length; i++) {
      const vertices = tiles[i];
      if (vertices.points.length < 2) continue; // Skip empty or single-point polygons

      // Calculate the center of the polygon
      let centerX = 0;
      let centerY = 0;
      for (let j = 0; j < vertices.points.length; j++) {
        centerX += vertices.points[j].x;
        centerY += vertices.points[j].y;
      }
      centerX /= vertices.points.length;
      centerY /= vertices.points.length;

      var rotatedVertices;
      if (isRotatingTile) {
        // Apply rotation transformation to the polygon vertices only for the selected polygon
        rotatedVertices =
          i === selectedTileIndex
            ? {
              label: vertices.label,
              points: vertices.points.map((vertex) => {
                const angleRadians = (rotationAngleTile * Math.PI) / 180;
                const cosTheta = Math.cos(angleRadians);
                const sinTheta = Math.sin(angleRadians);
                const x =
                  cosTheta * (vertex.x - centerX) -
                  sinTheta * (vertex.y - centerY) +
                  centerX;
                const y =
                  sinTheta * (vertex.x - centerX) +
                  cosTheta * (vertex.y - centerY) +
                  centerY;
                return { x, y };
              }),
              shape_type: "polygon",
            }
            : vertices;
      } else {
        rotatedVertices = vertices;
      }

      ctx.beginPath();
      ctx.moveTo(rotatedVertices.points[0].x, rotatedVertices.points[0].y);
      for (let j = 1; j < rotatedVertices.points.length; j++) {
        if (
          rotatedVertices.points[j].x !== undefined &&
          rotatedVertices.points[j].y !== undefined
        ) {
          ctx.lineTo(rotatedVertices.points[j].x, rotatedVertices.points[j].y);
        }
      }
      ctx.closePath();
      ctx.fillStyle = "rgba(0, 0, 255, 0.3)"; // Set the fill color to blue with transparency
      ctx.fill(); // Fill the rectangle with blue color

      if (i === selectedTileIndex) {
        try {
          if (rotatedVertices.points.length >= 2) {
            ctx.beginPath();
            ctx.moveTo(
              rotatedVertices.points[0].x,
              rotatedVertices.points[0].y
            );
            for (let j = 1; j < rotatedVertices.points.length; j++) {
              ctx.lineTo(
                rotatedVertices.points[j].x,
                rotatedVertices.points[j].y
              );
            }
            ctx.closePath();
            ctx.fillStyle =
              rotatedVertices.label == "sra"
                ? "rgba(255, 255, 0, 0.3)"
                : "rgba(255, 0, 0, 0.3)"; // Set the fill color to red or yellow depending on SRA
            ctx.fill(); // Fill the polygon with red color
            ctx.strokeStyle = rotatedVertices.label == "sra" ? "yellow" : "red"; // Set the stroke color to red
            ctx.lineWidth = 2; // Set the line width
            ctx.stroke();
          }
        } catch (e) { }
      }
    }
    // Highlight the hovered polygon in red for the ones that havent been selected
    if (hoveredTileIndex !== null && hoveredTileIndex !== selectedTileIndex) {
      const hoveredVertices = tiles[hoveredTileIndex];
      try {
        if (hoveredVertices.points.length >= 2) {
          ctx.beginPath();
          ctx.moveTo(hoveredVertices.points[0].x, hoveredVertices.points[0].y);
          for (let i = 1; i < hoveredVertices.points.length; i++) {
            ctx.lineTo(
              hoveredVertices.points[i].x,
              hoveredVertices.points[i].y
            );
          }
          ctx.closePath();
          ctx.fillStyle =
            hoveredVertices.label == "sra"
              ? "rgba(255, 255, 0, 0.3)"
              : "rgba(255, 0, 0, 0.3)"; // Set the fill color to red or yellow depending on SRA
          ctx.fill(); // Fill the polygon with red color
          ctx.strokeStyle = hoveredVertices.label == "sra" ? "yellow" : "red"; // Set the stroke color to red
          ctx.lineWidth = 2; // Set the line width
          ctx.stroke();
        }
      } catch (e) { }
    }

    //the rest
    // Draw the rest of the polygons
    for (const vertices of props.polygonData) {
      if (vertices.points.length < 2) continue; // Skip empty or single-point polygons

      // Draw the points as small dots
      ctx.fillStyle = showDots ? "blue" : "rgba(0,0,0,0)"; // Set the dot color to blue
      for (let i = 0; i < vertices.points.length; i++) {
        const point = vertices.points[i];
        if (point.x !== undefined && point.y !== undefined) {
          ctx.beginPath();
          ctx.arc(point.x, point.y, 5, 0, 2 * Math.PI);
          ctx.fill();
        }
      }

      ctx.beginPath();
      ctx.moveTo(vertices.points[0].x, vertices.points[0].y);
      for (let i = 1; i < vertices.points.length; i++) {
        if (
          vertices.points[i].x !== undefined &&
          vertices.points[i].y !== undefined
        ) {
          ctx.lineTo(vertices.points[i].x, vertices.points[i].y);
        }
      }
      ctx.closePath();
      ctx.fillStyle =
        vertices.label == "sra" ? "rgba(255, 255, 0, 0.3)" : "rgba(0,0,0,0)"; // Set the fill color to yellow depending if SRA
      ctx.fill(); // Fill the polygon with color
      ctx.strokeStyle = vertices.label == "sra" ? "yellow" : "red"; // Set the stroke color to red
      ctx.lineWidth = 2; // Set the line width
      ctx.stroke();
    }
  };
  useEffect(() => {
    //triggered to update the coordinates of teh polygon state, only after it finished dragging the slider
    console.log("7");
    if (!isRotatingTile && tileOption === "rotate") {
      if (selectedTileIndex !== null) {
        const rotatedVertices = props.tileData.map((vertices, index) => {
          if (index === selectedTileIndex) {
            // Calculate the center of the polygon
            let centerX = 0;
            let centerY = 0;
            for (let j = 0; j < vertices.points.length; j++) {
              centerX += vertices.points[j].x;
              centerY += vertices.points[j].y;
            }
            centerX /= vertices.points.length;
            centerY /= vertices.points.length;

            const angleRadians = (rotationAngleTile * Math.PI) / 180;
            const cosTheta = Math.cos(angleRadians);
            const sinTheta = Math.sin(angleRadians);

            return {
              label: vertices.label,
              points: vertices.points.map((vertex) => ({
                x:
                  cosTheta * (vertex.x - centerX) -
                  sinTheta * (vertex.y - centerY) +
                  centerX,
                y:
                  sinTheta * (vertex.x - centerX) +
                  cosTheta * (vertex.y - centerY) +
                  centerY,
              })),
              shape_type: "tile",
            };
          } else {
            return vertices;
          }
        });

        props.setTileData(rotatedVertices);
      }
    }
  }, [rotationAngleTile, tileOption, isRotatingTile]);
  useEffect(() => {
    console.log("8");
    const ctx = props.canvasRef.current?.getContext("2d");
    if (!ctx || !loadedImage) return;

    if (tileOption !== "rotate") {
      drawTile(ctx, props.tileData, props.polygonData);
    } else {
      drawTileRotation(ctx, props.tileData, rotationAngleTile);
    }
  }, [rotationAngleTile, selectedTileIndex, hoveredTileIndex, showDots]);

  /*
   *
   *DELETING HANDLERS
   * */
  const handleDeleteTile = () => {
    if (!loadedImage) return;
    // Check if a polygon is currently selected for deletion
    if (selectedTileIndex !== null) {
      // Remove the selected polygon from the polygonData state
      const newTileData = [...props.tileData];
      newTileData.splice(selectedTileIndex, 1);
      props.setTileData(newTileData);

      // Redraw the canvas with the updated polygons
      const ctx = props.canvasRef.current?.getContext("2d");
      if (ctx) {
        ctx.clearRect(
          0,
          0,
          props.canvasRef.current!.width,
          props.canvasRef.current!.height
        );
        ctx.drawImage(
          loadedImage,
          0,
          0,
          props.canvasRef.current!.width,
          props.canvasRef.current!.height
        );

        drawTile(ctx, newTileData, props.polygonData);
      }
    }

    // Reset the selectedPolygonIndex
    setSelectedTileIndex(null);
  };
  const handleCanvasClickTileDelete = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ) => {
    const canvas = props.canvasRef.current;
    if (!canvas) return;
    if (!loadedImage) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const clickX = (event.clientX - rect.left) * scaleX;
    const clickY = (event.clientY - rect.top) * scaleY;

    // Check if any polygon is clicked
    for (let i = 0; i < props.tileData.length; i++) {
      const vertices = props.tileData[i];
      const ctx = canvas.getContext("2d");
      try {
        if (ctx) {
          ctx.beginPath();
          ctx.moveTo(vertices.points[0].x, vertices.points[0].y);
          for (let j = 1; j < vertices.points.length; j++) {
            ctx.lineTo(vertices.points[j].x, vertices.points[j].y);
          }
          ctx.closePath();
          if (ctx.isPointInPath(clickX, clickY)) {
            // Tile is clicked, delete it from tileData state
            const newTileData = [...props.tileData];
            if (newTileData.length > 1) {
              newTileData.splice(i, 1);
              props.setTileData(newTileData);
            } else {
              props.setTileData([]);
            }

            // Redraw the canvas with the updated polygons
            ctx.clearRect(
              0,
              0,
              props.canvasRef.current!.width,
              props.canvasRef.current!.height
            );
            ctx.drawImage(
              loadedImage,
              0,
              0,
              props.canvasRef.current!.width,
              props.canvasRef.current!.height
            );

            drawTile(ctx, newTileData, props.polygonData);

            break;
          }
        }
      } catch (e) { }
    }
  };

  const handleFetchTilingButtonClick = async () => {
    try {
      const requestData = {
        shapes: props.polygonData,
      };

      const url = "https://absolarserver:7019/api/Script/getTiling";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        console.error(`Request failed with status: ${response.status}`);
      }

      const tileData = await response.json();
      props.setTileData(tileData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (props.convertedImage) {
      //if it's been converted into an image, it means we need to HIDE dots
      //this function will hide the dots, and trigger the saving process automatically
      if (showDots == false) {
        //they've already been hidden
        //just save the canvas as normal
        if (!props.canvasRef.current) return;
        const canvas = props.canvasRef.current;
        //reset canvas option
        setCanvasOption("");
        // Get the canvas data as a data URL (PNG format)
        const canvasDataUrl = canvas.toDataURL("image/png");
        console.log(canvasDataUrl);
        props.setCanvasImageURL(canvasDataUrl);
      } else {
        //if they haven't been hidden, we HIDe them now
        setShowDots(false);
        //this will trigger a rerendering of drawPolygon, and at teh end of it, we will save the canvas, just as above
      }
    } else {
      //if we switch from static image to edit
      props.setCanvasImageURL("");
    }
  }, [props.convertedImage]);

  //use to control the button to enlarge the satelliteimage

  return (
    <>
      <div
        style={{
          border:"1px black solid",
          width: "100%",
          transform: props.isLargeSatelliteImage ? "scale(2)" : "scale(1)",
          transformOrigin: "top"
        }}
      >
        <canvas
          ref={props.canvasRef}
          style={{
            cursor:
              canvasOption == "edit" || tileOption == "edit"
                ? "crosshair"
                : // : (canvasOption == "zoomin") ?
                //   "zoom-in"
                //   : (canvasOption == "zoomout") ?
                //     "zoom-out"
                canvasOption == "zoommove"
                  ? "move"
                  : (canvasOption == "move" || tileOption == "move") &&
                    (hoveredPolygonIndex != null || hoveredTileIndex != null)
                    ? "move"
                    : (canvasOption == "delete" ||
                      canvasOption == "rotate" ||
                      tileOption == "rotate" ||
                      tileOption == "delete") &&
                      (hoveredPolygonIndex != null || hoveredTileIndex != null)
                      ? "pointer"
                      : canvasOption == "draw"
                        ? "copy"
                        : undefined,
          }}
          onMouseDown={
            canvasOption == "edit"
              ? handleMouseDownEdit
              : canvasOption == "move"
                ? handleMouseDownMove
                : canvasOption == "zoommove"
                  ? handleMouseZoomDownMove
                  : canvasOption == "solar"
                    ? tileOption == "move"
                      ? handleMouseDownTileMove
                      : tileOption == "edit"
                        ? handleMouseDownTileEdit
                        : undefined
                    : undefined
          }
          onMouseMove={
            canvasOption == "edit"
              ? handleMouseMoveEdit
              : canvasOption == "move"
                ? handleMouseMoveMove
                : canvasOption == "zoommove"
                  ? handleMouseZoomMove
                  : canvasOption == "label"
                    ? handleMouseHoverLabelMove
                    : canvasOption == "delete" || canvasOption == "rotate"
                      ? handleMouseHoverMove
                      : canvasOption == "solar"
                        ? tileOption == "move"
                          ? handleMouseMoveTileMove
                          : tileOption == "delete" || tileOption == "rotate"
                            ? handleMouseHoverTileMove
                            : tileOption == "edit"
                              ? handleMouseMoveTileEdit
                              : undefined
                        : undefined
          }
          onMouseUp={
            canvasOption == "edit"
              ? handleMouseUpEdit
              : canvasOption == "move"
                ? handleMouseUpMove
                : canvasOption == "zoommove"
                  ? handleMouseZoomMoveUp
                  : canvasOption == "solar"
                    ? tileOption == "move"
                      ? handleMouseUpTileMove
                      : tileOption == "edit"
                        ? handleMouseUpTileEdit
                        : undefined
                    : undefined
          }
          onClick={(e) =>
            canvasOption == "edit"
              ? handleLeftClickEdit(e)
              : canvasOption == "draw"
                ? handleCanvasClickDraw(e)
                : canvasOption == "label"
                  ? handleClickLabel(e)
                  : canvasOption == "delete"
                    ? handleCanvasClickDelete(e)
                    : canvasOption == "rotate"
                      ? handleCanvasClickRotate(e)
                      : // canvasOption == "zoomin" ?
                      //   handleCanvasZoomClick(e, "in") :
                      //   canvasOption == "zoomout" ?
                      //     handleCanvasZoomClick(e, "out") :
                      canvasOption == "solar"
                        ? tileOption == "rotate"
                          ? handleCanvasClickTileRotate(e)
                          : tileOption == "delete"
                            ? handleCanvasClickTileDelete(e)
                            : undefined
                        : undefined
          }
          onContextMenu={
            canvasOption === "edit"
              ? handleRightClickEdit
              : canvasOption === "draw"
                ? handleRightClickDraw
                : undefined
          }
        />
      </div>
      <div id="coco">
        <div style={{ width:"50px" }}>
          {!props.convertedImage && (
            <div>
              <Button fullWidth onClick={props.zoomStatelliteImage}>Zoom</Button>
              <Tooltip title={<Typography>Edit Polygon</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor:
                      canvasOption == "edit" ? "#e9f5f9" : "lightblue",
                  }}
                  onClick={(e) => {
                    canvasOption == "edit"
                      ? setCanvasOption("")
                      : setCanvasOption("edit");
                    setSelectedLabelingPolygons([]);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              {rightClickMenuVisible && (
                <IconButton
                  className="editMenu"
                  style={{
                    padding: "1px",
                    border: "1px solid black",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                    cursor: "pointer",
                    position: "absolute",
                    left: rightClickMenuPosition.x,
                    top: rightClickMenuPosition.y,
                    backgroundColor: hovered ? "#e9f5f9" : "lightblue",
                  }}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >
                  <Typography
                    style={{ color: "black", fontSize: "small" }}
                    onClick={handleRemovePoint}
                  >
                    Remove Point
                    </Typography>
                </IconButton>
              )}
              <Tooltip title={<Typography>Draw Polygon</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor:
                      canvasOption == "draw" ? "#e9f5f9" : "lightblue",
                  }}
                  onClick={(e) => {
                    handleDrawing();
                    setSelectedLabelingPolygons([]);
                  }}
                >
                  <DrawIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography>Move Polygon</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor:
                      canvasOption == "move" ? "#e9f5f9" : "lightblue",
                  }}
                  onClick={(e) => {
                    canvasOption == "move"
                      ? setCanvasOption("")
                      : setCanvasOption("move");
                    setSelectedLabelingPolygons([]);
                  }}
                >
                  <OpenWithIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography>Rotate Polygon</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor:
                      canvasOption == "rotate" ? "#e9f5f9" : "lightblue",
                  }}
                  onClick={(e) => {
                    if (canvasOption == "rotate") {
                      setCanvasOption("");
                      setSelectedPolygonIndex(null);
                    } else {
                      setCanvasOption("rotate");
                      setSelectedLabelingPolygons([]);
                    }
                  }}
                >
                  <CachedIcon />

                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography>Save Polygon</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor: "lightblue",
                  }}
                  onClick={(e) => {
                    setCanvasOption("");
                    saveFilePolygon();
                  }}
                >
                  <SaveAltIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography>Add solar panels</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor:
                      canvasOption == "solar" ? "#e9f5f9" : "lightblue",
                  }}
                  onClick={(e) => {
                    canvasOption == "solar"
                      ? setCanvasOption("")
                      : setCanvasOption("solar");
                    setSelectedLabelingPolygons([]);
                  }}
                >
                  <SolarPowerIcon />

                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography>Label polygons</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor:
                      canvasOption == "label" ? "#e9f5f9" : "lightblue",
                  }}
                  onClick={(e) => {
                    canvasOption == "label"
                      ? setCanvasOption("")
                      : setCanvasOption("label");
                  }}
                >
                  <LabelIcon />

                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography>Delete polygons</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor:
                      canvasOption == "delete" ? "#e9f5f9" : "lightblue",
                  }}
                  onClick={(e) => {
                    canvasOption == "delete"
                      ? setCanvasOption("")
                      : setCanvasOption("delete");
                    handleDeletePolygon();
                    setSelectedLabelingPolygons([]);
                  }}
                >
                  <DeleteSweepIcon />
                </IconButton>
              </Tooltip>
              {canvasOption === "draw" && (
                <div style={{ display: "flex" }}>
                  <label>
                    <Checkbox
                      checked={drawSRA}
                      onChange={() => setDrawSRA(!drawSRA)}
                      name="SRA polygon"
                      color="primary"
                    />
                      SRA polygon
                    </label>
                </div>
              )}
              {canvasOption === "rotate" && (
                <Slider
                  value={rotationAngle}
                  min={-180}
                  max={180}
                  step={1}
                  onChange={handleRotation}
                  onChangeCommitted={() => {
                    setIsRotating(false);
                  }}
                  style={{ width: "150px" }}
                />
              )}
              {canvasOption === "label" && (
                <div style={{ marginTop: "10px" }}>
                  <TextField
                    label="Label name"
                    id="outlined-size-small"
                    defaultValue={0}
                    value={labelName}
                    onChange={(e) => {
                      setLabelName(e.target.value);
                    }}
                    size="small"
                  />
                  <Button
                    style={{
                      backgroundColor: "lightblue",
                      color: "black",
                      borderRadius: "5px",
                      border: "1px solid black",
                      padding: "5px",
                    }}
                    onClick={(e) => {
                      setLabelForPolygons();
                    }}
                  >
                    Set Label
                    </Button>
                  <Button
                    style={{
                      backgroundColor: "lightblue",
                      color: "black",
                      borderRadius: "5px",
                      border: "1px solid black",
                      padding: "5px",
                    }}
                    onClick={(e) => {
                      setLabelForPolygons("sra");
                    }}
                  >
                    Set as sra
                    </Button>
                </div>
              )}
              {canvasOption === "solar" && (
                <>
                  <div style={{ marginTop: "10px" }}>
                    <TextField
                      label="Number of Panels"
                      id="outlined-size-small"
                      defaultValue={0}
                      value={solarNumber}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value)))
                          setSolarNumber(Number(e.target.value));
                      }}
                      size="small"
                      style={{ width: "65%" }}
                    />
                    <Button
                      style={{
                        backgroundColor: "lightblue",
                        color: "black",
                        borderRadius: "5px",
                        border: "1px solid black",
                        padding: "5px",
                      }}
                      onClick={(e) => {
                        computeDefaultTiles(
                          props.canvasRef.current!.width,
                          props.canvasRef.current!.height
                        );
                      }}
                    >
                      Add
                      </Button>
                  </div>
                  {/*                   
                              TILE BUTTONS
                              
                              */}
                  <div style={{ marginTop: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <IconButton
                        style={{
                          backgroundColor:
                            tileOption == "edit" ? "#e9f5f9" : "lightblue",
                          borderRadius: "5px",
                          border: "1px solid black",
                          padding: "5px",
                        }}
                        onClick={(e) => {
                          tileOption == "edit"
                            ? setTileOption("")
                            : setTileOption("edit");
                        }}
                      >
                        <Tooltip title="Edit Tile">
                          <AspectRatioIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>

                      <IconButton
                        style={{
                          backgroundColor:
                            tileOption == "move" ? "#e9f5f9" : "lightblue",
                          borderRadius: "5px",
                          border: "1px solid black",
                          padding: "5px",
                        }}
                        onClick={(e) => {
                          tileOption == "move"
                            ? setTileOption("")
                            : setTileOption("move");
                        }}
                      >
                        <Tooltip title="Move Tile">
                          <OpenWithIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        style={{
                          backgroundColor:
                            tileOption == "rotate" ? "#e9f5f9" : "lightblue",
                          borderRadius: "5px",
                          border: "1px solid black",
                          padding: "5px",
                        }}
                        onClick={(e) => {
                          if (tileOption == "rotate") {
                            setTileOption("");
                            setSelectedTileIndex(null);
                          } else {
                            setTileOption("rotate");
                          }
                        }}
                      >
                        <Tooltip title="Rotate Tile">
                          <CachedIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>

                      <IconButton
                        style={{
                          backgroundColor:
                            tileOption == "delete" ? "#e9f5f9" : "lightblue",
                          borderRadius: "5px",
                          border: "1px solid black",
                          padding: "5px",
                        }}
                        onClick={(e) => {
                          tileOption == "delete"
                            ? setTileOption("")
                            : setTileOption("delete");
                          handleDeleteTile();
                        }}
                      >
                        <Tooltip title="Delete Polygon">
                          <DeleteSweepIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                    </div>
                  </div>
                  {tileOption === "rotate" && (
                    <Slider
                      value={rotationAngleTile}
                      min={-180}
                      max={180}
                      step={1}
                      onChange={handleTileRotation}
                      onChangeCommitted={() => {
                        setIsRotatingTile(false);
                      }}
                      style={{ width: "150px" }}
                    />
                  )}
                </>
              )}

              {/**ZOOMING BUTTONS */}
              <Tooltip title={<Typography>Zoom in</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor: "lightblue",
                  }}
                  onClick={(e) => {
                    canvasOption != "zoomin" ?? setCanvasOption("zoomin");
                    handleCanvasZoomClick(null, "in");
                  }}
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography>Zoom out</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor: "lightblue",
                  }}
                  onClick={(e) => {
                    canvasOption != "zoomout" ?? setCanvasOption("zoomout");
                    handleCanvasZoomClick(null, "out");
                  }}
                >
                  <ZoomOutIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography>Recentre</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor:
                      canvasOption == "zoommove" ? "#e9f5f9" : "lightblue",
                  }}
                  onClick={(e) => {
                    canvasOption == "zoommove"
                      ? setCanvasOption("")
                      : setCanvasOption("zoommove");
                  }}
                >
                  <ZoomOutMapIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography>Update</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor:
                      canvasOption == "recrop" ? "#e9f5f9" : "lightblue",
                  }}
                  onClick={(e) => {
                    setCanvasOption("");
                  }}
                >
                  <CropIcon />
                </IconButton>
              </Tooltip>

            </div>
          )}
          {!props.convertedImage && (
            <IconButton
              className="sra_button"
              onClick={handleFetchTilingButtonClick}
            >
              Fetch Tiling
            </IconButton>
          ) && (
              <Tooltip title={showDots ? <Typography>Hide dots</Typography> : <Typography>Show dots</Typography>}>
                <IconButton
                  className="sra_button"
                  style={{
                    backgroundColor: "lightblue",
                  }}
                  onClick={() => {
                    if (
                      canvasOption == "zoomin" ||
                      canvasOption == "zoomout" ||
                      canvasOption == "zoommove"
                    ) {
                      console.log("TRIGGERED");
                      //if we used any zoom options before, upon showing/hiding dots, we save the image state to preserve the zooming
                      //currently, this piece of code runs upon checking the functionality for the canvas, but we don;t have something in place for the show/hide button
                      //thus we add it here
                      handleImageTransformations();
                      const tempPol = [...temporaryPolygonData];
                      props.setPolygonData(tempPol);
                      setTemporaryPolygonData([]);

                      const tempTile = [...temporaryTileData];
                      props.setTileData(tempTile);
                      setTemporaryTileData([]);
                      setScale(1);
                      setTranslate({ x: 0, y: 0 });
                      setZoomFactor(1);
                      setSelectedZoomOptions(false);
                    }
                    setCanvasOption("");
                    setShowDots(!showDots);
                  }}
                >
                  {showDots ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </Tooltip>
            )}

          <IconButton
            className="sra_button"
            style={{
              backgroundColor: "lightblue",
            }}
            onClick={() => {
              props.setConvertedImage(!props.convertedImage);
            }}
          >
            {props.convertedImage ? <ArrowBackIcon /> : <CameraAltIcon />}

          </IconButton>
        </div>
      </div>
    </>
  );
};

export default CanvasWithEditor;
