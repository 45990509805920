import React, { useContext, useEffect, useState } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  FormControl,
  MenuItem,
  Button,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableRow,
  TableCell,
  Modal,
  OutlinedInput,
  Typography,
  TextareaAutosize,
  Input,
  Checkbox,
  ListItemText,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";

import { Theme, useTheme } from "@mui/material/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import SavingsIcon from "@mui/icons-material/Savings";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UploadIcon from "@mui/icons-material/Upload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { deleteData, getData, postData, putData } from "./get-data";
import CloseIcon from "@mui/icons-material/Close";
import { UserContext } from "./contexts/UserContext";
import "./UploadPage.css";
import ReplayIcon from "@mui/icons-material/Replay";
import { FileUploader } from "react-drag-drop-files";
import { sendFileUpdatesToBackend, submitData } from "./App";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import NotificationForm from "./NotificationForm";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const filter = createFilterOptions<Organisation>();

interface IProps {
  setDashboardDatas: (newdata: DashboardData[]) => void;
  dashboardDatas: DashboardData[];
  handleDownloadFile: (fileName: string, reference: string) => void;
  setOpenDialogueHandler: (newVal: string) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      overflowX: "scroll" as const,
    },
  },
};

const names = ["Absolar", "Eclipse", "Ecogen ", "Infinity "];

function getStyles(id: number, installerName: number[], theme: Theme) {
  return {
    fontWeight:
      installerName.indexOf(id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function UpdatePage(props: IProps) {
  const userContext = useContext(UserContext);

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);

  let reference = queryParams.get("upRef");

  const theme = useTheme();
  const [installerName, setInstallerName] = React.useState<number[]>([]);
  const [installers, setInstallers] = useState<Installer[]>([]);
  const handleChange = (event: SelectChangeEvent<number[] | string>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === "string") {
      const ids = value.split(",").map((x) => parseInt(x));
      setInstallerName(ids);
    } else {
      setInstallerName(value);
    }
  };
  const handleEditPropertyChange = (
    event: SelectChangeEvent<number[] | string>
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === "string") {
      setSelectedExistingData(value);
      getEditData(value);
    }
  };

  const [uploadData, setUploadData] = useState<IngressDto>({
    reference: "",
    assessment_date: getTodaysDate(),
    root_uprn: "",
    uprn_type: "",
    toid: "",
    class_code: "",
    class_desc: "",
    class_sub_desc: "",
    latitude: null,
    longitude: null,
    address: "",
    postcode: "",
    max_building_height: null,
    min_building_height: null,
    install_capacity_kw: null,
    expected_power_generation_kwh: null,
    potential_saving_year_1: null,
    co2_saving_year_1: null,
    indicative_cost: null,
    total_utility_bill_savings_20: 0,
    roi: null,
    payback: "",
    monthly_repayment: 0,
    loan_total_utility_bill_savings_20: 0,
    loan_payback: "",
    loan_roi: 0,

    council_property: null,
    conservation_area: null,
    listed: null,
    organisation_id: 0,
    building_geom: "",
    suitable_roof_geoms: [],
    type_of_installation: "",
    self_consumption: 0,
    reference_panel: "PS410M6-18/VH",
    energy_price_inflation: 7,
    assum_carbon_factor: 0.253,
    assum_tariff: "32p import / 8p export",
    existing_solar: false,
    open_solar_link: "",

    comments: "",
    cta: null,
  });
  const [showSelect, setShowSelect] = React.useState(false);
  const [value, setValue] = useState<Organisation | null>(null);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [sraFile, setSraFile] = useState<File | null>(null);
  const [sraImage, setSraImage] = useState<string | null>(null);
  const [radFile, setRadFile] = useState<File | null>(null);
  const [radImage, setRadImage] = useState<string | null>(null);
  const [dataCount, setDataCount] = useState<number>(0);
  const [addingNewOrg, setAddingNewOrg] = useState<boolean>(false);
  const [newOrg, setNewOrg] = useState<Organisation>({
    id: -1,
    name: "",
    lat: 0,
    lon: 0,
    display_name: "",
  });
  const [existingData, setExistingData] = useState<RefAddressData[]>([]);
  const [selectedExistingData, setSelectedExistingData] = useState<string>("");
  function getExistingData() {
    if (reference) {
      getEditData(reference);
      getFilesForReference(reference);
    } else {
      console.log("reference: " + reference);
    }
    getData(
      process.env.REACT_APP_API_URL + "/DashboardData",
      false,
      userContext.getCredentials
    ).then((data: RefAddressData[]) => {
      setExistingData(data);
      if (reference) {
        const foundMatching = data.find((x) => x.reference == reference);
        if (foundMatching) {
          setSelectedExistingData(reference);
        }
      }
    });

    // UPDATE THE SRA AND RADIATION IMAGES:
    //the variables are sraFile and radFile
    console.log("FETCHING IMAGES");
    let url = process.env.REACT_APP_API_URL + "/Dashboard/satellite?toid=";
    if (reference)
      getData(url + reference, true, userContext.getCredentials)
        .then((response) => response.blob())
        .then((blob) => {
          const fl = new File([blob], reference + "_sat.png");
          setSraFile(fl);
          setSraImage(URL.createObjectURL(blob));
        })
        .catch((e) => {
          console.log("couldn't get sattelite image", e);
        });

    url = process.env.REACT_APP_API_URL + "/Dashboard/radiation?toid=";
    if (reference)
      getData(url + reference, true, userContext.getCredentials)
        .then((responseRad) => responseRad.blob())
        .then((blob) => {
          const fl = new File([blob], reference + "_sat.png");
          setRadFile(fl);
          setRadImage(URL.createObjectURL(blob));
        })
        .catch((e) => {
          console.log("couldn't get radiation image", e);
        });
  }

  function getEditData(reference: string) {
    getData(
      process.env.REACT_APP_API_URL + "/DashboardData/" + reference,
      false,
      userContext.getCredentials
    ).then((data: IngressDto) => {
      //extract postcode programatically from address

      var addressString = data.address;

      // Define a regular expression to match UK postcodes
      var regex = /[A-Z]{1,2}\d{1,2} ?\d[A-Z]{2}/;

      // Use the regular expression to find the postcode in the address string
      var postcodeMatch = addressString.match(regex);

      // Check if a postcode match was found
      var postcode = "";
      if (postcodeMatch) {
        postcode = postcodeMatch[0];
        console.log("Extracted postcode:", postcode);
      } else {
        console.log("Postcode not found in the address string.");
      }

      setUploadData({
        ...data,
        assessment_date: data.assessment_date.split("/").reverse().join("-"),
        postcode: postcode,
      });
    });
    getData(
      process.env.REACT_APP_API_URL +
        "/Installer/building_data_installer?reference=" +
        reference,
      false,
      userContext.getCredentials
    )
      .then((installers: Installer[]) => {
        setInstallerName(installers.map((x) => x.id));
      })
      .catch((e) => {
        console.log("unable to get installers");
      });
  }
  function splitPolygonString(str: string) {
    const regex = /(MULTIPOLYGON|POLYGON)\s*\((.*?)\)/gi;
    const matches = [];
    let match;
    while ((match = regex.exec(str)) !== null) {
      matches.push(match);
    }
    return matches.map((match) =>
      (
        match[1] +
        " (" +
        match[2] +
        "))" +
        (match[1].toUpperCase() == "POLYGON" ? "" : ")")
      ).trim()
    );
  }
  function setUploadDataProperty<K extends keyof IngressDto>(
    key: K,
    value: IngressDto[K]
  ): void {
    setUploadData((prevState) => ({ ...prevState, [key]: value }));
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fileToSet: React.Dispatch<React.SetStateAction<File | null>>,
    fileTypeExpected: string,
    fileExpected: string
  ) => {
    if (
      event.target.files &&
      event.target.files[0].name.slice(-3).toLowerCase() ===
        fileTypeExpected.toLowerCase()
    ) {
      if (event.target.files && event.target.files.length > 0) {
        fileToSet(event.target.files[0]);
        if (fileToSet == setSraFile) {
          setSraImage(URL.createObjectURL(event.target.files[0]));
        } else if (fileToSet == setRadFile) {
          setRadImage(URL.createObjectURL(event.target.files[0]));
        }
      }
      setError("");
    } else {
      if (fileTypeExpected == "pdf") {
        setPdfFile(null);
      } else {
        if (fileExpected == "SRA") {
          setSraFile(null);
          setSraImage(null);
        } else if (fileExpected == "SRI") {
          setRadFile(null);
          setRadImage(null);
        }
      }
      setError(fileTypeExpected);
    }
  };
  function postOrganisation() {
    postData(
      process.env.REACT_APP_API_URL + "/organisation",
      newOrg,
      false,
      userContext.getCredentials
    ).then((org: Organisation) => {
      const prevOrgs = organisations;
      prevOrgs.push(org);
      setOrganisations(prevOrgs);
      setUploadDataProperty("organisation_id", org.id);
      setUploadDataProperty(
        "reference",
        org.name + "_" + org.id + "_" + (dataCount + 1).toString()
      );
      setAddingNewOrg(false);
      setNewOrg({
        id: -1,
        name: "",
        lat: 0,
        lon: 0,
        display_name: "",
      });
    });
  }

  async function submitUploadData() {
    if (dataCount !== 0) {
      postData(
        process.env.REACT_APP_API_URL + "/ingress",
        uploadData,
        true,
        userContext.getCredentials
      )
        .then((response) => response.text())
        .then((result: number) => {
          if (result == 0) {
            props.setOpenDialogueHandler("Successfully Uploaded");
            //alert("successfully uploaded");
            putData(
              process.env.REACT_APP_API_URL +
                "/Installer/building_data_installer?reference=" +
                uploadData.reference,
              installerName,
              false,
              userContext.getCredentials
            ).then((installers: Installer[]) => {
              console.log(installers.map((x) => x.name).join(","));
            });
            sendDataToTable();

            getReferenceCount();
            navigate("/");

            //upload the files to this property as well
            handleUploadFilesToBackend();
          }
        })
        .catch((e) => alert(e.message));
      if (pdfFile) {
        submitData(
          pdfFile,
          uploadData.reference + ".pdf",
          userContext.userState.idToken,
          undefined
        )
          .then((res) => {
            return res.text();
          })
          .then((result: string) => {
            if (result == "0") {
              alert("uploaded file " + pdfFile.name);
            }
          });
      }
      if (sraFile) {
        await submitData(
          sraFile,
          uploadData.reference + "_sat.png",
          userContext.userState.idToken,
          undefined
        )
          .then((res) => {
            return res.text();
          })
          .then((result: string) => {
            if (result == "0") {
              alert("uploaded file " + sraFile.name);
            }
          });
      }
      if (radFile) {
        await submitData(
          radFile,
          uploadData.reference + "_rad.png",
          userContext.userState.idToken,
          undefined
        )
          .then((res) => {
            return res.text();
          })
          .then((result: string) => {
            if (result == "0") {
              alert("uploaded file " + radFile.name);
            }
          });
      }
    } else {
      alert(
        "we haven't loaded the reference properly and therefore can't submit, check the reference let chris know if this continues."
      );
    }
  }
  // Assuming sraFile holds the File object with the PNG data
  const fileReader = new FileReader();

  fileReader.onload = (event) => {
    // event.target.result contains the data URL of the PNG file
    const dataURL: string = event.target!.result as string;

    // Now you can use dataURL as needed, e.g., display it as an image:
    const img = new Image();
    img.src = dataURL;
    document.body.appendChild(img);
  };

  function getReferenceCount() {
    getData(
      process.env.REACT_APP_API_URL + "/Ingress/referenceCount",
      true,
      userContext.getCredentials
    )
      .then((response) => response.text())
      .then((dataCount: string) => {
        setDataCount(parseInt(dataCount));
      });
  }
  function getOrganisations() {
    getData(
      process.env.REACT_APP_API_URL + "/Organisation/all",
      false,
      userContext.getCredentials
    ).then((organisations: Organisation[]) => {
      setOrganisations(organisations);
    });
    getReferenceCount();
    getData(
      process.env.REACT_APP_API_URL + "/Installer",
      false,
      userContext.getCredentials
    ).then((installers: Installer[]) => {
      setInstallers(installers);
    });
  }
  useEffect(() => {
    getOrganisations();
    getExistingData();
  }, [reference]);
  function checkMultipolygon(input: string) {
    let regex =
      /MULTIPOLYGON \(\(\((-?\d+\.\d+\s-?\d+\.\d+)(\s*,\s*-?\d+\.\d+\s-?\d+\.\d+)*\)\)\)/g;
    return regex.test(input.toUpperCase());
  }
  function checkPolygon(input: string) {
    let regex =
      /POLYGON \(\((-?\d+\.\d+\s-?\d+\.\d+)(\s*,\s*-?\d+\.\d+\s-?\d+\.\d+)*\)\)/g;
    return regex.test(input.toUpperCase());
  }
  function checkSRAPolygon(SRAGeoms: string[]) {
    return SRAGeoms.length > 0
      ? SRAGeoms.every((x) => checkMultipolygon(x) || checkPolygon(x))
      : true;
  }
  function checkBuildingOutline() {
    return uploadData.building_geom != ""
      ? checkMultipolygon(uploadData.building_geom) ||
          checkPolygon(uploadData.building_geom)
      : true;
  }
  const [error, setError] = React.useState("");

  const [newUserName, setnewUserName] = React.useState("");
  const [newEmail, setnewEmail] = React.useState("");
  const [newJob, setnewJob] = React.useState("");
  const [newCompany, setnewCompany] = React.useState("");
  const [newPhone, setnewPhone] = React.useState("");
  const [newEnv, setnewEnv] = React.useState<string[]>([]);

  const updateNewUserEnv = (event: any) => {
    setnewEnv(event.target.value);
  };

  async function submitNewUser() {
    var uploadDataUser = {
      emailAddress: newEmail,
      givenName: newUserName,
      title: newJob,
      company: newCompany,
      phone: newPhone,
      groups: newEnv,
    };

    postData(process.env.REACT_APP_API_URL + "/auth/signup", uploadDataUser)
      .then((response) => response.text())
      .then((result: number) => {
        if (result == 0) {
          alert("successfully created new user");
        }
      })
      .catch((e) => alert(e.message));
  }

  const [file_names, setFile_names] = useState<
    { fileName: string; isCrossed: boolean }[]
  >([]);
  const [file_templates, setFile_templates] = useState<
    {
      template: string;
      is_uploaded: boolean;
    }[]
  >([]);
  const [templatesChecked, setTemplatesChecked] = useState<
    {
      template: string;
      is_uploaded: boolean;
    }[]
  >([]);

  // const [editingFiles, setEditingFiles] = React.useState(true);
  function getFilesForReference(reference: string) {
    getData(
      process.env.REACT_APP_API_URL + "/File/file_names?reference=" + reference,
      false,
      userContext.getCredentials
    )
      .then((fileNames: string[]) => {
        var files: { fileName: string; isCrossed: boolean }[] = [];
        fileNames.forEach((eachFile) =>
          files.push({
            fileName: eachFile,
            isCrossed: false,
          })
        );
        setFile_names(files);
        console.log("successful retrieval of files from property");
      })
      .catch((e) => {
        console.log("unable to get files from property");
      });

    //get data for file templates
    var urlForTemplates =
      process.env.REACT_APP_API_URL + "/Template/FileTemplates";

    getData(urlForTemplates, false, userContext.getCredentials)
      .then((data: { orgId: number; templates: string[] }[]) => {
        console.log("templates are", data, data[0].orgId);
        //for future: if we need to sort these based on organisation id, this next line is the place
        var templatesStrings = data[0].templates;
        //now, retrieving the ones that are already uploaded/marked/
        const urlForMarkedTemplates =
          process.env.REACT_APP_API_URL +
          "/Template/ReferenceTemplates?reference=" +
          reference;
        getData(urlForMarkedTemplates, false, userContext.getCredentials)
          .then((data: { reference: string; template: string }[]) => {
            //retrieving the "marked"/"done" templates
            let templateArray = data.map((obj) => obj.template);
            //now, making an array for the state variable file_templates that includes which ones are ,arked and which aren't
            var arrayForFile_templates: {
              template: string;
              is_uploaded: boolean;
            }[] = [];
            templatesStrings.forEach((eachGeneralTemplate) => {
              if (templateArray.includes(eachGeneralTemplate)) {
                arrayForFile_templates.push({
                  template: eachGeneralTemplate,
                  is_uploaded: true,
                });
              } else {
                arrayForFile_templates.push({
                  template: eachGeneralTemplate,
                  is_uploaded: false,
                });
              }
            });
            setFile_templates(arrayForFile_templates);

            const clonedArray = arrayForFile_templates.map((obj) => ({
              ...obj,
            }));

            setTemplatesChecked(clonedArray);
          })
          .catch((e) => {
            console.log("couldn't retrieve file reference templates");
          });
      })
      .catch((e) => {
        console.log("couldn't retrieve file templates");
      });

    // //further in frontend: NOT NEEDED anymore
    // var objFromBackend = [{template: "Feasibility Report", is_uploaded: false}, {template: "Survey", is_uploaded: true}];
    // setFile_templates(objFromBackend);

    // const clonedArray = objFromBackend.map((obj) => ({ ...obj }));

    // setTemplatesChecked(clonedArray);
  }

  function handleCrossFile(index: number) {
    const updatedFileList = [...file_names];
    updatedFileList[index].isCrossed = !updatedFileList[index].isCrossed;
    setFile_names(updatedFileList);
  }
  // function handleEditSaveFiles() {
  //   if (editingFiles) { //if there has been edits (i.e. removal)
  //     const crossedFiles: { fileName: string; isCrossed: boolean; }[] = [];
  //     const remainingFiles: { fileName: string; isCrossed: boolean; }[] = [];

  //     file_names.forEach((file) => {
  //       if (file.isCrossed) {
  //         crossedFiles.push(file);
  //       } else {
  //         remainingFiles.push(file);
  //       }
  //     });

  //     setFile_names([...remainingFiles]);
  //   }
  //   editingFiles ? setEditingFiles(false) : setEditingFiles(true);

  // }

  const [newUploadedFiles, setNewUploadedFiles] = React.useState<File[]>([]);
  const handleNewFileUpload = (file: FileList) => {
    //adding new files through button
    var fileToUse = file.item(0);
    if (fileToUse) {
      const isFileInArray = newUploadedFiles.some(
        (eachFile) => eachFile === fileToUse
      );
      if (!isFileInArray) {
        setNewUploadedFiles([...newUploadedFiles, fileToUse]);
      }
    }
  };
  const handleRemoveNewFile = (file: File) => {
    //removing newly added files
    setNewUploadedFiles(newUploadedFiles.filter((f) => f !== file));
  };
  function handleUploadFilesToBackend() {
    //first, checking which (if any) files need to be removed
    const crossedFilesToRemove: { fileName: string; isCrossed: boolean }[] = [];
    const remainingFiles: { fileName: string; isCrossed: boolean }[] = [];

    file_names.forEach((file) => {
      if (file.isCrossed) {
        crossedFilesToRemove.push(file);
      } else {
        remainingFiles.push(file);
      }
    });

    sendFileUpdatesToBackend(
      crossedFilesToRemove,
      newUploadedFiles,
      reference != null ? reference : uploadData.reference,
      userContext.userState.idToken,
      userContext.getCredentials
    );

    //frontend:
    //check if there are any newly updated files and if there are, reflect the changes in frontend
    var arrayToAdd: { fileName: string; isCrossed: boolean }[] = [];
    newUploadedFiles.forEach((file) => {
      arrayToAdd.push({ fileName: file.name, isCrossed: false });
    });
    setFile_names([...remainingFiles, ...arrayToAdd]);

    //remove the uploading files list
    setNewUploadedFiles([]);

    //also update the backend for the template files if there's been a change
    updateTemplateFilesStatus();
  }

  function getTodaysDate(): string {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();

    return `${year}-${month}-${day}`;
  }

  function updateTemplateFilesStatus() {
    //we have file_templates and templatesChecked which are exactly teh same
    //but file_templates contain teh original values from teh backend
    //and templatesChecked contain the updated values

    //here, we update the changes in the backend:
    // Iterating through checkedCoco and comparing with originalCoco

    templatesChecked.forEach((eachCheck, index) => {
      if (
        file_templates[index] &&
        eachCheck.template === file_templates[index].template &&
        eachCheck.is_uploaded !== file_templates[index].is_uploaded
      ) {
        var endpointForPost =
          process.env.REACT_APP_API_URL +
          "/Template/ReferenceTemplates?reference=" +
          uploadData.reference +
          "&template=" +
          eachCheck.template;
        var endpointForDelete =
          process.env.REACT_APP_API_URL +
          "/Template/ReferenceTemplates/" +
          uploadData.reference +
          "/" +
          eachCheck.template;
        if (eachCheck.is_uploaded) {
          //it means we are removing it/marking it as done/etc
          postData(endpointForPost, [], true, userContext.getCredentials)
            .then((response) => {
              console.log("response is", response.text());
            })
            .then((data) => {
              console.log("response status is:", data);
            })
            .catch((e) => {
              console.log("unable to post change", e);
            });
        } else {
          //it means we are changing it from "done" to "undone"
          deleteData(endpointForDelete, true, userContext.getCredentials)
            .then((data) => {
              console.log("successful deletion");
            })
            .catch((e) => {
              console.log("failed to delete template", e);
            });
        }
      }
    });

    setFile_templates((prevTemplates) => {
      const updatedFileTemplates = prevTemplates.map((template, index) => {
        if (template.is_uploaded !== templatesChecked[index].is_uploaded) {
          return {
            ...template,
            is_uploaded: templatesChecked[index].is_uploaded,
          };
        }
        return template;
      });
      return updatedFileTemplates;
    });

    //TODO (backend): update the array in the backend
  }

  const [open_notification, setOpen_notification] = useState(false);
  const handleOpen_notification = () => setOpen_notification(true);
  const handleClose_notification = () => setOpen_notification(false);

  return (
    <>
      <div
        style={{
          width: "80%",
          maxWidth: "1200px",
          margin: "30px auto",
          textAlign: "left",
        }}
      >
        {/* <Button
      variant="contained"
      style={{ float: "right", textAlign: "center", padding: "5px 30px" }}
    >
      <IconButton>
        <PictureAsPdfIcon style={{ color: "white", fontSize: "30px" }} />
      </IconButton>
      <div>Download PDF report</div>
    </Button> */}

        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} md={9}>
            <h1>Result Upload Platform</h1>
            <p>
              This platform is for Absolar assessors to upload assessment
              results. For assistance about how to use the platform and
              guidance, please contact{" "}
              <a href="mailto:info@absolar.co.uk?subject=Question about how to upload results to Solar Watcher">
                info@absolar.co.uk
              </a>
            </p>
            <p>
              Query page:{" "}
              <a href="https://survey.absolar.co.uk/">
                https://survey.absolar.co.uk/
              </a>
            </p>
            <h3 style={{ marginTop: "40px" }}>Organisation ID</h3>
            <div className="editor_text_div">
              <div className="editor_text_label">
                Choose an organisation environment
              </div>
            </div>
            <FormControl fullWidth>
              <Select
                open={showSelect}
                onOpen={() => setShowSelect(true)}
                onClose={() => setShowSelect(false)}
                size="small"
                value={addingNewOrg ? null : uploadData.organisation_id}
                onChange={(e) => {
                  setUploadDataProperty(
                    "organisation_id",
                    e.target.value as number
                  );
                  //hotfix - frontend - change for updating reference
                  var orgNameAbbreviation = organisations.find(
                    (x) => x.id == e.target.value
                  )?.name;
                  var envBasedDashboard = props.dashboardDatas.filter(
                    (eachProp) =>
                      eachProp.reference
                        .toLowerCase()
                        .includes(
                          (
                            orgNameAbbreviation +
                            "_" +
                            e.target.value
                          ).toLowerCase()
                        )
                  );
                  // var highestNumber = 0;
                  // if (envBasedDashboard.length != 0) {
                  //   const highestNumberObject = envBasedDashboard.reduce(
                  //     (maxObj: DashboardData, currentObj: DashboardData) => {
                  //       const maxReference = maxObj
                  //         ? maxObj.reference
                  //         : undefined;
                  //       const currentReference = currentObj.reference;

                  //       // Skip objects with undefined references
                  //       if (!maxReference || !currentReference) {
                  //         return maxObj;
                  //       }

                  //       const maxNumber = parseInt(
                  //         maxReference.split("_").pop()!,
                  //         10
                  //       );
                  //       const currentNumber = parseInt(
                  //         currentReference.split("_").pop()!,
                  //         10
                  //       );

                  //       if (currentNumber > maxNumber) {
                  //         return currentObj;
                  //       } else {
                  //         return maxObj;
                  //       }
                  //     }
                  //   );
                  //   highestNumber = parseInt(
                  //     highestNumberObject.reference.split("_").pop()!,
                  //     10
                  //   );
                  // }
                  // console.log(envBasedDashboard, highestNumber);

                  setUploadDataProperty(
                    "reference",
                    organisations.find((x) => x.id == e.target.value)?.name +
                      "_" +
                      e.target.value +
                      "_" +
                      (dataCount + 1).toString()
                  );

                  // setUploadDataProperty(
                  //   "reference",
                  //   organisations.find((x) => x.id == e.target.value)?.name +
                  //     "_" +
                  //     e.target.value +
                  //     "_" +
                  //     (dataCount + 1).toString()
                  // );
                  setAddingNewOrg(false);
                }}
              >
                {organisations.map((organisation) => {
                  return (
                    <MenuItem key={organisation.id} value={organisation.id}>
                      {organisation.display_name}
                    </MenuItem>
                  );
                })}
                <Button
                  fullWidth
                  onClick={() => {
                    setShowSelect(false);
                    setAddingNewOrg(!addingNewOrg);
                  }}
                  style={{ textTransform: "none" }}
                >
                  {addingNewOrg ? "Cancel" : "Add a new organisation"}
                </Button>
              </Select>
            </FormControl>
            {addingNewOrg ? (
              <div
                style={{
                  padding: 20,
                  background: "lightgrey",
                  width: "80%",
                  margin: "30px auto",
                }}
              >
                <Grid container spacing={2} style={{ width: "100%" }}>
                  <Grid item xs={12}>
                    <Button
                      // variant="contained"
                      startIcon={<DeleteForeverIcon />}
                      style={{ textTransform: "none" }}
                      onClick={() => {
                        setAddingNewOrg(!addingNewOrg);
                      }}
                      color={addingNewOrg ? "error" : "info"}
                    >
                      <u>Cancel adding new organisation</u>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="editor_text_label">
                      New org short-name (e.g. ussp):
                    </div>
                    <TextField
                      size="small"
                      className="edit_textfield"
                      value={newOrg.name}
                      onChange={(e) => {
                        setNewOrg((prevState) => ({
                          ...prevState,
                          name: e.target.value,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="editor_text_label">
                      Display name (e.g. University of Southampton Science Park)
                    </div>
                    <TextField
                      size="small"
                      className="edit_textfield"
                      value={newOrg.display_name}
                      onChange={(e) => {
                        setNewOrg((prevState) => ({
                          ...prevState,
                          display_name: e.target.value,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="editor_text_label">
                      Lat (central location)
                    </div>{" "}
                    <TextField
                      size="small"
                      className="edit_textfield"
                      value={newOrg.lat}
                      type="number"
                      onChange={(e) => {
                        setNewOrg((prevState) => ({
                          ...prevState,
                          lat: parseFloat(e.target.value),
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="editor_text_label">
                      Lon (central location)
                    </div>
                    <TextField
                      size="small"
                      className="edit_textfield"
                      type="number"
                      value={newOrg.lon}
                      onChange={(e) => {
                        setNewOrg((prevState) => ({
                          ...prevState,
                          lon: parseFloat(e.target.value),
                        }));
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        postOrganisation();
                      }}
                    >
                      {" "}
                      Add organisation
                    </Button>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div>
                Optional choose to edit an existing property:
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={selectedExistingData}
                  label="existingDataChoice"
                  onChange={handleEditPropertyChange}
                >
                  {(uploadData.organisation_id == 0
                    ? existingData
                    : existingData.filter(
                        (x) => x.organisation_id == uploadData.organisation_id
                      )
                  ).map((refAddress) => {
                    return (
                      <MenuItem value={refAddress.reference}>
                        {refAddress.reference +
                          "; Address:" +
                          refAddress.address}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            )}

            {uploadData.organisation_id > 0 ? (
              <>
                <h3 id="start" style={{ marginTop: "80px" }}>
                  Assessment results
                </h3>
                <Grid container spacing={1} justifyContent="space-between">
                  <Grid item xs={12} md={12}>
                    <div className="editor_text_div">
                      <div className="question_main_label">
                        Address (single line)
                      </div>
                      <TextField
                        size="small"
                        className="edit_textfield_compulsory"
                        value={uploadData.address}
                        onChange={(e) =>
                          setUploadDataProperty("address", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="editor_text_div">
                      <div className="question_main_label">
                        Reference Number (set automatically)
                      </div>
                      <TextField
                        size="small"
                        className="edit_textfield"
                        value={uploadData.reference}
                        onChange={(e) =>
                          setUploadDataProperty("reference", e.target.value)
                        }
                        disabled
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="editor_text_div">
                      <div className="question_main_label">Assessment date</div>
                      <TextField
                        size="small"
                        type="date"
                        className="edit_textfield_compulsory"
                        value={uploadData.assessment_date}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setUploadDataProperty(
                            "assessment_date",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    {" "}
                    <div className="editor_text_div">
                      <div className="question_main_label">Postcode</div>
                      <TextField
                        size="small"
                        className="edit_textfield_compulsory"
                        value={uploadData.postcode}
                        onChange={(e) =>
                          setUploadDataProperty("postcode", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="editor_text_div">
                      <div className="question_main_label_non_comp">
                        Expected power generation kWh
                      </div>
                      <TextField
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kWh</InputAdornment>
                          ),
                        }}
                        size="small"
                        className="edit_textfield"
                        type="number"
                        value={uploadData.expected_power_generation_kwh}
                        onChange={(e) =>
                          setUploadDataProperty(
                            "expected_power_generation_kwh",
                            parseFloat(e.target.value)
                          )
                        }
                      />
                    </div>
                  </Grid>

                  {uploadData.organisation_id === 4 ||
                  uploadData.organisation_id === 20 ||
                  uploadData.organisation_id === 21 ? ( //if it's natwest, don't show the pdf upload here
                    <></>
                  ) : (
                    <Grid item xs={12} md={12}>
                      <h3 style={{ marginTop: "40px" }}>One page report</h3>
                      {pdfFile ? (
                        <>
                          <div className="editor_text_div" style={{}}>
                            <OfflinePinIcon
                              style={{ color: "#1976d2", fontSize: 50 }}
                            />{" "}
                            File successfully selected:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "#1976d2" }}
                            >
                              {pdfFile.name}
                            </span>
                          </div>
                          <Button
                            // fullWidth
                            variant="outlined"
                            component="label"
                            color="error"
                            endIcon={<DeleteForeverIcon />}
                            style={{
                              textTransform: "none",
                              marginTop: 20,
                              // height: 150,
                              // border: "#1976d2 1px dashed",
                            }}
                          >
                            {pdfFile
                              ? "Click to replace with another file."
                              : "Upload PDF"}
                            <input
                              type="file"
                              accept=".pdf"
                              hidden
                              onChange={(e) => {
                                handleFileChange(e, setPdfFile, "pdf", "UP");
                              }}
                            />
                          </Button>
                          {error == "pdf" ? (
                            <p
                              style={{
                                marginBottom: "0",
                                color: "red",
                                fontStyle: "italic",
                                fontSize: "smaller",
                              }}
                            >
                              Please upload a ".pdf" type file.
                            </p>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="editor_text_div">
                            <Button
                              fullWidth
                              variant="outlined"
                              component="label"
                              startIcon={<UploadIcon />}
                              style={{
                                textTransform: "none",
                                height: 150,
                                border: "#1976d2 1px dashed",
                              }}
                            >
                              {pdfFile
                                ? "Click to replace with another file."
                                : "Upload PDF"}
                              <input
                                type="file"
                                accept=".pdf"
                                hidden
                                onChange={(e) => {
                                  handleFileChange(e, setPdfFile, "pdf", "UP");
                                }}
                              />
                            </Button>
                            {error == "pdf" ? (
                              <p
                                style={{
                                  marginBottom: "0",
                                  color: "red",
                                  fontStyle: "italic",
                                  fontSize: "smaller",
                                }}
                              >
                                Please upload a ".pdf" type file.
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>

                {/* <hr style={{ margin: "20px auto" }} /> */}

                <Grid
                  container
                  style={{ minHeight: "180px", marginTop: 30 }}
                  spacing={2}
                  justifyItems="flex-end"
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={3}
                    className="hover_effect"
                  >
                    <div
                      style={{
                        background: "#e8e8e8",
                        height: "100%",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ margin: "10px auto 5px auto" }}>
                        <ViewModuleIcon
                          style={{
                            fontSize: "50px",
                            marginTop: 20,
                            color: "#1976d2",
                          }}
                        />
                      </div>
                      <div className="editor_text_div">
                        <div className="question_main_label_non_comp">
                          Install Capacity kW{" "}
                        </div>
                        <TextField
                          style={{ width: "80%" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">kW</InputAdornment>
                            ),
                          }}
                          size="small"
                          className="edit_textfield"
                          type="number"
                          value={uploadData.install_capacity_kw}
                          onChange={(e) =>
                            setUploadDataProperty(
                              "install_capacity_kw",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </div>
                      {/* <div>System Size</div> */}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={3}
                    className="hover_effect"
                  >
                    <div
                      style={{
                        background: "#e8e8e8",
                        height: "100%",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ margin: "10px auto 5px auto" }}>
                        <SavingsIcon
                          style={{
                            fontSize: "50px",
                            marginTop: 20,
                            color: "#1976d2",
                          }}
                        />
                      </div>
                      <div className="editor_text_div">
                        <div className="question_main_label_non_comp">
                          Potential savings in 1st year
                        </div>
                        <TextField
                          style={{ width: "80%" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          className="edit_textfield"
                          value={uploadData.potential_saving_year_1}
                          type="number"
                          onChange={(e) =>
                            setUploadDataProperty(
                              "potential_saving_year_1",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </div>
                      {/* <div>Estimated First Year Savings</div> */}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={3}
                    className="hover_effect"
                  >
                    <div
                      style={{
                        background: "#e8e8e8",
                        height: "100%",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ margin: "10px auto 5px auto" }}>
                        <EnergySavingsLeafIcon
                          style={{
                            fontSize: "50px",
                            marginTop: 20,
                            color: "#1976d2",
                          }}
                        />
                      </div>
                      <div className="editor_text_div">
                        <div className="question_main_label_non_comp">
                          CO2e savings in 1st year
                        </div>
                        <TextField
                          style={{ width: "80%" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span style={{ fontSize: "12px" }}>Tonnes</span>
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          className="edit_textfield"
                          value={uploadData.co2_saving_year_1}
                          type="number"
                          onChange={(e) =>
                            setUploadDataProperty(
                              "co2_saving_year_1",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </div>
                      {/* <div>
                  CO<sub>2e</sub> saving in year 1
                </div> */}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={3}
                    className="hover_effect"
                  >
                    <div
                      style={{
                        background: "#01579b",
                        height: "100%",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      <div style={{ margin: "10px auto 5px auto" }}>
                        <CurrencyPoundIcon
                          style={{ fontSize: "50px", marginTop: 20 }}
                        />
                      </div>
                      <div className="editor_text_div">
                        <div
                          className="question_main_label_non_comp"
                          style={{ color: "white" }}
                        >
                          Indicative cost (excl VAT)
                        </div>
                        <TextField
                          style={{ width: "80%" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          className="edit_textfield"
                          value={uploadData.indicative_cost}
                          type="number"
                          onChange={(e) =>
                            setUploadDataProperty(
                              "indicative_cost",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </div>
                      {/* <div>"Indicative cost (excl VAT)"</div> */}
                    </div>
                  </Grid>
                </Grid>

                <div
                  style={{
                    marginTop: 50,
                    boxShadow: "2px 2px 8px 1px grey",
                    borderRadius: "6px",
                    padding: 20,
                  }}
                >
                  <div style={{ fontSize: "22px", fontWeight: "500" }}>
                    Investment performance - based on cash purchase
                  </div>
                  <Table>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow className="table_row_result_page">
                      <TableCell>
                        <span className="question_main_label_non_comp">
                          Lifetime net savings (over 20 years)
                        </span>
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "18px", textAlign: "right" }}
                      >
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          className="edit_textfield"
                          value={uploadData.total_utility_bill_savings_20}
                          type="number"
                          onChange={(e) =>
                            setUploadDataProperty(
                              "total_utility_bill_savings_20",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow className="table_row_result_page">
                      <TableCell>
                        <span className="question_main_label">Payback</span>
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "18px", textAlign: "right" }}
                      >
                        <TextField
                          size="small"
                          className="edit_textfield"
                          value={uploadData.payback}
                          // type="number"
                          onChange={(e) =>
                            setUploadDataProperty("payback", e.target.value)
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow className="table_row_result_page">
                      <TableCell>
                        <span className="question_main_label_non_comp">
                          Investment return
                        </span>
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "18px", textAlign: "right" }}
                      >
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                          size="small"
                          className="edit_textfield"
                          value={uploadData.roi}
                          type="number"
                          onChange={(e) =>
                            setUploadDataProperty(
                              "roi",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </Table>
                </div>

                <div
                  style={{
                    marginTop: 50,
                    boxShadow: "2px 2px 8px 1px grey",
                    borderRadius: "6px",
                    padding: 20,
                  }}
                >
                  <div style={{ fontSize: "22px", fontWeight: "500" }}>
                    Investment performance - based on using bank loans
                  </div>
                  <Table>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow className="table_row_result_page">
                      <TableCell>
                        <span className="question_main_label">
                          Monthly repayment (over 5 years){" "}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "18px", textAlign: "right" }}
                      >
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          type="number"
                          className="edit_textfield"
                          value={uploadData.monthly_repayment}
                          onChange={(e) =>
                            setUploadDataProperty(
                              "monthly_repayment",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow className="table_row_result_page">
                      <TableCell>
                        <span className="question_main_label">
                          Lifetime net savings (over 20 years)
                        </span>
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "18px", textAlign: "right" }}
                      >
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          className="edit_textfield"
                          type="number"
                          value={uploadData.loan_total_utility_bill_savings_20}
                          onChange={(e) =>
                            setUploadDataProperty(
                              "loan_total_utility_bill_savings_20",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow className="table_row_result_page">
                      <TableCell>
                        <span className="question_main_label">Payback</span>
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "18px", textAlign: "right" }}
                      >
                        <TextField
                          size="small"
                          className="edit_textfield"
                          // type="number"
                          value={uploadData.loan_payback}
                          onChange={(e) =>
                            setUploadDataProperty(
                              "loan_payback",
                              e.target.value
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow className="table_row_result_page">
                      <TableCell>
                        <span className="question_main_label">
                          Investment return
                        </span>
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "18px", textAlign: "right" }}
                      >
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                          size="small"
                          className="edit_textfield"
                          type="number"
                          value={uploadData.loan_roi}
                          onChange={(e) =>
                            setUploadDataProperty(
                              "loan_roi",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </Table>
                  <div style={{ marginTop: 10, textAlign: "right" }}>
                    * Based on an indicative 60 month loan at 9.0% APR
                  </div>
                </div>

                <Grid
                  container
                  justifyContent="space-evenly"
                  style={{
                    // marginTop: 30,
                    boxShadow: "2px 2px 8px 1px grey",
                    borderRadius: "6px",
                    padding: 20,
                    width: "100%",
                    margin: "30px auto",
                  }}
                  spacing={2}
                >
                  <Grid item xs={12} md={6} style={{ padding: 0 }}>
                    <div>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "500",
                          marginBottom: 10,
                        }}
                      >
                        Suitable Roof Area
                      </div>

                      <div
                        id="radiation_image"
                        style={{ height: "fit-content" }}
                      >
                        <div className="editor_text_div">
                          {sraFile && sraImage ? (
                            <>
                              <div className="editor_text_div" style={{}}>
                                <div>
                                  <OfflinePinIcon
                                    style={{ color: "#1976d2", fontSize: 20 }}
                                  />{" "}
                                  Image successfully selected:{" "}
                                </div>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#1976d2",
                                  }}
                                >
                                  {sraFile.name}
                                </span>
                                <img
                                  alt="image of the building rooftop with an outline for the building and the solar area"
                                  src={sraImage}
                                  style={{ height: "320px", margin: "auto" }}
                                />
                              </div>
                              <Button
                                variant="outlined"
                                component="label"
                                color="error"
                                style={{ textTransform: "none", marginTop: 10 }}
                              >
                                Replace with another image
                                <input
                                  type="file"
                                  accept=".png"
                                  hidden
                                  onChange={(e) => {
                                    handleFileChange(
                                      e,
                                      setSraFile,
                                      "png",
                                      "SRA"
                                    );
                                  }}
                                />
                              </Button>
                            </>
                          ) : (
                            <>
                              <div className="editor_text_label">
                                Upload SRA polygon image{" "}
                              </div>
                              <Button
                                variant="outlined"
                                component="label"
                                startIcon={<UploadIcon />}
                                style={{ textTransform: "none" }}
                              >
                                Upload SRA png
                                <input
                                  type="file"
                                  accept=".png"
                                  hidden
                                  onChange={(e) => {
                                    handleFileChange(
                                      e,
                                      setSraFile,
                                      "png",
                                      "SRA"
                                    );
                                  }}
                                />
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: 0 }}>
                    <div>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "500",
                          marginBottom: 10,
                          padding: 0,
                        }}
                      >
                        Solar radiation image
                      </div>
                      <div
                        id="radiation_image"
                        style={{ height: "fit-content" }}
                      >
                        <div className="editor_text_div">
                          {radFile && radImage ? (
                            <>
                              <div className="editor_text_div" style={{}}>
                                <div>
                                  <OfflinePinIcon
                                    style={{ color: "#1976d2", fontSize: 20 }}
                                  />{" "}
                                  Image successfully selected:{" "}
                                </div>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#1976d2",
                                  }}
                                >
                                  {radFile.name}
                                </span>
                                <img
                                  alt="image of the building rooftop with an outline for the building and the solar area"
                                  src={radImage}
                                  style={{ height: "320px", margin: "auto" }}
                                />
                              </div>
                              <Button
                                variant="outlined"
                                component="label"
                                color="error"
                                style={{ textTransform: "none", marginTop: 10 }}
                              >
                                Replace with another image
                                <input
                                  type="file"
                                  accept=".png"
                                  hidden
                                  onChange={(e) => {
                                    handleFileChange(
                                      e,
                                      setRadFile,
                                      "png",
                                      "SRI"
                                    );
                                  }}
                                />
                              </Button>
                            </>
                          ) : (
                            <>
                              <div className="editor_text_label">
                                Upload radiation image{" "}
                              </div>
                              <Button
                                variant="outlined"
                                component="label"
                                startIcon={<UploadIcon />}
                                style={{ textTransform: "none" }}
                              >
                                Upload radiation png
                                <input
                                  type="file"
                                  accept=".png"
                                  hidden
                                  onChange={(e) => {
                                    handleFileChange(
                                      e,
                                      setRadFile,
                                      "png",
                                      "SRI"
                                    );
                                  }}
                                />
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  {error == "png" ? (
                    <p
                      style={{
                        marginBottom: "0",
                        color: "red",
                        fontStyle: "italic",
                        fontSize: "smaller",
                      }}
                    >
                      Please upload ".png" type files.
                    </p>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      marginTop: 30,
                      boxShadow: "2px 2px 8px 1px grey",
                      borderRadius: "6px",
                      padding: 10,
                    }}
                  >
                    <h3 style={{ marginBottom: "-20px", marginTop: "20px" }}>
                      Assumptions
                    </h3>
                    <Table>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow className="table_row_result_page">
                        <TableCell>
                          <span className="question_main_label">
                            Type of installation{" "}
                          </span>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {/* <div className="editor_text_div">
                    <div className="editor_text_label">
                      Type of installation (e.g. Pitched roof)
                    </div> */}
                          <TextField
                            size="small"
                            className="edit_textfield"
                            value={uploadData.type_of_installation}
                            onChange={(e) =>
                              setUploadDataProperty(
                                "type_of_installation",
                                e.target.value
                              )
                            }
                          />
                          {/* </div> */}
                        </TableCell>
                      </TableRow>
                      <TableRow className="table_row_result_page">
                        <TableCell>
                          <span className="question_main_label_non_comp">
                            Max building height{" "}
                          </span>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {/* <div className="editor_text_div">
                    <div className="editor_text_label">
                      Max Building Height
                    </div> */}
                          <TextField
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  m
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            className="edit_textfield"
                            value={uploadData.max_building_height}
                            type="number"
                            onChange={(e) =>
                              setUploadDataProperty(
                                "max_building_height",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                          {/* </div> */}
                        </TableCell>
                      </TableRow>
                      <TableRow className="table_row_result_page">
                        <TableCell>
                          <span className="question_main_label_non_comp">
                            Roof start height{" "}
                          </span>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {/* <div className="editor_text_div">
                    <div className="editor_text_label">
                      Min Building Height
                    </div> */}
                          <TextField
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  m
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            className="edit_textfield"
                            value={uploadData.min_building_height}
                            type="number"
                            onChange={(e) =>
                              setUploadDataProperty(
                                "min_building_height",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                          {/* </div> */}
                        </TableCell>
                      </TableRow>
                      <TableRow className="table_row_result_page">
                        <TableCell>
                          <span className="question_main_label">
                            Self consumption{" "}
                          </span>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {/* <div className="editor_text_div">
                    <div className="editor_text_label">Self consumption</div> */}
                          <TextField
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            className="edit_textfield"
                            value={uploadData.self_consumption}
                            type="number"
                            onChange={(e) =>
                              setUploadDataProperty(
                                "self_consumption",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                          {/* </div> */}
                        </TableCell>
                      </TableRow>
                      <TableRow className="table_row_result_page">
                        <TableCell>
                          <span className="question_main_label">
                            Reference panel used{" "}
                          </span>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {/* <div className="editor_text_div">
                    <div className="editor_text_label">
                      Reference panel used
                    </div> */}
                          <TextField
                            size="small"
                            className="edit_textfield"
                            value={uploadData.reference_panel}
                            onChange={(e) =>
                              setUploadDataProperty(
                                "reference_panel",
                                e.target.value
                              )
                            }
                          />
                          {/* </div> */}
                        </TableCell>
                      </TableRow>
                      <TableRow className="table_row_result_page">
                        <TableCell>
                          <span className="question_main_label">
                            Energy price inflation{" "}
                          </span>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {/* <div className="editor_text_div">
                    <div className="editor_text_label">
                      Energy price inflation
                    </div> */}
                          <TextField
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            className="edit_textfield"
                            value={uploadData.energy_price_inflation}
                            type="number"
                            onChange={(e) =>
                              setUploadDataProperty(
                                "energy_price_inflation",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                          {/* </div> */}
                        </TableCell>
                      </TableRow>
                      <TableRow className="table_row_result_page">
                        <TableCell>
                          <span className="question_main_label">
                            Assumed carbon equiv. factor{" "}
                          </span>{" "}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {/* <div className="editor_text_div">
                    <div className="editor_text_label">
                      Assumed carbon equiv. factor
                    </div> */}
                          <TextField
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  kg/kWh
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            className="edit_textfield"
                            value={uploadData.assum_carbon_factor}
                            type="number"
                            onChange={(e) =>
                              setUploadDataProperty(
                                "assum_carbon_factor",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                          {/* </div> */}
                        </TableCell>
                      </TableRow>
                      <TableRow className="table_row_result_page">
                        <TableCell>
                          <span className="question_main_label">
                            Assumed tariffs{" "}
                          </span>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {/* <div className="editor_text_div">
                    <div className="editor_text_label">Assumed tariffs</div> */}
                          <TextField
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  £/kWh
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            className="edit_textfield"
                            value={uploadData.assum_tariff}
                            onChange={(e) =>
                              setUploadDataProperty(
                                "assum_tariff",
                                e.target.value
                              )
                            }
                          />
                          {/* </div> */}
                        </TableCell>
                      </TableRow>
                    </Table>
                  </div>
                </Grid>

                <div
                  style={{
                    marginTop: 30,
                    marginBottom: 30,
                    boxShadow: "2px 2px 8px 1px grey",
                    borderRadius: "6px",
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      fontSize: "22px",
                      fontWeight: "500",
                      marginBottom: 20,
                    }}
                  >
                    Selection of installers (optional)
                  </div>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    {/* <InputLabel id="demo-multiple-name-label">Name</InputLabel> */}
                    <Select
                      multiple
                      value={installerName}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                    >
                      {installers.map((installer) => (
                        <MenuItem
                          key={installer.name}
                          value={installer.id}
                          style={getStyles(installer.id, installerName, theme)}
                        >
                          {installer.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    marginTop: 30,
                    marginBottom: 30,
                    boxShadow: "2px 2px 8px 1px grey",
                    borderRadius: "6px",
                    padding: 20,
                  }}
                >
                  <div
                    style={{
                      fontSize: "22px",
                      fontWeight: "500",
                      marginBottom: 20,
                    }}
                  >
                    CTA category
                  </div>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <Select
                      value={uploadData.cta}
                      type="number"
                      onChange={(e) => {
                        if (e.target.value) {
                          setUploadDataProperty(
                            "cta",
                            parseInt(e.target.value.toString())
                          );
                        }
                      }}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value={1}>
                        Suitable for Absolar turnkey installation
                      </MenuItem>
                      <MenuItem value={2}>
                        Not suitable. Third-party installers
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <Accordion
                  style={{
                    marginTop: 30,
                    boxShadow: "2px 2px 8px 1px grey",
                    borderRadius: "6px",
                    padding: 10,
                    background: "#e8e8e8",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ fontSize: "22px", fontWeight: "500" }}
                  >
                    Non-essential information
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            Building outline GEOM (wkt)
                          </div>
                          {uploadData.building_geom != ""
                            ? "Valid Geometry: " + checkBuildingOutline()
                            : null}
                          <TextareaAutosize
                            // multiline
                            // rows={5}
                            // size="small"
                            minRows={10}
                            className="edit_textfield"
                            value={uploadData.building_geom}
                            // type="number"
                            onChange={(e) => {
                              setUploadDataProperty(
                                "building_geom",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            Yellow Area GEOM (wkt)
                          </div>
                          {uploadData.suitable_roof_geoms.length > 0
                            ? "Valid Geometry: " +
                              checkSRAPolygon(uploadData.suitable_roof_geoms)
                            : null}
                          <TextareaAutosize
                            // multiline
                            // rows={5}
                            // size="small"
                            minRows={10}
                            className="edit_textfield"
                            // value={uploadData.suitable_roof_geoms}
                            // type="number"
                            onChange={(e) => {
                              setUploadDataProperty(
                                "suitable_roof_geoms",
                                splitPolygonString(e.target.value)
                              );
                              console.log(splitPolygonString(e.target.value));
                            }}
                            defaultValue={uploadData.suitable_roof_geoms.join(
                              ""
                            )}
                          />
                          {uploadData.suitable_roof_geoms.length > 0
                            ? uploadData.suitable_roof_geoms.length.toString() +
                              " polygons found "
                            : null}
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <h3
                          style={{ marginBottom: "-20px", marginTop: "40px" }}
                        >
                          Basic information
                        </h3>
                      </Grid>

                      <Grid item xs={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            UPRN
                          </div>
                          <TextField
                            size="small"
                            className="edit_textfield"
                            value={uploadData.root_uprn}
                            onChange={(e) =>
                              setUploadDataProperty("root_uprn", e.target.value)
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            UPRN Type
                          </div>
                          <TextField
                            size="small"
                            className="edit_textfield"
                            value={uploadData.uprn_type}
                            onChange={(e) =>
                              setUploadDataProperty("uprn_type", e.target.value)
                            }
                          />
                        </div>
                      </Grid>

                      <Grid item xs={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            TOID
                          </div>
                          <TextField
                            size="small"
                            className="edit_textfield"
                            value={uploadData.toid}
                            onChange={(e) =>
                              setUploadDataProperty("toid", e.target.value)
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            Classification Code
                          </div>
                          <TextField
                            size="small"
                            className="edit_textfield"
                            value={uploadData.class_code}
                            onChange={(e) =>
                              setUploadDataProperty(
                                "class_code",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            Classification Description
                          </div>
                          <TextField
                            size="small"
                            className="edit_textfield"
                            value={uploadData.class_desc}
                            onChange={(e) =>
                              setUploadDataProperty(
                                "class_desc",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            Classification Sub-Description
                          </div>
                          <TextField
                            size="small"
                            className="edit_textfield"
                            value={uploadData.class_sub_desc}
                            onChange={(e) =>
                              setUploadDataProperty(
                                "class_sub_desc",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            Latitude
                          </div>
                          <TextField
                            size="small"
                            className="edit_textfield"
                            value={uploadData.latitude}
                            type="number"
                            onChange={(e) =>
                              setUploadDataProperty(
                                "latitude",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            Longitude
                          </div>
                          <TextField
                            size="small"
                            className="edit_textfield"
                            type="number"
                            value={uploadData.longitude}
                            onChange={(e) =>
                              setUploadDataProperty(
                                "longitude",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <h3
                          style={{ marginBottom: "-20px", marginTop: "20px" }}
                        >
                          Property info
                        </h3>
                      </Grid>

                      <Grid item xs={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            Is the property a Council Property?
                          </div>
                          {/* <TextField size="small" className="edit_textfield" /> */}
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              onChange={(e) => {
                                setUploadDataProperty(
                                  "council_property",
                                  e.target.value == "true"
                                );
                              }}
                              value={
                                uploadData.council_property ? "true" : "false"
                              }
                            >
                              <MenuItem value="true">Yes</MenuItem>
                              <MenuItem value="false">No</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            Is the property in a Conservation Area?
                          </div>
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              onChange={(e) => {
                                setUploadDataProperty(
                                  "conservation_area",
                                  e.target.value == "true"
                                );
                              }}
                              value={
                                uploadData.conservation_area ? "true" : "false"
                              }
                            >
                              <MenuItem value="true">Yes</MenuItem>
                              <MenuItem value="false">No</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="editor_text_div">
                          <div className="question_main_label_non_comp">
                            Listed Property within 5m?{" "}
                          </div>
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              onChange={(e) => {
                                setUploadDataProperty(
                                  "listed",
                                  e.target.value == "true"
                                );
                              }}
                              value={uploadData.listed ? "true" : "false"}
                            >
                              <MenuItem value="true">Yes</MenuItem>
                              <MenuItem value="false">No</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={6}></Grid>
                      {uploadData.organisation_id === 4 ||
                      uploadData.organisation_id === 20 ||
                      uploadData.organisation_id === 21 ? ( //if it's natwest, don't show the pdf upload here
                        <Grid item xs={12} md={12}>
                          <h3 style={{ marginTop: "40px" }}>One page report</h3>
                          {pdfFile ? (
                            <>
                              <div className="editor_text_div" style={{}}>
                                <OfflinePinIcon
                                  style={{ color: "#1976d2", fontSize: 50 }}
                                />{" "}
                                File successfully selected:{" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#1976d2",
                                  }}
                                >
                                  {pdfFile.name}
                                </span>
                              </div>
                              <Button
                                // fullWidth
                                variant="outlined"
                                component="label"
                                color="error"
                                endIcon={<DeleteForeverIcon />}
                                style={{
                                  textTransform: "none",
                                  marginTop: 20,
                                  // height: 150,
                                  // border: "#1976d2 1px dashed",
                                }}
                              >
                                {pdfFile
                                  ? "Click to replace with another file."
                                  : "Upload PDF"}
                                <input
                                  type="file"
                                  accept=".pdf"
                                  hidden
                                  onChange={(e) => {
                                    handleFileChange(
                                      e,
                                      setPdfFile,
                                      "pdf",
                                      "UP"
                                    );
                                  }}
                                />
                              </Button>
                              {error == "pdf" ? (
                                <p
                                  style={{
                                    marginBottom: "0",
                                    color: "red",
                                    fontStyle: "italic",
                                    fontSize: "smaller",
                                  }}
                                >
                                  Please upload a ".pdf" type file.
                                </p>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="editor_text_div">
                                <Button
                                  fullWidth
                                  variant="outlined"
                                  component="label"
                                  startIcon={<UploadIcon />}
                                  style={{
                                    textTransform: "none",
                                    height: 150,
                                    border: "#1976d2 1px dashed",
                                  }}
                                >
                                  {pdfFile
                                    ? "Click to replace with another file."
                                    : "Upload PDF"}
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    hidden
                                    onChange={(e) => {
                                      handleFileChange(
                                        e,
                                        setPdfFile,
                                        "pdf",
                                        "UP"
                                      );
                                    }}
                                  />
                                </Button>
                                {error == "pdf" ? (
                                  <p
                                    style={{
                                      marginBottom: "0",
                                      color: "red",
                                      fontStyle: "italic",
                                      fontSize: "smaller",
                                    }}
                                  >
                                    Please upload a ".pdf" type file.
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <h3 style={{ marginTop: "20px" }}>Additional comments</h3>
                <TextField
                  multiline
                  rows={6}
                  style={{ marginBottom: "10px", width: "100%" }}
                  value={uploadData.comments}
                  onChange={(e) =>
                    setUploadDataProperty("comments", e.target.value)
                  }
                />

                {/* files for the property */}
                <h3 style={{ marginTop: "20px" }}>Upload files</h3>

                <div className="editor_text_div">
                  <div className="editor_text_label">
                    Supporting files including radiation, suitable-roof-area
                    images, and one-page report. Make sure that all filenames
                    are correctly named using the TOID reference.{" "}
                  </div>

                  <div
                    className="parentLabelUpload"
                    style={{ marginTop: "5px" }}
                  >
                    <FileUploader
                      multiple={true}
                      handleChange={handleNewFileUpload}
                      name="file"
                    />
                  </div>
                  {newUploadedFiles.length > 0 ? (
                    <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                      Upload files:
                    </p>
                  ) : (
                    <></>
                  )}
                  <div style={{ display: "flex" }}>
                    {newUploadedFiles.map((eachFile) => (
                      <div key={eachFile.name} className="fileBox">
                        <Tooltip title={eachFile.name} placement="top">
                          <p style={{ margin: "0px" }}>{eachFile.name}</p>
                        </Tooltip>
                        <CloseIcon
                          style={{ fontSize: "15px", cursor: "pointer" }}
                          onClick={() => handleRemoveNewFile(eachFile)}
                        />
                      </div>
                    ))}
                  </div>

                  <Typography variant="body2" style={{ marginTop: "5px" }}>
                    Documents
                  </Typography>
                  {file_templates.length > 0 ? (
                    <div style={{ marginTop: "5px" }}>
                      {file_templates.map((eachTemplate, index) =>
                        eachTemplate.is_uploaded ? null : (
                          <Button
                            startIcon={<AttachFileIcon />}
                            disabled
                            size="small"
                            fullWidth
                            style={{
                              textTransform: "none",
                              justifyContent: "flex-start",
                              padding: 0,
                            }}
                            key={index}
                          >
                            {eachTemplate.template.replace(/_/g, " ")}
                          </Button>
                        )
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  {file_names.map((eachFile, index) => {
                    if (
                      eachFile.fileName.indexOf(uploadData.reference + "/") !==
                      -1
                    ) {
                      return (
                        <div key={index} style={{ marginTop: "5px" }}>
                          {/* {editingFiles ? */}
                          <>
                            {!eachFile.isCrossed ? (
                              <Tooltip title="Remove file" placement="top">
                                <CloseIcon
                                  style={{
                                    fontSize: "12px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleCrossFile(index)}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="Cancel remove file"
                                placement="top"
                              >
                                <ReplayIcon
                                  style={{
                                    fontSize: "12px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleCrossFile(index)}
                                />
                              </Tooltip>
                            )}
                            <Button
                              startIcon={<AttachFileIcon />}
                              key={index}
                              size="small"
                              style={{
                                textTransform: "none",
                                justifyContent: "flex-start",
                                padding: 0,
                                fontSize: "12px",
                                textDecoration: eachFile.isCrossed
                                  ? "line-through"
                                  : "none",
                              }}
                              onClick={() =>
                                !eachFile.isCrossed
                                  ? props.handleDownloadFile(
                                      eachFile.fileName,
                                      reference != null ? reference : ""
                                    )
                                  : console.log("crossed")
                              }
                            >
                              {eachFile.fileName.replace(
                                uploadData.reference + "/",
                                ""
                              )}
                            </Button>
                          </>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                {userContext.userState.username.includes("absolar.co.uk") ? (
                  <>
                    <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                      Uploaded files:
                    </p>
                    {file_templates.map((eachTemplate, index) => (
                      <div key={index}>
                        <Checkbox
                          size="small"
                          checkedIcon={<VisibilityIcon />}
                          icon={<VisibilityOffIcon />}
                          style={{ padding: 0 }}
                          defaultChecked={templatesChecked[index].is_uploaded}
                          value={templatesChecked[index].is_uploaded}
                          onChange={(e) => {
                            const updatedTemplates = [...templatesChecked];
                            updatedTemplates[index].is_uploaded =
                              e.target.checked;
                            setTemplatesChecked(updatedTemplates);
                          }}
                        />
                        <Typography variant="caption" style={{ marginLeft: 5 }}>
                          {eachTemplate.template.replace(/_/g, " ")}
                        </Typography>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}

                <div className="editor_text_div">
                  {!(
                    checkBuildingOutline() &&
                    checkSRAPolygon(uploadData.suitable_roof_geoms)
                  ) ? (
                    <p style={{ color: "red" }}>
                      WARNING YOUR GEOMETRY IS NOT VALID, THIS WILL BREAK THE
                      MAP FOR THE CUSTOMER
                    </p>
                  ) : null}
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    // onClick={submitUploadData}
                    onClick={() => {
                      //add it to the table

                      submitUploadData();
                    }}
                    disabled={
                      uploadData.reference != "" &&
                      uploadData.assessment_date != "" &&
                      uploadData.address != "" &&
                      uploadData.postcode != "" &&
                      uploadData.payback != "" &&
                      !Number.isNaN(uploadData.monthly_repayment) &&
                      !Number.isNaN(
                        uploadData.loan_total_utility_bill_savings_20
                      ) &&
                      uploadData.loan_payback != "" &&
                      !Number.isNaN(uploadData.loan_roi) &&
                      !Number.isNaN(uploadData.organisation_id) &&
                      uploadData.type_of_installation != "" &&
                      !Number.isNaN(uploadData.self_consumption) &&
                      uploadData.reference_panel != "" &&
                      !Number.isNaN(uploadData.energy_price_inflation) &&
                      !Number.isNaN(uploadData.assum_carbon_factor) &&
                      uploadData.assum_tariff != ""
                        ? false
                        : true
                    }
                  >
                    Submit
                  </Button>

                  {uploadData.reference == "" ||
                  uploadData.assessment_date == "" ||
                  uploadData.address == "" ||
                  uploadData.postcode == "" ||
                  uploadData.payback == "" ||
                  Number.isNaN(uploadData.monthly_repayment) ||
                  Number.isNaN(uploadData.loan_total_utility_bill_savings_20) ||
                  uploadData.loan_payback == "" ||
                  Number.isNaN(uploadData.loan_roi) ||
                  Number.isNaN(uploadData.organisation_id) ||
                  uploadData.type_of_installation == "" ||
                  Number.isNaN(uploadData.self_consumption) ||
                  uploadData.reference_panel == "" ||
                  Number.isNaN(uploadData.energy_price_inflation) ||
                  Number.isNaN(uploadData.assum_carbon_factor) ||
                  !checkBuildingOutline() ||
                  !checkSRAPolygon(uploadData.suitable_roof_geoms) ||
                  uploadData.assum_tariff == "" ? (
                    <>
                      <p
                        style={{
                          marginBottom: "0",
                          color: "red",
                          fontStyle: "italic",
                          fontSize: "smaller",
                        }}
                      >
                        <a href="#start" style={{ color: "red" }}>
                          Please review the information you provided and
                          complete all required fields marked with *:{" "}
                        </a>

                        {uploadData.reference == "" && (
                          <>
                            <br></br>Project Reference
                          </>
                        )}
                        {uploadData.assessment_date == "" && (
                          <>
                            <br></br>Assessment Date
                          </>
                        )}
                        {uploadData.address == "" && (
                          <>
                            <br></br>Address
                          </>
                        )}
                        {uploadData.postcode == "" && (
                          <>
                            <br></br>Postcode
                          </>
                        )}
                        {uploadData.payback == "" && (
                          <>
                            <br></br>Payback
                          </>
                        )}
                        {Number.isNaN(uploadData.monthly_repayment) && (
                          <>
                            <br></br>Monthly Repayment
                          </>
                        )}
                        {Number.isNaN(
                          uploadData.loan_total_utility_bill_savings_20
                        ) && (
                          <>
                            <br></br>Loan Total Utility Savings
                          </>
                        )}
                        {uploadData.loan_payback == "" && (
                          <>
                            <br></br>Loan Payback
                          </>
                        )}
                        {Number.isNaN(uploadData.loan_roi) && (
                          <>
                            <br></br>Loan ROI
                          </>
                        )}
                        {Number.isNaN(uploadData.organisation_id) && (
                          <>
                            <br></br>Organisation ID
                          </>
                        )}
                        {uploadData.type_of_installation == "" && (
                          <>
                            <br></br>Type of Installation
                          </>
                        )}
                        {Number.isNaN(uploadData.self_consumption) && (
                          <>
                            <br></br>Self-consumption
                          </>
                        )}
                        {uploadData.reference_panel == "" && (
                          <>
                            <br></br>Reference Panel
                          </>
                        )}
                        {Number.isNaN(uploadData.energy_price_inflation) && (
                          <>
                            <br></br>Energy Price Inflation
                          </>
                        )}
                        {Number.isNaN(uploadData.assum_carbon_factor) && (
                          <>
                            <br></br>Assumed Carbon Factor
                          </>
                        )}
                        {!checkBuildingOutline() && (
                          <>
                            <br></br>Building Outline
                          </>
                        )}
                        {!checkSRAPolygon(uploadData.suitable_roof_geoms) && (
                          <>
                            <br></br>SRA Polygon
                          </>
                        )}
                        {uploadData.assum_tariff == "" && (
                          <>
                            <br></br>Assumed Tariff
                          </>
                        )}
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : null}
          </Grid>
          {/* ### */}
          <Grid item xs={12} md={3}>
            <div
              style={{
                background: "#e8e8e8",
                // borderLeft:"1px solid lightgrey",
                // minHeight: "100vh",
                // height: "calc(100% - 60px)",
                padding: "20px 15px",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  lineHeight: "1.5",
                  fontWeight: "600",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
              >
                Create new user
              </div>
              <div className="question_main_label_non_comp">Full name</div>
              <TextField
                size="small"
                className="edit_textfield"
                type="text"
                value={newUserName}
                onChange={(e) => {
                  setnewUserName(e.target.value);
                }}
              />
              <div className="question_main_label">Email</div>
              <TextField
                size="small"
                className="edit_textfield"
                type="text"
                value={newEmail}
                onChange={(e) => {
                  setnewEmail(e.target.value);
                }}
              />
              <div className="question_main_label_non_comp">Job title</div>
              <TextField
                size="small"
                className="edit_textfield"
                type="text"
                value={newJob}
                onChange={(e) => {
                  setnewJob(e.target.value);
                }}
              />
              <div className="question_main_label_non_comp">Company name</div>
              <TextField
                size="small"
                className="edit_textfield"
                type="text"
                value={newCompany}
                onChange={(e) => {
                  setnewCompany(e.target.value);
                }}
              />
              <div className="question_main_label_non_comp">Phone number</div>
              <TextField
                size="small"
                className="edit_textfield"
                type="text"
                value={newPhone}
                onChange={(e) => {
                  setnewPhone(e.target.value);
                }}
              />
              <div className="question_main_label_non_comp">
                Environments to be assigned
              </div>
              <FormControl
                fullWidth
                style={{
                  backgroundColor: "white",
                  border: "1px solid darkgray",
                }}
              >
                {/* <InputLabel id="demo-mutiple-checkbox-label">Select Environments</InputLabel> */}
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={newEnv}
                  onChange={updateNewUserEnv}
                  input={<Input />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  style={{ overflowX: "scroll" }}
                >
                  {organisations.map((organisation) => {
                    return (
                      <MenuItem
                        key={organisation.id}
                        value={organisation.display_name}
                      >
                        <Checkbox
                          checked={
                            newEnv.indexOf(organisation.display_name) > -1
                          }
                        />
                        <ListItemText primary={organisation.display_name} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <Button
                variant="outlined"
                component="label"
                style={{ textTransform: "none", marginTop: "10px" }}
                onClick={submitNewUser}
                disabled={
                  newEmail != "" &&
                  newEmail.includes("@") &&
                  newEmail.includes(".")
                    ? false
                    : true
                }
              >
                Create User
              </Button>

              <Button
                variant="contained"
                fullWidth
                color="success"
                onClick={handleOpen_notification}
                style={{ marginTop: 30 }}
              >
                Add notifications
              </Button>
              <Modal
                open={open_notification}
                onClose={handleClose_notification}
              >
                <div className="modal_container">
                  <NotificationForm
                    organisations={organisations}
                    handleClose_notification={handleClose_notification}
                  />
                </div>
              </Modal>

              <div
                style={{
                  textAlign: "left",
                  lineHeight: "1.5",
                  fontWeight: "600",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                  marginTop: 30,
                }}
              >
                Bulk upload
              </div>
              <div className="editor_text_div">
                <div className="editor_text_label">
                  Main data table. Click <a href="#">here</a> to download
                  template.{" "}
                </div>
                <Button
                  variant="outlined"
                  component="label"
                  style={{ textTransform: "none" }}
                  disabled
                >
                  Upload csv
                  <input type="file" hidden />
                </Button>
              </div>

              {/* {file_names.length > 0 ?
              <Button
              variant="outlined"
              component="label"
              style={{ textTransform: "none", marginTop: "5px" }}
              onClick={handleEditSaveFiles}
            >
              {editingFiles ? "Save changes" : "Edit current files"}
            </Button>
            :
            <></>} */}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );

  function sendDataToTable() {
    var allDashboardDatas = props.dashboardDatas;
    var objectToAdd: DashboardData = {
      reference: uploadData.reference,
      assessment_date: uploadData.assessment_date,
      root_uprn: uploadData.root_uprn,
      uprn_type: uploadData.uprn_type,
      toid: uploadData.toid,
      class_code: uploadData.class_code,
      class_desc: uploadData.class_desc,
      class_sub_desc: uploadData.class_sub_desc,
      latitude: uploadData.latitude,
      longitude: uploadData.longitude,
      address: uploadData.address,
      postcode: uploadData.postcode,
      max_building_height: uploadData.max_building_height,
      min_building_height: uploadData.min_building_height,
      install_capacity_kw: uploadData.install_capacity_kw,
      expected_power_generation_kwh: uploadData.expected_power_generation_kwh,
      potential_saving_year_1: uploadData.potential_saving_year_1,
      co2_saving_year_1: uploadData.co2_saving_year_1,
      indicative_cost: uploadData.indicative_cost,
      total_utility_bill_savings_20: uploadData.total_utility_bill_savings_20,
      roi: uploadData.roi,
      payback: uploadData.payback,
      monthly_repayment: uploadData.monthly_repayment,
      loan_total_utility_bill_savings_20:
        uploadData.loan_total_utility_bill_savings_20,
      loan_payback: uploadData.loan_payback,
      loan_roi: uploadData.loan_roi,
      council_property: uploadData.council_property,
      conservation_area: uploadData.conservation_area,
      listed: uploadData.listed,
      organisation_id: uploadData.organisation_id,
      type_of_installation: uploadData.type_of_installation,
      self_consumption: uploadData.self_consumption,
      reference_panel: uploadData.reference_panel,
      energy_price_inflation: uploadData.energy_price_inflation,
      assum_carbon_factor: uploadData.assum_carbon_factor,
      assum_tariff: uploadData.assum_tariff,
      stage: null,
      saved: false,
      existing_solar: uploadData.existing_solar,
      open_solar_link: uploadData.open_solar_link,
      site_survey_date: "",
      project_stage: null,
      comments: uploadData.comments,
      cta: uploadData.cta,
    };

    var indexOfEditedProperty = -1;
    indexOfEditedProperty = allDashboardDatas.findIndex(
      (eachObj) => eachObj.reference === objectToAdd.reference
    );
    if (indexOfEditedProperty != -1) {
      // If the index is found, it means we are editing and not uploading a new one
      allDashboardDatas = allDashboardDatas.map((obj, i) => {
        if (i === indexOfEditedProperty) {
          return objectToAdd;
        } else {
          return obj;
        }
      });
    } else {
      allDashboardDatas.push(objectToAdd);
    }

    props.setDashboardDatas(allDashboardDatas);
  }
}
