import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Tech_image from "../images/tech_background.png";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function ModalAboutPage() {
  return (
    <div
      style={{
        width: "90%",
        maxWidth: "1600px",
        margin: "30px auto",
        textAlign: "left",
        // background:"#e5e5e5"
      }}
    >
      <div className="modal_container_about">
        <Grid container spacing={5}>
          <Grid item xs={5}>
            <img src={Tech_image} style={{ width: "100%", maxWidth: 500 }} />
          </Grid>
          <Grid item xs={7}>
            <div>
              <Typography variant="h4" style={{ fontWeight: "300" }}>
                Technology background
              </Typography>
              <Typography>
                <p>
                  Absolar provides renewable energy management services - from
                  design, application, to turnkey installations and energy
                  trading. Using AI-powered remote survey technologies, Absolar
                  help organisations better review their solar potential,
                  assessing the business case of the entire building portfolio,
                  as well as financial feasibility of individual building.
                </p>
                <h4>About Solar Watcher Dashboard</h4>
                <p>
                  The Solar Watcher Dashboard provides users with access to a
                  range of tools and resources to help them make informed
                  decisions about solar panel installations. The dashboard is
                  equipped with the following features:
                </p>
                <ul>
                  <li style={{ margin: "10px 0px" }}>
                    Summary business case report of each individual building
                  </li>
                  <li style={{ margin: "10px 0px" }}>
                    Map view option to view potential across a portfolio
                  </li>
                  <li style={{ margin: "10px 0px" }}>
                    Initial estimates of installation cost, energy bill savings
                    and CO2e savings
                  </li>
                  <li style={{ margin: "10px 0px" }}>
                    Visual representation of areas of installation and solar
                    radiation
                  </li>
                  <li style={{ margin: "10px 0px" }}>
                    Sharable links and PDF reports for wider distribution
                  </li>
                </ul>
                <p>
                  Additional features where properties proceed to assessment and
                  installation (additional costs may apply):
                </p>
                <ul>
                  <li>
                    Further assessment of cashflow projections to evaluate
                    financial viabilities
                  </li>
                  <li>
                    Project management and tracking through the procurement and
                    installation phase
                  </li>
                  <li>
                    Energy trading set-up to sell surplus generation back to the
                    grid
                  </li>
                  <li>
                    Post-installation data displays of energy production and
                    customised dashboard creation
                  </li>
                </ul>
              </Typography>
            </div>
          </Grid>
        </Grid>
        <p>
          For any information related to the Solar Watcher dashboard, please
          contact: <a href="mailto:info@absolar.co.uk">info@absolar.co.uk</a>.
          &#169; Absolar Solutions 2023
        </p>
      </div>
    </div>
  );
}
