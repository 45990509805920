import React, { useState, useContext } from "react";
import {
  Tooltip,
  Button,
  Modal,
  Menu,
  MenuItem,
  FormControl,
  IconButton,
  Grid,
  Typography,
  Checkbox,
} from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import SendEmailError from "../infoPages/PopMessage_SendViaEmail";
import CircularProgress from "@mui/material/CircularProgress";
import financial_report_image from "../images/financial_report_2.png";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import { UserContext } from "../contexts/UserContext";
import { createPortal } from "react-dom";
import CloseIcon from "@mui/icons-material/Close";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import "./FoundPropertiesProject_Admin.css";
import { sendFileUpdatesToBackend } from "../App";
import { deleteData, getData, postData, putData } from "../get-data";
import ReplayIcon from "@mui/icons-material/Replay";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FileUploader } from "react-drag-drop-files";
import { AppContext } from "../contexts/AppContext";

export function PropertyRow(props: {
  dashboardData: DashboardData;
  allDashboardDatas: DashboardData[];
  setAllDashboardData: (newData: DashboardData[]) => void;
  key: string;
  isAdmin: boolean;
  valueTab: string;
  updatePanelChoice: (newOpt: string) => void;
  setRefData: React.Dispatch<React.SetStateAction<DashboardData | null>>;
}): JSX.Element {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);

  const [open_business_case, setOpen_business_case] = React.useState(false);
  const handleClose_business_case = () => setOpen_business_case(false);

  const [open_send_via_email_error, setOpen_send_via_email_error] =
    React.useState(false);
  const handleOpen_send_via_email_error = () => {
    setOpen_send_via_email_error(true);
    setAnchorEl(null);
  };
  const handleClose_send_via_email_error = () =>
    setOpen_send_via_email_error(false);

  const [stage, setStage] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setStage(event.target.value);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick_like_button = (reference: string, toUpdateTo: boolean) => {
    //check if we need to update the individual data in teh state too?

    var allDashboards = props.allDashboardDatas.map((dashboardEntry) =>
      dashboardEntry.reference === reference
        ? { ...dashboardEntry, saved: toUpdateTo }
        : dashboardEntry
    );
    props.setAllDashboardData(allDashboards);

    props.dashboardData.saved = toUpdateTo;
    saveRow(reference, toUpdateTo);
  };

  const [starStatus, setStarStatus] = React.useState("");

  const handleClick_savingAnimation = () => {
    appContext.setSavingAnimation(true);
    // Perform some async operation here
    setTimeout(() => {
      appContext.setSavingAnimation(false);
      setStarStatus("");
    }, 3000);
  };

  function saveRow(reference: string, saved: boolean) {
    putData(
      process.env.REACT_APP_API_URL +
        "/Dashboard/saved?reference=" +
        reference +
        "&saved=" +
        saved,
      {},
      true,
      userContext.getCredentials
    )
      .then((res) => res.text())
      .catch((e) => alert("save failed"));
  }

  function setRowStage(reference: string, stage: string) {
    putData(
      process.env.REACT_APP_API_URL +
        "/Dashboard/stage?reference=" +
        reference +
        "&stage=" +
        stage,
      {},
      true,
      userContext.getCredentials
    )
      .then((res) => res.text())
      .catch((e) => alert("stage update failed"));
  }

  const handleArchive = (reference: string, isArchived: boolean) => {
    setSelectionValue(isArchived ? "" : "Archived");

    var allDashboards = props.allDashboardDatas.map((dashboardEntry) =>
      dashboardEntry.reference === reference
        ? { ...dashboardEntry, stage: isArchived ? "" : "Archived" }
        : dashboardEntry
    );
    props.setAllDashboardData(allDashboards);

    // props.dashboardData.stage = isArchived ? "" : "Archived";
    setRowStage(reference, isArchived ? "" : "Archived");
  };
  const handleMoveToStage = (reference: string, stage: string) => {
    var allDashboards = [];

    if (stage == "None") {
      allDashboards = props.allDashboardDatas.map((dashboardEntry) =>
        dashboardEntry.reference === reference
          ? { ...dashboardEntry, stage: "" }
          : dashboardEntry
      );
    } else {
      allDashboards = props.allDashboardDatas.map((dashboardEntry) =>
        dashboardEntry.reference === reference
          ? { ...dashboardEntry, stage: stage }
          : dashboardEntry
      );
    }

    props.setAllDashboardData(allDashboards);
    props.dashboardData.stage = stage;
    setRowStage(reference, stage != "None" ? stage : "");

    setSelectionValue(stage);
  };

  const [selectionValue, setSelectionValue] = React.useState(
    props.dashboardData.stage
  );

  const buttonRef = React.useRef<any>(null);
  const handleButtonClick = () => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const iconRect = appContext.iconRef.current.getBoundingClientRect();

    appContext.setTargetX(`${iconRect.left - buttonRect.left}px`);
    appContext.setTargetY(`${iconRect.top - buttonRect.top}px`);
    appContext.setIsAnimating(props.dashboardData.reference);
  };
  const buttonStyle = {
    animation:
      appContext.isAnimating == props.dashboardData.reference
        ? props.dashboardData.saved
          ? "vacuum-animation 2s forwards"
          : "extraction-animation 2s forwards"
        : "none",
    "--target-x": appContext.targetX,
    "--target-y": appContext.targetY,
  };

  const renderAnimatedElement = () => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const top = buttonRect.top + window.scrollY; // Account for page scrolling
    const left = buttonRect.left + window.scrollX; // Account for page scrolling

    return createPortal(
      <IconButton
        style={{
          ...buttonStyle,
          position: "absolute",
          top: `${top}px`,
          left: `${left}px`,
        }}
        onAnimationEnd={() => appContext.setIsAnimating("")}
        disabled
      >
        {props.dashboardData.saved ? <StarIcon /> : <StarOutlineIcon />}
      </IconButton>,
      document.body
    );
  };

  //FILES TEMPLATES FUNCTIONS
  const [anchorTemplates, setAnchorTemplates] =
    React.useState<null | HTMLElement>(null);
  const open_templates = Boolean(anchorTemplates);
  const handleClose_Templates = () => {
    setAnchorTemplates(null);
  };

  const [file_names, setFile_names] = useState<
    { fileName: string; isCrossed: boolean }[]
  >([]);
  const [file_templates, setFile_templates] = useState<
    {
      template: string;
      is_uploaded: boolean;
    }[]
  >([]);
  const [templatesChecked, setTemplatesChecked] = useState<
    {
      template: string;
      is_uploaded: boolean;
    }[]
  >([]);

  const [isLoadingTemplates, setIsLoadingTemplates] = React.useState(false);

  function retrieveFiles() {
    console.log("doing");
    getData(
      process.env.REACT_APP_API_URL +
        "/File/file_names?reference=" +
        props.dashboardData.reference,
      false,
      userContext.getCredentials
    )
      .then((fileNames: string[]) => {
        var files: { fileName: string; isCrossed: boolean }[] = [];
        fileNames.forEach((eachFile) =>
          files.push({
            fileName: eachFile,
            isCrossed: false,
          })
        );
        setFile_names(files);
        console.log(
          "successful retrieval of files from property",
          files,
          props.dashboardData.reference
        );
      })
      .catch((e) => {
        console.log(
          "unable to get files from property",
          props.dashboardData.reference
        );
      });

    //get data for file templates
    var urlForTemplates =
      process.env.REACT_APP_API_URL + "/Template/FileTemplates";

    getData(urlForTemplates, false, userContext.getCredentials)
      .then((data: { orgId: number; templates: string[] }[]) => {
        console.log("templates are", data, data[0].orgId);
        //for future: if we need to sort these based on organisation id, this next line is the place
        var templatesStrings = data[0].templates;
        //now, retrieving the ones that are already uploaded/marked/
        const urlForMarkedTemplates =
          process.env.REACT_APP_API_URL +
          "/Template/ReferenceTemplates?reference=" +
          props.dashboardData.reference;
        getData(urlForMarkedTemplates, false, userContext.getCredentials)
          .then((data: { reference: string; template: string }[]) => {
            //retrieving the "marked"/"done" templates
            let templateArray = data.map((obj) => obj.template);
            //now, making an array for the state variable file_templates that includes which ones are ,arked and which aren't
            var arrayForFile_templates: {
              template: string;
              is_uploaded: boolean;
            }[] = [];
            templatesStrings.forEach((eachGeneralTemplate) => {
              if (templateArray.includes(eachGeneralTemplate)) {
                arrayForFile_templates.push({
                  template: eachGeneralTemplate,
                  is_uploaded: true,
                });
              } else {
                arrayForFile_templates.push({
                  template: eachGeneralTemplate,
                  is_uploaded: false,
                });
              }
            });
            setFile_templates(arrayForFile_templates);

            const clonedArray = arrayForFile_templates.map((obj) => ({
              ...obj,
            }));

            setTemplatesChecked(clonedArray);
            setIsLoadingTemplates(false);
          })
          .catch((e) => {
            console.log("couldn't retrieve file reference templates");
          });
      })
      .catch((e) => {
        console.log("couldn't retrieve file templates");
      });
  }

  function handleCrossFile(index: number) {
    const updatedFileList = [...file_names];
    updatedFileList[index].isCrossed = !updatedFileList[index].isCrossed;
    setFile_names(updatedFileList);
  }

  const [newUploadedFiles, setNewUploadedFiles] = React.useState<File[]>([]);
  const handleNewFileUpload = (file: FileList) => {
    //adding new files through button
    var fileToUse = file.item(0);
    if (fileToUse) {
      const isFileInArray = newUploadedFiles.some(
        (eachFile) => eachFile === fileToUse
      );
      if (!isFileInArray) {
        setNewUploadedFiles([...newUploadedFiles, fileToUse]);
      }
    }
  };
  const handleRemoveNewFile = (file: File) => {
    //removing newly added files
    setNewUploadedFiles(newUploadedFiles.filter((f) => f !== file));
  };
  function handleUploadFilesToBackend() {
    //first, checking which (if any) files need to be removed
    const crossedFilesToRemove: { fileName: string; isCrossed: boolean }[] = [];
    const remainingFiles: { fileName: string; isCrossed: boolean }[] = [];

    file_names.forEach((file) => {
      if (file.isCrossed) {
        crossedFilesToRemove.push(file);
      } else {
        remainingFiles.push(file);
      }
    });

    sendFileUpdatesToBackend(
      crossedFilesToRemove,
      newUploadedFiles,
      props.dashboardData.reference,
      userContext.userState.idToken,
      userContext.getCredentials
    );

    //frontend:
    //check if there are any newly updated files and if there are, reflect the changes in frontend
    var arrayToAdd: { fileName: string; isCrossed: boolean }[] = [];
    newUploadedFiles.forEach((file) => {
      arrayToAdd.push({ fileName: file.name, isCrossed: false });
    });
    setFile_names([...remainingFiles, ...arrayToAdd]);

    //remove the uploading files list
    setNewUploadedFiles([]);

    //also update the backend for the template files if there's been a change
    updateTemplateFilesStatus();
  }

  function updateTemplateFilesStatus() {
    //we have file_templates and templatesChecked which are exactly teh same
    //but file_templates contain teh original values from teh backend
    //and templatesChecked contain the updated values

    //here, we update the changes in the backend:
    // Iterating through checkedCoco and comparing with originalCoco

    templatesChecked.forEach((eachCheck, index) => {
      if (
        file_templates[index] &&
        eachCheck.template === file_templates[index].template &&
        eachCheck.is_uploaded !== file_templates[index].is_uploaded
      ) {
        var endpointForPost =
          process.env.REACT_APP_API_URL +
          "/Template/ReferenceTemplates?reference=" +
          props.dashboardData.reference +
          "&template=" +
          eachCheck.template;
        var endpointForDelete =
          process.env.REACT_APP_API_URL +
          "/Template/ReferenceTemplates/" +
          props.dashboardData.reference +
          "/" +
          eachCheck.template;
        if (eachCheck.is_uploaded) {
          //it means we are removing it/marking it as done/etc
          postData(endpointForPost, [], true, userContext.getCredentials)
            .then((response) => {
              console.log("response is", response.text());
            })
            .then((data) => {
              console.log("response status is:", data);
            })
            .catch((e) => {
              console.log("unable to post change", e);
            });
        } else {
          //it means we are changing it from "done" to "undone"
          deleteData(endpointForDelete, true, userContext.getCredentials)
            .then((data) => {
              console.log("successful deletion");
            })
            .catch((e) => {
              console.log("failed to delete template", e);
            });
        }
      }
    });

    setFile_templates((prevTemplates) => {
      const updatedFileTemplates = prevTemplates.map((template, index) => {
        if (template.is_uploaded !== templatesChecked[index].is_uploaded) {
          return {
            ...template,
            is_uploaded: templatesChecked[index].is_uploaded,
          };
        }
        return template;
      });
      return updatedFileTemplates;
    });

    //TODO (backend): update the array in the backend
  }

  return (
    <tr className="table_row" key={props.key}>
      <td>
        <Tooltip title="Save Property" placement="top-start">
          <>
            <div style={{ position: "relative" }}>
              {appContext.isAnimating === props.dashboardData.reference &&
                renderAnimatedElement()}

              <IconButton
                id={props.dashboardData.reference}
                ref={buttonRef}
                onClick={() => {
                  // handleButtonClick();
                  console.log(
                    props.dashboardData.reference,
                    !props.dashboardData.saved
                  );
                  handleClick_like_button(
                    props.dashboardData.reference,
                    !props.dashboardData.saved
                  );
                  if (props.dashboardData.saved) {
                    //if we click the savwe button, open the corresponding modal
                    setStarStatus("saved");
                  } else {
                    setStarStatus("unsaved");
                  }
                  handleClick_savingAnimation();
                }}
              >
                {props.dashboardData.saved ? <StarIcon /> : <StarOutlineIcon />}
              </IconButton>
            </div>
            {appContext.savingAnimation && (
              <>
                <Modal
                  open={starStatus == "saved"}
                  style={{
                    opacity: 0.8,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "white",
                      padding: 30,
                      opacity: "1 !important",
                    }}
                  >
                    <Typography variant="body1">
                      This address has been added to your saved properties list.
                    </Typography>
                  </div>
                </Modal>
                <Modal
                  open={starStatus == "unsaved"}
                  style={{
                    opacity: 0.8,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "white",
                      padding: 30,
                      opacity: "1 !important",
                    }}
                  >
                    <Typography variant="body1">
                      This address has been removed from your saved properties
                      list.
                    </Typography>
                  </div>
                </Modal>
              </>
            )}
          </>
        </Tooltip>
      </td>

      <td style={{ textAlign: "left" }}>
        <Button
          style={{
            textAlign: "left",
            maxWidth: "-webkit-fill-available",
            display: "block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            padding: 0,
          }}
          onClick={() => {
            // appContext.getProjectPage(props.dashboardData);
            appContext.setRefData(props.dashboardData);
            props.updatePanelChoice("Property Edit");
            appContext.setShowGeneratingList(false);
          }}
        >
          <Tooltip
            title={
              <Typography variant="body1">
                {props.dashboardData.address}
              </Typography>
            }
          >
            <span>{props.dashboardData.address}</span>
          </Tooltip>
        </Button>
      </td>
      <td style={{ textAlign: "left" }}>
        <span
          style={{
            maxWidth: "-webkit-fill-available",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {props.dashboardData.reference}
        </span>
      </td>
      <td style={{ textAlign: "left" }}>
        <span
          style={{
            maxWidth: "-webkit-fill-available",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <IconButton
            onClick={(e) => {
              retrieveFiles();
              setIsLoadingTemplates(true);
              setAnchorTemplates(e.currentTarget);
            }}
          >
            {anchorTemplates ? (
              <ArrowCircleRightIcon />
            ) : (
              <ArrowCircleRightOutlinedIcon />
            )}
          </IconButton>
        </span>
      </td>
      <Menu
        anchorEl={anchorTemplates}
        open={open_templates}
        onClose={handleClose_Templates}
      >
        {isLoadingTemplates ? (
          <>
            <CircularProgress />
          </>
        ) : (
          <div
            style={{
              minHeight: "200px",
              width: "90%",
              maxWidth: "400px",
              padding: 20,
              textAlign: "left",
            }}
          >
            <h3 style={{ marginTop: "0px" }}>Upload files</h3>

            <div className="editor_text_div">
              <div className="editor_text_label">
                Supporting files including radiation, suitable-roof-area images,
                and one-page report. Make sure that all filenames are correctly
                named using the TOID reference.{" "}
              </div>

              <div className="parentLabelUpload" style={{ marginTop: "5px" }}>
                <FileUploader
                  multiple={true}
                  handleChange={handleNewFileUpload}
                  name="file"
                />
              </div>
              {newUploadedFiles.length > 0 ? (
                <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                  Upload files:
                </p>
              ) : (
                <></>
              )}
              <div style={{ display: "flex" }}>
                {newUploadedFiles.map((eachFile) => (
                  <div key={eachFile.name} className="fileBox">
                    <Tooltip title={eachFile.name} placement="top">
                      <p style={{ margin: "0px" }}>{eachFile.name}</p>
                    </Tooltip>
                    <CloseIcon
                      style={{ fontSize: "15px", cursor: "pointer" }}
                      onClick={() => handleRemoveNewFile(eachFile)}
                    />
                  </div>
                ))}
              </div>

              <Typography variant="body2" style={{ marginTop: "5px" }}>
                Documents
              </Typography>
              {file_templates.length > 0 ? (
                <div style={{ marginTop: "5px" }}>
                  {file_templates.map((eachTemplate, index) =>
                    eachTemplate.is_uploaded ? null : (
                      <Button
                        startIcon={<AttachFileIcon />}
                        disabled
                        size="small"
                        fullWidth
                        style={{
                          textTransform: "none",
                          justifyContent: "flex-start",
                          padding: 0,
                        }}
                        key={index}
                      >
                        {eachTemplate.template.replace(/_/g, " ")}
                      </Button>
                    )
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {file_names.map((eachFile, index) => {
                if (
                  eachFile.fileName.indexOf(
                    props.dashboardData.reference + "/"
                  ) !== -1
                ) {
                  return (
                    <div key={index} style={{ marginTop: "5px" }}>
                      {/* {editingFiles ? */}
                      <>
                        {!eachFile.isCrossed ? (
                          <Tooltip title="Remove file" placement="top">
                            <CloseIcon
                              style={{
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleCrossFile(index)}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Cancel remove file" placement="top">
                            <ReplayIcon
                              style={{
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleCrossFile(index)}
                            />
                          </Tooltip>
                        )}
                        <Button
                          startIcon={<AttachFileIcon />}
                          key={index}
                          size="small"
                          style={{
                            textTransform: "none",
                            justifyContent: "flex-start",
                            padding: 0,
                            fontSize: "12px",
                            textDecoration: eachFile.isCrossed
                              ? "line-through"
                              : "none",
                          }}
                          onClick={() =>
                            !eachFile.isCrossed
                              ? appContext.handleDownloadFile(
                                  eachFile.fileName,
                                  props.dashboardData.reference
                                )
                              : console.log("crossed")
                          }
                        >
                          {eachFile.fileName.replace(
                            props.dashboardData.reference + "/",
                            ""
                          )}
                        </Button>
                      </>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>

            <>
              <p style={{ fontSize: "14px", marginBottom: "0px" }}>
                Uploaded files:
              </p>
              {file_templates.map((eachTemplate, index) => (
                <div key={index}>
                  <Checkbox
                    size="small"
                    checkedIcon={<VisibilityIcon />}
                    icon={<VisibilityOffIcon />}
                    style={{ padding: 0 }}
                    defaultChecked={templatesChecked[index].is_uploaded}
                    value={templatesChecked[index].is_uploaded}
                    onChange={(e) => {
                      const updatedTemplates = [...templatesChecked];
                      updatedTemplates[index].is_uploaded = e.target.checked;
                      setTemplatesChecked(updatedTemplates);
                    }}
                  />
                  <Typography variant="caption" style={{ marginLeft: 5 }}>
                    {eachTemplate.template.replace(/_/g, " ")}
                  </Typography>
                </div>
              ))}
            </>
            <Button
              variant="outlined"
              fullWidth
              style={{ textTransform: "none", marginTop: "5px" }}
            >
              Update
            </Button>
          </div>
        )}
      </Menu>

      {/* <TableCell>{props.dashboardData.postcode}</TableCell> */}
      {props.valueTab === "Generating" ? null : (
        <>
          <td style={{ textAlign: "left" }}>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 120 }}
              style={{ border: "none", margin: 0 }}
            >
              {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
              {props.isAdmin ? (
                <Select
                  disableUnderline
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={props.dashboardData.stage ?? "None"}
                  onChange={handleChange}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {[
                    "None",
                    "Recommended",
                    "Approved",
                    "Installing",
                    "Generating",
                    "Archived",
                  ].map((value, index) => (
                    <MenuItem
                      value={value}
                      onClick={() =>
                        handleMoveToStage(props.dashboardData.reference, value)
                      }
                    >
                      {" "}
                      <Typography variant="body2">
                        <em>{value}</em>
                      </Typography>{" "}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography variant="body2">{selectionValue}</Typography>
              )}
            </FormControl>
          </td>
        </>
      )}

      <Modal
        open={open_send_via_email_error}
        onClose={handleClose_send_via_email_error}
      >
        <SendEmailError />
      </Modal>
      <Modal open={open_business_case} onClose={handleClose_business_case}>
        <div className="modal_container_business_case">
          <Grid container spacing={3} style={{ height: "100%" }}>
            <div
              className="modal_close_button"
              onClick={handleClose_business_case}
            >
              <CloseIcon className="closeIcon" />
            </div>
            <Grid item xs={6} style={{ width: "100%", height: "100%" }}>
              <iframe
                src="https://forms.office.com/Pages/ResponsePage.aspx?id=0gy-4oN0C0eT04_zvRCUdoBRbbKGL0xFnOkiGjX3aA9UNkpYQUo4SjJNRlAxWUdMQk9ENEIxU0o2Qi4u&embed=true"
                width="100%"
                height="100%"
                style={{ overflow: "hidden", border: "none" }}
              >
                Loading…
              </iframe>
            </Grid>
            <Grid item xs={6} style={{ height: "100%", width: "100%" }}>
              <div style={{ height: "100%", width: "100%" }}>
                <img
                  src={financial_report_image}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </tr>
  );
}
