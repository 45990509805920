import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Button,
  TablePagination,
  TextField,
  Modal,
  Tabs,
  MenuItem,
  FormControl,
  InputLabel,
  Tab,
  Box,
  TableSortLabel,
  Typography,
  Chip,
  ToggleButtonGroup,
  Slider,
  Autocomplete,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import { UserContext } from "../contexts/UserContext";
import { initialCustomFilters } from "../constants/customFilters";
import ToggleButton from "@mui/material/ToggleButton";
import "./FoundPropertiesProject_Admin.css";
import { getData } from "../get-data";
import { AppContext } from "../contexts/AppContext";
import { PropertyRow } from "./AdminPropertyRow";

interface IProps {
  setRefData: React.Dispatch<React.SetStateAction<DashboardData | null>>;
  dashboardDatas: DashboardData[];
  setSearchDatas: (newdata: DashboardData[]) => void;
  isAdmin: boolean;
  defaultRows: number;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  searchData: (searchOverride?: string | null) => void;
  resetSearch: () => void;
  updatePanelChoice: (newOpt: string) => void;
}

const customFilterModelStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const createHeaders = (headers: string[]) => {
  return headers.map((item: string) => ({
    text: item,
    ref: React.useRef(),
  }));
};
export function ResultsTable(
  props: IProps,
  page: number,
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void,
  rowsPerPage: number,
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void,
  currentProp: number,
  selectAll: boolean,
  setMap: (property: DashboardData) => void
) {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const [buttonAddFiltersModal, setButtonAddFiltersModal] =
    React.useState(false);
  const [filterChips, setFilterChips] = useState<CustomFilterOption[]>([]);
  const [selectedCustomFilterType, setSelectedCustomFilterType] =
    useState<CustomFilterOption | null>(null);
  const [customSelectMenuList, setCustomSelectMenuList] =
    useState<CustomFilterOption[]>(initialCustomFilters);

  const handleFilterAddChip = (option: CustomFilterOption) => {
    setFilterChips((prevChips) => [...prevChips, option]);
    setCustomSelectMenuList((prevList) =>
      prevList.filter((item) => item.id !== option.id)
    );
  };

  const handleFilterDeleteChip = (chip: CustomFilterOption) => {
    setFilterChips((prevChips) =>
      prevChips.filter((item) => item.id !== chip.id)
    );

    const addMenuItemBackToList = initialCustomFilters.find(
      (item) => item.id === chip.id
    );
    if (addMenuItemBackToList) {
      setCustomSelectMenuList((prevList) => {
        // This is done to maintain the order of the menu list.
        const newList = [...prevList];
        const index = initialCustomFilters.findIndex(
          (item) => item.id === addMenuItemBackToList.id
        );
        if (index !== -1) {
          newList.splice(index, 0, addMenuItemBackToList);
        }
        return newList;
      });
    }
  };

  const handleFilterFormSelect = (event: SelectChangeEvent) => {
    const selectedId = Number(event.target.value);
    const selectedOptionObject = customSelectMenuList.find(
      (filter) => filter.id === selectedId
    );
    setSelectedCustomFilterType(selectedOptionObject || null);
    setSelectedCustomFilterTypeStringSelectedValue([]);
  };

  const [
    selectedCustomFilterTypeNumberRangeValues,
    setSelectedCustomFilterTypeNumberRangeValues,
  ] = useState<number[]>([]);
  const [
    selectedCustomFilterTypeNumberRangeMin,
    setSelectedCustomFilterTypeNumberRangeMin,
  ] = useState<number>(0);
  const [
    selectedCustomFilterTypeNumberRangeMax,
    setSelectedCustomFilterTypeNumberRangeMax,
  ] = useState<number>(0);
  const [
    selectedCustomFilterTypeNumberRangeMarks,
    setSelectedCustomFilterTypeNumberRangeMarks,
  ] = useState<Marks>([]);

  function getCustomFilterTypeNumberRangeValues(
    dashboardDataVariable: keyof DashboardData
  ): number[] {
    const values = dashboardDataTable.map(
      (data) => data[dashboardDataVariable]
    );
    const validValues = values.filter(
      (value) => typeof value === "number"
    ) as number[];

    const minValue = validValues.length > 0 ? Math.min(...validValues) : 0;
    const maxValue = validValues.length > 0 ? Math.max(...validValues) : 0;

    return [minValue, maxValue];
  }

  const handleCustomFilterTypeNumberRangeChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setSelectedCustomFilterTypeNumberRangeValues(newValue as [number, number]);
  };

  const handleSelectedCustomFilterTypeNumberOptionSubmit = (
    event: React.FormEvent
  ) => {
    event.preventDefault();
    if (selectedCustomFilterType) {
      const selectedValueFromForm = `${selectedCustomFilterTypeNumberRangeValues[0]} to ${selectedCustomFilterTypeNumberRangeValues[1]}`;

      handleFilterAddChip({
        ...selectedCustomFilterType,
        selectedValue: selectedValueFromForm,
      });
      setSelectedCustomFilterTypeNumberRangeValues([]);
      setSelectedCustomFilterTypeNumberRangeMin(0);
      setSelectedCustomFilterTypeNumberRangeMax(0);
      setSelectedCustomFilterTypeNumberRangeMarks([]);
      setSelectedCustomFilterType(null);
    }
  };

  const [
    selectedCustomFilterTypeBooleanOption,
    setSelectedCustomFilterTypeBooleanOption,
  ] = useState("true");

  const handleSelectedCustomFilterTypeBooleanOptionChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setSelectedCustomFilterTypeBooleanOption(newValue);
    }
  };

  const handleSelectedCustomFilterTypeBooleanOptionSubmit = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (!selectedCustomFilterTypeBooleanOption) {
      alert("Please select True or False.");
      return;
    }

    if (selectedCustomFilterType) {
      handleFilterAddChip({
        ...selectedCustomFilterType,
        selectedValue: selectedCustomFilterTypeBooleanOption,
      });
      setSelectedCustomFilterType(null);
      setSelectedCustomFilterTypeBooleanOption("true");
    }
  };

  const [
    selectedCustomFilterTypeStringOptions,
    setSelectedCustomFilterTypeStringOptions,
  ] = useState<AutocompleteOption[]>([]);
  const [
    selectedCustomFilterTypeStringSelectedValue,
    setSelectedCustomFilterTypeStringSelectedValue,
  ] = useState<AutocompleteOption[]>([]);

  const handleSelectedCustomFilterTypeStringAutocompleteChange = (
    event: React.ChangeEvent<{}>,
    value: AutocompleteOption | AutocompleteOption[]
  ) => {
    setSelectedCustomFilterTypeStringSelectedValue(
      value as AutocompleteOption[]
    );
  };

  const handleSelectedCustomFilterTypeStringOptionSubmit = (
    event: React.FormEvent
  ) => {
    event.preventDefault();
    // Check if the text is empty or null
    if (!selectedCustomFilterTypeStringSelectedValue) {
      alert("Please provide a text to search!");
      return;
    }

    if (selectedCustomFilterType) {
      const selectedValuesString = selectedCustomFilterTypeStringSelectedValue
        .map((option) => option.value)
        .join(" | ");
      handleFilterAddChip({
        ...selectedCustomFilterType,
        selectedValue: selectedValuesString,
      });
      setSelectedCustomFilterTypeStringSelectedValue([]);
      setSelectedCustomFilterType(null);
    }
  };

  function checkCustomFiltersForRowData(data: DashboardData) {
    if (filterChips.length > 0) {
      const passesFilters = filterChips.every((filterOption) => {
        const value = data[filterOption.dashboardDataVariable];

        if (value !== null && value !== undefined) {
          if (filterOption.type === "string" && filterOption.selectedValue) {
            const multipleFilters = filterOption.selectedValue.split("|");
            const isMatch = multipleFilters.some((filter) => {
              return filter
                .toLowerCase()
                .includes((value as string).toLowerCase());
            });
            if (isMatch) {
              return true;
            }
          } else if (filterOption.type === "boolean") {
            return (
              typeof value === "boolean" &&
              value === (filterOption.selectedValue === "true")
            );
          } else if (filterOption.type === "number") {
            if (filterOption.selectedValue) {
              const [startNumberString, endNumberString] =
                filterOption.selectedValue.split(" to ");
              const startNumber = parseFloat(startNumberString);
              const endNumber = parseFloat(endNumberString);
              return (
                typeof value === "number" &&
                value >= startNumber &&
                value <= endNumber
              );
            } else {
              return false;
            }
          }
        }
        return false; // Exclude data if the value is null or the filterOption type is not handled
      });

      if (passesFilters) {
        return data; // Return the data if it passes all filters
      } else {
        return null; // Exclude the data if it fails any filter
      }
    } else {
      return data;
    }
  }

  const [dashboardDataTable, setDashboardDataTable] = useState<DashboardData[]>(
    appContext.dashboardDatas
  );

  useEffect(() => {
    const filteredData = appContext.dashboardDatas.filter((data) =>
      checkCustomFiltersForRowData(data)
    );
    setDashboardDataTable(filteredData);
  }, [filterChips]);

  useEffect(() => {
    if (
      selectedCustomFilterType?.type &&
      selectedCustomFilterType.type === "number"
    ) {
      const rangeValues = getCustomFilterTypeNumberRangeValues(
        selectedCustomFilterType.dashboardDataVariable
      );
      setSelectedCustomFilterTypeNumberRangeValues(rangeValues);
      setSelectedCustomFilterTypeNumberRangeMin(rangeValues[0]);
      setSelectedCustomFilterTypeNumberRangeMax(rangeValues[1]);
      setSelectedCustomFilterTypeNumberRangeMarks(
        rangeValues.map((value) => ({ value, label: value.toLocaleString() }))
      );
    } else if (selectedCustomFilterType?.type === "string") {
      const values = dashboardDataTable.map(
        (data) => data[selectedCustomFilterType.dashboardDataVariable]
      );
      setSelectedCustomFilterTypeStringOptions(() => {
        return values
          .filter((value) => value !== null && value !== "") // Exclude null values and empty strings
          .filter((value, index, self) => self.indexOf(value) === index) // Filter unique values
          .map((value) => ({
            value: value!.toString(), // Use non-null assertion operator !
            label: value!.toString(),
          }));
      });
    }
  }, [selectedCustomFilterType]);

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof DashboardData>("reference");
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DashboardData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler =
    (property: keyof DashboardData) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  function isString(value: any): value is string {
    return typeof value === "string";
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (isString(a[orderBy]) && isString(b[orderBy])) {
      const versionA = (a[orderBy] as string).split("_").pop() || "";
      const versionB = (b[orderBy] as string).split("_").pop() || "";

      const numA = parseInt(versionA, 10);
      const numB = parseInt(versionB, 10);

      if (!isNaN(numA) && !isNaN(numB)) {
        if (numA < numB) {
          return 1;
        }
        if (numA > numB) {
          return -1;
        }
        return 0;
      }
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string | null | boolean },
    b: { [key in Key]: number | string | null | boolean }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  let navigate = useNavigate();

  function getProjectPage(data: DashboardData) {
    // setProjectPage(true);
    props.setRefData(data);
    navigate("/project");
  }

  function getLandingPage(data: DashboardData) {
    // setProjectPage(true);
    props.setRefData(data);
    navigate("/landing");
  }

  const [valueTab, setValueTabs] = React.useState("All");

  const handleChangeTabs = (event: React.SyntheticEvent, newTab: string) => {
    setValueTabs(newTab);
  };
  const [liveData, setLiveData] = React.useState<LiveGeneration | null>(null);
  useEffect(() => {
    if (appContext.showGeneratingList) {
      setValueTabs("Generating");
      getData(
        process.env.REACT_APP_API_URL + "/Dashboard/liveGeneration",
        false
      ).then((data: LiveGeneration) => {
        setLiveData(data);
        //setDataLoaded(true);
      });
    } else {
      setValueTabs("All");
    }
  }, [appContext.showGeneratingList]);

  function retrieveNumberFromCategory(stage: string): string {
    if (stage == "All") {
      return (
        dashboardDataTable.length -
        dashboardDataTable.filter((data) => data.stage === "Archived").length
      ).toString();
      // } else if (stage == "Saved") {
      //   return appContext.dashboardDatas.filter(data => data.saved === true).length.toString();
    } else {
      return dashboardDataTable
        .filter((data) => data.stage === stage)
        .length.toString();
    }
  }

  //if the environment is natwest, show the most recently-added property at the top
  useEffect(() => {
    if (userContext.isNatwest) {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
  }, [userContext.isNatwest]);

  const buttonRefs = React.useRef<any[]>([]);

  const genColumns = [
    "",
    "Address",
    "Reference",
    "Templates",
    "Generating now",
    "Equv. cost saving today",
  ];
  const normalColumns = ["", "Address", "Reference", "Templates", "Stage"];

  const [tableHeight, setTableHeight] = React.useState("auto");
  const [activeIndex, setActiveIndex] = React.useState(null);
  const tableElement = React.useRef(null);
  const columnsNormal = createHeaders(normalColumns);
  const columnsGenerating = createHeaders(genColumns);

  useEffect(() => {
    if (tableElement && tableElement.current) {
      setTableHeight((tableElement.current as any).offsetHeight);
    }
  }, []);

  const mouseDown = (index: any) => {
    setActiveIndex(index);
  };

  const mouseMove = useCallback(
    (e: any) => {
      const gridColumns = (
        valueTab != "Generating" ? columnsNormal : columnsGenerating
      ).map((col, i) => {
        if (i === activeIndex && col.ref.current) {
          const width = e.clientX - (col.ref.current as any).offsetLeft;

          if (width >= 120) {
            return `${width}px`;
          }
        }
        if (col.ref.current) return `${(col.ref.current as any).offsetWidth}px`;
      });

      if (tableElement && tableElement.current)
        (
          tableElement.current as any
        ).style.gridTemplateColumns = `${gridColumns.join(" ")}`;
    },
    [activeIndex, columnsNormal, columnsGenerating, 120]
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  const resetTableCells = () => {
    if (tableElement && tableElement.current)
      (tableElement.current as any).style.gridTemplateColumns = "";
  };

  return (
    <>
      <div style={{ textAlign: "left", marginTop: 20 }}>
        <Button
          variant="contained"
          style={{ textTransform: "none" }}
          onClick={() => {
            setButtonAddFiltersModal(true);
          }}
        >
          Advanced filters
        </Button>
        {filterChips.map((chip) => (
          <Chip
            key={chip.id}
            label={`${chip.label}: ${chip.selectedValue}`}
            onDelete={() => handleFilterDeleteChip(chip)}
            style={{ margin: "0px 0.5rem" }}
          />
        ))}
      </div>
      <Modal
        open={buttonAddFiltersModal}
        onClose={() => {
          setButtonAddFiltersModal(false);
          setSelectedCustomFilterType(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customFilterModelStyle} className="customFilterModal">
          {filterChips.map((chip) => (
            <Chip
              key={chip.id}
              label={`${chip.label}: ${chip.selectedValue}`}
              onDelete={() => handleFilterDeleteChip(chip)}
              style={{ margin: "0.5rem" }}
            />
          ))}
          {/* <h3 style={{ paddingLeft: "50px" }}>Add a filter</h3> */}
          <Typography variant="h5" style={{ marginBottom: 30 }}>
            Add a filter
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="add-filter-select-label">Select Filter</InputLabel>
            <Select
              labelId="custom-filter-select-label"
              id="custom-filter-select"
              label="Select Filter"
              onChange={handleFilterFormSelect}
            >
              <MenuItem value="" disabled>
                Select a filter
              </MenuItem>
              {customSelectMenuList.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedCustomFilterType &&
            selectedCustomFilterType.type === "string" &&
            selectedCustomFilterTypeStringOptions.length > 0 && (
              <form
                onSubmit={handleSelectedCustomFilterTypeStringOptionSubmit}
                style={{
                  // padding: '16px',
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Autocomplete
                  multiple
                  value={selectedCustomFilterTypeStringSelectedValue}
                  onChange={
                    handleSelectedCustomFilterTypeStringAutocompleteChange
                  }
                  options={selectedCustomFilterTypeStringOptions}
                  getOptionLabel={(option) => option.label}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select an option" />
                  )}
                />
                <Button
                  variant="contained"
                  type="submit"
                  style={{ marginTop: "20px" }}
                >
                  Submit
                </Button>
              </form>
            )}
          {selectedCustomFilterType &&
            selectedCustomFilterType.type === "number" &&
            selectedCustomFilterTypeNumberRangeValues.length > 0 && (
              <form
                onSubmit={handleSelectedCustomFilterTypeNumberOptionSubmit}
                style={{
                  padding: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Slider
                  value={selectedCustomFilterTypeNumberRangeValues}
                  onChange={handleCustomFilterTypeNumberRangeChange}
                  min={selectedCustomFilterTypeNumberRangeMin} // Set the minimum value of the range
                  max={selectedCustomFilterTypeNumberRangeMax} // Set the maximum value of the range
                  aria-labelledby="range-slider"
                  valueLabelDisplay="auto"
                  marks={selectedCustomFilterTypeNumberRangeMarks}
                />
                <br />
                <Button variant="contained" type="submit">
                  Add
                </Button>
              </form>
            )}
          {selectedCustomFilterType &&
            selectedCustomFilterType.type === "boolean" && (
              <form
                onSubmit={handleSelectedCustomFilterTypeBooleanOptionSubmit}
                style={{
                  padding: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "normal",
                }}
              >
                <ToggleButtonGroup
                  color="primary"
                  value={selectedCustomFilterTypeBooleanOption}
                  exclusive
                  onChange={handleSelectedCustomFilterTypeBooleanOptionChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="true" aria-label="true">
                    True
                  </ToggleButton>
                  <ToggleButton value="false" aria-label="false">
                    False
                  </ToggleButton>
                </ToggleButtonGroup>
                <br />
                <Button
                  variant="contained"
                  type="submit"
                  style={{ marginLeft: "10px" }}
                >
                  Add
                </Button>
              </form>
            )}
        </Box>
      </Modal>
      {appContext.showGeneratingList ? (
        <div style={{ height: "20px" }}></div>
      ) : (
        <div
          style={{
            border: "1px solid #eeeeee",
            margin: "20px 0px 1px 0px",
            // width: "97.4vw",
          }}
        >
          {appContext.showSavedList ? (
            <Tabs variant="fullWidth">
              <Tab
                className="hover_highlight"
                label={"Saved (" + dashboardDataTable.length.toString() + ")"}
              />
            </Tabs>
          ) : (
            <Tabs
              value={valueTab}
              onChange={handleChangeTabs}
              variant="fullWidth"
            >
              <Tab
                className="hover_highlight"
                value="All"
                label={"All (" + retrieveNumberFromCategory("All") + ")"}
              />
              {/* <Tab className="hover_highlight" value="Saved" label={"Saved (" + retrieveNumberFromCategory("Saved") + ")"} /> */}
              <Tab
                className="hover_highlight"
                value="Recommended"
                label={
                  "Recommended (" +
                  retrieveNumberFromCategory("Recommended") +
                  ")"
                }
              />
              <Tab
                className="hover_highlight"
                value="Approved"
                label={
                  "Approved (" + retrieveNumberFromCategory("Approved") + ")"
                }
              />
              <Tab
                className="hover_highlight"
                value="Installing"
                label={
                  "Installing (" +
                  retrieveNumberFromCategory("Installing") +
                  ")"
                }
              />
              <Tab
                className="hover_highlight"
                value="Archived"
                label={
                  "Archived (" + retrieveNumberFromCategory("Archived") + ")"
                }
              />
            </Tabs>
          )}
        </div>
      )}
      <table
        style={{ width: "100%", overflow: "auto", marginTop: "0px" }}
        className="resizeable-table2"
        ref={tableElement}
      >
        <thead style={{}}>
          <tr>
            {(valueTab != "Generating" ? columnsNormal : columnsGenerating).map(
              ({ ref, text }, i) => (
                <th
                  ref={ref as any}
                  key={text}
                  style={{ backgroundColor: "rgb(226, 226, 226)" }}
                >
                  {text == "Address" ? (
                    <TableSortLabel
                      active={orderBy === "address"}
                      direction={orderBy === "address" ? order : "asc"}
                      onClick={createSortHandler("address")}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                        {text}
                      </span>
                    </TableSortLabel>
                  ) : text == "Reference" ? (
                    <TableSortLabel
                      active={orderBy === "reference"}
                      direction={orderBy === "reference" ? order : "asc"}
                      onClick={createSortHandler("reference")}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                        {text}
                      </span>
                    </TableSortLabel>
                  ) : text == "Classification" ? (
                    <TableSortLabel
                      active={orderBy === "class_sub_desc"}
                      direction={orderBy === "class_sub_desc" ? order : "asc"}
                      onClick={createSortHandler("class_sub_desc")}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                        {text}
                      </span>
                    </TableSortLabel>
                  ) : text == "Site" ? (
                    <TableSortLabel
                      active={orderBy === "class_code"}
                      direction={orderBy === "class_code" ? order : "asc"}
                      onClick={createSortHandler("class_code")}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                        {text}
                      </span>
                    </TableSortLabel>
                  ) : text == "System" ? (
                    <TableSortLabel
                      active={orderBy === "install_capacity_kw"}
                      direction={
                        orderBy === "install_capacity_kw" ? order : "asc"
                      }
                      onClick={createSortHandler("install_capacity_kw")}
                      style={{ fontWeight: "bold" }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                        {text}
                      </span>
                    </TableSortLabel>
                  ) : text == "Investment return" ? (
                    <TableSortLabel
                      active={orderBy === "roi"}
                      direction={orderBy === "roi" ? order : "asc"}
                      onClick={createSortHandler("roi")}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Investment
                        <br />
                        return
                      </span>
                    </TableSortLabel>
                  ) : (
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      {text}
                    </span>
                  )}

                  <div
                    style={{ height: tableHeight }}
                    onMouseDown={() => mouseDown(i)}
                    className={`resize-handle ${
                      activeIndex === i ? "active" : "idle"
                    }`}
                  />
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {stableSort(
            valueTab == "All"
              ? dashboardDataTable.filter((data) => data.stage != "Archived")
              : appContext.showSavedList
              ? dashboardDataTable
              : dashboardDataTable.filter((data) => data.stage == valueTab),
            getComparator(order, orderBy)
          )
            .slice(currentProp, currentProp + rowsPerPage)
            .map((element, index) =>
              valueTab == "All" && element.stage != "Archived" ? (
                <PropertyRow
                  allDashboardDatas={dashboardDataTable}
                  setAllDashboardData={props.setSearchDatas}
                  dashboardData={element}
                  key={element.reference}
                  isAdmin={props.isAdmin}
                  valueTab={valueTab}
                  updatePanelChoice={props.updatePanelChoice}
                  setRefData={appContext.setRefData}
                />
              ) : appContext.showSavedList ? (
                <PropertyRow
                  allDashboardDatas={dashboardDataTable}
                  setAllDashboardData={props.setSearchDatas}
                  dashboardData={element}
                  key={element.reference}
                  isAdmin={props.isAdmin}
                  valueTab={valueTab}
                  updatePanelChoice={props.updatePanelChoice}
                  setRefData={appContext.setRefData}
                />
              ) : element.stage == valueTab ? (
                <PropertyRow
                  allDashboardDatas={dashboardDataTable}
                  setAllDashboardData={props.setSearchDatas}
                  dashboardData={element}
                  key={element.reference}
                  isAdmin={props.isAdmin}
                  valueTab={valueTab}
                  updatePanelChoice={props.updatePanelChoice}
                  setRefData={appContext.setRefData}
                />
              ) : (
                <></>
              )
            )}
        </tbody>
      </table>
      <TablePagination
        style={{ display: "block" }}
        count={
          valueTab == "All"
            ? dashboardDataTable.filter((data) => data.stage != "Archived")
                .length
            : appContext.showSavedList
            ? dashboardDataTable.length
            : dashboardDataTable.filter((data) => data.stage == valueTab).length
        }
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {appContext.showSavedList ? (
        <Button
          style={{
            marginLeft: "5px",
            display: "block",
            width: "auto",
            marginBottom: "5px",
          }}
          onClick={() => appContext.setShowSavedList(false)}
          sx={{ width: 60 }}
        >
          {"<-"} Back to Dashboard
        </Button>
      ) : (
        <></>
      )}
    </>
  );
}
