import React, { useState } from "react";

import { Typography, Grid , CardActionArea } from "@mui/material";
import Tech_image from "../images/tech_background.png";

export default function InfoHealthSafety() {
  return (
    <>
      <Typography
        variant="h5"
        style={{ marginTop: 20, borderBottom: "1px grey solid" }}
        gutterBottom
        paragraph
      >
        Health and safety procedures
      </Typography>
      <Typography variant="body2" paragraph>
      Health and safety procedures are of utmost importance in rooftop solar PV projects to ensure the well-being of workers, mitigate risks, and maintain a safe working environment. Here's an overview of the typical health and safety procedures involved: 
      </Typography>
      
      <Typography variant="body2" paragraph>
        <ol>
          <li style={{marginBottom:10}}>
          Risk Assessment: Before commencing any work, a thorough risk assessment is conducted to identify potential hazards associated with the rooftop solar PV project. This assessment includes evaluating risks such as falls from heights, electrical hazards, structural stability, and any other relevant dangers. 
          </li>
          <li style={{marginBottom:10}}>
          Personal Protective Equipment (PPE): Adequate personal protective equipment is provided to all workers involved in the project. This typically includes items such as hard hats, safety glasses, high-visibility vests, gloves, and safety harnesses. Workers are trained on the proper use and maintenance of PPE. 
          </li>
          <li style={{marginBottom:10}}>
          Safe Access and Egress: Safe access to and egress from the rooftop is crucial. Properly secured ladders, scaffolding, or other means of access are used to ensure workers can safely reach and leave the work area. Guardrails or barriers are installed around the perimeter of the rooftop to prevent falls. 
          </li>
          <li style={{marginBottom:10}}>
          Fall Protection Systems: Measures are implemented to prevent falls from heights. This can include the use of safety harnesses and fall arrest systems, along with appropriate anchorage points and lifelines. Workers are trained on fall protection procedures and equipment usage. 
          </li>
          <li style={{marginBottom:10}}>
          Electrical Safety: Given the electrical nature of solar PV installations, electrical safety protocols are strictly followed. This includes proper lockout/tagout procedures when working on live electrical components, ensuring proper grounding and earthing, and adherence to electrical codes and regulations. 
          </li>
          <li style={{marginBottom:10}}>
          Equipment Safety: All tools, equipment, and machinery used in the installation process are regularly inspected, properly maintained, and operated by trained personnel. Safety features, such as machine guards and emergency stop buttons, are in place to prevent accidents. 
          </li>
          <li style={{marginBottom:10}}>
          Hazardous Materials: If any hazardous materials or substances are involved, such as chemicals used for cleaning or adhesives, proper storage, handling, and disposal procedures are followed to minimize risks to workers and the environment. 
          </li>
          <li style={{marginBottom:10}}>
          Emergency Preparedness: Adequate emergency response plans and procedures are in place in case of accidents, injuries, or other emergencies. This includes having trained personnel on-site, readily accessible first aid kits, and clear evacuation plans. 
          </li>
          <li style={{marginBottom:10}}>
          Training and Supervision: Workers involved in rooftop solar PV projects receive appropriate training on safety procedures, equipment usage, and emergency protocols. Supervisors and managers ensure that safety guidelines are followed, and they regularly monitor the work area for any potential risks. 
          </li>
          <li style={{marginBottom:10}}>
          Ongoing Safety Evaluation: Throughout the project, safety procedures are continually evaluated and adjusted as necessary. Regular safety inspections and audits are conducted to identify areas for improvement and ensure compliance with regulations and industry best practices. 
          </li>
        </ol>
      </Typography>
      <Typography variant="body2" paragraph>
      By adhering to these health and safety procedures, rooftop solar PV projects can be carried out in a safe and secure manner, protecting the well-being of workers and minimizing the risk of accidents or injuries.
      </Typography>
     
    </>
  );
}
