/**
 * @description This is default data of calculationsheet
 * It is used in BusinessCasePage
 * @returns an object of default dataset for calculationsheet
 * @Writer Unknown but refactored by Roy
 */
export const defaultCalculationsheetData: CalculationSheet = {
  projectReference: "",
  /** CAUTION: default panel is JAM66S-30-500, therefore the power is set to be 500 by default */
  panelPower: 500,
  /** CAUTION: default panel is  JAM66S-30-500, when u changed this, please also change the panelPower */
  panelReference: 1,
  kWpSystemCapacity: 0,
  kWhAtYear0: 0,
  selfConsumption: 0,
  roofType: "Trapazoidal",
  installationType: "Pitched",
  scaffoldingTowers: 0,
  scaffoldingPerimeter: 0,
  roofHeight: 0,
  solarPVPanelQty: 0,
  solarPVPanelUnitCost: 0,
  roofTypeQty: 0,
  roofTypeUnitCost: 45,
  dcCableQty: 0,
  dcCableUnitCost: 0.73,
  invertersQty: 1,
  invertersUnitCost: 0,
  otherACElectricalQty: 1,
  otherACElectricalUnitCost: 0,
  mountingKitDelievryQty: 0,
  mountingKitDeliveryUnitCost: 0.75,
  panelDeliveryQty: 0,
  panelDeliveryUnitCost: 2,
  inverterDeliveryQty: 1,
  inverterDeliveryUnitCost: 250,
  plantQty: 0,
  plantUnitCost: 0,
  equipmentHireQty: 0,
  equipmentHireUnitCost: 0,
  scaffoldingEdgeProtectionQty: 0,
  scaffoldingEdgeProtectionUnitCost: 40,
  scaffoldingTowersQty: 0,
  scaffoldingTowersUnitCost: 300,
  roofingSolarInstallersQty: 0,
  roofingSolarInstallersUnitCost: 150,
  electricialQty: 1,
  electricianUnitCost: 0,
  designQty: 0,
  designUnitCost: 500,
  g99Qty: 0,
  g99UnitCost: 500,
  additionalConsultingQty: 0,
  additionalConsultingUnitCost: 0,
  contingency: 5,
  targetMargin: 0.3,
  discountRate: 0,
  electricityInflation: 0,
  feedInTariffInflation: 0,
  minAnnualMaintainenceCost: 0,
  annualMaintainenceCost: 0,
  optimisationCostInflation: 0,
  importRate: 0,
  exportRate: 0,
  panelDegPerYear: 0,
  carbonFactor: 0,
  bankLoanAmount: 0,
  interestRate: 0.0075,
  noOfPeriods: 60,
  cta: 1, //default
  comments: "",
  organisationID: 0,
  min_building_height: 0,
  max_building_height: 0,
  assessment_date: "",
  potential_saving_year_1: 0,
  indicative_cost: 0,
  total_utility_bill_savings_20: "",
  payback: "",
  loan_payback: "",
  roi: null,
  loan_total_utility_bill_savings_20: "",
  loan_roi: null,
  address: "",
  class_sub_desc: "",
  /* this is a repeat property same as "installationType" above */
  type_of_installation: "Flat",
  council_property: "No",
  listed: "No",
  conservation_area: "No",
  monthly_repayment: null,
  installationCalculated: false,
};
