import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Loading } from "../loading/Loading";
import CanvasWithEditor from "../toidSearchHelpers/CanvasWithEditor";
import { submitData } from "../../App";
import { UserContext } from "../../contexts/UserContext";
import { getData, postData, putData } from "../../get-data";
import { useNavigate } from "react-router";

import { getDashboardData } from "../../services";
import {
  getTodaysDate,
  transformCalculationSheetToIngressDto,
} from "../../utils";
import { defaultCalculationsheetData } from "../../data";


const Container = styled.div``;

const MainContainer = styled.div``;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const Bottom = styled.div`
  display: flex;
`;

const Left = styled.div`
  width: 70%;
  padding: 5px;
`;

const Text = styled.h3``;

const ButtonContainer = styled.div`
  margin: 10px 0;
`;

const Right = styled.div`
  width: 30%;
  padding: 5px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
`;

const ImageError = styled.h1`
  display: flex;
  justify-content: center;
`;

const CustomButton = styled.button`
  color: white;
  background-color: #3b82f6ff;
  padding: 10px 35px;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const UploadingSpinner = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface Props {
  selectedPolygon: FeatureCustomData;
  editedPolygonCoordinates: google.maps.LatLng[];
  originalPolygonCoordinates: object[];
  onNext: (data: String) => void;
  reference: string | undefined;
  setReference: (value: string) => void;
  organisationId: number;
  setOrganisationId: (newNumber: number) => void;
  address: string;
  setSelectedPolygon: (newPol: FeatureCustomData) => void;
  textFields: TextFieldType[];
  setTextFields: (newVal: TextFieldType[]) => void;
  setOpenDialogueHandler: (newVal: string) => void;
  inputData?: CalculationSheet | null;
  setCalculationSheet: (newData: CalculationSheet) => void;
  setDashboardDatas: React.Dispatch<React.SetStateAction<DashboardData[]>>;
}

const GetImageCrops: React.FC<Props> = (props) => {
  const userContext = useContext(UserContext);

  const initialValues: RadiationImageFields = {
    osLandUseTierA: props.selectedPolygon.oslandusetiera,
    relativeHeightMaximum:
      props.selectedPolygon.relativeheightmaximum?.toString(),
    relativeHeightRoofBase:
      props.selectedPolygon.relativeheightroofbase?.toString(),
    geometryArea: props.selectedPolygon.geometry_area?.toString(),
    osLandUseTierB:
      props.selectedPolygon.oslandusetierb.length > 0
        ? props.selectedPolygon.oslandusetierb.join(",")
        : "Unknown",
  };

  const [originalValues, setOriginalValues] =
    useState<RadiationImageFields>(initialValues);
  const [fieldValues, setFieldValues] =
    useState<RadiationImageFields>(initialValues);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [radiationImage, setRadiationImage] = useState<string | null>(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  //this is the raw data of satelliteMapImage without crop
  const [satelliteMapImage, setSatelliteMapImage] = useState<string>("");
  //this is the new data sent from the backend (Ivan) with crop
  const [croppedSatelliteMapImage, setCroppedSatelliteMapImage] = useState<
    string | null
  >(null);

  const [coordinateList, setCoordinateList] = useState<Coordinates[]>([]);
  const [polygonData, setPolygonData] = useState<Shape[] | null>(null);
  const [tileData, setTileData] = useState<Shape[]>([]);
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);
  const [satelliteImageZoom, setSatelliteImageZoom] = useState<number>(18);

  const [canvasImageURL, setCanvasImageURL] = useState<string>("");
  const [convertedImage, setConvertedImage] = useState<boolean>(false);
  const [preparedToProceed, setpreparedToProceed] = useState<boolean>(false);

  const [scaffoldingPerimeter, setScaffoldingPerimeter] = useState(0);

  //variables migrated from BusinessCasePage
  const updatedDefaultCalculationsheetData = {
    ...defaultCalculationsheetData,
    projectReference: props.reference ?? "",
    roofHeight: props.selectedPolygon.relativeheightroofbase,
    scaffoldingEdgeProtectionQty: scaffoldingPerimeter,
    organisationID: props.organisationId,
    min_building_height: parseFloat(props.textFields[0].subcategories[1].value),
    max_building_height: parseFloat(props.textFields[0].subcategories[0].value),
    assessment_date: getTodaysDate(),
    address: props.address,
    class_sub_desc: props.selectedPolygon.oslandusetiera,
    optimisationCostInflation: parseFloat(
      props.textFields[1].subcategories[0].value
    ),
    importRate: parseFloat(props.textFields[1].subcategories[1].value),
    exportRate: parseFloat(props.textFields[1].subcategories[2].value),
  };
  const inputData = useMemo(
    () =>
      props.inputData != null
        ? {
            ...props.inputData,
            invertersQty: 1,
            optimisationCostInflation:
              parseFloat(props.textFields[1].subcategories[0].value) / 100,
            importRate: parseFloat(props.textFields[1].subcategories[1].value),
            exportRate: parseFloat(props.textFields[1].subcategories[2].value),
            min_building_height: parseFloat(
              props.textFields[0].subcategories[1].value
            ),
            max_building_height: parseFloat(
              props.textFields[0].subcategories[0].value
            ),
          }
        : // props.inputData TODO: the above replces any object passed here with a replica containing
          //invertersQty as 1. This will not be needed anymore once we fix the inverterQty calculation
          updatedDefaultCalculationsheetData,
    [props.inputData, props.textFields]
  );
  const [solarPanelPowerOptions, setSolarPanelPowerOptions] = useState<
    SolarPanel[]
  >([]);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  //use to control the button to enlarge the satelliteimage
  const [isLargeSatelliteImage, setIsLargeSatelliteImage] =
    useState<boolean>(false);
  const zoomStatelliteImage = () =>
    setIsLargeSatelliteImage(!isLargeSatelliteImage);

  const handleEditClick = () => {
    setIsEditing(true);
    setOriginalValues(fieldValues);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    //Handle save action: update selectedPolygon with the needed updates
    var newPolygon = { ...props.selectedPolygon };
    newPolygon.oslandusetiera = fieldValues.osLandUseTierA;
    newPolygon.geometry_area = parseFloat(fieldValues.geometryArea);
    newPolygon.oslandusetierb =
      fieldValues.osLandUseTierB.length > 0
        ? fieldValues.osLandUseTierB.split(",")
        : [];
    newPolygon.relativeheightmaximum = parseFloat(
      fieldValues.relativeHeightMaximum
    );
    newPolygon.relativeheightroofbase = parseFloat(
      fieldValues.relativeHeightRoofBase
    );
    props.setSelectedPolygon(newPolygon);
    // //if we update the OS land tier A - reflect in the right table
    // const updatedTextFields = [...props.textFields];
    // updatedTextFields[0].subcategories[2].value =
    // fieldValues.osLandUseTierA;
    // props.setTextFields(updatedTextFields);
  };

  const handleResetClick = () => {
    setFieldValues(originalValues); // Reset the values to their original state
    setIsEditing(false);
  };

  const handleInputChange =
    (field: keyof RadiationImageFields) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValues({
        ...fieldValues,
        [field]: event.target.value,
      });
    };

  useEffect(() => {
    if (coordinateList.length > 0) {
      const fetchData = async () => {
        try {
          setIsLoadingImage(true);
          const requestData = {
            selectedPolygon: {
              toidID: props.selectedPolygon.toid,
              osLandUseTierA: props.selectedPolygon.oslandusetiera,
              relativeHeightMaximum:
                props.selectedPolygon.relativeheightmaximum?.toString(),
              relativeHeightRoofBase:
                props.selectedPolygon.relativeheightroofbase?.toString(),
              geometryArea: props.selectedPolygon.geometry_area?.toString(),
              osLandUseTierB: props.selectedPolygon.oslandusetierb.length
                ? props.selectedPolygon.oslandusetierb.join(",")
                : "Unknown",
            },
            coordinateList: coordinateList,
            originalCoordinateList: props.originalPolygonCoordinates, // pass the edited coordinates to the AI team as well
            /* pase the base64 google crops to the backend. It is noted that we have trim the string to get pure data */
            satelliteMapImage: satelliteMapImage.split(",")[1],
          };

          const requestDataJson = JSON.stringify(requestData);

          const url = `https://absolarserver:7019/api/Script/radiationImageFromCoordinates`;
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: requestDataJson,
          });

          if (response.ok) {
            if (response.status === 204) {
              setRadiationImage(null);
            } else if (response.status === 200) {
              const data: string[] = (await response.text())
                .split("\r\n")
                .filter((x) => x !== "");
              console.log(data);
              setRadiationImage(data[0]);
              setCroppedSatelliteMapImage(data[1]);
            }
          } else {
            setRadiationImage(null);
            console.error("Error fetching data:", response);
          }
        } catch (error) {
          setRadiationImage(null);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoadingImage(false);
        }
      };

      fetchData().then(() => {});
    }
  }, [coordinateList, props.selectedPolygon, satelliteMapImage]);

  function measure(lat1: number, lon1: number, lat2: number, lon2: number) {
    // generally used geo measurement function
    var R = 6378.137; // Radius of earth in KM
    var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d * 1000; // meters
  }

  const _C = { x: 128, y: 128 };
  const _J = 256 / 360;
  const _L = 256 / (2 * Math.PI);

  function tb(a: number) {
    return (180 * a) / Math.PI;
  }

  function sb(a: number) {
    return (a * Math.PI) / 180;
  }

  function bounds(a: number, b: number | null, c: number | null) {
    null != b && (a = Math.max(a, b));
    null != c && (a = Math.min(a, c));
    return a;
  }

  function latlonToPt(ll: number[]) {
    const a = bounds(Math.sin(sb(ll[0])), -(1 - 1e-15), 1 - 1e-15);
    return {
      x: _C.x + ll[1] * _J,
      y: _C.y + 0.5 * Math.log((1 + a) / (1 - a)) * -_L,
    };
  }

  function ptToLatlon(pt: { x: any; y: any }) {
    return [
      tb(2 * Math.atan(Math.exp((pt.y - _C.y) / -_L)) - Math.PI / 2),
      (pt.x - _C.x) / _J,
    ];
  }

  function calculateBbox(ll: any, zoom: number, sizeX: number, sizeY: number) {
    const cp = latlonToPt(ll);
    const pixelSize = Math.pow(2, -(zoom + 1));
    const pwX = sizeX * pixelSize;
    const pwY = sizeY * pixelSize;

    return {
      ne: ptToLatlon({ x: cp.x + pwX, y: cp.y - pwY }),
      sw: ptToLatlon({ x: cp.x - pwX, y: cp.y + pwY }),
    };
  }

  useEffect(() => {
    if (props.editedPolygonCoordinates.length > 0) {
      const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const coordinates: Coordinates[] = props.editedPolygonCoordinates.map(
        (coordinate) => {
          return {
            latitude: coordinate.lat(),
            longitude: coordinate.lng(),
          };
        }
      );

      //create polygon data for static image
      const newPol: Shape[] = [
        {
          label: "",
          points: props.editedPolygonCoordinates.map((coordinate) => {
            return {
              x: coordinate.lat(),
              y: coordinate.lng(),
            };
          }),
          shape_type: "polygon",
        },
      ];
      if (props.editedPolygonCoordinates != null) setPolygonData(newPol);

      setCoordinateList(coordinates);

      // let zoom = maxDistance > 100 ? 18 : 19;
      //HOTFIX: CHANGE ZOOM TO DEFAULT TO 19 FOR NOW
      let zoom = 19;
      setSatelliteImageZoom(zoom);

      if (coordinates.length > 0) {
        const fetchData = async () => {
          try {
            setIsLoadingImage(true);

            const url = `https://absolarserver:7019/api/Script/centreFromCoordinates`;
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(coordinates),
            });

            if (response.ok) {
              if (response.status === 200) {
                const data: string[] = (await response.text()).split(" ");
                const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?senor=false&size=640x640&zoom=${zoom}&maptype=satellite&center=${data[1]},${data[0]}&key=${apiKey}`;

                fetch(mapUrl)
                  .then((response) => {
                    if (!response.ok) {
                      throw new Error("Network response was not ok");
                    }
                    return response.blob();
                  })
                  .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      const base64Image = reader.result?.toString() || "";
                      setSatelliteMapImage(base64Image);
                    };
                    reader.readAsDataURL(blob);
                  })
                  .catch((error) => {
                    console.error("Error fetching static map:", error);
                  });
              }
            } else {
            }
          } catch (error) {
            alert("Error fetching centroid for satellite image");
          }
        };
        fetchData().then(() => {});
      } else {
        alert(JSON.stringify(coordinates));
      }
      //WITH MARKER:
      //const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?senor=false&size=640x640&markers=color:FFFF00%7Csize:tiny%7C%7C${northEastCorner[0]},${northEastCorner[1]}&zoom=${zoom}&maptype=satellite&center=${avgLat},${avgLng}&key=${apiKey}`;
      //WITHOUT MARKER:

      /**
       * Create coordinateList
       */
    }
  }, [props.editedPolygonCoordinates]);

  const [showImageWarning, setShowImageWarning] = useState(false);
  const handleNextClick = () => {
    if (canvasImageURL == "") {
      //setShowImageWarning(true);
      setpreparedToProceed(true);
    } else {
      createNewProject(canvasImageURL);
      jumpCalculations();
    }
  };

  useEffect(() => {
    if (preparedToProceed) {
      setShowImageWarning(false);
      // set the converted image to true so that we can hide the dots
      setConvertedImage(true);
    }
  }, [preparedToProceed]);

  function separateShapes(shapes: any) {
    // const polygons: Shape[] = [];
    // const tiles: Shape[] = [];

    // for (const shape of shapes) {
    //     const newShape: any = {
    //       label: shape.label,
    //       shape_type: shape.shape_type,
    //       points: shape.points.map((point: any[])  => {
    //         return { x: point[0], y: point[1] };
    //       }),
    //     };

    //     if (shape.shape_type === "polygon") {
    //       polygons.push(newShape);
    //     } else if (shape.shape_type === "tile") {
    //       tiles.push(newShape);
    //     }
    // }
    /**
     * Correct variables: polygons
     */
    var example = {
      label: "panel_array",
      points: [
        { x: 121.0, y: 63.0 },
        { x: 88.0, y: 153.0 },
        { x: 141.0, y: 174.0 },
        { x: 137.0, y: 184.0 },
        { x: 156.0, y: 192.0 },
        { x: 195.0, y: 91.0 },
        { x: 177.0, y: 84.0 },
        { x: 181.0, y: 74.0 },
      ],
      shape_type: "polygon",
    };

    //"example" is the old default polygon.
    //setPolygonData([example]);
    setPolygonData([]);
  }

  const getPolygonData = async () => {
    try {
      // const response = await fetch('your-api-endpoint');
      // const jsonData = await response.json();
      const shapesJson = `{"shapes": [{"label": "panel_array", "points": [[300.17459186062433, 344.7668882355274], [328.02761341945074, 335.71689293146494], [322.1617165680916, 317.66351876126885], [294.3086950092652, 326.7135140653313], [300.17459186062433, 344.7668882355274]], "shape_type": "polygon"}, {"label": "panel_array", "points": [[330.46231810116626, 334.92580942586505], [352.74473534822744, 327.6858131826151], [346.8788384968683, 309.632439012419], [324.59642124980724, 316.872435255669], [330.46231810116626, 334.92580942586505]], "shape_type": "polygon"}, {"label": "panel_array", "points": [[293.5176115036654, 324.2788093836158], [321.37063306249183, 315.22881407955333], [319.90415884965205, 310.7154705370043], [292.05113729082564, 319.76546584106677], [293.5176115036654, 324.2788093836158]], "shape_type": "polygon"}, {"label": "panel_array", "points": [[323.80533774420735, 314.43773057395344], [346.0877549912685, 307.1977343307035], [344.62128077842874, 302.6843907881545], [322.33886353136756, 309.9243870314044], [323.80533774420735, 314.43773057395344]], "shape_type": "polygon"}]}`;

      const parsedShapes: { shapes: Shape[] } = JSON.parse(shapesJson);
      const shapes: Shape[] = parsedShapes.shapes;

      separateShapes(shapes);
    } catch (error) {
      console.error("Error fetching static image:", error);
    }
  };

  useEffect(() => {
    getPolygonData();
  }, []);

  const retrievePolygonData = async (
    base64Image: string,
    zoomLevel: number
  ) => {
    //create polygon data for static image
    const polData: Shape[] = [
      {
        label: "",
        points: props.editedPolygonCoordinates.map((coordinate) => {
          return {
            x: coordinate.lat(),
            y: coordinate.lng(),
          };
        }),
        shape_type: "polygon",
      },
    ];

    try {
      const requestData = {
        shapes: polData,
        image: base64Image,
        zoomLevel: zoomLevel,
      };

      const url = "https://absolarserver:7019/api/Script/getTiling";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        console.error(`Request failed with status: ${response.status}`);
      }

      const canvasPolygonData = await response.json();
      setPolygonData(canvasPolygonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function base64ToFile(base64: string, filename: string) {
    const arr = base64.split(",");

    let mime: string | undefined;

    if (arr[0]) {
      mime = arr[0].match(/:(.*?);/)?.[1];
    }

    if (!mime) {
      mime = "image/png";
    }

    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const handleUploadImageButton = async (canvasDataUrl: string) => {
    setIsUploadingImage(true);

    /**
     * CHANGE [george]:
     * changed what is passed and stored in the backend
     * from the satelliteMapImage variable (which is just a map grab of the property)
     * to the canvasDataUrl variable (which is the map brag containing the polygons drawn on it)
     */
    try {
      if (canvasDataUrl) {
        const satelliteImageFile = base64ToFile(
          canvasDataUrl,
          "satellite_map.png"
        );

        const satelliteImageResult = await submitData(
          satelliteImageFile,
          props.reference + "_sat.png",
          userContext.userState.idToken,
          undefined
        );

        const satelliteImageResultText = await satelliteImageResult.text();
        if (satelliteImageResultText === "0") {
          props.setOpenDialogueHandler(
            "Uploaded Satellite Image: " +
              satelliteImageFile.name +
              " with--name-- " +
              props.reference +
              "_sat.png"
          );
          //alert("Uploaded Satellite Image: " + satelliteImageFile.name + " with--name-- " + props.reference + "_sat.png");
        }
      }

      if (radiationImage) {
        const radiationImageFile = base64ToFile(
          radiationImage,
          "radiation.png"
        );

        const radiationImageResult = await submitData(
          radiationImageFile,
          props.reference + "_rad.png",
          userContext.userState.idToken,
          undefined
        );

        const radiationImageResultText = await radiationImageResult.text();
        if (radiationImageResultText === "0") {
          props.setOpenDialogueHandler(
            "Uploaded Radiation Image: " + radiationImageFile.name
          );
        }
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setIsUploadingImage(false);
    }
  };

  async function createNewProject(canvasDataUrl: string) {
    handleUploadImageButton(canvasDataUrl);
    try {
      const url =
        process.env.REACT_APP_API_URL +
        "/ProjectDelivery/createProjectAndProjectStage?projectReference=" +
        props.reference;

      const response = await getData(url, true, userContext.getCredentials);

      if (response.ok) {
        const data = await response.json();

        // Round up each value in the data array to the nearest integer
        console.log("Project ID: " + data.reference);
        console.log("lastUpdate: " + data.lastUpdate);
        console.log("projectStage: " + data.projectStage);
      } else {
        console.log("Error:", response.status);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const handleTextFieldChange = (
    categoryIndex: number,
    subcategoryIndex: number,
    val: string
  ) => {
    const updatedTextFields = [...props.textFields];
    updatedTextFields[categoryIndex].subcategories[subcategoryIndex].value =
      val;
    props.setTextFields(updatedTextFields);

    // if (categoryIndex == 0 && subcategoryIndex == 2){ //if we make a change to the building classification in right table, update above as well at top of page
    //   console.log("BAA");
    //   var newPolygon = { ...props.selectedPolygon };
    // newPolygon.oslandusetiera = val;

    // props.setSelectedPolygon(newPolygon);
    // }
  };

  //skip calculation sheet
  let navigate = useNavigate();
  function getNewReference(ref: string) {
    getData(
      process.env.REACT_APP_API_URL + "/dashboard/" + ref,
      false,
      userContext.getCredentials
    ).then((data: DashboardData[]) => {
      props.setDashboardDatas((prevState: DashboardData[]) => [
        ...prevState,
        ...data,
      ]);
    });
  }

  async function jumpCalculations() {
    try {
      const savedDashboardData = await getDashboardData(props.reference);
      const ingressData = transformCalculationSheetToIngressDto(
        inputData,
        props,
        savedDashboardData,
        solarPanelPowerOptions
      );
      submitUploadData(ingressData);
    } catch (error) {
      console.log("Error:", error);
    }
    try {
      putData(
        process.env.REACT_APP_API_URL +
          "/ProjectDelivery/setProjectToRemoteSurvey?projectReference=" +
          props.reference,
        {},
        true,
        userContext.getCredentials
      )
        .then(() => {
          getNewReference(props.reference as string);
          navigate("/upload?upRef=" + props.reference);
        })
        .catch((e) => {
          console.error("Update failed:", e);
          alert("Update failed");
        });
      // await putData(
      //   process.env.REACT_APP_API_URL +
      //     "/Installer/building_data_installer?reference=" +
      //     reference,
      //   installerName,
      //   false,
      //   userContext.getCredentials
      // );
    } catch (error: any) {
      // Explicitly specify the type of 'error' as 'any'
      alert("An error occurred: " + error.message);
    }
  }

  function submitUploadData(uploadData: IngressDto) {
    postData(
      process.env.REACT_APP_API_URL + "/ingress",
      uploadData,
      true,
      userContext.getCredentials
    )
      .then((response) => response.text())
      .then((result: number) => {
        if (result == 0) {
          putData(
            process.env.REACT_APP_API_URL +
              "/Installer/building_data_installer?reference=" +
              uploadData.reference,
            [], //installerName
            false,
            userContext.getCredentials
          ).then((installers: Installer[]) => {
            props.setOpenDialogueHandler("Successfully Uploaded.");
            //alert("successfully uploaded");
          });
        }
      })
      .catch((e) => alert(e.message));
  }

  return (
    <Container>
      <MainContainer>
        <Top>
          <Text>TOID: {props.selectedPolygon.toid}</Text>
          <Text>Reference: {props.reference}</Text>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    id="osLandUseTierA"
                    label="OS Land Use Tier A"
                    value={fieldValues.osLandUseTierA}
                    onChange={handleInputChange("osLandUseTierA")}
                    disabled={!isEditing}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="osLandUseTierB"
                    label="OS Land Use Tier B"
                    value={fieldValues.osLandUseTierB}
                    onChange={handleInputChange("osLandUseTierB")}
                    disabled={!isEditing}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="relativeHeightMaximum"
                    label="Relative Height Maximum"
                    value={fieldValues.relativeHeightMaximum}
                    onChange={handleInputChange("relativeHeightMaximum")}
                    disabled={!isEditing}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="relativeHeightRoofBase"
                    label="Relative Height Roof-Base"
                    value={fieldValues.relativeHeightRoofBase}
                    onChange={handleInputChange("relativeHeightRoofBase")}
                    disabled={!isEditing}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="geometryArea"
                    label="Geometry Area"
                    value={fieldValues.geometryArea}
                    onChange={handleInputChange("geometryArea")}
                    disabled={!isEditing}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isEditing ? (
            <ButtonContainer>
              <Button
                style={{ width: "100%", marginBottom: "10px" }}
                variant="contained"
                color="primary"
                onClick={handleSaveClick}
              >
                Save
              </Button>
              <Button
                style={{ width: "100%" }}
                variant="contained"
                color="secondary"
                onClick={handleResetClick}
              >
                Reset
              </Button>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <Button
                style={{ width: "100%" }}
                variant="contained"
                color="primary"
                onClick={handleEditClick}
              >
                Edit
              </Button>
            </ButtonContainer>
          )}
        </Top>
        {/* Nic wants the organization selection to be at the first step */}
        {/* <OrganisationReferenceID
          reference={props.reference}
          setReference={props.setReference}
          organisationId={props.organisationId}
          setOrganisationId={props.setOrganisationId}
        /> */}
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={isLargeSatelliteImage ? 12 : 8}
          >
            {croppedSatelliteMapImage &&
            croppedSatelliteMapImage !== "" &&
            polygonData != null ? (
              <div style={{ display: "flex" }}>
                <CanvasWithEditor
                  //image={satelliteMapImage}
                  image={croppedSatelliteMapImage}
                  polygonData={polygonData}
                  setPolygonData={setPolygonData}
                  tileData={tileData}
                  setTileData={setTileData}
                  zoomLevel={satelliteImageZoom}
                  canvasImageURL={canvasImageURL}
                  setCanvasImageURL={setCanvasImageURL}
                  canvasRef={canvasRef}
                  convertedImage={convertedImage}
                  setConvertedImage={setConvertedImage}
                  setpreparedToProceed={setpreparedToProceed}
                  preparedToProceed={preparedToProceed}
                  createNewProject={createNewProject}
                  onNext={props.onNext}
                  isLargeSatelliteImage={isLargeSatelliteImage}
                  zoomStatelliteImage={zoomStatelliteImage}
                  jumpCalculations={jumpCalculations}
                />
              </div>
            ) : (
              <ImageError>
                Loading Satellite Image, check you are connected on an office
                PC, this should take 10 seconds
              </ImageError>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={isLargeSatelliteImage ? 12 : 4}
          >
            {isLargeSatelliteImage ? null : (
              <div>
                <div style={{ width: "100%" }}>
                  {isLoadingImage ? (
                    <Loading />
                  ) : (
                    <>
                      {radiationImage?.includes("data:image") ? (
                        <Image src={radiationImage} alt="Base64 Image" />
                      ) : (
                        "Radiation Error: " +
                        radiationImage +
                        "Please upload the radiation image later in the control panel."
                      )}
                    </>
                  )}
                </div>
                <div style={{ alignItems: "left" }}>
                  {props.textFields.map((cat, categoryIndex) => (
                    <>
                      <Typography
                        key={categoryIndex}
                        style={{
                          fontSize: "1.5rem",
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}
                        variant="h1"
                        component="h1"
                      >
                        {cat.category}
                      </Typography>
                      <Box
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "8px",
                          padding: "16px",
                          background: "#f5f5f5",
                          borderRadius: "8px",
                          width: "95%",
                        }}
                      >
                        {cat.subcategories.map(
                          (subcategory, subcategoryIndex) => (
                            <>
                              <div style={{ alignSelf: "center" }}>
                                <Typography variant="body1" component="p">
                                  {subcategory.label}
                                </Typography>
                              </div>
                              <TextField
                                variant="standard"
                                label={subcategory.label}
                                value={subcategory.value}
                                onChange={(event) =>
                                  handleTextFieldChange(
                                    categoryIndex,
                                    subcategoryIndex,
                                    event.target.value
                                  )
                                }
                              />
                            </>
                          )
                        )}
                      </Box>
                    </>
                  ))}
                </div>
              </div>
            )}
          </Grid>
        </Grid>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: isLargeSatelliteImage ? "100%" : "50%" }}></div>
          {isLargeSatelliteImage ? <></> : null}
        </div>
        {props.reference && props.reference !== "" && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}></Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  style={{ width: "30%" }}
                  onClick={handleNextClick}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        <Modal
          open={showImageWarning}
          onClose={() => setShowImageWarning(false)}
        >
          <div
            className="modal_container_business_case"
            style={{ height: "15%", width: "40%" }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ width: "100%", height: "100%" }}>
                <Typography>
                  Are you sure you wish to proceed? You did not convert the map
                  to an image. If you proceed, the map will be automatically
                  converted into an image for you.
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ display: "grid" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowImageWarning(false)}
                >
                  Return
                </Button>
              </Grid>
              <Grid item xs={6} style={{ display: "grid" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowImageWarning(false);
                    // set the converted image to true so that we can hide the dots
                    setConvertedImage(true);
                    //this above will trigger useEffect in the CanvasWithEditor file, to hide the dots, and will automatically update the global variable imageURL and the canvas

                    // if (!canvasRef.current) return;
                    // const canvas = canvasRef.current;
                    // // Get the canvas data as a data URL (PNG format)
                    // const canvasDataUrl = canvas.toDataURL('image/png');
                    // console.log(canvasDataUrl);
                    // setCanvasImageURL(canvasDataUrl);
                  }}
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </MainContainer>
    </Container>
  );
};

export default GetImageCrops;
