/**
 * getDashboardData - This is a api to fetch data for Dashboard .
 * This covers data that calculation sheet does not have
 * Mostly used in 'Preview'in 'ProjectPreviewModal'
 * @param {reference} props - This is the project reference to request for corresponding data
 * @Writer Roy Luo
 */

export async function getDashboardData(reference: string | undefined) {
  try {
    const url = process.env.REACT_APP_API_URL + "/DashboardData/" + reference;

    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      //header
      /* It is a public endpoint hence no need to prepare authorization */
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });

    //used legacy code for the rest
    if (response.ok) {
      if (response.status === 204) {
        return null;
      }
      return response.json();
    } else {
      console.log("Error:", response.status);
    }
  } catch (error) {
    console.log("Error:", error);
  }
}
