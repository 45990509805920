import React, { useState } from "react";

import { Typography, Grid , CardActionArea } from "@mui/material";
import Tech_image from "../images/tech_background.png";

export default function InfoLandlordArrangement() {
  return (
    <>
      <Typography
        variant="h5"
        style={{ marginTop: 20, borderBottom: "1px grey solid" }}
        gutterBottom
        paragraph
      >
        Solar PV for Landlords or Tenants within a leasehold building
      </Typography>
      <Typography variant="body2" paragraph>
        It's crucial for landlords and tenants to consult with solar energy experts, legal professionals, and relevant stakeholders to navigate some complexities of solar investment within leasehold commercial buildings. By collaborating and leveraging solar power, both parties can contribute to a greener future while reaping the benefits of renewable energy.​       </Typography>
      <Typography variant="h6">
        <b>For landlord</b>
      </Typography>
      <Typography variant="body2" paragraph>
        If you're a Landlord, you have the option to invest in solar panels for the commercial building you own. By doing so you can offset a portion of the building's energy consumption, increasing the energy performance and lowering the running costs of the building. 
      </Typography>
      <Typography variant="body2" paragraph>
        The solar energy produced can either just be used to power common areas, lighting, elevators, or other shared building amenities or you can sell the power back to your tenants through existing service charge arrangement or a separate power purchase agreement (PPA). Regardless of the option selected, you can earn additional revenues by selling any unused electricity back to the grid, meaning even void periods can generate an income.  
      </Typography>
      <Typography variant="h6">
        <b>For tenants</b>
      </Typography>
      <Typography variant="body2" paragraph>
        Incorporating a solar investment within a leased commercial building requires specific considerations, namely to ensure lease terms address the installation, ownership, maintenance, and cost-sharing aspects of the solar system or that additional agreements are made. These terms can vary depending on the specific arrangement between the Landlord and Tenant.​ With payback periods for suitable projects currently around 5 years, leases and break points are becoming less of an issue, however given the long life span of solar (25 years plus), the long term impacts should be considered. 
      </Typography>
    </>
  );
}
