import React, { useState } from "react";

import { Typography, Grid, CardActionArea } from "@mui/material";
import Tech_image from "../images/tech_background.png";

export default function ScheduleSurvey() {
  return (
    <>
      {/* <Typography
        variant="h5"
        style={{ marginTop: 20, borderBottom: "1px grey solid" }}
        gutterBottom
        paragraph
      >
        Schedule a site survey
      </Typography>
      <Typography variant="body2" paragraph>
        [Paragraph to explain]
      </Typography> */}
      <div style={{minWidth:500 }}>
        <iframe
          src="https://app.usemotion.com/meet/absolar/sitevisit"
          title="Site survey"
          width="100%"
          style={{minHeight:650, border:"none"}}
        ></iframe>
      </div>
    </>
  );
}
