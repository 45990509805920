import React, { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";

import {
  Grid,
  Button,
  Modal,
  Box,
  Drawer,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Fab,
  Divider,
} from "@mui/material";

import ModalApprovedInstallerDummy from "./infoPages/ApprovedInstallerDummy";
import ModalFAQ from "./infoPages/FaqModal";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ModalTestimonial from "./infoPages/TestimonialVideo";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import YouTubeIcon from "@mui/icons-material/YouTube";
import data_display_image from "./images/data_display.png";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

export default function LandingApprovedInstallerPage() {
  let navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const [open_approved_installer, setOpen_approved_installer] = useState(false);
  const handleOpen_approved_installer = () => setOpen_approved_installer(true);
  const handleClose_approved_installer = () =>
    setOpen_approved_installer(false);

  const [open_testimonial, setOpen_testimonial] = useState(false);
  const handleOpen_testimonial = () => setOpen_testimonial(true);
  const handleClose_testimonial = () => setOpen_testimonial(false);

  const [open_faq, setOpen_faq] = useState(false);
  const handleOpen_faq = () => setOpen_faq(true);
  const handleClose_faq = () => setOpen_faq(false);

  const [open_virtual_meeting, setOpen_virtual_meeting] = useState(false);
  const handleOpen_virtual_meeting = () => setOpen_virtual_meeting(true);
  const handleClose_virtual_meeting = () => setOpen_virtual_meeting(false);

  const [open_site_survey, setOpen_site_survey] = useState(false);
  const handleOpen_site_survey = () => setOpen_site_survey(true);
  const handleClose_site_survey = () => setOpen_site_survey(false);
  const share_switch = { inputProps: { "aria-label": "Switch demo" } };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        width: "90%",
        maxWidth: "1200px",
        margin: "30px auto",
        textAlign: "left",
      }}
    >
      <Fab onClick={handleDrawerOpen} color="primary" variant="extended" style={{float:"right", position:"fixed", right:"20px", bottom:"20px"}}>
        <ArrowLeftIcon sx={{ mr: 1 }} />
        More
      </Fab>
      <Typography variant="h5" noWrap>
        Enterprise Road, Epsilon House, Southampton
      </Typography>
      <Typography variant="subtitle2">Reference No.: NW_11_04</Typography>
      <Typography variant="subtitle2">
        Assessment Date: 23 April 2023
      </Typography>

      <div style={{ marginTop: 50, marginBottom: 50 }}>
        <Grid container spacing={3}>
          <Grid item xs={2} sm={2} md={1}>
            <div
              style={{
                background: "#1976d2",
                color: "white",
                borderRadius: "100%",
                height: 40,
                width: 40,
                textAlign: "center",
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              1
            </div>
            <div style={{ height: "100%", width: "20px" }}>
              <Divider
                orientation="vertical"
                variant="fullWidth"
                textAlign="center"
                style={{ height: "calc(100% - 20px)", marginTop: 10 }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={11}
            container
            spacing={3}
            style={{ paddingBottom: 20 }}
          >
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button onClick={handleDrawerOpen}>
                <Typography
                  variant="h6"
                  style={{ color: "#1976d2", textTransform: "none" }}
                >
                  Feasibility assessment
                </Typography>
              </Button>
              {/* <Typography variant="h6" style={{ color: "#1976d2" }}>
                    Feasibility assessment
                  </Typography> */}
              <IconButton
                // size="large"
                color="info"
                onClick={handleDrawerOpen}
              >
                <DownloadForOfflineIcon style={{ fontSize: "30px" }} />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "#1565c0",
                      }}
                    >
                      Feasibility report
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Roof space assessment</li>
                      <li>Solar radiation simulation</li>
                      <li>CAPEX estimate</li>
                      <li>Payback period</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                  >
                    View details
                  </Button>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "#1565c0",
                      }}
                    >
                      Business Case
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Cash flow projection</li>
                      <li>Finance calculator</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      navigate("/business_case");
                    }}
                  >
                    View details
                  </Button>
                </div>
              </Card>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "#1565c0",
                      }}
                    >
                      Schedule site survey
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>On-site survey</li>
                      <li>Review project proposal</li>
                      <li>Contract</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    onClick={handleOpen_site_survey}
                    variant="contained"
                    style={{ textTransform: "none" }}
                  >
                    Schedule appointment
                  </Button>
                </div>
              </Card>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          <Grid item xs={2} sm={2} md={1}>
            <div
              style={{
                // background: "#1976d2",
                background: "lightgrey",
                // color: "white",
                borderRadius: "100%",
                height: 40,
                width: 40,
                textAlign: "center",
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              2
            </div>
            <div style={{ height: "100%", width: "20px" }}>
              <Divider
                orientation="vertical"
                variant="fullWidth"
                textAlign="center"
                style={{ height: "calc(100% - 20px)", marginTop: 10 }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={11}
            container
            spacing={3}
            style={{ paddingBottom: 20 }}
          >
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: "#1976d2" }}>
                Preparation, applications and installation
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "#1565c0",
                      }}
                    >
                      {/* <EventAvailableIcon style={{ marginRight: 10 }} /> */}
                      Approved installer
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Site survey</li>
                      <li>Project proposal</li>
                      <li>Installation & arrangement</li>
                      <li>Project commissioning</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={handleOpen_approved_installer}
                  >
                    Choose installer
                  </Button>
                </div>
              </Card>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "#1565c0",
                      }}
                    >
                      Standard procedure
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>How does it work</li>
                      <li>Timeline</li>
                      <li>FAQ</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    View details
                  </Button>
                </div>
              </Card>
            </Grid> */}
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "#1565c0",
                      }}
                    >
                      <AttachFileIcon style={{ marginRight: 10 }} />
                      Handover files
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Certificates</li>
                      <li>User manual</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    Download
                  </Button>
                </div>
              </Card>
            </Grid> */}
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "#1565c0",
                      }}
                    >
                      Gallery
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Drone videos</li>
                      <li>Photographic images</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    View Gallery
                  </Button>
                </div>
              </Card>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          <Grid item xs={2} sm={2} md={1}>
            <div
              style={{
                // background: "#1976d2",
                background: "lightgrey",
                // color: "white",
                borderRadius: "100%",
                height: 40,
                width: 40,
                textAlign: "center",
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              3
            </div>
            {/* <div style={{ height: "100%", width: "20px" }}>
              <Divider
                orientation="vertical"
                variant="fullWidth"
                textAlign="center"
                style={{ height: "calc(100% - 20px)", marginTop: 10 }}
              />
            </div> */}
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={11}
            container
            spacing={3}
            style={{ paddingBottom: 20 }}
          >
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: "#1976d2" }}>
                Maintainance and energy export management
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "#1565c0",
                      }}
                    >
                      {/* <ConnectedTvIcon style={{ marginRight: 10 }} /> */}
                      Data Monitoring
                    </Typography>
                  </div>
                  <CardMedia sx={{ height: 120 }} image={data_display_image} />
                  {/* <Typography
                      variant="body2"
                      className="function_card_content"
                    >
                      <ul style={{ paddingLeft: 20 }}>
                      </ul>
                    </Typography> */}
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    View details
                  </Button>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "#1565c0",
                      }}
                    >
                      {/* <ScatterPlotIcon style={{ marginRight: 10 }} /> */}
                      Export Tariff
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      {/* <li>Activated after installation completion</li> */}
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    View details
                  </Button>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card sx={{ maxWidth: 345 }} className="premium_tool_card">
                <CardContent>
                  <div style={{ borderBottom: "1px grey solid" }}>
                    <Typography
                      gutterBottom
                      style={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        color: "#1565c0",
                      }}
                    >
                      {/* <ScatterPlotIcon style={{ marginRight: 10 }} /> */}
                      Support
                    </Typography>
                  </div>
                  <Typography variant="body2" className="function_card_content">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>Contact us</li>
                      <li>FAQ</li>
                    </ul>
                  </Typography>
                </CardContent>
                <div style={{ padding: "0px 20px 10px 20px" }}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ textTransform: "none" }}
                    disabled
                  >
                    View details
                  </Button>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Drawer anchor={"right"} open={drawerOpen} onClose={handleDrawerClose}>
          <div style={{ width: "300px", padding: 20 }}>
            <Typography
              variant="h6"
              style={{ borderBottom: "1px lightgrey solid" }}
            >
              Documents
            </Typography>
            <Button
              fullWidth
              startIcon={<PictureAsPdfIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Feasibility report
            </Button>
            <Button
              fullWidth
              startIcon={<AttachFileIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Cash flow projection (.xls)
            </Button>
            <Button
              fullWidth
              disabled
              startIcon={<PictureAsPdfIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Site survey report
            </Button>
            <Button
              fullWidth
              disabled
              startIcon={<PictureAsPdfIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Project proposal
            </Button>
            <Button
              fullWidth
              disabled
              startIcon={<PictureAsPdfIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Contract
            </Button>
            <Typography
              variant="h6"
              style={{ borderBottom: "1px lightgrey solid", marginTop: 30 }}
            >
              FAQ
            </Typography>
            <Button
              fullWidth
              startIcon={<AccountTreeIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Procedure
            </Button>
            <Button
              fullWidth
              startIcon={<AccountBalanceIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Finance options
            </Button>
            <Button
              fullWidth
              startIcon={<InfoOutlinedIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Background information
            </Button>
            <Typography
              variant="h6"
              style={{ borderBottom: "1px lightgrey solid", marginTop: 30 }}
            >
              Support
            </Typography>
            <Button
              fullWidth
              startIcon={<PlayCircleFilledWhiteIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Interview of previous projects
            </Button>
            <Button
              fullWidth
              startIcon={<HelpOutlineOutlinedIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Customer support
            </Button>
            <Button
              fullWidth
              startIcon={<CallOutlinedIcon />}
              style={{ justifyContent: "flex-start", textTransform: "none" }}
            >
              Contact us
            </Button>
          </div>
        </Drawer>
      </div>

      <Modal open={open_faq} onClose={handleClose_faq}>
        <ModalFAQ />
      </Modal>
      <Modal
        open={open_approved_installer}
        onClose={handleClose_approved_installer}
      >
        <ModalApprovedInstallerDummy />
      </Modal>
    </div>
  );
}
