import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { postData } from "./get-data";
import { UserContext } from "./contexts/UserContext";
interface IProps {
  organisations: Organisation[];
  handleClose_notification: () => void;
}
const NotificationForm = (props: IProps) => {
  const userContext = useContext(UserContext);
  const [notification, setNotification] = useState<NotificationCreationDto>({
    timestamp: new Date(),
    title: "",
    description: "",
    image: null,
    variant: null,
    org_ids: null,
    user_group_ids: null,
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setNotification((prevNotification) => ({
      ...prevNotification,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (
      (notification.title !== "" && notification.description !== "") ||
      !notification.org_ids ||
      notification.org_ids?.length < 1
    ) {
      postData(
        process.env.REACT_APP_API_URL + "/notifications",
        notification,
        false,
        userContext.getCredentials
      )
        .then((notification: NotificationCreationDto) => {
          alert("notification sent");
        })
        .catch((e: any) => alert(e.message));
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h5">Add notification</Typography>
      <TextField
        label="Title"
        name="title"
        fullWidth
        required
        value={notification.title}
        onChange={handleChange}
        style={{ marginTop: 10 }}
      />
      <TextField
        fullWidth
        required
        label="Description"
        name="description"
        multiline
        rows={4}
        value={notification.description}
        onChange={handleChange}
        style={{ marginTop: 10 }}
      />
      <TextField
        fullWidth
        label="Image Base 64"
        name="image"
        value={notification.image}
        onChange={handleChange}
        style={{ marginTop: 10 }}
      />
      <FormControl fullWidth>
        <InputLabel>Org IDs</InputLabel>
        <Select
          fullWidth
          style={{ marginTop: 10 }}
          name="org_ids"
          value={notification.org_ids ?? []}
          onChange={handleChange}
          multiple
        >
          {props.organisations.map((org) => {
            return <MenuItem value={org.id}>{org.display_name}</MenuItem>;
          })}
        </Select>
      </FormControl>
      <div style={{ justifyContent: "space-between", display: "flex" }}>
        <Tooltip
          title={
            notification.title === "" ||
            notification.description === "" ||
            !notification.org_ids ||
            notification.org_ids?.length < 1
              ? "title and description can't be empty and you must select one organisation"
              : "Press to send the notification"
          }
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSave();
                props.handleClose_notification();
              }}
              disabled={
                notification.title === "" ||
                notification.description === "" ||
                !notification.org_ids ||
                notification.org_ids?.length < 1
              }
              style={{ marginTop: "20px" }}
            >
              Send notification
            </Button>
          </span>
        </Tooltip>

        <Button
          variant="outlined"
          color="primary"
          onClick={props.handleClose_notification}
          style={{ marginTop: "20px" }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default NotificationForm;
