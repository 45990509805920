import React from "react";
import { styled } from "@mui/material/styles";

import { Grid, Button } from "@mui/material";
import Check from "@mui/icons-material/Check";
import monitor_image from "./images/monitor_background.png";

export default function DataMonitorPage() {
  return (
    <div
      style={{
        width: "calc(100vw - 5px)",
        height: "calc(100vh - 64px)",
        overflow: "hidden",
      }}
    >
      <div
      id="monitor_page"
      style={{ backgroundImage: "url(" + monitor_image + ")" }}
    >
      <iframe id="monitor_content" src="https://kdh.solarwatcher.co.uk"  />
    </div>
      {/* <iframe
        src="https://kdh.solarwatcher.co.uk/"
        style={{ width: "100%", height: "99%", margin: "auto" }}
      /> */}
    </div>
  );
}
