export async function postData(
  url: string,
  data = {},
  noJson?: boolean,
  getCredentials?: (expiredCredentials?: UserState) => UserState | null
): Promise<any> {
  let userState: UserState | null = null;
  if (getCredentials) {
    userState = getCredentials(); // Retry up to 5 times
  }
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userState?.idToken,

      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (response.ok) {
    if (noJson) {
      return response;
    }
    return response.json(); // parses JSON response into native JavaScript objects
  } else {
    if (response.status === 401) {
      if (getCredentials && userState?.loggedIn) {
        await getCredentials(userState);
      }
    }
    const text = (await response.text()).valueOf();
    throw new Error(text);
  }
}
export async function putData(
  url: string,
  data = {},
  noJson?: boolean,
  getCredentials?: (expiredCredentials?: UserState) => UserState | null
): Promise<any> {
  let userState: UserState | null = null;
  if (getCredentials) {
    userState = getCredentials(); // Retry up to 5 times
  }
  // Default options are marked with *
  const response = await fetch(url, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userState?.idToken,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (response.ok) {
    if (noJson) {
      return response;
    }
    return response.json(); // parses JSON response into native JavaScript objects
  } else {
    if (response.status === 401) {
      if (getCredentials && userState?.loggedIn) {
        await getCredentials(userState);
      }
    }
    const text = (await response.text()).valueOf();
    throw new Error(text);
  }
}
export async function deleteData(
  url: string,
  noJson?: boolean,
  getCredentials?: (expiredCredentials?: UserState) => UserState | null
): Promise<any> {
  let userState: UserState | null = null;
  if (getCredentials) {
    userState = getCredentials(); // Retry up to 5 times
  }
  const response = await fetch(url, {
    method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    headers: userState
      ? new Headers({
          Authorization: "Bearer " + userState.idToken,
        })
      : undefined,
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  if (response.ok) {
    if (response.status === 204) {
      return null;
    }
    if (noJson) {
      return response;
    }
    return response.json();
    // parses JSON response into native JavaScript objects
  } else {
    if (response.status === 401) {
      if (getCredentials && userState?.loggedIn) {
        await getCredentials(userState);
      }
    }
    const text = (await response.text()).valueOf();
    throw new Error(text);
  }
}
export async function getData(
  url: string,
  noJson?: boolean,
  getCredentials?: (expiredCredentials?: UserState) => UserState | null
): Promise<any> {
  let userState: UserState | null = null;
  if (getCredentials) {
    userState = getCredentials(); // Retry up to 5 times
  }
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    headers: userState
      ? new Headers({
          Authorization: "Bearer " + userState.idToken,
        })
      : undefined,
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  if (response.ok) {
    if (response.status === 204) {
      return null;
    }
    if (noJson) {
      return response;
    }
    return response.json();
    // parses JSON response into native JavaScript objects
  } else {
    if (response.status === 401) {
      if (getCredentials && userState?.loggedIn) {
        await getCredentials(userState);
      }
    }
    const text = (await response.text()).valueOf();
    throw new Error(text);
  }
}
export async function refresh(userState: UserState) {
  const refreshResponse = await fetch(
    process.env.REACT_APP_API_URL +
      "/auth/refresh?username=" +
      userState.username,
    {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken: userState.refreshToken }),
    }
  );
  if (refreshResponse.status === 500) {
    throw Error;
  }
  if (refreshResponse.ok) {
    const response: LoginResponse = await refreshResponse.json();
    if (response.authenticationResult) {
      return {
        ...userState,
        idToken: response.authenticationResult["idToken"],
        accessToken: response.authenticationResult["accesssToken"],
        refreshToken: response.authenticationResult["refreshToken"],
        loggedIn: true,
        expired: false,
      };
    } else {
      return {
        ...userState,
        expired: true,
      };
    }
  } else {
    return {
      ...userState,
      expired: true,
    };
  }
}
// const getCredentialsWithRetry = async (
//   maxRetries = Infinity,
//   getCredentials: (expiredCredentials?: UserState) => UserState | null
// ): Promise<UserState> => {
//   const userState = getCredentials();
//   if (userState) {
//     return userState;
//   } else if (maxRetries > 0) {
//     await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 0.5 seconds before the next retry
//     return getCredentialsWithRetry(maxRetries - 1, getCredentials); // Recursively call getCredentialsWithRetry with decreased maxRetries
//   } else {
//     throw Error("Max retries reached");
//   }
// };
