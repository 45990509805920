import { getData, postData, putData } from "../get-data";

interface IProps {
    username: string;
    password: string;
}

interface ChangePasswordProps {
    username: string;
    password: string;
    newPassword: string;
}

interface RefreshToken {
    username: string;
    refreshToken: string;
}

export function GetLogins(props: IProps) {
    return postData(process.env.REACT_APP_API_URL + '/auth/login', props);
}

export function ChangePassword(props: ChangePasswordProps) {
    return putData(process.env.REACT_APP_API_URL + '/auth/password', props);
}

export function Refresh(props: RefreshToken) {
    return putData(process.env.REACT_APP_API_URL + '/auth/refresh', props);
}

export function RequestCode(username: string){
    return getData(process.env.REACT_APP_API_URL + '/auth/triggerForgotPassword?username='+username, true);

}
