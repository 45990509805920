import React, { useState, useEffect, useContext } from "react";
import { Divider, Grid, Button, Modal, Typography } from "@mui/material";

import { CardActionArea } from "@mui/material";
import ModalFAQ from "../infoPages/FaqModal";
import monitor_visitor from "../images/monitor_visitor.jpg";
import mcs_image from "../images/mcs_cert.png";
import meeting_image from "../images/meeting.jpg";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import SupportPageNextStep from "./supportPageNextStep";
import SupportPageDataDisplay from "./supportPageDataDisplay";
import SupportPageTrading from "./supportPageTrading";
import SupportPageProjectManagement from "./supportPageProjectManagement";
import SupportPagePreviousProject from "./supportPagePreviousProject";
import SupportPageAccuracy from "./supportPageAccuracy";
import SupportPageContact from "./supportPageContact";
import ScheduleSurvey from "./supportSchedule";
import SupportQualifications from "./supportQualifications";
import InfoFAQ from "./infoFAQ";
import { MailOutline } from "@mui/icons-material";
import { UserContext } from "../contexts/UserContext";
import { ISideBarProps } from "../project_page/side_bar";

interface IProps {
  selectedProperty: DashboardData | null;
  getPdf: (property: DashboardData | null) => void;
  project_Status: number | null;
  sideBarProps: ISideBarProps;
}
export default function SupportPage(props: IProps) {
  const [open_faq, setOpen_faq] = useState(false);
  const handleOpen_faq = () => setOpen_faq(true);
  const handleClose_faq = () => setOpen_faq(false);
  const [columnStatus, setColumnStatus] = useState(null);
  const [siteSurveyDate, setSiteSurveyDate] = useState("");

  const userContext = useContext(UserContext);

  const [content, setContent] = useState<React.ReactNode>(
    <>
      <Typography
        variant="body2"
        style={{
          // borderBottom: "1px lightgrey solid",
          marginTop: 20,
          marginBottom: "10px",
          paddingBottom: "10px",
        }}
      >
        Next steps
      </Typography>
      <Grid container style={{ color: "grey" }} spacing={1}>
        <Grid item xs={1}>
          <div style={{ height: "100%", width: "20px" }}>
            <Divider
              orientation="vertical"
              variant="fullWidth"
              textAlign="center"
              style={{ height: "calc(100% - 20px)", marginTop: 10 }}
            />
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          {/* <Typography variant="body2" gutterBottom>
            <b>Schedule a site survey</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            A site survey helps to confirm a number of assumptions and gather
            all the necessary information to provide a final proposal. Once
            accepted, we use the site survey findings to apply for grid
            permission on your behalf and commence the necessary project
            set-up steps to ensure a smooth project with minimum disturbance.{" "}
          </Typography> */}
          {props.project_Status === 1 ? (
            <>
              <Typography variant="body2" gutterBottom>
                <b>Schedule a site survey</b>
              </Typography>
              <Typography variant="body2" gutterBottom>
                A site survey helps to confirm a number of assumptions and
                gather all the necessary information to provide a final
                proposal. Once accepted, we use the site survey findings to
                apply for grid permission on your behalf and commence the
                necessary project set-up steps to ensure a smooth project with
                minimum disturbance.{" "}
              </Typography>
              <Button
                variant="contained"
                fullWidth
                color="success"
                style={{ textTransform: "none" }}
                onClick={handleClick_scheduleSurvey}
              >
                Schedule a site survey
              </Button>
            </>
          ) : null}
          {props.project_Status === 2 ? (
            <>
              <Typography variant="body2" gutterBottom>
                <b>Schedule a site survey</b>
              </Typography>
              <Typography variant="body2" gutterBottom>
                A Site survey has been scheduled on:
                {props.selectedProperty?.site_survey_date}
              </Typography>
              <Typography variant="h5" color="black" textAlign="center">
                {props.sideBarProps.projectDelivery?.siteSurveyDate}
              </Typography>
              <Button
                href={
                  "mailto:survey@absolar.co.uk?subject=Request change of site survey date - Ref: " +
                  props.selectedProperty?.reference
                }
                fullWidth
                // color="success"
                style={{ textTransform: "none" }}
              >
                Click to request survey date change
              </Button>
            </>
          ) : null}
          {props.project_Status === 3 ? (
            <>
              <Typography variant="body2" gutterBottom>
                <b>Waiting project proposal</b>
              </Typography>
              <Typography variant="body2" gutterBottom>
                A Site survey has been completed on {props.sideBarProps.projectDelivery?.siteSurveyDate}. We are
                currently producing a final proposal for this project based on
                findings from the site survey. You will receive an email
                notification when the proposal is ready for your review.
              </Typography>
            </>
          ) : null}
        </Grid>

        <Grid item xs={12} style={{ textAlign: "left", paddingLeft: 20 }}>
          or
        </Grid>
        <Grid item xs={1}>
          <div style={{ height: "100%", width: "20px" }}>
            <Divider
              orientation="vertical"
              variant="fullWidth"
              textAlign="center"
              style={{ height: "calc(100% - 20px)", marginTop: 10 }}
            />
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Typography variant="body2" gutterBottom>
            <b>Contact us</b>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Should you have any questions regarding Solar PV or want to talk
            through your Remote Solar Survey findings, please contact us or
            arrange an online meeting to explore further.{" "}
          </Typography>
          <Button
            variant="contained"
            fullWidth
            color="success"
            style={{ textTransform: "none" }}
            onClick={handleClick_contact}
          >
            {" "}
            Contact us{" "}
          </Button>
          <Grid
            container
            style={{ margin: "20px 0px", textAlign: "center", color: "grey" }}
          >
            <Grid item xs={6}>
              <div>
                <LocalPhoneIcon />
              </div>
              <Typography variant="body2">0238 268 0106</Typography>
            </Grid>
            <Grid item xs={6}>
              <div>
                <MailOutline />
              </div>
              <Typography variant="body2">survey@absolar.co.uk</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography
        variant="body2"
        style={{
          // borderBottom: "1px lightgrey solid",
          marginTop: 30,
          marginBottom: 5,
        }}
      >
        Explore features of Absolar installations
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <CardActionArea
            style={{ height: "100%" }}
            onClick={handleClick_financialAssessment}
          >
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 0,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={mcs_image}
                  style={{
                    maxWidth: "90%",
                    margin: "auto",
                    maxHeight: "100px",
                    marginBottom: 0,
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>
                Quality assurance
              </div>
            </div>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CardActionArea
            style={{ height: "100%" }}
            onClick={handleClick_dataDisplay}
          >
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 0,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={monitor_visitor}
                  style={{
                    maxWidth: "90%",
                    margin: "auto",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>
                Data monitoring and display
              </div>
            </div>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CardActionArea
            style={{ height: "100%" }}
            onClick={handleClick_trading}
          >
            <div className="hover_card" style={{ height: "100%" }}>
              <div
                style={{
                  height: 120,
                  margin: 0,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <img
                  src={meeting_image}
                  style={{
                    maxWidth: "90%",
                    margin: "auto",
                    maxHeight: "100px",
                  }}
                />
              </div>
              <div style={{ padding: 10, fontSize: "14px" }}>
                Trading your surplus energy
              </div>
            </div>
          </CardActionArea>
        </Grid>
      </Grid>

      <Typography
        variant="body2"
        style={{
          borderBottom: "1px lightgrey solid",
          marginTop: 20,
        }}
      >
        Additional information
      </Typography>
      {/* <Button
      fullWidth
      startIcon={<InfoIcon />}
      style={{
        justifyContent: "flex-start",
        textTransform: "none",
      }}
      onClick={handleClick_contact}
    >
      Contact us
    </Button> */}
      <Button
        fullWidth
        startIcon={<InfoIcon />}
        style={{
          justifyContent: "flex-start",
          textTransform: "none",
        }}
        onClick={handleClick_faq}
      >
        FAQ
      </Button>
      <Button
        fullWidth
        onClick={handleClick_previousProject}
        startIcon={<PlayCircleFilledWhiteIcon />}
        style={{
          justifyContent: "flex-start",
          textTransform: "none",
        }}
      >
        Interview of previous projects
      </Button>
    </>
  );
  const [previousContent, setPreviousContent] = useState<string[]>([]);
  const [contentChanged, setContentChanged] = useState(false);

  function handleClick_nextSteps() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPageNextStep />);
    setContentChanged(true);
  }

  function handleClick_financialAssessment() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportQualifications />);
    setContentChanged(true);
  }

  function handleClick_dataDisplay() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPageDataDisplay />);
    setContentChanged(true);
  }

  function handleClick_contact() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPageContact />);
    setContentChanged(true);
  }

  function handleClick_trading() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPageTrading />);
    setContentChanged(true);
  }

  function handleClick_projectManagement() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPageProjectManagement />);
    setContentChanged(true);
  }

  function handleClick_scheduleSurvey() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<ScheduleSurvey />);
    setContentChanged(true);
  }

  function handleClick_faq() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<InfoFAQ />);
    setContentChanged(true);
  }

  function handleClick_previousProject() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPagePreviousProject />);
    setContentChanged(true);
  }

  function handleClick_accuracy() {
    setPreviousContent([...previousContent, content as string]);
    setContent(<SupportPageAccuracy />);
    setContentChanged(true);
  }

  function handleGoBack() {
    const previous = previousContent.pop();
    if (previous) {
      setContent(previous);
      setPreviousContent(previousContent);
      setContentChanged(previousContent.length > 0);
    }
  }

  return (
    <>
      {contentChanged && (
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
          style={{ marginTop: 10 }}
        >
          Go Back
        </Button>
      )}

      <div>{content}</div>
      <Modal open={open_faq} onClose={handleClose_faq}>
        <ModalFAQ />
      </Modal>
    </>
  );
}
