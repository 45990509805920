import React, { useState } from "react";

import { Typography, Grid , CardActionArea } from "@mui/material";
import ccs_image from "../images/ccs.jpg";
import chas_image from "../images/chas.png";
import mcs_image from "../images/mcs_2.png";
import hub_image from "../images/renewable_hub.png";
import solar_energy_uk_image from "../images/solar_energy_uk.png";
import rics_image from "../images/rics_partner.jpg";
import os_image from "../images/os_partner.png";
import cyber_image from "../images/cyber_image.png";


export default function SupportQualifications() {
  return (
    <>
      <Typography
        variant="h6"
        style={{ marginTop: 20, borderBottom: "1px grey solid" }}
        gutterBottom
        paragraph
      >
        Quality assurance by Absolar
      </Typography>
      <Typography variant="body2" paragraph>
      Absolar places a strong emphasis on ensuring the quality of solar PV projects for our clients. We understand that a high-quality installation not only maximises energy generation but also ensures long-term system reliability and client satisfaction. Here's an overview of how we ensure the quality of our solar PV projects: 
      </Typography>
      <Typography variant="body2" paragraph>
        <ol>
          <li style={{marginBottom:10}}>
          Experienced and Qualified Team: Our team consists of experienced and qualified professionals who specialize in solar PV installations. Our technicians, engineers, and project managers possess the necessary expertise and industry certifications to execute projects to the highest standards. 
          </li>
          <li style={{marginBottom:10}}>
          Stringent Supplier and Equipment Selection: We carefully select suppliers and equipment for our projects, choosing reputable manufacturers known for their high-quality solar panels, inverters, mounting systems, and other components. We prioritise durability, performance, and reliability in our selection process. 
          </li>
          <li style={{marginBottom:10}}>
          Design and Engineering Expertise: Our design and engineering team follows industry best practices and guidelines to create customized solar PV system designs that meet the unique requirements of each client. We utilise advanced software and tools to optimise system performance, taking into account factors such as roof layout, shading analysis, and energy consumption patterns. 
          </li>
          <li style={{marginBottom:10}}>
          Comprehensive Project Management: We employ effective project management techniques to ensure smooth execution and timely completion of solar PV projects. This includes meticulous planning, regular progress monitoring, and clear communication with the client at every stage of the project. 
          </li>
          <li style={{marginBottom:10}}>
          Stringent Quality Assurance and Control: We implement robust quality assurance and control processes throughout the project lifecycle. This includes conducting thorough inspections and tests during installation to verify the correct installation of components, proper electrical connections, and adherence to safety protocols and industry standards. 
          </li>
          <li style={{marginBottom:10}}>
          Regulatory Compliance: We ensure strict compliance with all relevant local, regional, and national regulations, codes, and standards. This includes obtaining necessary permits and approvals, adhering to electrical and building codes, and following best practices for safety, interconnection, and grid compliance. 
          </li>
          <li style={{marginBottom:10}}>
          Performance Monitoring and Analysis: We provide performance monitoring and analysis tools that allow our clients to track the energy generation and performance of their solar PV systems. This enables proactive identification of any underperformance issues and facilitates timely maintenance and optimization. 
          </li>
          <li style={{marginBottom:10}}>
          Ongoing Support and Maintenance: We offer comprehensive support and maintenance services to our clients, including regular system inspections, cleaning, and troubleshooting. Our responsive team is available to address any issues and ensure that the solar PV system continues to operate at peak efficiency over its lifespan. 
          </li>
        </ol>
      </Typography>
      <Typography variant="body2" paragraph>
      By implementing these quality assurance measures, Absolar ensures that our clients receive high-quality solar PV installations that deliver optimal performance, reliability, and long-term value. We are committed to exceeding client expectations and providing renewable energy solutions that contribute to a sustainable future.  
      </Typography>
      <Grid container spacing={2} style={{alignItems:"center"}}>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={mcs_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={ccs_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={chas_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={hub_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={solar_energy_uk_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={rics_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={os_image} style={{width:"100%"}} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="hover_image">
            <img src={cyber_image} style={{width:"100%"}} />
          </div>
        </Grid>
      </Grid>
      {/* <Typography variant="body2" paragraph>
        <ol>
          <li>
            Expertise and Experience: Absolar's team consists of knowledgeable experts in solar energy systems. They possess extensive experience in designing, installing, and maintaining solar installations for various residential and commercial projects. Their expertise enables them to assess specific site requirements, develop tailored solutions, and execute flawless installations.
          </li>
          <li>
          Quality Components: Absolar is committed to using top-quality solar components and equipment. They carefully select reputable suppliers and partners to source solar panels, inverters, mounting systems, and other necessary components. By using reliable and durable products, Absolar ensures the longevity and optimal performance of the installed solar systems.
          </li>
          <li>
          Engineering Excellence: Absolar employs a team of skilled engineers who meticulously design each solar installation. They consider factors such as site conditions, structural integrity, electrical compatibility, and local regulations to create efficient and safe systems. Their engineering expertise guarantees that the solar installations are tailored to maximize energy production and overall system performance.
          </li>
          <li>
          Professional Installation Practices: Absolar follows rigorous installation practices to ensure precision and safety. Their installation team adheres to industry best practices and relevant codes and regulations. They pay meticulous attention to detail during the installation process, from panel placement and wiring to mounting and connections. This professional approach results in reliable, aesthetically pleasing, and efficient solar installations.
          </li>
        </ol>
      </Typography> */}
      

    </>
  );
}
