import React, { useState } from "react";

import { Typography, Grid } from "@mui/material";

import display_o from "../images/display_o.png";
import display_s from "../images/display_s.png";
import monitor_visitor2 from "../images/monitor_visitor2.jpg";


export default function SupportPageDataDisplay() {
  return (
    <div style={{ marginTop: 10 }}>
      {/* <Typography
        variant="h6"
        style={{ marginTop:30, borderBottom: "1px grey solid" }}
        gutterBottom
        paragraph
      >
        Standard steps for commercial solar
      </Typography> */}
      
      <Typography variant="body2" paragraph >
        We understand that investing in solar power is not just about generating clean energy but also about visualizing the impact of that investment. With our cutting-edge monitoring system, you can now easily track and visualize the real-time performance of your solar panels.
      </Typography>
      <Typography variant="body2" paragraph >
        Our intuitive display interface provides detailed insights into your solar production, allowing you to see how much energy your panels are generating at any given moment. You can also view historical data, giving you a clear understanding of the impact your solar investment has made over time.
      </Typography>
      <div style={{textAlign:"center" , marginBottom: 20}}>
        <div>
          <img src={monitor_visitor2} style={{width:"70%"}} />
        </div>
        <div style={{width:"70%", margin:"auto"}}>
          <Typography variant="caption" style={{lineHeight:0.5}}>Image 1. People gathered around a solar dashboard expressing interest of the renewable energy generated and the journey of sustainability</Typography>
        </div>
      </div>
      <div style={{textAlign:"center" , marginBottom: 20}}>
        <div>
          <img src={display_o} style={{width:"70%"}} />
        </div>
        <div style={{width:"70%", margin:"auto"}}>
          <Typography variant="caption" style={{lineHeight:0.5}}>Image 2. Solar display unit installed in reception area</Typography>
        </div>
      </div>
      {/* <Grid container style={{ marginTop: 30 }} spacing={2}>
        <Grid item xs={8}>
          <img src={display_o} style={{ width: "100%" }} />
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>Main reception</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>Digital signage</Typography>
        </Grid>
        <Grid item xs={8}>
          <img src={display_s} style={{ width: "100%" }} />
        </Grid>
        
      </Grid> */}
    </div>
  );
}
