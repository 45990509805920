import { Typography } from "@mui/material";
import { GoogleMap, Polygon } from "@react-google-maps/api";
import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "styled-components";

const Container = styled.div``;

const Header = styled.h2`
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const Button = styled.button`
  color: white;
  background-color: #3b82f6ff;
  padding: 10px 35px;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.01);
  }
`;

interface EditOSPolygonProps {
  onNext: (editedPolygonCoordinates: google.maps.LatLng[]) => void;
  polygon: FeatureCustomData;
  reference: string | undefined;
}

const EditOSPolygon: React.FC<EditOSPolygonProps> = ({
  onNext,
  polygon,
  reference,
}) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [path, setPath] = useState<google.maps.MVCArray>(
    new google.maps.MVCArray<google.maps.LatLng>(polygon.coordinates)
  );

  const handleOnLoad = (map: google.maps.Map) => {
    if (polygon) {
      mapRef.current = map;
      const coordinates = polygon.coordinates;
      const google = window.google;
      const bounds = new google.maps.LatLngBounds();
      coordinates.forEach((coordinate) => bounds.extend(coordinate));
      map.fitBounds(bounds);
    }
  };

  useEffect(() => {
    if (window.google && window.google.maps) {
      setIsMapLoaded(true);
    } else {
      window.addEventListener("load", () => setIsMapLoaded(true));
    }
  }, []);

  useEffect(() => {
    if (mapRef.current && polygon) {
      const coordinates = polygon.coordinates;
      const google = window.google;
      const bounds = new google.maps.LatLngBounds();
      coordinates.forEach((coordinate) => bounds.extend(coordinate));
      mapRef.current.fitBounds(bounds);

      if (polygon.coordinates) {
        setPath(
          new google.maps.MVCArray<google.maps.LatLng>(polygon.coordinates)
        );
      }
    }
  }, [polygon]);

  if (!isMapLoaded || !polygon) {
    return null;
  }

  const handleButtonClick = () => {
    onNext(path.getArray());
  };

  return (
    <Container>
      <Typography>TOID: {polygon.toid}</Typography>
      <Typography>Reference: {reference}</Typography>
      <ButtonContainer>
        <Button onClick={handleButtonClick}>Assess</Button>
      </ButtonContainer>
      <GoogleMap
        id="map"
        mapContainerStyle={{ height: "80vh", width: "100%" }}
        onLoad={handleOnLoad}
        mapTypeId="satellite"
        options={{
          tilt: 0,
          mapTypeId: "satellite",
        }}
      >
        <Polygon
          editable
          draggable
          options={{
            strokeColor: "#FF0000",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: "#d56868",
            fillOpacity: 0.35,
            paths: path,
          }}
        />
      </GoogleMap>
    </Container>
  );
};

export default EditOSPolygon;
