import React, { useContext, useEffect, useState } from "react";

import "../App.css";
import {
  FormControl,
  MenuItem,
  Button,
  TextField,
  Input,
  Checkbox,
  ListItemText,
  Tooltip,
  Select,
  Divider,
  InputLabel,
} from "@mui/material";
import { getData, postData } from "../get-data";
import { UserContext } from "../contexts/UserContext";

interface IProps {
  upRef: string | null;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      overflowX: "scroll" as const,
    },
  },
};

export default function UserManagement(props: IProps) {
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext.userState.loggedIn) {
      userContext.getNotificationsData();
    }
  }, []);

  const [newUserName, setnewUserName] = React.useState("");
  const [newEmail, setnewEmail] = React.useState("");
  const [newJob, setnewJob] = React.useState("");
  const [newCompany, setnewCompany] = React.useState("");
  const [newPhone, setnewPhone] = React.useState("");
  const [newEnv, setnewEnv] = React.useState<string[]>([]);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);

  const updateNewUserEnv = (event: any) => {
    setnewEnv(event.target.value);
  };

  let reference = props.upRef;

  useEffect(() => {
    getOrganisations();
  }, [reference]);

  function getOrganisations() {
    getData(
      process.env.REACT_APP_API_URL + "/Organisation/all",
      false,
      userContext.getCredentials
    ).then((organisations: Organisation[]) => {
      setOrganisations(organisations);
    });
  }

  async function submitNewUser() {
    var uploadDataUser = {
      emailAddress: newEmail,
      givenName: newUserName,
      title: newJob,
      company: newCompany,
      phone: newPhone,
      groups: newEnv,
    };

    postData(process.env.REACT_APP_API_URL + "/auth/signup", uploadDataUser)
      .then((response) => response.text())
      .then((result: number) => {
        if (result == 0) {
          alert("successfully created new user");
        }
      })
      .catch((e) => alert(e.message));
  }

  const [notification, setNotification] = useState<NotificationCreationDto>({
    timestamp: new Date(),
    title: "",
    description: "",
    image: null,
    variant: null,
    org_ids: null,
    user_group_ids: null,
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setNotification((prevNotification) => ({
      ...prevNotification,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (
      (notification.title !== "" && notification.description !== "") ||
      !notification.org_ids ||
      notification.org_ids?.length < 1
    ) {
      postData(
        process.env.REACT_APP_API_URL + "/notifications",
        notification,
        false,
        userContext.getCredentials
      )
        .then((notification: NotificationCreationDto) => {
          alert("notification sent");
        })
        .catch((e: any) => alert(e.message));
    }
  };

  return (
    <div className="main_container" style={{ padding: 20, width:"100%", height:"100%", overflow:"auto"}}>
      <div
        style={{
          textAlign: "left",
          lineHeight: "1.5",
          fontWeight: "600",
          fontSize: "16px",
          fontFamily: "sans-serif",
        }}
      >
        Create new user
      </div>
      <div className="question_main_label_non_comp" style={{ display: "flex" }}>
        Full name
      </div>
      <TextField
        style={{ display: "flex", width: "50vw" }}
        size="small"
        className="edit_textfield"
        type="text"
        value={newUserName}
        onChange={(e) => {
          setnewUserName(e.target.value);
        }}
      />
      <div className="question_main_label" style={{ display: "flex" }}>
        Email
      </div>
      <TextField
        style={{ display: "flex", width: "50vw" }}
        size="small"
        className="edit_textfield"
        type="text"
        value={newEmail}
        onChange={(e) => {
          setnewEmail(e.target.value);
        }}
      />
      <div className="question_main_label_non_comp" style={{ display: "flex" }}>
        Job title
      </div>
      <TextField
        style={{ display: "flex", width: "50vw" }}
        size="small"
        className="edit_textfield"
        type="text"
        value={newJob}
        onChange={(e) => {
          setnewJob(e.target.value);
        }}
      />
      <div className="question_main_label_non_comp" style={{ display: "flex" }}>
        Company name
      </div>
      <TextField
        style={{ display: "flex", width: "50vw" }}
        size="small"
        className="edit_textfield"
        type="text"
        value={newCompany}
        onChange={(e) => {
          setnewCompany(e.target.value);
        }}
      />
      <div className="question_main_label_non_comp" style={{ display: "flex" }}>
        Phone number
      </div>
      <TextField
        style={{ display: "flex", width: "50vw" }}
        size="small"
        className="edit_textfield"
        type="text"
        value={newPhone}
        onChange={(e) => {
          setnewPhone(e.target.value);
        }}
      />
      <div className="question_main_label_non_comp" style={{ display: "flex" }}>
        Environments to be assigned
      </div>
      <FormControl
        fullWidth
        style={{
          backgroundColor: "white",
          border: "1px solid darkgray",
          display: "flex",
          width: "50vw",
        }}
      >
        {/* <InputLabel id="demo-mutiple-checkbox-label">Select Environments</InputLabel> */}
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={newEnv}
          onChange={updateNewUserEnv}
          input={<Input />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          style={{ overflowX: "scroll" }}
        >
          {organisations.map((organisation) => {
            return (
              <MenuItem key={organisation.id} value={organisation.display_name}>
                <Checkbox
                  checked={newEnv.indexOf(organisation.display_name) > -1}
                />
                <ListItemText primary={organisation.display_name} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Button
        variant="outlined"
        component="label"
        style={{
          textTransform: "none",
          marginTop: "10px",
          display: "flex",
          width: "20vw",
        }}
        onClick={submitNewUser}
        disabled={
          newEmail != "" && newEmail.includes("@") && newEmail.includes(".")
            ? false
            : true
        }
      >
        Create User
      </Button>
      <Divider style={{ marginTop: "20px" }}></Divider>

      <div
        style={{
          textAlign: "left",
          lineHeight: "1.5",
          fontWeight: "600",
          fontSize: "16px",
          fontFamily: "sans-serif",
          marginTop: "20px",
        }}
      >
        Send Notifications
      </div>

      <div style={{ marginTop: "20px" }}>
        <TextField
          style={{ display: "flex", width: "50vw", marginBottom: "10px" }}
          size="small"
          className="edit_textfield"
          label="Title"
          name="title"
          fullWidth
          required
          value={notification.title}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          required
          label="Description"
          name="description"
          multiline
          rows={4}
          value={notification.description}
          onChange={handleChange}
          style={{ display: "flex", width: "50vw", marginBottom: "10px" }}
          size="small"
          className="edit_textfield"
        />
        <TextField
          fullWidth
          label="Image Base 64"
          name="image"
          value={notification.image}
          onChange={handleChange}
          style={{ display: "flex", width: "50vw", marginBottom: "10px" }}
          size="small"
          className="edit_textfield"
        />
        <FormControl fullWidth>
          <InputLabel>Org IDs</InputLabel>
          <Select
            fullWidth
            style={{ display: "flex", width: "50vw", marginBottom: "10px" }}
            size="small"
            className="edit_textfield"
            name="org_ids"
            value={notification.org_ids ?? []}
            onChange={handleChange}
            multiple
          >
            {organisations.map((org) => {
              return <MenuItem value={org.id}>{org.display_name}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <Tooltip
            title={
              notification.title === "" ||
              notification.description === "" ||
              !notification.org_ids ||
              notification.org_ids?.length < 1
                ? "title and description can't be empty and you must select one organisation"
                : "Press to send the notification"
            }
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSave();
                }}
                disabled={
                  notification.title === "" ||
                  notification.description === "" ||
                  !notification.org_ids ||
                  notification.org_ids?.length < 1
                }
                style={{ marginTop: "20px" }}
              >
                Send notification
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
