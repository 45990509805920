import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { GoogleMap, InfoWindow, Polygon } from "@react-google-maps/api";
import { Button } from "@mui/material";

const Container = styled.div``;

const InfoWindowContainer = styled.div``;

const InfoWindowEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftText = styled.div`
  font-weight: bold;
`;

const RightText = styled.div``;

interface SelectOSPolygonProps {
  onNext: (polygon: FeatureCustomData | null) => void;
  bounds: google.maps.LatLngBounds | null;
  featureCustomDataArray: FeatureCustomData[];
}

const SelectOSPolygon: React.FC<SelectOSPolygonProps> = ({
  onNext,
  bounds,
  featureCustomDataArray,
}) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [selectedPolygon, setSelectedPolygon] =
    useState<FeatureCustomData | null>(null);

  const handleOnLoad = (map: google.maps.Map) => {
    if (bounds) {
      mapRef.current = map;
      map.fitBounds(bounds);
    }
  };

  useEffect(() => {
    // Check if the Google Maps API has been loaded
    if (window.google && window.google.maps) {
      setIsMapLoaded(true);
    } else {
      window.addEventListener("load", () => setIsMapLoaded(true));
    }
  }, []);

  useEffect(() => {
    if (mapRef.current && bounds) {
      mapRef.current.fitBounds(bounds);
    }
  }, [bounds]);

  if (!isMapLoaded) {
    return null;
  }

  const handlePolygonClick = (polygon: FeatureCustomData) => {
    setSelectedPolygon(polygon);
  };

  const calculateMiddleCoordinate = (coordinates: google.maps.LatLng[]) => {
    if (coordinates.length === 0) {
      return null;
    }

    const latSum = coordinates.reduce((sum, coord) => sum + coord.lat(), 0);
    const lngSum = coordinates.reduce((sum, coord) => sum + coord.lng(), 0);
    const middleLat = latSum / coordinates.length;
    const middleLng = lngSum / coordinates.length;

    return { lat: middleLat, lng: middleLng };
  };

  const handlePolygonEditButtonClick = () => {
    onNext(selectedPolygon);
  };

  return (
    <Container>
      <GoogleMap
        id="map"
        zoom={19}
        mapContainerStyle={{ height: "80vh", width: "100%" }}
        onLoad={handleOnLoad}
        mapTypeId="satellite"
        options={{
          tilt: 0,
          mapTypeId: "satellite",
        }}
      >
        {featureCustomDataArray.map((item) => {
          const middleCoordinate = calculateMiddleCoordinate(item.coordinates);

          return (
            <React.Fragment key={item.osid}>
              <Polygon
                paths={
                  new google.maps.MVCArray<google.maps.LatLng>(item.coordinates)
                }
                onClick={() => handlePolygonClick(item)}
                options={{
                  strokeColor: "#0000FF",
                  strokeOpacity: 0.8,
                  fillColor: "#9595dc",
                  fillOpacity: 0.35,
                }}
              />
              {selectedPolygon === item && (
                <InfoWindow
                  position={
                    middleCoordinate ? middleCoordinate : item.coordinates[0]
                  } // Show the InfoWindow at the first point of the polygon
                  onCloseClick={() => setSelectedPolygon(null)} // Close the InfoWindow when the close button is clicked
                >
                  <InfoWindowContainer>
                    {/* well, Nic does not want it */}
                    {/* <InfoWindowEntry>
                      <LeftText>OSID:</LeftText>
                      <RightText>{item.osid}</RightText>
                    </InfoWindowEntry> */}
                    <InfoWindowEntry>
                      <LeftText>TOID:</LeftText>
                      <RightText>{item.toid}</RightText>
                    </InfoWindowEntry>
                    <InfoWindowEntry>
                      <LeftText>OS Land use:</LeftText>
                      <RightText>{item.oslandusetiera}</RightText>
                    </InfoWindowEntry>
                    <InfoWindowEntry>
                      <LeftText>Relative Height Roof-base:</LeftText>
                      <RightText>{item.relativeheightroofbase}</RightText>
                    </InfoWindowEntry>
                    <InfoWindowEntry>
                      <LeftText>Relative Height Max:</LeftText>
                      <RightText>{item.relativeheightmaximum}</RightText>
                    </InfoWindowEntry>
                    <InfoWindowEntry>
                      <LeftText>Geometry Area:</LeftText>
                      <RightText>{item.geometry_area}</RightText>
                    </InfoWindowEntry>
                    <Button
                      onClick={handlePolygonEditButtonClick}
                      style={{
                        width: "35%",
                        backgroundColor: "#3b82f6ff",
                        color: "white",
                        marginTop: "4px",
                      }}
                    >
                      Select
                    </Button>
                  </InfoWindowContainer>
                </InfoWindow>
              )}
            </React.Fragment>
          );
        })}
      </GoogleMap>
    </Container>
  );
};

export default SelectOSPolygon;
