export const initialCustomFilters: CustomFilterOption[] = [
    {
        id: 0,
        type: "string",
        dashboardDataVariable: "uprn_type",
        label: "UPRN Type",
        selectedValue: "",
    },
    {
        id: 1,
        type: "string",
        dashboardDataVariable: "class_desc",
        label: "Description",
        selectedValue: "",
    },
    {
        id: 2,
        type: "string",
        dashboardDataVariable: "class_sub_desc",
        label: "Sub-Description",
        selectedValue: "",
    },
    {
        id: 3,
        type: "number",
        dashboardDataVariable: "max_building_height",
        label: "Max. Building Height",
        selectedValue: "",
    },
    {
        id: 4,
        type: "number",
        dashboardDataVariable: "min_building_height",
        label: "Min. Building Height",
        selectedValue: "",
    },
    {
        id: 5,
        type: "number",
        dashboardDataVariable: "install_capacity_kw",
        label: "Install Capacity (KW)",
        selectedValue: "",
    },
    {
        id: 6,
        type: "number",
        dashboardDataVariable: "indicative_cost",
        label: "Indicative Cost",
        selectedValue: "",
    },
    {
        id: 7,
        type: "number",
        dashboardDataVariable: "roi",
        label: "ROI",
        selectedValue: "",
    },
    {
        id: 8,
        type: "string",
        dashboardDataVariable: "payback",
        label: "Payback",
        selectedValue: "",
    },
    {
        id: 9,
        type: "boolean",
        dashboardDataVariable: "council_property",
        label: "Council Property",
        selectedValue: "",
    },
    {
        id: 10,
        type: "boolean",
        dashboardDataVariable: "conservation_area",
        label: "Conservation Area",
        selectedValue: "",
    },
    {
        id: 11,
        type: "boolean",
        dashboardDataVariable: "listed",
        label: "Listed",
        selectedValue: "",
    },
    {
        id: 12,
        type: "string",
        dashboardDataVariable: "type_of_installation",
        label: "Type of installation",
        selectedValue: "",
    }
];