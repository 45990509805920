import React, { useState, useEffect, useContext } from "react";
import "./login-page.css";
import {
  // Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import { GetLogins, ChangePassword, Refresh, RequestCode } from "./login-fetch";
import Logo from "./logo_absolar.png";
import { Button, TextField } from "@mui/material";
import Sun_background from "../images/sunrise.jpg";
import { getData } from "../get-data";
import { group } from "console";

interface IProps {
  redirected: boolean;
  setUserState: React.Dispatch<React.SetStateAction<UserState>>;
}
export const LogInPage: React.FC<IProps> = (props: IProps) => {
  const [emailValue, setEmailValue] = React.useState<string>("");
  const [passwordValue, setPasswordValue] = React.useState<string>("");
  const [newPasswordValue, setNewPasswordValue] = React.useState<string>("");
  const [newPasswordValueConfirm, setNewPasswordValueConfirm] =
    React.useState<string>("");
  const [codeRequested, setCodeRequested] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [newPassRequired, setNewPassRequired] = React.useState<boolean>(false);
  const [triggerForgotPass, setTriggerForgotPass] = useState<boolean>(false);
  const handleSignUp = () => {
    alert("gonna open sign up");
    //history.push(something);
  };
  const checkCredentials = () => {
    // alert(emailValue + ' ' + passwordValue);
    GetLogins({
      username: emailValue,
      password: passwordValue,
    })
      .then((response: LoginResponse) => {
        //todo check response for change password
        if (
          response.challengeName &&
          Object.values(response.challengeName).includes(
            "NEW_PASSWORD_REQUIRED"
          )
        ) {
          alert("New password required");
          setNewPassRequired(true);
        } else {
          getData(
            process.env.REACT_APP_API_URL+"/Dashboard/groups",
            false,
            (expiredCredentials? : UserState)=> {return{
              idToken:
                response.authenticationResult &&
                Object.keys(response.authenticationResult).includes("idToken")
                  ? response.authenticationResult["idToken"]
                  : "",
              refreshToken: "",
              accessToken: "",
              username: "",
              loggedIn: false,
              groups: [],
              expired: false,
            }}
          ).then((groups: string[]) => {
            if (
              response.authenticationResult &&
              Object.keys(response.authenticationResult).includes("idToken")
            ) {
              props.setUserState({
                username: emailValue,
                idToken: response.authenticationResult["idToken"],
                accessToken: response.authenticationResult["accesssToken"],
                refreshToken: response.authenticationResult["refreshToken"],
                loggedIn: true,
                groups: groups,
                expired: false,
              });
            }
          });

          // if (!props.redirected) {
          //     history.push('/');
          // }
        }
      })
      .catch((e: Error) => {
        alert("We’re unable to recognise the username or password you entered. Please double-check your login details and try again. \n \nIf you're certain that your login details are correct, some organisations have specific rules that require sites to be whitelisted before you can login. If you suspect this may be the case, please consult with your IT department to ensure that your access to our site is not being blocked. \n \nFor further assistance please contact: info@absolar.co.uk"
              );
        alert(e.message);
      });
  };

  const changePassword = () => {
    // alert(emailValue + ' ' + passwordValue);
    ChangePassword({
      username: emailValue,
      password: passwordValue,
      newPassword: newPasswordValue,
    })
      .then((response: LoginResponse) => {
        //todo check response for change password
        if (
          response.challengeName &&
          Object.values(response.challengeName).includes(
            "NEW_PASSWORD_REQUIRED"
          )
        ) {
          alert("New Pass required");
          setNewPassRequired(true);
        } else if (
          response.authenticationResult &&
          Object.keys(response.authenticationResult).includes("idToken")
        ) {
          getData(
            process.env.REACT_APP_API_URL+"/Dashboard/groups",
            false,
             (expiredCredentials?: UserState) => {
              return {
              idToken:
                response.authenticationResult &&
                Object.keys(response.authenticationResult).includes("idToken")
                  ? response.authenticationResult["idToken"]
                  : "",
              refreshToken: "",
              accessToken: "",
              username: "",
              loggedIn: false,
              groups: [],
              expired: false,
            }}
          ).then((groups: string[]) => {
            if (
              response.authenticationResult &&
              Object.keys(response.authenticationResult).includes("idToken")
            ) {
              props.setUserState({
                username: emailValue,
                idToken: response.authenticationResult["idToken"],
                accessToken: response.authenticationResult["accesssToken"],
                refreshToken: response.authenticationResult["refreshToken"],
                loggedIn: true,
                groups: groups,
                expired: false,
              });
            }
          });
          // if (!props.redirected) {
          //     history.push('/');
          // }
        } else {
          alert("Something went wrong");
        }
      })
      .catch((e: Error) => {
        alert(e.message);
      });
  };

  return (
    <div
      className="login-page"
      style={{ backgroundImage: "url(" + Sun_background + ")" }}
    >
      <Grid
        textAlign="center"
        id="log-in-container"
        verticalAlign="middle"
        style={{}}
      >
        <Grid.Column>
          <div id="login_title_container">
            <img src={Logo} style={{ width: "100px" }} />
            <br />
            <span className="loginTitle">Solar Dashboard</span>
          </div>
          <Form id="log_in_form">
            {newPassRequired ? null : (
              <>
                <TextField
                  // id="log_in_form"
                  label="User email"
                  variant="outlined"
                  style={{ marginBottom: 20 }}
                  fullWidth
                  value={emailValue}
                  onChange={(e) => {
                    setEmailValue(e.target.value);
                  }}
                />
                {triggerForgotPass ? null : (
                  <TextField
                    // id="log_in_form"
                    label="Password"
                    type="password"
                    variant="outlined"
                    style={{ marginBottom: 20 }}
                    fullWidth
                    value={passwordValue}
                    onChange={(p) => {
                      setPasswordValue(p.target.value);
                    }}
                  />
                )}

                {triggerForgotPass ? (
                  <Button
                    id="btn_SSC"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      RequestCode(emailValue)
                        .then(() => {
                          setCodeRequested(true);
                        })
                        .catch(() => {
                          alert(
                            "Please check the username is correct and then try again."
                          );
                        });
                    }}
                  >
                    Request reset code
                  </Button>
                ) : (
                  <Button
                    id="btn_SSC"
                    disabled={newPassRequired}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={checkCredentials}
                  >
                    Login
                  </Button>
                )}
                <div
                  style={{ width: "100%", textAlign: "right", marginTop: 10 }}
                >
                  <a
                    // style={{ float: "right" }}
                    className="linkButton"
                    href="mailto:info@absolar.co.uk"
                  >
                    Can't log in?
                  </a>
                </div>
                <div style={{ marginTop: 25 }}>
                  <p style={{ fontSize: "14px", color: "#7D7D7D" }}>
                    By logging in, you agree to the{" "}
                    <a
                      href="https://www.absolar.co.uk/dashboardtermsofuse"
                      target="_blank"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.absolar.co.uk/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                    .{" "}
                  </p>
                </div>
              </>
            )}
            {/* </Segment> */}
            {newPassRequired ? (
              <Segment>
                {/* <div>Please update your password:</div> */}
                <br />
                <br />
                <TextField
                  // id="log_in_form"
                  label="New Password"
                  type="password"
                  variant="outlined"
                  style={{ marginBottom: 20 }}
                  fullWidth
                  value={newPasswordValue}
                  onChange={(p) => {
                    setNewPasswordValue(p.target.value);
                  }}
                />
                <TextField
                  // id="log_in_form"
                  label="Confirm New Password"
                  type="password"
                  variant="outlined"
                  // style={{ marginBottom: 10 }}
                  fullWidth
                  value={newPasswordValueConfirm}
                  onChange={(p) => {
                    setNewPasswordValueConfirm(p.target.value);
                  }}
                />
                {/* <Form.Input
                                    fluid
                                    icon="lock"
                                    iconPosition="left"
                                    placeholder="Password"
                                    type="password"
                                    value={passwordValue}
                                    onChange={(e) => {
                                        setNewPasswordValue(e.target.value);
                                    }}
                                /> */}
                <ul
                  style={{ textAlign: "left", color: "red", fontSize: "14px" }}
                >
                  {newPasswordValue != newPasswordValueConfirm ||
                  newPasswordValue == "" ? (
                    <li>New passwords do not match</li>
                  ) : null}
                  {newPasswordValue.length < 8 ? (
                    <li>Your password must be at least 8 characters long</li>
                  ) : null}
                  {!/[A-Z]/.test(newPasswordValue) ? (
                    <li>Your password must have an uppercase letter</li>
                  ) : null}
                  {!/[a-z]/.test(newPasswordValue) ? (
                    <li>Your password must have a lowercase letter</li>
                  ) : null}
                  {!/[1-9]/.test(newPasswordValue) ? (
                    <li>Your password must have a number</li>
                  ) : null}
                  {!/^(?=.*[!@#$%^&*])/.test(newPasswordValue) ? (
                    <li>
                      Your password must have at least one special character
                      !@#$%^&*
                    </li>
                  ) : null}
                </ul>

                <Button
                  id="btn_SSC"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={
                    newPasswordValue == "" ||
                    newPasswordValue != newPasswordValueConfirm ||
                    !/[A-Z]/.test(newPasswordValue) ||
                    !/[a-z]/.test(newPasswordValue) ||
                    newPasswordValue.length < 8
                  }
                  onClick={changePassword}
                >
                  Update
                </Button>
              </Segment>
            ) : null}
          </Form>
          {/* <Message id="signUpPanel">
                        New to us?{' '}
                        <a href="#" onClick={handleSignUp}>
                            Sign Up
                        </a>
                    </Message> */}
        </Grid.Column>
      </Grid>
    </div>
  );
};
