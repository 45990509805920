import React, { useEffect, useState } from "react";
import {
  Drawer,
  Grid,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  IconButton,
  Tooltip,
  Typography,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Modal,
} from "@mui/material";
import ProjectLandingPage from "../ProjectLandingPage";
import GeneralProjectInfo from "../infoPages/GeneralProjectInfo";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

import mcs_image from "../images/mcs_cert.png";

import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";

import { getData, postData, putData } from "../get-data";
import { useNavigate } from "react-router-dom";

import { FileUploader } from "react-drag-drop-files";
import { sendFileUpdatesToBackend } from "../App";

import "../ProjectPage.css";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import SupportPageContact from "../supportDrawer/supportPageContact";

import ModalApprovedInstaller from "../infoPages/ApprovedInstaller";
import SupportPage from "../supportDrawer/supportPage";
import ModalFAQ from "../infoPages/FaqModal";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface ProjectPageProps {
  data: DashboardData;
  setSavingAnimation: (newBool: boolean) => void;
  dashboardDatas: DashboardData[];
  setDashboardDatas: (newdata: DashboardData[]) => void;
  setIsNatwest: (newBol: boolean) => void;
  loadPdfDownloading: boolean;
  setLoadPdfDownloading: (newBol: boolean) => void;
  getPdf: (property: DashboardData | null) => void;
  handleDownloadFile: (fileName: string, reference: string) => void;
}
export interface ISideBarProps {
  userContext: {
    userState: UserState;
    setUserState: (value: React.SetStateAction<UserState>) => void;
    getCredentials: (
      expiredCredentials?: UserState | undefined
    ) => UserState | null;
    isNatwest: boolean;
    userInfo: UserInformation;
    getNotificationsData: () => void;
  };
  shared: boolean;
  settingShared: boolean;
  updateShared: (checked: boolean) => void;
  projectProps: ProjectPageProps;
  file_templates: { template: string; is_uploaded: boolean }[];
  file_names: string[];
  handleNewFileUpload: (file: FileList) => void;
  newUploadedFiles: File[];
  handleRemoveNewFile: (file: File) => void;
  templatesChecked: { template: string; is_uploaded: boolean }[];
  setTemplatesChecked: React.Dispatch<
    React.SetStateAction<{ template: string; is_uploaded: boolean }[]>
  >;
  setFile_names: React.Dispatch<React.SetStateAction<string[]>>;
  setNewUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  updateTemplateFilesStatus: () => void;
  trackClick: (buttonClicked: string) => void;
  handleDownloadReport: (property: DashboardData) => void;
  projectDelivery: ProjectDeliveryDto | null;
  getNewProjectStageForNextSteps: () => Promise<void>;
  setProjectDelivery: React.Dispatch<
    React.SetStateAction<ProjectDeliveryDto | null>
  >;
}
interface ProjectStage {
  projectStageID: number;
  description: string;
}
export function SideBar(sideBarProps: ISideBarProps) {
  const {
    userContext,
    shared,
    settingShared,
    updateShared,
    projectProps,
    file_templates,
    file_names,
    handleNewFileUpload,
    newUploadedFiles,
    handleRemoveNewFile,
    templatesChecked,
    setTemplatesChecked,
    setFile_names,
    setNewUploadedFiles,
    updateTemplateFilesStatus,
    trackClick,
    handleDownloadReport,
    projectDelivery,
    getNewProjectStageForNextSteps,
    setProjectDelivery,
  } = sideBarProps;
  const [infoContactUs, setInfoContactUs] = React.useState<null | HTMLElement>(
    null
  );
  const open_infoContactUs = Boolean(infoContactUs);

  const handleClickLink_infoContactUs = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    setInfoContactUs(event.currentTarget);
  };
  const handleClose_infoContactUs = () => {
    setInfoContactUs(null);
  };
  const [infoTurnkey, setInfoTurnkey] = React.useState<null | HTMLElement>(
    null
  );
  const open_infoTurnkey = Boolean(infoTurnkey);
  const handleClick_infoTurnkey = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setInfoTurnkey(event.currentTarget);
  };
  const handleClose_infoTurnkey = () => {
    setInfoTurnkey(null);
  };
  const handleOpen_approved_installer = () => setOpen_approved_installer(true);
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const [drawerOpen_steps, setDrawerOpen_steps] = useState<boolean>(false);
  const handleDrawerOpen_steps = () => {
    setDrawerOpen_steps(true);
  };

  const handleDrawerClose_steps = () => {
    setDrawerOpen_steps(false);
  };

  const [drawerOpen_generalInfo, setDrawerOpen_generalInfo] =
    useState<boolean>(false);
  const handleDrawerOpen_generalInfo = () => {
    setDrawerOpen_generalInfo(true);
  };

  const handleDrawerClose_generalInfo = () => {
    setDrawerOpen_generalInfo(false);
  };

  const [open_faq, setOpen_faq] = useState(false);
  const handleOpen_faq = () => setOpen_faq(true);
  const handleClose_faq = () => setOpen_faq(false);

  const [open_virtual_meeting, setOpen_virtual_meeting] = useState(false);
  const handleOpen_virtual_meeting = () => setOpen_virtual_meeting(true);
  const handleClose_virtual_meeting = () => setOpen_virtual_meeting(false);

  const [open_site_survey, setOpen_site_survey] = useState(false);
  const handleOpen_site_survey = () => setOpen_site_survey(true);
  const handleClose_site_survey = () => setOpen_site_survey(false);
  const share_switch = { inputProps: { "aria-label": "Switch demo" } };
  const [content_supportBar, setContent_supportBar] =
    useState<React.ReactNode>(null);
  const [expanded_cta, setExpanded_cta] = React.useState<string | false>(
    "panel_absolar"
  );

  const handleChange_cta =
    (panel: string) =>
    (event: React.SyntheticEvent, newExpanded_cta: boolean) => {
      setExpanded_cta(newExpanded_cta ? panel : false);
    };

  const [previousContent_supportBar, setPreviousContent_supportBar] = useState<
    string[]
  >([]);
  const [contentChanged_supportBar, setContentChanged_supportBar] =
    useState(false);

  function handleClick_contact() {
    setPreviousContent_supportBar([
      ...previousContent_supportBar,
      content_supportBar as string,
    ]);
    setContent_supportBar(<SupportPageContact />);
    setContentChanged_supportBar(true);
  }

  const handleClick_like_button = (reference: string, toUpdateTo: boolean) => {
    //check if we need to update the individual data in teh state too?

    var allDashboards = projectProps.dashboardDatas.map((dashboardEntry) =>
      dashboardEntry.reference === reference
        ? { ...dashboardEntry, saved: toUpdateTo }
        : dashboardEntry
    );
    projectProps.setDashboardDatas(allDashboards);

    projectProps.data.saved = toUpdateTo;
    saveRow(reference, toUpdateTo);
  };
  function saveRow(reference: string, saved: boolean) {
    putData(
      process.env.REACT_APP_API_URL +
        "/Dashboard/saved?reference=" +
        reference +
        "&saved=" +
        saved,
      {},
      true,
      userContext.getCredentials
    )
      .then((res) => res.text())
      .catch((e) => alert("save failed"));
  }

  function handleGoBack_supportBar() {
    const previous = previousContent_supportBar.pop();
    if (previous) {
      setContent_supportBar(previous);
      setPreviousContent_supportBar(previousContent_supportBar);
      setContentChanged_supportBar(previousContent_supportBar.length > 0);
    }
  }

  useEffect(() => {
    if (projectDelivery !== null) {
      setContent_supportBar(
        <>
          <div
            style={{
              width: "calc(100% - 40px)",
              maxWidth: "500px",
              padding: 20,
              // height: "calc(100vh - 120px - 40px - 40px)",
              overflow: "auto",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">Project Centre</Typography>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <SupportPage
              selectedProperty={projectProps.data}
              getPdf={projectProps.getPdf}
              project_Status={projectDelivery.projectStage}
              sideBarProps={sideBarProps}
            />
          </div>
        </>
      );
    }
  }, [projectDelivery]);
  const handleDrawerOpen = async () => {
    setDrawerOpen(true);
    getNewProjectStageForNextSteps();
  };
  const [open_approved_installer, setOpen_approved_installer] = useState(false);
  const handleClose_approved_installer = () =>
    setOpen_approved_installer(false);

  let navigate = useNavigate();

  const [projectStageFromDropDown, setProjectStageFromDropDown] =
    React.useState<string>(projectDelivery?.projectStage?.toString() ?? "1");
  const [openSolarUrl, setOpenSolarUrl] = React.useState<string>(
    projectDelivery?.openSolarLink ?? ""
  );
  const [updatingStatus, setUpdatingStatus] = useState<boolean>(false);
  useEffect(() => {
    setProjectStageFromDropDown(
      projectDelivery?.projectStage?.toString() ?? "1"
    );
    setOpenSolarUrl(projectDelivery?.openSolarLink ?? "");
  }, [projectDelivery]);
  const handleChange = async (event: SelectChangeEvent) => {
    setProjectStageFromDropDown(event.target.value);
  };

  async function onClickUpdateProjectStage() {
    setUpdatingStatus(true);

    const apiUrl = `${
      process.env.REACT_APP_API_URL
    }/ProjectDelivery/UpdateStageFromUI?project_stage=${encodeURIComponent(
      projectStageFromDropDown
    )}&reference=${projectProps.data.reference}`;

    try {
      await putData(apiUrl, {}, false, userContext.getCredentials);
      await getNewProjectStageForNextSteps();
    } catch (error) {
      console.log("Error:", error);
    }
    setUpdatingStatus(false);
    setSurveyDateSetFlag(false);
  }

  const [projectStages, setProjectStages] = useState<ProjectStage[]>([]);
  const [siteSurveyDate, setSiteSurveyDate] = useState<string>("");
  const [surveyDateSetFlag, setSurveyDateSetFlag] = useState<boolean>(false);

  function onClickSetOpenSolarUrl() {
    const isValid = validateLink(openSolarUrl);
    if (isValid) {
      const url = `${process.env.REACT_APP_API_URL}/ProjectDelivery/setOpenSolar?openSolarLink=${openSolarUrl}&reference=${projectProps.data.reference}`;
      putData(url, {}, false, userContext.getCredentials)
        .then((response: ProjectDeliveryDto) => {
          setProjectDelivery(response);
        })
        .catch((e) => console.log(e.message()));
    } else {
      alert("Open solar link is not a valid link");
    }
  }

  const onClickSetSiteSurveyDate = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/ProjectDelivery/setScheduleDate?schedule_date=${siteSurveyDate}&reference=${projectProps.data.reference}`;
      const response = await putData(url, {}, true, userContext.getCredentials);

      if (response.ok) {
        const data: ProjectDeliveryDto = await response.json();
        setProjectDelivery(data);

        console.log("Site Survet Date response:", data);
      } else {
        console.log("Error:", response.status);
      }
    } catch (error) {
      console.log("Error:", error);
    }
    setSurveyDateSetFlag(true);

    // setSiteSurveyDate("");
  };

  // const [openSolarUrl, setOpenSolarUrl] = useState("");
  const [isValidLink, setIsValidLink] = useState(true);

  const validateLink = (link: string) => {
    // Regular expression to validate the link
    const linkRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return linkRegex.test(link);
  };

  useEffect(() => {
    fetchProjectStageDescription();
  }, []);

  async function fetchProjectStageDescription() {
    try {
      const url =
        process.env.REACT_APP_API_URL +
        "/ProjectDelivery/fetchProjectStageDescription";
      const response = await getData(url, true, userContext.getCredentials);

      if (response.ok) {
        const data = await response.json();
        setProjectStages(data); // Set the API response data to the state variable
      } else {
        console.log("Error:", response.status);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // projectProps.setLoadPdfDownloading(true);
    // handleDownloadReport(projectProps.data);
    // trackClick("Download PDF page top");
  };
  const [emailsShared, setEmailsShared] = useState<string[]>([]);
  const [newEmail, setNewEmail] = useState<string>("");
  const [emailName, setEmailName] = useState<string>("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailErrors, setEmailErrors] = useState(false);

  const handleEmailsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    var emailIsErronous = false;
    const email = event.target.value;
    if (!email.includes("@") || !email.includes(".")) emailIsErronous = true;

    if (emailIsErronous) {
      setEmailErrors(true);
    } else {
      setEmailErrors(false);
    }
    setNewEmail(email);
  };

  useEffect(() => {
    if (userContext.userState.loggedIn) {
      getEmails();
    }
  }, []);

  function getEmails() {
    console.log("get emails");
  }

  function sendEmails(): void {
    setSendingEmail(true);
    postData(
      process.env.REACT_APP_API_URL + "/Dashboard/shareEmail",
      {
        name: emailName,
        email: newEmail,
        address: projectProps.data.address.trim(),
        shareLink:
          "https://share.solarwatcher.co.uk?ref=" + projectProps.data.reference,
      },
      true,
      userContext.getCredentials
    )
      .then((res) => {
        res.text();
        alert(
          "Email sent successfully to " +
            newEmail +
            " please check survey@absolar.co.uk for the BCC confirmation"
        );
        setEmailName("");
        setNewEmail("");
        setSendingEmail(false);
      })
      .catch((e) => {
        alert(
          "Failed to send email, please check survey inbox for BCC and then send manually"
        );
        setSendingEmail(false);
      });
  }

  const disableEditForDesignerSurveys = (projectReference: string): boolean => {
    const arr = projectReference.split("_");
    const number = parseInt(arr[arr.length - 1]);

    return number >= 5000;
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={3}>
      <>
        <Modal
          open={open_approved_installer}
          onClose={handleClose_approved_installer}
        >
          <ModalApprovedInstaller
            user={userContext.userState}
            reference={projectProps.data.reference}
          />
        </Modal>

        <Modal open={open_faq} onClose={handleClose_faq}>
          <ModalFAQ />
        </Modal>

        <Modal
          open={open_virtual_meeting}
          onClose={handleClose_virtual_meeting}
        >
          <div className="modal_container">
            <iframe
              src="https://app.usemotion.com/meet/absolar/solarmeeting"
              title="Virtual meeting"
              width="100%"
              height="100%"
            ></iframe>
          </div>
        </Modal>

        <Modal open={open_site_survey} onClose={handleClose_site_survey}>
          <div className="modal_container">
            <iframe
              src="https://app.usemotion.com/meet/absolar/sitevisit"
              title="Site survey"
              width="100%"
              height="100%"
            ></iframe>
          </div>
        </Modal>

        {Number(projectProps.data.reference.split("_")[2]) < 5150 ? <>
        {userContext.userState.loggedIn ? (
          <div
            style={{
              // background: "#e8e8e8",
              border: "lightgrey 1px solid",
              padding: "20px 15px",
              marginBottom: 15,
            }}
          >
            <div
              style={{
                marginLeft: "-10px",
                width: "100%",
                display: "flex",
              }}
            >
              <Switch
                {...share_switch}
                checked={shared}
                disabled={settingShared}
                onChange={(e) => {
                  updateShared(e.target.checked);
                }}
              />
              <Tooltip
                title={
                  <span style={{ fontSize: "16px", fontWeight: "400" }}>
                    This will make this webpage public and can be accessed by
                    anyone
                  </span>
                }
                placement="top-start"
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontFamily: "sans-serif",
                    fontWeight: "500",
                    paddingTop: "10px",
                    width: "250px",
                  }}
                >
                  Share this report
                </div>
              </Tooltip>
            </div>
            {shared ? (
              <div style={{ marginTop: 10 }}>
                Access link:{" "}
                <a
                  href={
                    "https://share.solarwatcher.co.uk/?ref=" +
                    projectProps.data.reference
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {"https://share.solarwatcher.co.uk/?ref=" +
                    projectProps.data.reference}
                </a>
              </div>
            ) : null}
            <hr />
            {shared ? (
              <>
                <Typography variant="body1">
                  <b>Email Link</b>
                </Typography>
                <TextField
                  value={emailName}
                  disabled={sendingEmail}
                  onChange={(e) => {
                    setEmailName(e.target.value);
                  }}
                  variant="filled"
                  label="Enter name of recipient"
                  placeholder=""
                />
                <TextField
                  value={newEmail}
                  disabled={sendingEmail}
                  onChange={handleEmailsChange}
                  variant="filled"
                  label="Enter their Email"
                  placeholder=""
                />
                {emailErrors ? (
                  <Typography
                    variant="body2"
                    style={{
                      color: "red",
                      fontSize: "10px",
                      fontStyle: "italic",
                    }}
                  >
                    Please enter a valid email address.
                  </Typography>
                ) : (
                  <></>
                )}
                <div style={{ marginTop: 20, textAlign: "center" }}>
                  <Button
                    fullWidth
                    disabled={
                      emailErrors ||
                      sendingEmail ||
                      newEmail === "" ||
                      emailName === ""
                    }
                    color="warning"
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={sendEmails}
                  >
                    {sendingEmail ? "Sending Email..." : "Send Emails"}
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        ) : null}

        {userContext.userState.username.includes("absolar.co.uk") ? (
          <LoadingOverlayWrapper active={updatingStatus}>
            <div
              style={{
                border: "lightgrey 1px solid",
                marginTop: 20,
                marginBottom: 20,
                padding: "20px 15px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel>Project Stage</InputLabel>
                <Select
                  fullWidth
                  value={projectStageFromDropDown}
                  label="Project Stage"
                  onChange={handleChange}
                >
                  {projectStages.map((stage) => (
                    <MenuItem
                      key={stage.projectStageID}
                      value={stage.projectStageID}
                    >
                      {stage.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {projectStageFromDropDown === "4" ? (
                <div style={{ marginTop: 20 }}>
                  Please enter the open solar url:
                  <TextField
                    fullWidth
                    value={openSolarUrl}
                    onChange={(e) => {
                      setOpenSolarUrl(e.target.value);
                      setIsValidLink(true); // Reset validation on change
                    }}
                    error={!isValidLink} // Apply error style based on validation
                    helperText={!isValidLink && "Please enter a valid link"} // Display error message
                  />
                  <div style={{ marginTop: 20, textAlign: "center" }}>
                    <Button
                      fullWidth
                      color="warning"
                      variant="contained"
                      style={{ textTransform: "none" }}
                      onClick={onClickSetOpenSolarUrl}
                    >
                      Save Link
                    </Button>
                  </div>
                </div>
              ) : null}
              {projectStageFromDropDown === "2" && (
                <div style={{ marginTop: 20 }}>
                  {surveyDateSetFlag ? (
                    <div>
                      <Typography variant="body2" gutterBottom>
                        Site survey scheduled on:
                      </Typography>
                      <Typography variant="h5" textAlign="center">
                        {siteSurveyDate}
                      </Typography>
                      {/* <input type="text" value={siteSurveyDate} readOnly /> */}
                      <Button
                        fullWidth
                        onClick={() => setSurveyDateSetFlag(false)}
                      >
                        Change
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Typography variant="body2">
                        Please enter the date:
                      </Typography>
                      <input
                        type="date"
                        value={siteSurveyDate}
                        className="date_picker"
                        onChange={(e) => {
                          setSiteSurveyDate(e.target.value);
                        }}
                      />
                      <div style={{ marginTop: 20, textAlign: "center" }}>
                        <Button
                          fullWidth
                          color="warning"
                          variant="contained"
                          style={{ textTransform: "none" }}
                          onClick={onClickSetSiteSurveyDate}
                        >
                          Set Schedule Date
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* {surveyDateSetFlag == true ? () : null} */}

              <div style={{ marginTop: 20, textAlign: "center" }}>
                <Button
                  fullWidth
                  color="warning"
                  variant="contained"
                  style={{ textTransform: "none" }}
                  onClick={onClickUpdateProjectStage}
                  disabled={
                    projectStageFromDropDown ===
                    projectDelivery?.projectStage.toString()
                  } // Call the handleChange function directly on button click
                >
                  Update Project Stage
                </Button>
              </div>
            </div>
          </LoadingOverlayWrapper>
        ) : null}
        <div
          style={{
            // position: userContext.isNatwest ? "relative" : "sticky",
            position: userContext.isNatwest ? "sticky" : "sticky",
            top: "5px",
          }}
        >
          {projectDelivery?.openSolarLink &&
          projectDelivery.openSolarLink.indexOf("https://") !== -1 &&
          projectDelivery.projectStage === 4 ? null : projectProps.data.cta ===
            2 ? (
            <>
              <div
                style={{
                  border: "lightgrey 1px solid",
                  marginTop: 20,
                  padding: "20px 15px",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <img src={mcs_image} style={{ width: "80px" }} />
                </div>
                <Typography variant="body2" gutterBottom>
                  View our approved installers tailored to your location and
                  building characteristics and contact them directly.
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  style={{ textTransform: "none", marginTop: 10 }}
                  onClick={handleOpen_approved_installer}
                >
                  View Approved Installers
                </Button>
                <Typography variant="body2" style={{ marginTop: 20 }}>
                  To find out more about Solar PV projects, use our{" "}
                  <Link
                    onClick={handleDrawerOpen_generalInfo}
                    style={{ cursor: "pointer" }}
                  >
                    Knowledge Base
                  </Link>{" "}
                  or{" "}
                  <Link
                    onClick={handleClickLink_infoContactUs}
                    style={{
                      textTransform: "none",
                      cursor: "pointer",
                    }}
                  >
                    Contact Absolar
                  </Link>{" "}
                  for more assistance.
                </Typography>
              </div>
            </>
          ) : projectDelivery && projectDelivery.projectStage !== 5 ? (
            <>
              <div style={{ border: "1px lightgrey solid" }}>
                <Accordion
                  expanded={expanded_cta === "panel_absolar"}
                  onChange={handleChange_cta("panel_absolar")}
                  style={{ margin: 0 }}
                >
                  <AccordionSummary
                    style={{
                      opacity: 1,
                      minHeight: "1px",
                      marginBottom: 0,
                      paddingBottom: 0,
                      textAlign: "center",
                    }}
                    disabled={expanded_cta === "panel_absolar"}
                    // expandIcon={
                    //   expanded_cta === "panel_absolar" ? null : (
                    //     <UnfoldMoreOutlinedIcon style={{ marginTop: 12 }} />
                    //   )
                    // }
                    sx={{
                      "& .MuiAccordionSummary-content": {
                        display: "grid",
                        marginBottom: 0,
                      },
                      "& .MuiAccordionSummary-expandIconWrapper": {
                        alignSelf: "flex-start",
                      },
                      backgroundColor:
                        expanded_cta === "panel_absolar" ? "white" : "#e8e8e8",
                    }}
                  >
                    <Typography variant="body1" marginBottom={1}>
                      <b>Find out more</b>
                    </Typography>
                    {expanded_cta === "panel_absolar" ? null : (
                      <>
                        <Link variant="body1" underline="none">
                          02382 680106
                        </Link>
                        <Link variant="body1" underline="none">
                          survey@absolar.co.uk
                        </Link>
                      </>
                    )}
                    {expanded_cta === "panel_absolar" ? null : (
                      <>
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: 10,
                            marginRight: -24,
                          }}
                        >
                          <ExpandMoreOutlinedIcon />
                        </div>
                      </>
                    )}
                  </AccordionSummary>
                  <AccordionDetails style={{ paddingTop: 0 }}>
                    <div style={{ textAlign: "center" }}>
                      <Link variant="body1" underline="none">
                        02382 680106
                      </Link>
                    </div>
                    <div style={{ textAlign: "center", marginBottom: 20 }}>
                      <Link
                        variant="body1"
                        href={
                          "mailto:survey@absolar.co.uk?subject=Solar assessment enquiry - " +
                          projectProps.data.reference.toUpperCase()
                        }
                      >
                        survey@absolar.co.uk
                      </Link>
                    </div>
                    <Button
                      color="success"
                      variant="contained"
                      fullWidth
                      style={{ textTransform: "none" }}
                      href={
                        "mailto:survey@absolar.co.uk?subject=Book a site survey - " +
                        projectProps.data.reference.toUpperCase()
                      }
                    >
                      Book a site survey
                    </Button>
                    {/* <Button
                        onClick={handleClick_infoTurnkey}
                        endIcon={<InfoOutlinedIcon />}
                        style={{
                          textTransform: "none",
                          padding: 0,
                          marginTop: 0,
                        }}
                      >
                        Turnkey project delivery{" "}
                      </Button> */}
                    {/* <Button endIcon={<InfoOutlinedIcon />} style={{textTransform:"none" , padding:0 , marginTop: 5}}>Project protection and warranty </Button> */}
                    <hr style={{ margin: "20px -15px" }} />
                    <Typography variant="body2" marginBottom={1}>
                      Absolar are renewable energy managers, designing and
                      delivering{" "}
                      <Button
                        onClick={handleClick_infoTurnkey}
                        variant="text"
                        style={{
                          textTransform: "none",
                          padding: 0,
                          marginTop: 0,
                        }}
                      >
                        turnkey solar PV installations
                      </Button>{" "}
                      through our in-house engineering teams and specialist
                      contractors.
                    </Typography>
                    <Typography variant="body2" marginBottom={1}>
                      Contact us to see how Absolar can help you realise the
                      full potential of solar energy, or book in a site visit to
                      get a full proposal for our{" "}
                      <Button
                        onClick={handleClick_infoTurnkey}
                        variant="text"
                        style={{
                          textTransform: "none",
                          padding: 0,
                          marginTop: 0,
                          minWidth: "1px",
                        }}
                      >
                        turnkey
                      </Button>{" "}
                      service.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {userContext.isNatwest ? (
                  <Accordion
                    expanded={expanded_cta === "panel2"}
                    onChange={handleChange_cta("panel2")}
                    style={{ margin: 0 }}
                  >
                    <AccordionSummary
                      style={{ opacity: 1 }}
                      disabled={expanded_cta === "panel2"}
                      expandIcon={
                        expanded_cta === "panel2" ? null : (
                          <UnfoldMoreOutlinedIcon style={{ marginTop: 40 }} />
                        )
                      }
                      sx={{
                        "& .MuiAccordionSummary-content": {
                          display: "grid",
                        },
                        "& .MuiAccordionSummary-expandIconWrapper": {
                          alignSelf: "flex-start",
                        },
                        paddingBottom: expanded_cta === "panel2" ? 0 : "15px",
                        backgroundColor:
                          expanded_cta === "panel2" ? "white" : "#e8e8e8",
                      }}
                    >
                      {expanded_cta === "panel2" ? null : (
                        <div
                          style={{
                            textAlign: "center",
                            marginRight: -24,
                          }}
                        >
                          <ExpandLessIcon />
                        </div>
                      )}
                      <Typography variant="body1">
                        <b>View Approved Installers</b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingBottom: 30 }}>
                      <div style={{ textAlign: "center" }}>
                        <img src={mcs_image} style={{ width: "80px" }} />
                      </div>
                      <Typography variant="body2" gutterBottom>
                        View our approved installers tailored to your location
                        and building characteristics and contact them directly.
                      </Typography>
                      <Button
                        fullWidth
                        variant="contained"
                        style={{ textTransform: "none", marginTop: 10 }}
                        onClick={handleOpen_approved_installer}
                      >
                        View Approved Installers
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                ) : null}
              </div>
            </>
          ) : (
            <></>
          )}
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Project Centre</Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </div> */}
          {/* <div
            style={{
              border: "lightgrey 1px solid",
              padding: "20px 15px",
              marginTop: 20,
            }}
          >
            <Typography variant="body2">
              Find answers to frequently asked questions about Solar PV projects
            </Typography>
            <Button
              fullWidth
              variant="contained"
              style={{ textTransform: "none", marginTop: 10 }}
              onClick={handleDrawerOpen_generalInfo}
            >
              Knowledge Base
            </Button>
          </div> */}

          <Menu
            anchorEl={infoTurnkey}
            open={open_infoTurnkey}
            onClose={handleClose_infoTurnkey}
          >
            <div
              style={{
                width: "90%",
                maxWidth: "300px",
                padding: 10,
                margin: "0px auto",
              }}
            >
              <Typography variant="body2" gutterBottom>
                <b>What is turnkey installation?</b>
              </Typography>
              <Typography variant="body2" gutterBottom>
                Fully managed design and installation process including
                procurement, scaffolding, risk management, on-site support and
                on-going optimisation. It ensures disruption is kept to a
                minimum, so you can focus on your business.
              </Typography>
              <Link
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDrawerOpen_generalInfo();
                  handleClose_infoTurnkey();
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Learn more about our project support.
                </Typography>
              </Link>
            </div>
          </Menu>

          <Menu
            anchorEl={infoContactUs}
            open={open_infoContactUs}
            onClose={handleClose_infoContactUs}
          >
            <div
              style={{
                width: "90%",
                maxWidth: "300px",
                padding: "10px 20px",
                margin: "0px auto",
              }}
            >
              <Typography variant="body2" gutterBottom>
                <b>Contact Details</b>
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary="survey@absolar.co.uk" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <LocalPhoneIcon />
                  </ListItemIcon>
                  <ListItemText primary="02382 680106" />
                </ListItem>
              </List>
            </div>
          </Menu>

          <Drawer
            anchor={"left"}
            open={drawerOpen_steps}
            onClose={handleDrawerClose_steps}
          >
            <div style={{ padding: "0px 20px" }}>
              {contentChanged_supportBar && (
                <Button
                  startIcon={<ArrowBackIcon />}
                  onClick={handleGoBack_supportBar}
                  style={{ marginTop: 10 }}
                >
                  Go Back
                </Button>
              )}
            </div>
            <ProjectLandingPage
              data={projectProps.data}
              user={userContext.userState}
              sideBarProps={sideBarProps}
            />
          </Drawer>
          <Drawer
            anchor={"left"}
            open={drawerOpen_generalInfo}
            onClose={handleDrawerClose_generalInfo}
            PaperProps={{
              sx: { width: "90%", maxWidth: "1000px" },
            }}
          >
            <div style={{ padding: 30 }}>
              <GeneralProjectInfo
                propertyData={projectProps.data}
                handleDrawerClose_generalInfo={handleDrawerClose_generalInfo}
              />
            </div>
          </Drawer>

          <Drawer
            anchor={"right"}
            open={drawerOpen}
            onClose={handleDrawerClose}
          >
            <div style={{ padding: "0px 20px" }}>
              {contentChanged_supportBar && (
                <Button
                  startIcon={<ArrowBackIcon />}
                  onClick={handleGoBack_supportBar}
                  style={{ marginTop: 10 }}
                >
                  Go Back
                </Button>
              )}
            </div>
            {content_supportBar}
          </Drawer>

          {/* files for the property */}
          {/* {file_names.some((eachFile) => eachFile.indexOf(projectProps.data.reference + "/") !== -1) || userContext.userState.username.includes("absolar.co.uk")? ( */}
          <div
            style={{
              border: "lightgrey 1px solid",
              marginTop: "20px",
              padding: "20px 15px",
            }}
          >
            <Typography variant="body2" style={{ marginBottom: "10px" }}>
              Project Specific Documents
            </Typography>

            {file_templates.length > 0 ? (
              <div style={{ marginTop: "5px" }}>
                {projectProps.data.reference === "ABS_27_3897" ||
                projectProps.data.reference === "ABS_27_3899" ||
                projectProps.data.reference === "ABS_27_3892" ? null : (
                  <Button
                    startIcon={<AttachFileIcon />}
                    disabled={projectProps.data.install_capacity_kw === 0}
                    onClick={() => projectProps.getPdf(projectProps.data)}
                    size="small"
                    fullWidth
                    style={{
                      textTransform: "none",
                      justifyContent: "flex-start",
                      padding: 0,
                      textAlign: "left",
                    }}
                  >
                    {"Feasibility Report"}
                  </Button>
                )}

                {file_templates.map((eachTemplate, index) =>
                  eachTemplate.is_uploaded ? null : ( // ) //   </Button> //     {eachTemplate.template.replace(/_/g, " ")} //   <Button startIcon={<AttachFileIcon />} onClick={() => eachTemplate.template.toLowerCase().includes("feasibility") ? projectProps.getPdf(projectProps.data) : null} size="small" fullWidth style={{ textTransform: "none", justifyContent: "flex-start", padding: 0 }} key={index}> //   : //   null // (userContext.userState.username.includes("absolar.co.uk") ? //-and if that button is "feasibility", download pdf //-show it as a normal button for the user to be able to interact with //now, a check for the average user: if a template file is marked as done:
                    <Tooltip
                      title={
                        <Typography variant="body2">
                          This document requires the completion of a site
                          survey. Please contact Absolar to arrange a site
                          survey
                        </Typography>
                      }
                    >
                      <span>
                        <Button
                          startIcon={<AttachFileIcon />}
                          disabled
                          size="small"
                          fullWidth
                          style={{
                            textTransform: "none",
                            justifyContent: "flex-start",
                            padding: 0,
                            textAlign: "left",
                          }}
                          key={index}
                        >
                          {eachTemplate.template.replace(/_/g, " ")}
                        </Button>
                      </span>
                    </Tooltip>
                  )
                )}
              </div>
            ) : (
              <></>
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
              {file_names.map((eachFile: string, index) => {
                if (eachFile.includes("images/")) return;
                if (
                  eachFile.indexOf(projectProps.data.reference + "/") !== -1
                ) {
                  return (
                    <Button
                      startIcon={<AttachFileIcon />}
                      key={index}
                      size="small"
                      onClick={() =>
                        projectProps.handleDownloadFile(
                          eachFile,
                          projectProps.data.reference
                        )
                      }
                      style={{
                        textTransform: "none",
                        justifyContent: "flex-start",
                        padding: 0,
                        textAlign: "left",
                      }}
                    >
                      {eachFile.replace(projectProps.data.reference + "/", "")}
                    </Button>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            {userContext.userState.username.includes("absolar.co.uk") ? (
              <>
                <div className="parentLabel" style={{ marginTop: "5px" }}>
                  <FileUploader
                    multiple={true}
                    handleChange={handleNewFileUpload}
                    name="file"
                  />
                </div>
                <div>
                  {newUploadedFiles.map((eachFile) => (
                    <div
                      key={eachFile.name}
                      className="fileBox"
                      style={{ padding: 5, marginTop: 10 }}
                    >
                      <Tooltip title={eachFile.name} placement="top">
                        <p style={{ margin: "0px" }}>{eachFile.name}</p>
                      </Tooltip>
                      <CloseIcon
                        style={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={() => handleRemoveNewFile(eachFile)}
                      />
                    </div>
                  ))}
                </div>
                {userContext.userState.username.includes("absolar.co.uk") ? (
                  <>
                    <Typography
                      variant="body2"
                      style={{ marginTop: 15, marginBottom: 5 }}
                    >
                      Template management (for Admin):
                    </Typography>
                    {file_templates.map((eachTemplate, index) => (
                      <div key={index}>
                        <Checkbox
                          size="small"
                          checkedIcon={<VisibilityOffIcon />}
                          icon={<VisibilityIcon />}
                          style={{ padding: 0 }}
                          defaultChecked={templatesChecked[index].is_uploaded}
                          value={templatesChecked[index].is_uploaded}
                          onChange={(e) => {
                            const updatedTemplates = [...templatesChecked];
                            updatedTemplates[index].is_uploaded =
                              e.target.checked;
                            setTemplatesChecked(updatedTemplates);
                          }}
                        />
                        <Typography variant="caption" style={{ marginLeft: 5 }}>
                          {eachTemplate.template.replace(/_/g, " ")}
                        </Typography>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}

                <Button
                  variant="outlined"
                  fullWidth
                  style={{ textTransform: "none", marginTop: "5px" }}
                  onClick={() => {
                    sendFileUpdatesToBackend(
                      [],
                      newUploadedFiles,
                      projectProps.data.reference,
                      userContext.userState.idToken,
                      userContext.getCredentials
                    ).finally(() => {
                      var arrayToAdd: string[] = [];
                      newUploadedFiles.forEach((file) => {
                        arrayToAdd.push(
                          projectProps.data.reference + "/" + file.name
                        );
                      });
                      setFile_names((prevFileNames) => [
                        ...prevFileNames,
                        ...arrayToAdd,
                      ]);

                      setNewUploadedFiles([]);

                      updateTemplateFilesStatus();
                    });
                  }}
                >
                  Update
                </Button>
              </>
            ) : null}
          </div>

          {projectDelivery && projectDelivery.projectStage !== 5 ? (
            <>
              <div
                style={{
                  marginTop: 10,
                  textAlign: "center",
                  justifyContent: "space-evenly",
                }}
              >
                {userContext.userState.loggedIn ? (
                  <Tooltip title="Save Property" placement="top-start">
                    <IconButton
                      aria-label="Customer support"
                      color="primary"
                      size="large"
                      onClick={() => {
                        handleClick_like_button(
                          projectProps.data.reference,
                          !projectProps.data.saved
                        );
                        projectProps.setSavingAnimation(true);
                        // Perform some async operation here
                        setTimeout(() => {
                          projectProps.setSavingAnimation(false);
                        }, 2000);
                      }}
                    >
                      {projectProps.data.saved ? (
                        <StarIcon />
                      ) : (
                        <StarOutlineIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                ) : (
                  <></>
                )}
                <Tooltip title="Help" placement="top-start">
                  <IconButton
                    aria-label="Customer support"
                    color="primary"
                    size="large"
                    onClick={() => {
                      // handleOpen_faq();
                      handleDrawerOpen_generalInfo();
                      // handleDrawerOpen();
                      trackClick("Help icon button");
                    }}
                  >
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Email Us" placement="top-start">
                  <IconButton
                    aria-label="Email us"
                    color="primary"
                    size="large"
                    href={
                      "mailto:survey@absolar.co.uk?subject=Solar Assessment Enquiry - " +
                      projectProps.data.reference.toUpperCase()
                    }
                    target="_blank"
                  >
                    <EmailIcon />
                  </IconButton>
                </Tooltip>
                {projectProps.data.open_solar_link &&
                projectProps.data.open_solar_link.indexOf("https://") !==
                  -1 ? null : (
                  <>
                    <Tooltip title="Print Information" placement="top-start">
                      <IconButton
                        aria-label="Print"
                        color="primary"
                        size="large"
                        onClick={() => {
                          window.print();
                          trackClick("Print icon button");
                        }}
                      >
                        <PrintIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Download Report" placement="top-start">
                      <IconButton
                        aria-label="Download PDF"
                        color="primary"
                        size="large"
                        disabled={projectProps.data.install_capacity_kw === 0}
                        onClick={() => {
                          projectProps.setLoadPdfDownloading(true);
                          handleDownloadReport(projectProps.data);
                          trackClick("Download report icon button page bottom");
                        }}
                      >
                        <DownloadForOfflineIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
              {userContext.isNatwest && projectDelivery.projectStage !== 4 ? (
                <div style={{ marginTop: 10, textAlign: "center" }}>
                  <Typography
                    // variant="body2"
                    style={{
                      textAlign: "left",
                      padding: 20,
                      fontSize: "12px",
                      color: "grey",
                    }}
                  >
                    This report has been provided to you as part of a pilot
                    Energy help and support scheme. To help us improve our
                    support available to you, please consider leaving us
                    feedback via a short survey
                  </Typography>
                  <Button
                    variant="outlined"
                    // color="success"
                    style={{
                      textTransform: "none",
                      color: "grey",
                      border: "lightgrey 1px solid",
                    }}
                    href="http://bit.ly/3zhQkI6"
                    target="_blank"
                  >
                    Feedback survey
                  </Button>
                </div>
              ) : null}
              {userContext.userState.username.includes("absolar.co.uk") ? (
                <div style={{ marginTop: 20, textAlign: "center" }}>
                  <Button
                    color="success"
                    variant="outlined"
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      navigate("/upload?upRef=" + projectProps.data.reference);
                    }}
                    disabled={disableEditForDesignerSurveys(
                      projectProps.data.reference
                    )}
                  >
                    Edit results
                  </Button>
                </div>
              ) : null}
            </>
          ) : (
            <></>
          )}
        </div>
        </> : 
        null}
      </>
    </Grid>
  );
}
