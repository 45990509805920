import React, { useContext, useState } from "react";
import { Drawer, Fab, Modal , Typography , IconButton } from "@mui/material";

import { putData } from "./get-data";
import { UserContext } from "./contexts/UserContext";
import ModalFAQ from "./infoPages/FaqModal";
import { ISideBarProps, SideBar } from "./project_page/side_bar";
import CloseIcon from "@mui/icons-material/Close";

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

interface IProps {
  propertyData: DashboardData;
  fileNames: string[];
  file_templates: { template: string; is_uploaded: boolean }[];
  projectDelivery: ProjectDeliveryDto;
  getPdf: (property: DashboardData | null) => void;
  handleDownloadFile: (fileName: string, reference: string) => void;

  dashboardDatas: DashboardData[];
  setDashboardDatas: (newdata: DashboardData[]) => void;
  setLoadPdfDownloading: (newBol: boolean) => void;
  sideBarProps: ISideBarProps;
}

export default function ProposalSentIframe(props: IProps) {
  type Anchor = "top" | "left" | "bottom" | "right";

  const userContext = useContext(UserContext);

  // const handleDrawerOpen_generalInfo = () => {
  //   // Implement the function as per your requirements
  // };

  // if (props.data.open_solar_link && props.data.open_solar_link.indexOf('https://') !== -1) {
  console.log("Inside ProposalSentIframe");
  console.log("List of files:", props.fileNames);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleSideBar = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  function trackClick(buttonClicked: string) {
    // code to track the click event
    gtag("event", buttonClicked, {
      event_category: "Button",
      event_label: buttonClicked,
    });
  }

  // const handleDrawerClose_generalInfo = () => {
  //   setIsDrawerOpen(false);
  // };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setComponentVisible(false);
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const [isComponentVisible, setComponentVisible] = useState(false);

  const handleKnowledgeBaseDrawerOpen = () => {
    setComponentVisible(true);
  };
  // This function is for the print option inside drawer. If we want, we can try to hide it and then print. Confirm what needs to be done
  // const [prinrDrawerVisible, setprintDrawerVisible] = useState(true);

  //   const handlePrint = () => {
  //     // Hide the drawer before printing
  //     setprintDrawerVisible(false);

  //     // Call window.print() to trigger the print dialog
  //     window.print();

  //     // Show the drawer after printing is done (you may need to add a delay if necessary)
  //     setTimeout(() => {
  //       setprintDrawerVisible(true);
  //     }, 100); // Adjust the delay as needed
  //   };

  const handleDrawerClose_generalInfo = () => {
    setComponentVisible(false);
    // setIsDrawerOpen(false);
  };

  const [open_faq, setOpen_faq] = useState(false);
  const handleOpen_faq = () => setOpen_faq(true);
  const handleClose_faq = () => setOpen_faq(false);

  const handleClick_like_button = (reference: string, toUpdateTo: boolean) => {
    //check if we need to update the individual data in teh state too?

    var allDashboards = props.dashboardDatas.map((dashboardEntry) =>
      dashboardEntry.reference === reference
        ? { ...dashboardEntry, saved: toUpdateTo }
        : dashboardEntry
    );
    props.setDashboardDatas(allDashboards);

    props.propertyData.saved = toUpdateTo;
    saveRow(reference, toUpdateTo);
  };

  function saveRow(reference: string, saved: boolean) {
    putData(
      process.env.REACT_APP_API_URL +
        "/Dashboard/saved?reference=" +
        reference +
        "&saved=" +
        saved,
      {},
      true,
      userContext.getCredentials
    )
      .then((res) => res.text())
      .catch((e) => alert("save failed"));
  }

  return (
    <div>
      <div
        style={{
          width: "100vw",
          height: "calc(100vh - 80px)",
          padding: "0px",
          margin: 0,
          position: "absolute",
          top: 70,
          zIndex: 10,
        }}
      >
        <iframe
          src={props.projectDelivery.openSolarLink}
          width="100%"
          height="100%"
          style={{ border: "none", zIndex: 10 }}
        ></iframe>
        <Fab
          color="success"
          onClick={handleSideBar}
          variant="extended"
          size="large"
          style={{
            float: "right",
            position: "fixed",
            padding: "20px 30px",
            fontSize: "18px",
            right: "40px",
            bottom: "40px",
            textTransform:"none",
          }}
        >
          <KeyboardArrowLeftIcon  sx={{ mr: 1 }} />
          Documents
        </Fab>
        {isDrawerOpen && (
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleSideBar}
            // onOpen={handleSideBar}
          >
            <div style={{ maxWidth: "500px", padding: 20 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6">Project Centre</Typography>
                <IconButton onClick={handleDrawerClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <SideBar {...props.sideBarProps} />
            </div>
          </Drawer>
        )}
      </div>

      {/* <div>
        <React.Fragment key="right">
          <Button onClick={toggleDrawer("right", true)}></Button>
          <Drawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {list("right")}
          </Drawer>
        </React.Fragment>
      </div> */}
      <Modal open={open_faq} onClose={handleClose_faq}>
        <ModalFAQ />
      </Modal>
    </div>
  );
}
