import { calculateCO2Savings } from "./calculateCO2Savings";
import { calculateAssumedTariffs } from "./calculateAssumedTariffs";
import { totalUtilityBillSavingConverter } from "./totalUtilityBillSavingConverter";

interface Props {
  selectedPolygon: FeatureCustomData;
  reference: string | undefined;
  onNext: (data: string) => void;
  inputData?: CalculationSheet | null;
  setCalculationSheet: (newData: CalculationSheet) => void;
  organisationId: number;
  setOrganisationId: (newNumber: number) => void;
  address: string;
}

/**
 * @description This is a function to use cal sheet to obtain ingressDo
 * This is used in "BusinessCasePage" to deliver data to "Review" in admin panel
 * @returns an object that contains data for review page
 * @Writer Unknown but refactored by Roy
 */
export function transformCalculationSheetToIngressDto(
  input: CalculationSheet,
  props: Props,
  savedDashboardData: DashboardData,
  solarPanelPowerOptions: SolarPanel[]
): IngressDto {
  console.log("test" + input.total_utility_bill_savings_20);

  const panelName = solarPanelPowerOptions.find(
    (panel) => parseInt(panel.solarPanelReference) === input.panelReference
  )?.solarPanelName;
  return {
    reference: input.projectReference,
    assessment_date: input.assessment_date
      ? input.assessment_date
      : savedDashboardData.assessment_date,
    root_uprn: "", // Fill in the appropriate value
    uprn_type: "", // Fill in the appropriate value
    toid:
      props.selectedPolygon.toid === ""
        ? savedDashboardData.toid
        : props.selectedPolygon.toid, // Fill in the appropriate value
    class_code: "", // Fill in the appropriate value
    class_desc: "", // Fill in the appropriate value
    class_sub_desc: input.class_sub_desc
      ? input.class_sub_desc
      : savedDashboardData.class_sub_desc,
    latitude: props.selectedPolygon.coordinates[0]
      ? props.selectedPolygon.coordinates[0].lat()
      : savedDashboardData.latitude, // Fill in the appropriate value
    longitude: props.selectedPolygon.coordinates[0]
      ? props.selectedPolygon.coordinates[0].lng()
      : savedDashboardData.longitude, // Fill in the appropriate value
    address: input.address ? input.address : savedDashboardData.address,
    postcode: "", // Fill in the appropriate value
    max_building_height:
      input.max_building_height === 0
        ? savedDashboardData.max_building_height
        : input.max_building_height,
    min_building_height:
      input.min_building_height === 0
        ? savedDashboardData.min_building_height
        : input.min_building_height,
    install_capacity_kw: input.kWpSystemCapacity || null,
    expected_power_generation_kwh: input.kWhAtYear0, // Fill in the appropriate value
    potential_saving_year_1: input.potential_saving_year_1 || 0,
    co2_saving_year_1: savedDashboardData
      ? savedDashboardData.co2_saving_year_1
      : calculateCO2Savings(input), // Fill in the appropriate value
    indicative_cost: input.indicative_cost || null,
    total_utility_bill_savings_20: totalUtilityBillSavingConverter(
      input.total_utility_bill_savings_20
    ),
    roi: input.roi || null,
    payback: input.payback,
    monthly_repayment: 0, // Fill in the appropriate value
    loan_total_utility_bill_savings_20:
      parseFloat(input.loan_total_utility_bill_savings_20) || 0,
    loan_payback: input.loan_payback,
    loan_roi: input.loan_roi || 0,

    /* There r issues with these 3 properties but the plan b is that they are stored in 
    inputData
    */
    council_property: input.council_property === "Yes", // Fill in the appropriate value
    conservation_area: input.conservation_area === "Yes", // Fill in the appropriate value
    listed: input.listed === "Yes", // Fill in the appropriate value

    organisation_id: input.organisationID
      ? input.organisationID
      : savedDashboardData.organisation_id,
    building_geom: "", // Fill in the appropriate value
    suitable_roof_geoms: [], // Fill in the appropriate value
    type_of_installation: input.installationType
      ? input.installationType
      : savedDashboardData.type_of_installation,
    self_consumption: Math.round(input.selfConsumption * 100),
    reference_panel: panelName ? panelName : "", // Fill in the appropriate value
    energy_price_inflation: input.electricityInflation * 100, // Fill in the appropriate value
    assum_carbon_factor: input.carbonFactor,
    assum_tariff: calculateAssumedTariffs(input), // Fill in the appropriate value
    existing_solar: false, // Fill in the appropriate value
    open_solar_link: "", // Fill in the appropriate value
    comments: input.comments || null,
    cta: input.cta || null,
  };
}
