import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Alert,
  Button,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import FetchOSData from "../components/toidSearchSteps/FetchOSData";
import SelectOSPolygon from "../components/toidSearchSteps/SelectOSPolygon";
import EditOSPolygon from "../components/toidSearchSteps/EditOSPolygon";
import GetImageCrops from "../components/toidSearchSteps/GetImageCrops";
import ReviewPage from "../components/toidSearchSteps/ReviewPage";

const Container = styled.div``;

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
`;

const Bottom = styled.div`
  margin-top: 10px;
`;

const AllStepsCompleted = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.h2``;

const StepContent = styled.div``;

const BackButton = styled.div`
  display: flex;
`;

interface IProps {
  activeStep?: number;
  project?: ProjectDeliveryDto;
  inputData: CalculationSheet | null;
  reference?: string;
  setCalculationSheet: (newData: CalculationSheet | null) => void;
  backButtonInnacesible: boolean;
  address: string;
  setOpenDialogueHandler: (newVal: string) => void;
  setDashboardDatas: React.Dispatch<React.SetStateAction<DashboardData[]>>;
}

const ToidSearch = (props: IProps) => {
  const [activeStep, setActiveStep] = useState(
    props.activeStep ? props.activeStep : 0
  );
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);
  const [featureCustomDataArray, setFeatureCustomDataArray] = useState<
    FeatureCustomData[]
  >([]);
  const [selectedPolygon, setSelectedPolygon] = useState<FeatureCustomData>({
    absoluteheightmaximum: 0,
    absoluteheightminimum: 0,
    absoluteheightroofbase: 0,
    coordinates: [],
    geometry_area: 0,
    heightconfidencelevel: "",
    osid: "",
    oslandusetiera: "",
    relativeheightmaximum: 0,
    relativeheightroofbase: 10, //CEO said that the roofheight should be 10 if there is no value obtained
    toid: "",
    oslandusetierb: [],
  });

  const [editedPolygonCoordinates, setEditedPolygonCoordinates] = useState<
    google.maps.LatLng[]
  >(selectedPolygon.coordinates);
  const [reference, setReference] = useState<string>();
  const [organisationId, setOrganisationId] = useState<number>(-1);
  const [address, setAddress] = useState<string>(props.address);
  const [postcode, setPostcode] = useState<string>("");
  const [dashboardDatas, setDashboardDatas] = useState<DashboardData[]>([]);
  const [originalCoordinateList, setOriginalCoordinateList] = useState<
    object[]
  >([]); // this state is used to store the orginal coordinate list for comparison study

  const [textFields, setTextFields] = useState([
    {
      category: "Building Info",
      subcategories: [
        {
          label: "Max Building Height",
          value: selectedPolygon.absoluteheightmaximum.toString(),
        },
        {
          label: "Min Building Height",
          value: selectedPolygon.absoluteheightminimum.toString(),
        },
        // { label: "Building Classification", value: selectedPolygon.oslandusetiera },
      ],
    },
    {
      category: "Inputs",
      subcategories: [
        { label: "Cost Inflation %", value: "5.00" },
        { label: "Import Rate £", value: "0.32" },
        { label: "Export Rate £", value: "0.12" },
      ],
    },
  ]);

  const handleNextStep1 = (
    bounds: google.maps.LatLngBounds,
    featureCustomDataArray: FeatureCustomData[]
  ) => {
    setBounds(bounds);
    setFeatureCustomDataArray(featureCustomDataArray);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNextStep2 = (polygon: FeatureCustomData | null) => {
    if (polygon) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSelectedPolygon(polygon);
      const originalList = polygon.coordinates.map((x) => {
        return {
          latitude: x.lat(),
          longitude: x.lng(),
        };
      });
      setOriginalCoordinateList(originalList);

      //now update the textfields from the canvas page as well - with the valus from the selected polygon
      const updatedTextFields = [...textFields];
      updatedTextFields[0].subcategories[0].value =
        polygon.absoluteheightmaximum.toString();
      updatedTextFields[0].subcategories[1].value =
        polygon.absoluteheightminimum.toString();
      // updatedTextFields[0].subcategories[2].value =
      // polygon.oslandusetiera;
      setTextFields(updatedTextFields);
    }
  };

  const handleNextStep3 = (editedPolygonCoordinates: google.maps.LatLng[]) => {
    setEditedPolygonCoordinates(editedPolygonCoordinates);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNextStep4 = (data: String) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNextStep5 = (data: String) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep - 1 == 2) {
      props.setCalculationSheet(null);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNextStep6 = (data: String) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const skipStep = (data: String) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 2);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps = ["Search", "Select", "Edit", "Assess", "Review"];

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <FetchOSData
            onNext={handleNextStep1}
            setAddress={setAddress}
            setCalculationSheet={props.setCalculationSheet}
            setPostcode={setPostcode}
            reference={reference}
            setReference={setReference}
            organisationId={organisationId}
            setOrganisationId={setOrganisationId}
          />
        );
      case 1:
        return (
          <SelectOSPolygon
            onNext={handleNextStep2}
            bounds={bounds}
            featureCustomDataArray={featureCustomDataArray}
          />
        );
      case 2:
        return (
          <EditOSPolygon
            onNext={handleNextStep3}
            polygon={selectedPolygon}
            reference={reference}
          />
        );
      case 3:
        return (
          <GetImageCrops
            editedPolygonCoordinates={editedPolygonCoordinates} // new coordinates
            onNext={handleNextStep4}
            selectedPolygon={selectedPolygon} //Roy thinks this prop is as same as editedPolygonCoordinates, which needs to be checked when doing optimization
            originalPolygonCoordinates={originalCoordinateList} // original coordinates
            setSelectedPolygon={setSelectedPolygon}
            reference={reference}
            setReference={setReference}
            organisationId={organisationId}
            setOrganisationId={setOrganisationId}
            address={address}
            textFields={textFields}
            setTextFields={setTextFields}
            setOpenDialogueHandler={props.setOpenDialogueHandler}
            inputData={props.inputData}
            setCalculationSheet={props.setCalculationSheet}
            setDashboardDatas={props.setDashboardDatas}
          />
        );
      case 4:
        return (
          <ReviewPage
            reference={props.inputData ? props.inputData.projectReference : ""}
            onNext={handleNextStep5}
            dashboardDatas={dashboardDatas}
            setDashboardDatas={setDashboardDatas}
            setOpenDialogueHandler={props.setOpenDialogueHandler}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <Main>
        <MainContainer>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Bottom>
            {activeStep === steps.length ? (
              <AllStepsCompleted>
                <HeaderText>All steps completed</HeaderText>
                {editedPolygonCoordinates.map((coordinate, index) => (
                  <p key={index}>{`Coordinate ${
                    index + 1
                  }: lat: ${coordinate.lat()}, lng: ${coordinate.lng()}`}</p>
                ))}
                <Button onClick={handleReset}>Reset</Button>
              </AllStepsCompleted>
            ) : (
              <StepContent>
                <div style={{ textAlign: "left", marginBottom: 20 }}>
                  <Button
                    onClick={handleBack}
                    disabled={
                      activeStep === 0 ||
                      (activeStep == 4 && props.backButtonInnacesible)
                    }
                  >
                    Back
                  </Button>
                </div>
                {getStepContent(activeStep)}
              </StepContent>
            )}
          </Bottom>
        </MainContainer>
      </Main>
    </Container>
  );
};

export default ToidSearch;
