export const calculateAssumedTariffs = (inputData: CalculationSheet) => {
  return (
    (inputData.importRate * 100).toFixed(1) +
    "p import" +
    " / " +
    " " +
    (inputData.exportRate * 100).toFixed(1) +
    "p export "
  );
};
