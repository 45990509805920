import React, { useState } from "react";

import { Typography } from "@mui/material";

export default function SupportPageFinancial() {
  return (
    <>
      <Typography
        variant="h6"
        style={{ marginTop: 30, borderBottom: "1px grey solid" }}
        gutterBottom
        paragraph
      >
        Business Case: 5 Case Model
      </Typography>
      {/* <Typography variant="body2" paragraph>
        <strong>[content - financial assessment]</strong>{" "}
      </Typography> */}
      <Typography variant="body2" paragraph>
        We use the 5-case model to help organisation review the feasibility of
        each project, addressing the impacts from the following aspects:
      </Typography>
      <Typography>
        <b>1. Strategic Case</b>
      </Typography>
      <Typography variant="body2" paragraph>
        The investment in rooftop solar PV systems aligns with most
        organisations’ strategic objectives of reducing carbon emissions and
        demonstrating a commitment to sustainability. By investing in renewable
        energy sources, the organization can reduce its carbon footprint, meet
        regulatory requirements, and improve its brand image. Additionally, the
        investment in solar PV systems can provide a competitive advantage by
        reducing energy costs and improving energy independence.
      </Typography>
      <Typography>
        <b>2. Economic Case</b>
      </Typography>
      <Typography variant="body2" paragraph>
        The investment in rooftop solar PV systems can result in significant
        cost savings for an organisation. By generating its own electricity, the
        organisation can reduce its reliance on the grid and lower its energy
        bills. Additionally, if the system generates excess electricity, Absolar
        could help the organisation sell it back to energy suppliers and
        generate revenue. The cost of solar PV systems has decreased
        significantly in recent years, making it a more affordable option for
        organisations. The investment can also qualify for tax incentives,
        further reducing the overall cost.
      </Typography>
      <Typography>
        <b>3. Commercial Case</b>
      </Typography>
      <Typography variant="body2" paragraph>
        Investing in rooftop solar PV systems can improve an organisation's
        brand image and attract environmentally conscious customers and
        investors. By demonstrating a commitment to sustainability and reducing
        its carbon footprint, the organisation can differentiate itself from its
        competitors. Additionally, investing in renewable energy sources can
        help the organisation prepare for future regulatory requirements and
        reduce the risk of energy price volatility.
      </Typography>
      <Typography>
        <b>4. Financial Case</b>
      </Typography>
      <Typography variant="body2" paragraph>
        The investment in rooftop solar PV systems can provide a positive return
        on investment. By reducing energy costs and potentially generating
        revenue, the system can pay for itself in a few years. Additionally, the
        investment can provide a hedge against future energy price increases and
        reduce the organization's exposure to energy price volatility. Although
        the system requires ongoing maintenance, the maintenance costs are
        generally lower than the savings generated by the system.
      </Typography>
      <Typography>
        <b>5. Management Case</b>
      </Typography>
      <Typography variant="body2" paragraph>
        The investment in rooftop solar PV systems requires an organisation to
        manage the installation and ongoing maintenance of the system. Absolar
        provides fully managed services in both installation and maintenance.
      </Typography>
    </>
  );
}
