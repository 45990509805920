import React, { useState, useEffect, useContext } from "react";
import { Button, TextField, Modal, Box, Grid } from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";
import MapComponent from "../../Map";
import { UserContext } from "../../contexts/UserContext";
import RequestAssessment from "../../RequestAssessment";
import { Divider } from "semantic-ui-react";

import "./FoundPropertiesProject.css";
import { AppContext } from "../../contexts/AppContext";
import { ResultsTable } from "./ResultsTable";

interface IProps {
  dashboardDatas: DashboardData[];
  setSearchDatas: (newdata: DashboardData[]) => void;
  isAdmin: boolean;
  defaultRows: number;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  searchData: (searchOverride?: string | null) => void;
  resetSearch: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function FoundPropertiesProject(props: IProps) {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  useEffect(() => {
    document.title = "Absolar solar dashboard";
  }, []);
  // const history = useHistory();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [currentProp, setCurrentProp] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.defaultRows);

  const [open, setOpen] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<{ lat: number; lon: number }>({
    lat: 0,
    lon: 0,
  });
  const [loadingMap, setLoadingMap] = useState<boolean>(true);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setCurrentProp(newPage * rowsPerPage);
  };

  function setMap(property: DashboardData) {
    setCoordinates({
      lat: property.latitude ? property.latitude : 50,
      lon: property.longitude ? property.longitude : -1,
    });
    setOpen(true);
    setLoadingMap(true);
  }

  function handleDownloadCSV() {
    var dataForCSV = props.dashboardDatas;
    var columns = [
      "address",
      "reference",
      "assessment_date",
      // "root_uprn",
      // "uprn_type",
      // "toid",
      "class_code",
      "class_desc",
      "class_sub_desc",
      "latitude",
      "longitude",
      "postcode",
      "max_building_height",
      "min_building_height",
      "install_capacity_kw",
      "expected_power_generation_kwh",
      "potential_saving_year_1",
      "co2_saving_year_1",
      "indicative_cost",
      "total_utility_bill_savings_20",
      "roi",
      "payback",
      // "monthly_repayment",
      // "loan_total_utility_bill_savings_20",
      // "loan_payback",
      // "loan_roi",
      "council_property",
      "conservation_area",
      "listed",
      "organisation_id",
      "type_of_installation",
      "self_consumption",
      "reference_panel",
      "energy_price_inflation",
      "assum_carbon_factor",
      "assum_tariff",
      "stage",
      // "saved",
      // "existing_solar",
      // "open_solar_link",
      // 'site_survey_date',
    ];

    var columnHeadlines = [
      "Address",
      "Reference",
      "Assessment Date",
      // "Root UPRN",
      // "UPRN Type",
      // "Toid",
      "Class Code",
      "Class Description",
      "Sub-class Description",
      "Latitude",
      "Longitude",
      "Postcode",
      "Max Building Height (m)",
      "Min Building Height (m)",
      "Install Capacity (kW)",
      "Expected Power Generation (kWh/year)",
      "Potential Cost Saving in Year 1 (GBP)",
      "CO2 Saving in Year 1 (Tonne)",
      "Indicative Cost (GBP)",
      "Total Utility Bill Savings 20 (GBP)",
      "ROI (%)",
      "Payback period",
      // "Monthly Repayment",
      // "Loan Total Utility Bill Savings 20",
      // "Loan Payback",
      // "Loan ROI",
      "Council Property",
      "Conservation Area",
      "Listed",
      "Organisation ID",
      "Type of Installation",
      "Self Consumption (%)",
      "Reference Panel",
      "Energy Price Inflation (%)",
      "Assumption Carbon Factor (kg/kWh)",
      "Assumption Tariff",
      "Stage",
      // "Saved",
      // "Existing Solar",
      // "Open Solar Link",
      // 'Site Survey Date',
    ];

    var fileName =
      appContext.organisations.filter(
        (orgObject) => orgObject.id == props.dashboardDatas[0].organisation_id
      )[0].display_name + " Properties.csv";
    const csvRows = [];
    // Add the column headers to the CSV
    csvRows.push(columnHeadlines.join(","));

    // Loop through each row of data
    dataForCSV.forEach((item) => {
      const values = columns.map((col) => {
        // If the value of the column is an object, convert it to a string

        var coll = col as keyof typeof item;
        return `"${item[coll]?.toString()}"`;
      });

      // Add the values to the CSV
      csvRows.push(values.join(","));
    });

    const csvString = csvRows.join("\n");

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const [isRequestAssessmentModalOpen, setIsRequestAssessmentModalOpen] =
    React.useState(false);

  return (
    <div style={{ overflow: "auto" }}>
      <Grid container justifyContent="space-between" style={{ paddingTop: 20 }}>
        <Grid item md={10} xs={12} container>
          <Grid item md={7} xs={12}>
            <TextField
              size="small"
              label="🔍 Search by reference, postcode or address"
              className="inner_container"
              value={props.search}
              onChange={(e) => {
                props.setSearch(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item md={5} xs={12} style={{ textAlign: "left" }}>
            <Button
              className="inner_container"
              style={{ margin: "0px 10px" }}
              onClick={() => {
                props.searchData();
                setPage(0);
                setCurrentProp(0);
              }}
            >
              Search
            </Button>
            <Button
              className="inner_container"
              style={{ margin: "0px 10px" }}
              onClick={() => {
                props.resetSearch();
                setPage(0);
                setCurrentProp(0);
              }}
            >
              reset text search
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} style={{ textAlign: "right" }}>
          <Button
            endIcon={<DownloadIcon />}
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={handleDownloadCSV}
          >
            Download CSV
          </Button>
        </Grid>
      </Grid>

      <ResultsTable
        {...props}
        page={page}
        handleChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        currentProp={currentProp}
        setMap={setMap}
      />
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setLoadingMap(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="outer_map_container">
          {loadingMap && coordinates.lat != 0 ? (
            <div className="map_loading">
              <div className="loading_overlay">
                <CircularProgress />
                <div>Loading</div>
              </div>
            </div>
          ) : null}
          <div className="map_container">
            {appContext.mapDatas.length > 0 ? (
              <MapComponent
                mapData={appContext.mapDatas}
                lat={coordinates.lat}
                lng={coordinates.lon}
                zoom={19}
                handleToidSelect={appContext.handleToidSelect}
              />
            ) : null}
          </div>
        </Box>
      </Modal>
      <Modal
        open={isRequestAssessmentModalOpen}
        onClose={() => {
          setIsRequestAssessmentModalOpen(false);
        }}
        // disableEnforceFocus
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="assessmentModal">
          <h3 style={{ paddingLeft: "50px" }}>Requesting a new Assessment</h3>
          <Divider
            style={{ backgroundColor: "#888888", height: "1px" }}
          ></Divider>
          <RequestAssessment
            userCompany={
              userContext.userInfo.company ? userContext.userInfo.company : ""
            }
            userEmail={userContext.userInfo.email}
            userName={
              userContext.userInfo.name ? userContext.userInfo.name : ""
            }
          ></RequestAssessment>
        </Box>
      </Modal>
    </div>
  );
}
