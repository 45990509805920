import React, { useState } from "react";

import { Typography, Grid , CardActionArea } from "@mui/material";
import Tech_image from "../images/tech_background.png";

export default function InfoMinimiseDisturb() {
  return (
    <>
      <Typography
        variant="h5"
        style={{ marginTop: 20, borderBottom: "1px grey solid" }}
        gutterBottom
        paragraph
      >
        How we minimise the disturbance to your business operation
      </Typography>
      <Typography variant="body2" paragraph>
      Our operations are designed to minimise disturbance to business operations during a rooftop solar PV installation. We understand that maintaining a smooth workflow and minimising disruptions is crucial for businesses, which is why we prioritise a streamlined and efficient installation process. Here's how we achieve minimal disturbance: 
      </Typography>
      
      <Typography variant="body2" paragraph>
        <ol>
          <li style={{marginBottom:10}}>
          Planning and Scheduling: We work closely with the business to carefully plan and schedule the installation process. We take into account the business's operational needs, peak periods, and any specific requirements or preferences. By aligning our installation timeline with the business's schedule, we aim to minimise disruption to daily operations. 
          </li>
          <li style={{marginBottom:10}}>
          Pre-Installation Site Assessment: Prior to the installation, we conduct a comprehensive site assessment to identify any potential challenges or areas that may require special attention. This includes evaluating access points, logistics, and any specific considerations related to the business's operations. By identifying and addressing potential issues in advance, we minimise unexpected disruptions during the installation. 
          </li>
          <li style={{marginBottom:10}}>
          Efficient Installation Techniques: Our experienced installation team utilises efficient techniques to ensure swift and organised installation. We prioritise safety and professionalism, employing skilled technicians who strive to complete the installation process with minimal disruption to the business's regular activities. 
          </li>
          <li style={{marginBottom:10}}>
          Effective Communication: Clear and transparent communication is essential throughout the installation process. We maintain open lines of communication with the business, providing regular updates on the progress of the installation, addressing any concerns promptly, and ensuring that all parties are well-informed and prepared. 
          </li>
          <li style={{marginBottom:10}}>
          Safety Measures: We prioritise safety during the installation, adhering to all relevant safety guidelines and regulations, including making sure you are aware of your responsibilities as the client. 
          </li>
          <li style={{marginBottom:10}}>
          Post-Installation Support: Once the installation is complete, we provide comprehensive post-installation support to address any remaining concerns or issues. We offer guidance on how to effectively manage and monitor the solar PV system, ensuring that the business can seamlessly integrate it into its operations. 
          </li>
        </ol>
      </Typography>
      <Typography variant="body2" paragraph>
        By implementing these measures, we aim to complete the rooftop solar PV installation with minimal disturbance to the business's day-to-day operations. Our goal is to provide a seamless experience that allows the business to continue its activities uninterrupted while benefiting from the advantages of clean and renewable solar energy.
      </Typography>
      
    </>
  );
}
